<script type="text/ecmascript-6">
import { mapGetters } from 'vuex';
import Bloodhound from 'corejs-typeahead/dist/bloodhound';
import typeahead from 'corejs-typeahead/dist/typeahead.jquery';
import Notifications from '../components/Component/Notification.vue'
import Cookies from 'js-cookie'

export default {
    /**
     * The component's data.
     */
    data: () => ({
        q: '',
        notifications: [],
        upcoming_notifications: [],
        suggestions: null,
        noti_count: Cookies.get('melo_noti_count'),
        noti_loading: false,
        price: '',
        check_artists: null,
        deep_link: {
            url: null,
        },
    }),
    components: {
        Notifications
    },

    /**
     * Computed these properties.
     */
    computed: {
        ...mapGetters({
            user: 'getProfile',
            isAuthenticated: 'isAuthenticated',
            history: 'getHistory',
        }),
    },

    /**
     * Created the component.
     */
    created() {
        // console.log(this.noti_count);
        // if (this.isAuthenticated) {
        //     this.getNotifications();
        // }

        let self = this;
        this.$nextTick(() => {
            $(".ms_admin_name").on('click', function () {
                console.log('click admin menu');
                $(".pro_dropdown_menu").toggleClass("open_dropdown");
            });

            if (self.$route.name == 'Album') {
                self.getDeeplinkData('albums', self.$route.params.uuid);
            } else if (self.$route.name == 'artist') {
                self.getDeeplinkData('artists', self.$route.params.id);
            } else if (self.$route.name == 'playlist-detail') {
                self.getDeeplinkData('playlists', self.$route.params.id);
            } else if (self.$route.name == 'my-play-list') {
                self.getDeeplinkData('user-playlists', self.$route.params.id);
            }
            else {
                this.deep_link.url = "https://melomm.app.link/KTCSS9W6t5";
            }
        });
    },

    mounted() {
        this.$nextTick(() => {
            $(".popper-header").on('click', function () {
                console.log('Click Read All');
            });

            let self = this;

            var artist = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace,
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: '/api/v2/search',
                    prepare: function (query, settings) {
                        settings.url = settings.url + '?q=' + query
                        settings.headers = {
                            "api-key": 'yC5wfrIBSh7hrIHd4tvPmsAdId0cQwqkQjf3frqtdCP5Sd7Kbj2tGjyDCp4vJrgB'
                        };
                        return settings;
                    },
                    wildcard: '%QUERY%',
                    transform: function (data) {
                        self.check_artists = data.artists;
                        return data.artists;
                    }
                },
            });

            var song = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace,
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: '/api/v2/search',
                    prepare: function (query, settings) {
                        settings.url = settings.url + '?q=' + query
                        settings.headers = {
                            "api-key": 'yC5wfrIBSh7hrIHd4tvPmsAdId0cQwqkQjf3frqtdCP5Sd7Kbj2tGjyDCp4vJrgB'
                        };
                        return settings;
                    },
                    wildcard: '%QUERY%',
                    transform: function (data) {
                        return data.songs;
                    }
                },
            });

            var album = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace,
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: '/api/v2/search',
                    prepare: function (query, settings) {
                        settings.url = settings.url + '?q=' + query
                        settings.headers = {
                            "api-key": 'yC5wfrIBSh7hrIHd4tvPmsAdId0cQwqkQjf3frqtdCP5Sd7Kbj2tGjyDCp4vJrgB'
                        };
                        return settings;
                    },
                    wildcard: '%QUERY%',
                    transform: function (data) {
                        return data.albums.slice(0, 5);
                    }
                },
            });

            var collections = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace,
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: '/api/v2/search',
                    prepare: function (query, settings) {
                        settings.url = settings.url + '?q=' + query
                        settings.headers = {
                            "api-key": 'yC5wfrIBSh7hrIHd4tvPmsAdId0cQwqkQjf3frqtdCP5Sd7Kbj2tGjyDCp4vJrgB'
                        };

                        return settings;
                    },
                    wildcard: '%QUERY%',
                    transform: function (data) {
                        return data.collections.slice(0, 5);
                    }
                },
            });
            var playlists = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace,
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: '/api/v2/search',
                    prepare: function (query, settings) {
                        settings.url = settings.url + '?q=' + query
                        settings.headers = {
                            "api-key": 'yC5wfrIBSh7hrIHd4tvPmsAdId0cQwqkQjf3frqtdCP5Sd7Kbj2tGjyDCp4vJrgB'
                        };
                        return settings;
                    },
                    wildcard: '%QUERY%',
                    transform: function (data) {
                        return data.playlists.slice(0, 5);
                    }
                },
            });

            var my_playlists = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace,
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: '/api/v2/search',
                    prepare: function (query, settings) {
                        settings.url = settings.url + '?q=' + query
                        settings.headers = {
                            "api-key": 'yC5wfrIBSh7hrIHd4tvPmsAdId0cQwqkQjf3frqtdCP5Sd7Kbj2tGjyDCp4vJrgB'
                        };
                        return settings;
                    },
                    wildcard: '%QUERY%',
                    transform: function (data) {
                        // return data.my_playlists.slice(0,5);
                        return;
                    }
                },
            });

            let inputEl = $('#search');

            inputEl.typeahead({
                minLength: 0,
                hint: false,
                // highlight: true,
                autoselect: true,
            },
                {
                    name: 'history',
                    display: function (data) {
                        return data.keyword;
                    },
                    source: self.suggestionsWithDefaults,
                    templates: {
                        empty: '',
                        header: '<h3 class="league-name">History<a href="#" id="clear-history" class="float-right" style="color:#dc3545">Clear all</a></h3>',
                        suggestion: function (data) {
                            return '<a href="/search?q=' + data.keyword + '" data-query="' + data.keyword + '" data-type="history" style="font-weight:normal; margin-top:10px ! important;border:0;" class="list-group-item"><div>' + data.keyword + '</div></div></a>'
                        }
                    }
                },
                {
                    name: 'artist',
                    display: function (data) {
                        if (data.name_en === null) {
                            return data.name_mm  //Input value to be set when you select a suggestion.
                        }

                        return data.name_en  //Input value to be set when you select a suggestion.
                    },
                    source: artist,
                    templates: {
                        empty: '',
                        header: '<h3 class="league-name">Artists</h3>',
                        suggestion: function (data) {
                            const image = `<img src='${data.image}' alt='${data.name_mm}' class='search-artist-image' onError="this.onerror=null;this.src='/images/album/Artist.png';"/>`;
                            if (data.artist_name_eng === null) {
                                return '<a href="/artist/' + data.uuid + '" data-query="' + data.uuid + '" data-type="artist" style="font-weight:normal; margin-top:10px ! important;border:0;" class="list-group-item"><div>' + image + data.name_mm + '</div></div></a>'
                            }

                            return '<a href="/artist/' + data.uuid + '" data-query="' + data.uuid + '" data-type="artist" style="font-weight:normal; margin-top:10px ! important;border:0;" class="list-group-item"><div>' + image + data.name_en + '</div></div></a>'
                        }
                    }
                },
                {
                    name: 'song',
                    display: function (data) {
                        if (data.name_mm === null) {
                            return data.name_eng  //Input value to be set when you select a suggestion.
                        }

                        return data.name_mm  //Input value to be set when you select a suggestion.

                    },
                    source: song,
                    templates: {
                        empty: '',
                        header: '<h3 class="league-name">Songs</h3>',
                        suggestion: function (data) {
                            const image = `<img src='${data.album_image}' alt='${data.album_name_mm}' class='search-album-image' onError="this.onerror=null;this.src='/images/album/Album.png';"/>`;
                            if (data.name_mm === null) {
                                return '<a href="/album/' + data.album_uuid + '" data-query="' + data.album_uuid + '" data-type="song" style="font-weight:normal; margin-top:10px ! important;border:0;" class="list-group-item"><div class="d-flex">' + image + '<div class="d-inline-block">' + data.name_en + '<p style="font-size: 0.8em">' + data.artists.map(x => x.name_en).join(", ") + '</p></div></div></div></a>'
                            }

                            return '<a href="/album/' + data.album_uuid + '" data-query="' + data.album_uuid + '" data-type="song" style="font-weight:normal; margin-top:10px ! important;border:0;" class="list-group-item"><div class="d-flex">' + image + '<div class="d-inline-block">' + data.name_mm + '<p style="font-size: 0.8em">' + data.artists.map(x => x.name_en).join(", ") + '</p></div></div></div></a>'

                        }
                    }
                },
                {
                    name: 'album',
                    display: function (data) {
                        if (data.name_mm === null) {
                            return data.name_eng  //Input value to be set when you select a suggestion.
                        } else {
                            return data.name_mm  //Input value to be set when you select a suggestion.
                        }
                    },
                    source: album,
                    templates: {
                        empty: '',
                        header: '<h3 class="league-name">Albums</h3>',
                        suggestion: function (data) {
                            const image = `<img src='${data.image}' alt='${data.name_mm}' class='search-album-image' onError="this.onerror=null;this.src='/images/album/Album.png';"/>`;

                            if (data.name_mm === null) {
                                return '<a href="/album/' + data.uuid + '" data-query="' + data.uuid + '" data-type="album" style="font-weight:normal; margin-top:10px ! important;border:0;" class="list-group-item">' +
                                    '<div class="d-flex">' + image + '<div class="d-inline-block">' + data.name_eng + '<p style="font-size: 0.8em">' + data.str_artists + '</p></div></div></div>' +
                                    '</a>'
                            }

                            return '<a href="/album/' + data.uuid + '" data-query="' + data.uuid + '" data-type="album" style="font-weight:normal; margin-top:10px ! important;border:0;" class="list-group-item"><div class="d-flex">' + image + '<div class="d-inline-block">' + data.name_mm + '<p style="font-size: 0.8em">' + data.str_artists + '</p></div></div></div></a>'

                        }
                    }
                },
                {
                    name: 'playlists',
                    display: function (data) {
                        if (data.name_mm === null) {
                            return data.name_eng  //Input value to be set when you select a suggestion..
                        } else {
                            return data.name_mm  //Input value to be set when you select a suggestion
                        }
                    },
                    source: playlists,
                    templates: {
                        empty: '',
                        header: '<h3 class="league-name">Playlist</h3>',
                        suggestion: function (data) {
                            const image = `<img src='${data.image}' alt='${data.name_mm}' class='search-album-image' onError="this.onerror=null;this.src='/images/album/collection_playlist.png';"/>`;

                            if (data.name_mm === null) {
                                return '<a href="/playlist/' + data.uuid + '" data-query="' + data.uuid + '" data-type="playlists" style="font-weight:normal; margin-top:10px ! important;border:0;" class="list-group-item"><div>' + image + data.name_eng + '</div></div></a>'
                            }

                            return '<a href="/playlist/' + data.uuid + '" data-query="' + data.uuid + '" data-type="playlists" style="font-weight:normal; margin-top:10px ! important;border:0;" class="list-group-item"><div>' + image + data.name_mm + '</div></div></a>'

                        }
                    }
                },
                {
                    name: 'collections',
                    display: function (data) {
                        if (data.name_mm === null) {
                            return data.name_eng  //Input value to be set when you select a suggestion..
                        } else {
                            return data.name_mm  //Input value to be set when you select a suggestion
                        }
                    },
                    source: collections,
                    templates: {
                        empty: '',
                        header: '<h3 class="league-name">Collections</h3>',
                        suggestion: function (data) {
                            const image = `<img src='${data.image}' alt='${data.name_mm}' class='search-album-image' onError="this.onerror=null;this.src='/images/album/collection_playlist.png';"/>`;

                            if (data.name_mm === null) {
                                return '<a href="/collection/' + data.id + '" data-query="' + data.id + '" data-type="collections" style="font-weight:normal; margin-top:10px ! important;border:0;" class="list-group-item"><div>' + image + data.name_eng + '</div></div></a>'
                            }

                            return '<a href="/collection/' + data.id + '" data-query="' + data.id + '" data-type="collections" style="font-weight:normal; margin-top:10px ! important;border:0;" class="list-group-item"><div>' + image + data.name_mm + '</div></div></a>'

                        }
                    }
                },
                {
                    name: 'my_playlists',
                    display: function (data) {
                        // if (data.name_mm === null) {
                        //     return data.name_eng  //Input value to be set when you select a suggestion.
                        // }
                        return data.name  //Input value to be set when you select a suggestion.

                    },
                    source: my_playlists,
                    templates: {
                        empty: '',
                        header: '<h3 class="league-name">User Playlist</h3>',
                        suggestion: function (data) {
                            return '<a href="/search?q=' + data.name + '" data-query="' + data.id + '" data-type="my_playlists" style="font-weight:normal; margin-top:10px ! important;border:0;" class="list-group-item"><div>' + data.name + '</div></div></a>'

                        }
                    }
                }
            );


            $(".ms_top_search").on("click", ".tt-dataset a", function (e) {
                e.preventDefault();

                let query = $(this).data("query");
                if ($(this).data("type") === 'artist' || $(this).data("type") === 'album' || $(this).data("type") === 'song' || $(this).data("type") === 'playlists' || $(this).data("type") === 'collections') {
                    self.storeSearchKeyword();
                }

                if ($(this).data("type") === 'artist') {
                    return self.$router.push({ name: 'artist', params: { id: query } });
                }

                if ($(this).data("type") === 'album' || $(this).data("type") === 'song') {
                    return self.$router.push({ name: 'Album', params: { uuid: query } });
                }

                if ($(this).data("type") === 'my_playlists') {
                    return self.$router.push({ name: 'my-play-list', params: { id: query } });
                }

                if ($(this).data("type") === 'playlists') {
                    return self.$router.push({ name: 'playlist-detail', params: { id: query } });
                }
                if ($(this).data("type") === 'collections') {
                    return self.$router.push({ name: 'collection', params: { id: query } });
                }
                self.$router.push({ name: 'Search', query: { q: query }});
            });

            // update "q" if user selects an item from the suggestions
            inputEl.on('typeahead:select', (e, item) => {
                // if("keyword" in item){
                //     this.q = item.keyword;
                // }else{
                //     this.q = item.name_en;
                // }
                self.goFirstResult();
            });

            $(".ms_top_search").on("click", ".tt-dataset #clear-history", function (e) {
                self.deleteHistory();
                $('#search').typeahead('close');
            });
        })
    },


    methods: {
        logout() {
            this.$store.dispatch('authLogout').then(this.$router.push('/'));
        },

        gotoSearch() {
            if (!this.q.length) return;

            this.storeSearchKeyword();
            this.$router.push({ name: 'Search', query: { q: this.q } });
            $('#search').typeahead('close');
        },

        async getNotifications() {
            Cookies.set('melo_noti_count', 0);
            axios.get('/api/v2/notifications').then((response) => {

                this.notifications = response.data.data;
                // this.noti_count = this.notifications.length;

                this.noti_loading = true;
            });
            axios.get('/api/v2/upcoming/notifications').then((response) => {

                this.upcoming_notifications = response.data.data;

                this.noti_loading = true;
            });
        },

        readNotification() {
            if (this.noti_count === 0) return;

            axios.post('/api/v2/read-notifications').then((response) => {
                this.noti_count = 0;
            });
        },

        readNotificationId(id) {
            axios.post('/api/v2/read-notifications/' + id).then((response) => {
                return response.data.data;
            });
        },

        routeToNotification(noti) {
            // $('.custom-popper').removeClass('d-block');
            // let noti = this.notifications.filter((item) => {
            //         return item.id === id;
            //     });
            --this.noti_count;
            console.log(noti['id']);
            this.readNotificationId(noti.id);
            if (noti["type"] == "album" && noti["clickable"] == true) {
                this.$router.push({ name: 'Album', params: { uuid: noti["related_uuid"] } });
            }
            if (noti["type"] == "playlist" && noti["clickable"] == true) {
                this.$router.push({ name: 'playlist-detail', params: { id: noti["related_uuid"] } });
            }
            if (noti["type"] == "album_list" && noti["clickable"] == true) {
                this.$router.push({ name: 'collection', params: { id: noti["related_uuid"] } });
            }
            if (noti["clickable"] == false) {
                this.$router.push({ name: 'Notification', params: { id: noti["id"] } });
            }
        },

        getPrice() {
            axios.get('/api/mmn/get-subscription').then((response) => {
                this.price = response.data.data;
            }).catch((error) => {
                this.$toast.warn({ title: 'Warning !!', message: error.response.data.message });
            });
        },

        goFirstResult() {
            let self = this;
            if (!$('.tt-menu').hasClass('tt-empty')) {
                let query = $(".tt-dataset a").data("query");

                if ($(".tt-dataset a").data("type") === 'artist') {
                    return self.$router.push({ name: 'artist', params: { id: query } });
                }

                if ($(".tt-dataset a").data("type") === 'album' || $(".tt-dataset a").data("type") === 'song') {
                    return self.$router.push({ name: 'Album', params: { uuid: query } });
                }

                if ($(".tt-dataset a").data("type") === 'my_playlists') {
                    return self.$router.push({ name: 'my-play-list', params: { id: query } });
                }

                if ($(".tt-dataset a").data("type") === 'playlists') {
                    return self.$router.push({ name: 'playlist-detail', params: { id: query } });
                }
                if ($(".tt-dataset a").data("type") === 'collections') {
                    return self.$router.push({ name: 'collection', params: { id: query } });
                }
                self.$router.push({ name: 'Search', query: { q: self.q } });
            }
        },

        suggestionsWithDefaults(q, sync, async) {
            if (q === '' && this.isAuthenticated) {     // if query is empty, show default suggestions
                sync(this.history);
            }
        },

        storeSearchKeyword() {
            if (this.isAuthenticated) {
                let data = {
                    keyword: this.q
                }

                axios.post('/api/v2/recent-search', data).then((response) => {
                    this.$store.dispatch('searchHistory');
                }).catch((error) => {
                    console.log(error);
                })
            }
        },
        getDeeplinkData(type, uuid) {
            axios.get(`/api/mmn/deeplink/${type}/${uuid}`).then((response) => {
                this.deep_link.url = response.data;
            }).catch((error) => {
                this.loading = false;
            });
        }
    },
}
</script>

<template>
    <div class="ms_header" id="ms_header">
        <div class="ms_top_left">
            <div class="ms_top_search">
                <input type="text" name="q" v-model.trim="q" @keypress.enter="goFirstResult()" class="form-control"
                    id="search" autocomplete="off" placeholder="Artist, Song, Album, etc.....">
                <button class="search_icon" @click.prevent="goFirstResult()">
                    <img src="/images/svg/search.svg" alt="">
                </button>
            </div>
        </div>
        <div class="ms_top_right">
            <div class="ms_top_btn deep_btn" style="padding-right:22px;">
                <a :href="deep_link.url" class="ms_btn">Open App</a>
            </div>
            <div class="ms_top_btn">
                <template v-if="isAuthenticated">

                    <a id="notification" href="javascript:void(0)" style="font-size: 20px; color:#fff;margin-left: 18px;"
                        @click="getNotifications"><img class="img-fluid rounded-circle" src="/images/svg/Noti.svg"
                            width="33" /><span id="noti_badge"><span class="noti-badge" v-if="noti_count > 0">
                                <span class="noti_count">{{ noti_count }}</span></span></span>
                    </a>&nbsp;
                    <div class="custom-popper">
                        <div id="popper-header" class="popover-header">
                            <h3>Notifications<span id="read-all-box"><a href="javascript:;"
                                        @click.prevent="readNotification()" id="read-all-noti">Read All</a></span></h3>
                        </div>

                        <div id="popper-body" class="popover-body">
                            <template v-if="notifications.length">
                                <notifications :notifications="notifications" :upcoming="upcoming_notifications"
                                    :routeToNotification="routeToNotification"></notifications>
                            </template>

                            <!-- <template v-if="notifications.length">
                            <template v-for="notification in notifications">
                                <div class="noti-container">
                                    <a href='javascript:;' @click="routeToNotification(notification.id)" class="noti-box">
                                        <img class="noti-image h-100" :src="notification.image" onError="this.onerror=null;this.src='/img/mmn-logo1.png';">
                                        <div class="noti-body">
                                            <span class="noti-header">{{ notification.name }}</span>
                                            <span class="noti-time" v-html="notification.description"></span>
                                        </div>
                                    </a>
                                </div>
                            </template>
                        </template> -->

                            <!-- <template v-else>
                            <div class="text-center"><a href="javascript:;">No Notifications</a> </div>
                        </template> -->
                        </div>
                    </div>


                    <a href="javascript:void(0);" class="ms_admin_name"><img
                            :src="user.image ? user.image : '/images/svg/Profile.svg'" alt=""
                            onerror="this.src='/images/svg/Profile.png';"><span>{{ user.name }}</span></a>

                    <ul class="pro_dropdown_menu">
                        <li style="padding: 10px 20px;">
                            <span class="opt_icon" style="float:left;margin-right:10px;"><img src="/images/svg/Profile.svg"
                                    style="width: 30px;"></span>
                            <router-link to="/user/profile"> Profile</router-link>
                        </li>

                        <!-- <li style="padding: 10px 20px;">
                            <span class="opt_icon" style="float:left;margin-right:10px;margin-top: 8px;"><img src="/images/svg/Buy_topup.svg" style="width: 25px;"></span>
                            <router-link to="/user/payment" id="redeem_btn">
                                <span> Redeem (Top Up) </span><br>{{ user.remained_amount}} (MMK)</router-link>
                        </li> -->

                        <li style="padding: 10px 20px;">
                            <template v-if="user.subscribe">
                                <span class="opt_icon" style="float:left;margin-right:10px;margin-top: 8px;"><img
                                        src="/images/svg/Preminum.svg" style="width: 25px;"></span>
                                <!-- <a data-toggle="modal" data-target="#subscribeModal" href="#"> -->
                                <div>
                                    <span style="text-transform:none">Premium User </span> <span
                                        style="color:green;font-weight:bold">{{ user.left_day }} Days Left</span>
                                </div>
                                <!-- </a> -->
                            </template>

                            <!-- <template v-else-if="user.remained_amount < price.amount">
                                <span class="opt_icon" style="float:left;margin-right:10px;margin-top: 8px;"><img src="/images/svg/Preminum.svg" style="width: 25px;"></span>
                                <a data-toggle="modal" data-target="#insufficientToSubscribeModel" href="#">
                                    <span style="text-transform:none">Premium</span> Subscribe <span style="color:red;font-weight:bold">(Unactivated)</span>
                                </a>
                            </template> -->

                            <template v-else>
                                <span class="opt_icon" style="float:left;margin-right:10px;"><img
                                        src="/images/svg/Preminum.svg" style="width: 25px;"></span>
                                <a data-toggle="modal" data-target="#subscribeModal" href="#">
                                    <span style="text-transform:none">Premium</span> Subscribe <span
                                        style="color:red;font-weight:bold">(Unactivated)</span>
                                </a>
                            </template>
                        </li>
                        <!--<li>
                            <a data-toggle="modal" data-target="#myQuality" href="#">Streaming Quality</a>
                        </li>-->
                        <li style="padding: 10px 20px;">
                            <span class="opt_icon" style="float:left;margin-right:10px;"><img src="/images/svg/logout.svg"
                                    style="width: 25px;"></span>
                            <a href="#" @click.prevent="logout"> Logout </a>
                        </li>
                    </ul>

                </template>
                <template v-else>
                    <!-- <router-link to="/register" class="ms_btn reg_btn"> <span>Register</span></router-link>
                    <router-link to="/login" class="ms_btn login_btn"> <span>Login</span></router-link> -->
                </template>

            </div>
        </div>
    </div>
</template>
<style>
.custom-popper {
    position: absolute;
    top: 50px;
    min-width: 450px;
    right: 0;
    display: none;
}

.custom-popper.open-popper {
    display: block !important;
}

.custom-popper::after {
    /*content:"";*/
    position: absolute;
    width: 0;
    height: 0;
    top: -10px;
    right: 40%;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #fff;
    transform: translateX(-50%);
}

.custom-popper #popper-header h3 {
    font-size: 20px;
    color: #555;
}

.custom-popper #popper-header h3 #read-all-box {
    color: #555;
}

.custom-popper .popper-body {
    position: relative;
}

/*#popper-body .noti-image{
        padding-top: 5px;
    }
    #popper-body .noti-body .noti-header{
        line-height: 2rem;
    }
    #popper-body .noti-body .noti-time{
        line-height: 1.2rem;
    }*/
#popper-body.popover-body {
    max-height: 60vh;
    /*overflow-y:auto;*/
}

#read-all-box {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 12px;
}

#read-all-box a {
    cursor: pointer;
}

#read-all-box a:hover {
    color: #ff2150;
}

@media (max-width: 629px) {
    .custom-popper {
        min-width: 350px;
        right: -66px !important;
    }

    #notification img {
        margin-top: 5px;
    }
}
</style>

