<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'serach',

        components: {
            Layout,
            MainHeader
        },

        metaInfo() {
            return {
                title: 'Search',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            album_loading: true,
            song_loading: true,
            artist_loading: true,
            playlist_loading: true,
            download_btn_loading: false,
            albums: [],
            songs: [],
            artists: [],
            playlists: [],
            query: '',
        }),


        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                isPlay: 'isPlay',
                playerCurrentTrack: 'playerCurrentTrack',
                isAuthenticated: 'isAuthenticated'
            }),
        },


        /**
         * Created the component.
         */
        created() {
            this.query = this.$route.query.q;
            this.fetchSearch()
            // this.fetchAlbums();
            // this.fetchSongs();
            // this.fetchArtists();
            // this.fetchPlaylist();
        },

        /**
         * Watch these properties for changes.
         */
        watch: {

        },


        methods: {

            shareTwitterLink(url) {
                return 'http://www.twitter.com/intent/tweet?url=' + encodeURIComponent(this.asset(url));
            },

            shareFacebookLink(url) {
                return 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.asset(url))
            },

            fetchSearch() {
                axios.get('/api/v2/search?q=' + this.query).then((response) => {
                    this.albums = response.data.albums;
                    this.songs = response.data.songs;
                    this.playlists = response.data.playlists;
                    this.artists = response.data.artists;

                    this.album_loading = false;
                    this.artist_loading = false;
                    this.song_loading = false;
                    this.playlist_loading = false;
                }).catch((error) => {
                    this.album_loading = false;
                    this.artist_loading = false;
                    this.song_loading = false;
                    this.playlist_loading = false;
                  console.log(error)
                });
            },
            /**
             * get the album.
             */
            fetchalbums() {
                axios.get('/api/mmn/search-by-album?q=' + this.query).then((response) => {
                    this.albums = response.data.data;
                    this.album_loading = false;
                }).catch((error) => {
                    this.album_loading = false;
                });
            },

            /**
             * get the songs.
             */
            fetchsongs() {
                axios.get('/api/mmn/search-by-song?q=' + this.query).then((response) => {
                    this.songs = response.data.data;
                    this.song_loading = false;
                }).catch((error) => {
                    this.song_loading = false;
                });
            },

            /**
             * get the artists.
             */
            fetchartists() {
                axios.get('/api/mmn/search-by-artist?q=' + this.query).then((response) => {
                    this.artists = response.data.data;
                    this.artist_loading = false;
                }).catch((error) => {
                    this.artist_loading = false;
                });
            },

            /**
             * get the playlist.
             */
            fetchplaylist() {
                axios.get('/api/mmn/search-by-playlist?q=' + this.query).then((response) => {
                    this.playlist = response.data.data;
                    this.playlist_loading = false;
                }).catch((error) => {
                    this.playlist_loading = false;
                });
            },

            favouritealbum() {
                axios.post(`/api/mmn/favourite-sync/album/${this.album.uuid}`).then((response) => {
                    this.album.is_favourite = response.data.is_favourite;
                }).catch((error) => {

                })
            },

            favouritesong(song, song_id) {
                axios.post(`/api/mmn/favourite/song/${song_id}`).then((response) => {
                    song.is_favourite = response.data.is_favourite;
                }).catch((error) => {

                })
            },

        }
    }
</script>

<template>
    <layout>

        <!----album single section start---->
        <div class="ms_album_single_wrapper ms_profile">

            <main-header/>
            <template v-if="albums.length > 0 || songs.length > 0 || playlists.length > 0 || artists.length > 0">
                <div class="ms_heading">
                    <h1>search results for <b>"{{ query }}"</b></h1>
                </div>
            </template>
            <template v-else>
                <div class="no_result_search_txt">
                    <h1 style="font-size: 22px">No results for <b>"{{ query }}"</b> </h1>
                    <p style="margin-top:10px;">Plaease check your spelling or try using different keywords.</p>
                </div>
            </template>

            <div class="album_single_data" v-if="albums.length > 0">
                <h3 style="font-size: 20px">albums</h3>
            </div>

            <!-- <div class="album_single_data text-center" v-if="albums.length === 0 && !album_loading">
                <h3 style="font-size: 20px">no album</h3>
            </div> -->

            <template v-if="albums.length > 0">
                <div class="row">
                    <div v-for="album in albums" class="col-lg-2 col-md-6 col-6">
                        <div class="ms_rcnt_box marger_bottom30">
                            <div class="ms_rcnt_box_img">
                                <img :src="album.image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/album/album.jpg';">
                                <div class="ms_main_overlay">
                                    <router-link tag="a" :to="{ name: 'Album', params: { uuid: album.uuid }}" :title="album.name_mm">
                                        <div class="ms_box_overlay"></div>
                                    </router-link>

                                    <div class="ms_more_icon">
                                        <img src="/images/svg/more.svg" alt="">
                                    </div>

                                    <ul class="more_option">
                                        <template v-if="isAuthenticated">
                                            <li><a href="#" @click.prevent="syncAlbumFavourite(album.uuid)"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites </a></li>
                                            <li v-if="album.is_store && album.price > 0 && !album.is_bought">
                                                <a href="#" @click="buyAlbum(album.uuid)"><span class="opt_icon"><span class="fa fa-shopping-cart"></span></span>{{ album.price }} Kyat</a>
                                            </li>
                                        </template>

                                        <template v-else>
                                            <li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites </a></li>
                                            <li v-if="album.is_store && album.price > 0">
                                                <a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-shopping-cart"></span></span>{{ album.price }} Kyat</a>
                                            </li>
                                        </template>

                                        <li><a :href="shareFacebookLink('/album/' + album.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share to facebook</a></li>
                                        <li><a :href="shareTwitterLink('/album/' + album.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share to twitter</a></li>
                                        <li><a href="#" @click.prevent="copyText('/album/' + album.uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                    </ul>

                                    <div class="ms_play_icon">
                                    </div>
                                </div>
                            </div>
                            <div class="ms_rcnt_box_text">
                                <h3><router-link tag="a" :to="{ name: 'Album', params: { uuid: album.uuid }}">{{ album.name_mm }}</router-link></h3>
                                <p>
                                    <template v-if="album.artists && album.artists.length > 3">
                                        <a href="javascript:;">Various Artists</a>
                                    </template>

                                    <template v-else>
                                        <template v-for="(artist, index1) in album.artists">
                                            <router-link :to="{ name : 'artist', params:{ id : artist.uuid }}">{{ artist.name_eng }} <span v-if="isNotLast(album.artists, index1)">, </span></router-link>
                                        </template>
                                    </template>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </template>



            <div class="more_loading" v-show="album_loading && albums.lenght > 0">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
            </div>

            <div class="album_single_data mt-5 pt-5" v-if="songs.length > 0">
                <h3 style="font-size: 20px">songs</h3>
            </div>

            <!----song list---->
            <div class="album_inner_list padder_bottom30" v-if="songs.length > 0">
                <div class="album_list_wrapper">
                    <ul class="album_list_name">
                        <li>#</li>
                        <li>title</li>
                        <li>album</li>
                        <li>artist</li>
                        <li class="text-center">duration</li>
                        <li class="text-center">favourite</li>
                        <li class="text-center">more</li>
                        <li class="text-center">buy</li>
                    </ul>
                    <ul v-if="songs.length > 0" v-for="(song, key) in songs" :class="isPlay && playerCurrentTrack.id === song.id ? 'play_active_song' : ''">
                        <li>
                            <a href="javascript:;" class="mmn-play" @click="handleClickTrack(song, songs)">
                                <span class="play_no">{{ $_.padStart(key + 1, 2, 0) }}</span>
                                <span class="play_hover"></span>
                            </a>
                        </li>

                        <li><a href="javascript:;" class="mmn-play" @click="handleClickTrack(song, songs)">{{ song.name_mm }}</a></li>

                        <li><router-link :to="{ name: 'Album', params :{ uuid : song.album_uuid }}">{{ song.album_name_mm }}</router-link></li>

                        <li>
                            <a v-if="song.artists.length > 3" href="javascript:;">Various Artists</a>

                            <template v-for="(artist, artist_index) in song.artists" v-else>
                                <router-link :to="{ name: 'artist', params : {id : artist.uuid}}">{{ artist.name_en }}
                                    <span v-if="isNotLast(song.artists, artist_index)">, </span>
                                </router-link>
                            </template>

                        </li>

                        <li class="text-center"><a href="javascript:;">{{ song.duration }}</a></li>


                        <template v-if="isAuthenticated">
                            <li class="text-center"><a href="#" @click.prevent="favouriteSong(song,song.id)" ><span :class="song.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span> </a></li>
                            <li class="text-center ms_more_icon"><a href="javascript:;"><span class="ms_icon1 ms_active_icon"></span></a>
                                <ul class="more_option">
                                    <li><a :href="shareFacebookLink('/album/' + song.album_uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                    <li><a :href="shareTwitterLink('/album/' + song.album_uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                    <li><a href="#" @click.prevent="copyText('/album/' + song.album_uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                </ul>
                            </li>

                            <li v-if="song.is_bought" class="text-center">
                                <a href="#" @click.prevent="downloadSong(song.id)" class="song_download">
                                    <span class="ms_close"><img src="/images/svg/download.svg" alt=""></span>
                                </a>
                            </li>

                            <li v-else-if="song.is_store" class="text-center">
                                <a href="javascript:;" @click.prevent="buySong(song.id)">{{ song.price }} Kyat</a>
                            </li>

                            <li v-else-if="song.is_free" class="text-center">
                                <a :href="'/download_free_song/' + song.id" class="song_download">
                                    <span class="ms_close"><img src="/images/svg/download.svg" alt=""></span>
                                </a>
                            </li>

                            <li></li>
                        </template>

                        <template v-else>
                            <li class="text-center"><a href="#" data-toggle="modal" data-target="#loginModal"><span class="fa fa-heart"></span> </a></li>
                            <li class="text-center ms_more_icon"><a href="javascript:;"><span class="ms_icon1 ms_active_icon"></span></a>
                                <ul class="more_option">
                                    <li><a :href="shareFacebookLink('/album/' + song.album_uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                    <li><a :href="shareTwitterLink('/album/' + song.album_uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                    <li><a href="#" @click.prevent="copyText('/album/' + song.album_uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                </ul>
                            </li>

                            <li v-if="song.is_store" class="text-center"><a href="#" data-toggle="modal" data-target="#loginModal">{{ song.price }} Kyat</a></li>

                            <li v-else-if="song.is_free" class="text-center">
                                <a :href="'/download_free_song/' + song.id" class="song_download">
                                    <span class="ms_close"><img src="/images/svg/download.svg" alt=""></span>
                                </a>
                            </li>
                            <li></li>
                        </template>

                    </ul>

                    <!-- <ul v-else>
                        <li class="w-100 text-center"><a href="#">No Songs</a></li>
                    </ul> -->
                </div>
            </div>

            <div class="more_loading" v-show="song_loading && songs.length > 0">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
            </div>


            <div class="album_single_data mt-5 pt-5" v-if="artists.length > 0">
                <h3 style="font-size: 20px">Artists</h3>
            </div>

           <!--  <div class="album_single_data text-center" v-if="artists.length === 0 && !artist_loading">
                <h3 style="font-size: 20px"> No Artists</h3>
            </div> -->

            <div class="row" v-if="artists.length > 0">
                <div v-for="artist in artists" class="col-lg-2 col-md-2 col-6">
                    <div class="ms_rcnt_box marger_bottom30">
                        <div class="ms_rcnt_box_img">
                            <img :src="artist.image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/album/album.jpg';">
                            <div class="ms_main_overlay">
                                <router-link tag="a" :to="{ name: 'artist', params: { id : artist.uuid }}" :title="artist.name_en">
                                    <div class="ms_box_overlay"></div>
                                </router-link>
                                <div class="ms_play_icon">
                                </div>
                            </div>
                        </div>
                        <div class="ms_rcnt_box_text">
                            <h3><router-link :to="{ name: 'artist', params: { id : artist.uuid }}">{{ artist.name_en }}</router-link></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="more_loading" v-show="artist_loading && artists.length > 0">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
            </div>

            <div class="album_single_data mt-5 pt-5" v-if="playlists.length > 0">
                <h3 style="font-size: 20px">Playlist</h3>
            </div>

            <!-- <div class="album_single_data text-center" v-if="playlists.length === 0 && !playlist_loading">
                <h3 style="font-size: 20px">No Playlist</h3>
            </div> -->

            <div class="row" v-if="playlists.length > 0">
                <div v-for="item in playlists" class="col-lg-2 col-md-2 col-6">
                    <div class="ms_genres_box">
                        <div class="ms_rcnt_box_img">
                            <img :src="item.image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/album/album.jpg';">
                            <div class="ms_main_overlay">
                                <router-link :to="{ name: 'playlist-detail', params: { id: item.uuid }}" :title="item.name_mm">
                                <div class="ms_box_overlay"></div>
                                </router-link>

                                <div class="ms_play_icon">
                                </div>
                            </div>
                        </div>
                        <div class="ms_box_overlay_on">
                            <div class="ovrly_text_div">
                                <span class="ovrly_text1"><router-link :to="{ name: 'playlist-detail', params: { id: item.uuid }}">{{ item.name_mm }}</router-link></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="more_loading" v-show="playlist_loading && playlists.length > 0">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
            </div>

        </div>


    </layout>
</template>

<style>
.no_result_search_txt{
    justify-content: center;
    display: flex;
    align-items: center;
    height: 75vh;
    flex-direction: column;
    font-size: 16px;
}
</style>

