<script type="text/ecmascript-6">
    import Cookies from 'js-cookie'

    export default {
        props:{
            url: {
                type : String,
                default : 'https://melomm.app.link/KTCSS9W6t5'
            },
            songs: {
                type: Array,
            }
        },
        data: () => ({
            has_app: Cookies.get('melo_has_app')
        }),

        computed: {
        },

        methods: {
            may_be(){
                $('.deep-view-card').removeClass('d-block');
                $('.deep-view-card').addClass('d-none');
                this.handleClickTrack(this.songs[0],this.songs);
            }
        }
    }
</script>

<template>
    <div class="deep-view-card d-block d-sm-none">
        <div class="deep-view">
            <div class="d-block w-100 p-4">
                <h5 class="mt-2">Melo Music</h5>
                <div class="deep-btn-group">
                    <a href="javascript:;" class="deep-may-be" @click.prevent="may_be()">Play</a>
                    <a :href="url" class="deep-download">
                        <span v-if="!has_app">Download App</span>
                        <span v-else>Open App</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.deep-view-card .deep-view{
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    height: 180px;
    text-align: center;
    align-items: center;
    background: #fff;
    border-radius: 30px;
    z-index: 100000;
}
.deep-view-card .deep-view{

}
.deep-btn-group{
    display: flex;   
    width: 100%;
    padding: 20px 0;
}
.deep-btn-group .deep-may-be{
    border-radius: 20px;
    padding: 10px 20px;
    border: 1px solid;
    background: #ff2150;
    color: #fff;
    flex: 1;
    margin: 5px;
}
.deep-btn-group .deep-download{
    border-radius: 20px;
    padding: 10px 20px ;
    border: 1px solid #ff2150;
    background: #fff;
    color: #ff2150;
    flex: 1;
    margin: 5px;
}
</style>
