<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'
    import DeepView from '../components/Component/DeepView.vue'

    export default {
        name: 'playlist-detail',

        components: {
            Layout,
            MainHeader,
            DeepView
        },

        metaInfo() {
            return {
                // if no subcomponents specify a metaInfo.title, this title will be used
                title: this.playlist.name_eng,

                meta: [

                    // OpenGraph data (Most widely used)
                    {property: 'og:title', content: `${this.playlist.name_eng} - Melo`},

                    // Should the the same as your canonical link, see below.
                    {property: 'og:url', content: this.playlist.image},
                    {property: 'og:image', content: this.playlist.image},

                    // Twitter card
                    {name: 'twitter:title', content: `${this.playlist.name_eng} - Melo`},
                    // Your twitter handle, if you have one.
                    {name: 'twitter:image:src', content: this.playlist.image},

                    // Google / Schema.org markup:
                    {itemprop: 'name', content: `${this.playlist.name_eng} - Melo`},
                    {itemprop: 'image', content: this.playlist.image}
                ],
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            playlist_image_loading : true,
            playlist: {
                name_eng: null,
                name_mm: null,
                image: null,
                follower_counts : null,
            },
            songs: [],
        }),


        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                isAuthenticated: 'isAuthenticated',
                playerCurrentTrack: 'playerCurrentTrack',
                isPlay: 'isPlay'
            }),

            totalPlay: function () {
                let sum = 0;
                return this.songs.reduce((sum, song) => sum + song.play_count, 0);
            }
        },


        /**
         * Created the component.
         */
        created() {
            this.fetchPlaylistDetail();
        },


        methods: {
            /**
             *  Get the playlist detail.
             */
            async fetchPlaylistDetail() {
                axios.get(`/api/mmn/playlist/${this.$route.params.id}`).then((response) => {
                    this.playlist = response.data.playlist;
                    this.songs = response.data.songs;
                    if(this.songs && this.songs.length > 0 && this.$route.query.autoplay === 'yes') {
                        this.handleClickTrack(this.songs[0], this.songs)
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

            /**
             *  Favourite the song.
             */
            favouriteSong(song, song_id) {
                axios.post(`/api/mmn/favourite/song/${song_id}`).then((response) => {
                    song.is_favourite = response.data.is_favourite;
                }).catch((error) => {

                })
            },

            /**
             *  Follow the playlist.
             */
            followPlaylist() {
                 axios.post(`/api/v2/follow-playlist` ,{ playlist_id : this.playlist.id} ).then((response) => {
                    this.playlist.is_follow = response.data.followed;
                    this.playlist.follower_counts = response.data.playlist.follower_counts;
                }).catch((error) => {

                })
            },

            scrollToAbout() {
                var elmnt = document.getElementById("description-playlist");
                this.scrollIt(elmnt);
            },
        }
    }
</script>

<style scoped>
.dropdown-item {
    font-size: 14px;
    color: #777;
}
.dropdown-item span {
    margin-right: 5px;
}
</style>

<template>
    <layout>
        <deep-view :url="getDeepLink('playlists',playlist.id,playlist.uuid)" :songs="songs"></deep-view>
        <!----Album Single Section Start---->
        <div class="ms_album_single_wrapper ms_profile playlist">

            <main-header/>
            <div class="album-cover-wrapper" :style="{ 'background-image': 'url(' + playlist.image + ')' }" style="filter: blur(120px);height:320px;position:absolute;width: 100%;background-size: cover;opacity: 0.4;background-position: center; " v-show="!loading"></div>
            <div class="album-cover-wrapper" v-show="!loading">
                <div class="album_single_data" >
                    <div class="album_single_img">
                        <div class="load-album-image" v-show="loading"></div>
                        <img :src="playlist.image" alt="" v-show="!loading" class="img-fluid" onError="this.onerror=null;this.src='/images/album/collection_playlist.png';">
                    </div>
                    <div class="album_single_text">
                        <h2>{{ playlist.name_mm }}</h2>
                        <p v-show="playlist.follower_counts > 0" class="album_date" style="margin-bottom: 8px;font-size:12px;color: #fff"><span class="fa icon_follower" style="font-size:18px;margin-right: 10px;width: 25px;height: 20px;"></span>{{ playlist.follower_counts }} Follower<span v-if="playlist.follower_counts > 1">s</span></p>
                        <!--<p v-else class="album_date">No followers yet!</p>-->
                        <!-- <a href="#" class="album_date">{{ songs.length }} song<span v-if="songs.length > 1">s</span></a> -->
                        <!-- <p style="margin-bottom: 8px;font-size:12px;">
                            <a href="#" class="album_date"><span class="fa icon_earphone_ic" style="margin-right: 5px;margin-left:1px;"></span> {{ totalPlay.toLocaleString() }}</a>
                        </p> -->
                        <p style="margin-bottom: 13px;font-size:12px;">
                            <span><img src="/images/svg/melo_icon_white.png" class="img-fluid" style="width:25px;margin-right:5px;"></img></span>
                            <a href="#" class="album_date" v-show="playlist.release_date">Released - {{ playlist.release_date }}</a>
                        </p>
                        <!-- <p class="description">{{ playlist.desc }}</p> -->
                        <div class="album_feature">
                             <!-- <a v-if="playlist.desc && playlist.desc.length > 80" href="#description-playlist" @click.prevent="scrollIt('description-playlist')" class="read-more">Read more <i class="fa fa-chevron-right"></i></a> -->

                                <template v-if="playerCurrentTrack != null && songs.findIndex(x => x.id === playerCurrentTrack.id) >= 0 && isPlay">
                                    <a @click.prevent="$store.dispatch(isPlay ? 'pause' : 'play')" href="javascript:;" class="custom_play_btn"><span class="fa icon_pause_btn"></span><span style="padding: 5px;">Pause</span></a>
                                </template>
                                <template v-else>
                                    <a @click.prevent="handleClickTrack(songs[0],songs)" href="javascript:;" class="custom_play_btn"><span class="fa fa fa-play"></span><span style="padding: 5px;">Play</span></a>
                                </template>
                                <div class="btn-group dropright">
                                    <button type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="custom_btn_style ml-2" style="background: transparent;">
                                        <span :class="'fa icon_sharer'"></span> Share
                                    </button>
                                    <div class="dropdown-menu album_more_detail" style="background: #28282E;border-radius: 0px;">
                                        <a class="dropdown-item" :href="facebookLinkShare(playlist.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a>
                                        <a class="dropdown-item" :href="twitterLinkShare(playlist.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a>
                                        <a class="dropdown-item" href="#" @click.prevent="copyText('playlist/'+playlist.uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a>
                                    </div>
                                </div>

                                <a v-if="isAuthenticated" @click.prevent="followPlaylist()"  href="#" id="artist-follow" class="ml-2 custom_btn_style" style="min-width:120px;"><span class="fa icon_follower"></span><span style="padding: 5px;">{{ playlist.is_follow ? 'Followed' : 'Follow' }}</span></a>
                                <a v-else href="#" data-toggle="modal" data-target="#loginModal" class="ml-2 custom_btn_style" style="min-width:120px;"><span class="fa icon_follower"></span><span style="padding: 5px;">Follow</span></a>
                            

                        </div>
                    </div>

                </div>
            </div>
            <!----Song List---->
            <div class="album-track-wrapper album_inner_list" v-show="!loading">
                <div style="background: #1e1e25;position: absolute;top: 0;width: 100%;height: 100%;z-index: -1;left: 0;"></div>
                <div class="album_list_wrapper album_track_content" v-show="!loading">
                    <h5 style="padding:20px;">Tracks<span style="font-size:11px;margin-left: 5px;">Total - {{ songs.length }} song<span v-if="songs.length > 1">s</span></span></h5>

                    <ul class="track_ul" v-for="(song, key) in songs" :class="isPlay && playerCurrentTrack.id === song.id ? 'play_active_song' : ''">
                        <li style="padding-top: 10px;top:-5px;">
                            <a href="javascript:;" class="mmn-play" @click="handleClickTrack(song, songs)">
                                <span class="play_no" style="position: absolute;top: 0;">{{ $_.padStart(key + 1, 0) }}</span>
                                <div class="ms_bars for_track_ul" v-if="isPlay && playerCurrentTrack.id === song.id">
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                </div>
                                <span class="play_hover" v-else></span>
                            </a>
                        </li>

                        <li><a href="javascript:;" class="mmn-play" @click="handleClickTrack(song, songs)">
                            <span class="song_title">
                                {{ song.name_mm }}
                            </span>
                            <p style="font-size:10px;margin-bottom: 5px;">
                                <a v-if="song.artists.length > 3" href="javascript:;">Various Artists</a>

                                <template v-for="(artist, artist_index) in song.artists" v-else>
                                    <router-link :to="{ name: 'artist', params: { id : artist.uuid }}">{{ artist.name_eng }}
                                        <span v-if="isNotLast(song.artists, artist_index)">,</span>
                                    </router-link>
                                </template>

                                <template v-if="song.feats.length">
                                    <a href="javascript:;">, ft : </a>
                                    <template v-for="(feat, feat_index) in song.feats">
                                        <router-link :to="{ name: 'artist', params: { id : feat.uuid }}">{{ feat.name_eng }}
                                            <span v-if="isNotLast(song.feats, feat_index)">, </span>
                                        </router-link>
                                    </template>
                                </template>
                            </p>
                        </a></li>

                        <li style="top: -10px;"><a href="javascript:;">{{ song.duration }}</a></li>

                        <template v-if="isAuthenticated">
                            <li class="text-center" style="top: -10px;"><a href="#" @click.prevent="favouriteSong(song,song.id)" ><span :class="song.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span> </a></li>

                            <li v-if="song.is_bought" class="text-center" style="top: -11px;">
                                <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                    <span class="ms_close"><img src="/images/svg/download_1.svg" alt="" style="width:15px;"></span>
                                </a>
                            </li>

                            <li v-else-if="song.is_store" class="text-center" style="top: -11px;">
                                <a href="javascript:;" @click="buySong(song.id)"><i class="fa icon_buy_dollar" ></i></a>
                            </li>

                            <li v-else-if="song.is_free" class="text-center" style="top: -11px;">
                                <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                    <span class="ms_close"><img src="/images/svg/download_1.svg" alt="" style="width:15px;"></span>
                                </a>
                            </li>
                            <li v-else></li>
                            <li class="text-center ms_more_icon" style="top: -11px;"><a href="javascript:;"><span class="fa icon_menu_more"></span></a>
                                <ul class="more_option album_more">
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#" @click.prevent="saveSong(song.id)"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                    <li style="width: 100% !important ;padding: 10px 10px;"><router-link :to="{ name: 'Album', params: { uuid: song.album_uuid }}"><span class="opt_icon"><img src="/images/svg/go_to_album.svg" style="width:18px;margin-right:7px;"/></span>Go To Album </router-link></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongFacebook(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongTwitter(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#" @click.prevent="copyText('album/'+song.album_uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                </ul>
                            </li>
                        </template>

                        <template v-else>
                            <li class="text-center" style="top: -10px;"><a href="#" data-toggle="modal" data-target="#loginModal"><span class="fa fa-heart"></span> </a></li>

                            <li v-if="song.is_store" class="text-center" style="top: -11px;"><a href="#" data-toggle="modal" data-target="#loginModal"><i class="fa icon_buy_dollar"></i></a></li>

                            <li v-else-if="song.is_free" class="text-center" style="top: -11px;">
                                <a href="#" data-toggle="modal" data-target="#loginModal" class="song_download">
                                    <span class="ms_close"><img src="/images/svg/download_1.svg" alt="" style="width:15px;"></span>
                                </a>
                            </li>

                            <li v-else></li>
                            <li class="text-center ms_more_icon" style="top: -11px;"><a href="javascript:;"><span class="fa icon_menu_more"></span></a>
                                <ul class="more_option album_more">
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                    <li style="width: 100% !important ;padding: 10px 10px;"><router-link :to="{ name: 'Album', params: { uuid: song.album_uuid }}"><span class="opt_icon"><img src="/images/svg/go_to_album.svg" style="width:18px;margin-right:7px;"/></span>Go To Album </router-link></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongFacebook(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongTwitter(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#" @click.prevent="copyText('album/'+song.album_uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                </ul>
                            </li>

                        </template>

                    </ul>

                </div>

            </div>
            <div style="height: 100vh;align-items: center;display: flex;margin-top: -10%;" v-show="loading">
                <div class="more_loading" >
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>
            </div>

            <br>
            <br>

            <div id="description-playlist" class="col-md-12" v-if="!loading && playlist.desc && playlist.desc.length > 80">
                <h2 style="margin-top: 20px; text-align: center; font-size: 22px;color: #fff;padding-bottom: 10px;">About {{ playlist.name_mm }}</h2>
                <p class="text-white" style="white-space: pre-line;">{{ playlist.desc }}</p>
            </div>
        </div>


    </layout>
</template>
