<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import DownloadAlbumModal from './DownloadAlbumModal';

    export default {

        components: { DownloadAlbumModal },

        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                album_uuid: 'getAlbumUUid'
            }),
        },

        methods: {
            /**
             * Buy music
             */
            buy() {
                axios.post('/api/mmn/user/buy_album', { id: this.album_uuid }).then((response) => {
                    this.$toast.success({ title: 'Success', message: response.data.message });
                    this.$store.dispatch('setRemainedAmount', response.data.remained_amount);
                    this.closeModal();
                    this.showDownloadAlbumModal();

                }).catch((error) => {

                    let message = error.response.data.message;

                    if (error.response.data.status === 102) {
                        this.$toast.warning({ title: 'Warning', message: message });
                    }else if (error.response.data.status === 103) {
                        this.$toast.error({ title: 'Error', message: message });
                    }

                    this.closeModal();
                });
            },

            /**
             * Close Modal
             */
            closeModal() {
                this.$nextTick(() => {
                    $('#buyAlbumModal').modal('hide');
                });
            },

            /**
             * Show download album Modal
             */
            showDownloadAlbumModal() {
                this.$nextTick(() => {
                    $('#downloadAlbumModal').modal('show');
                });
            }

        }
    }
</script>

<template>
    <div>
        <div class="ms_save_modal">
            <div id="buyAlbumModal" class="modal centered-modal" role="dialog">
                <div class="modal-dialog">
                    <!-- Modal content-->
                    <div class="modal-content">
                        <div class="modal-content">
                            <button type="button" class="close" data-dismiss="modal">
                                <i class="fa_icon form_close"></i>
                            </button>
                            <div class="modal-body">
                                <h1>Are you sure you want to buy ?</h1>
                                <div class="clr_modal_btn">
                                    <a href="#" @click="buy">Buy</a>
                                    <a href="#" data-dismiss="modal">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <download-album-modal :album_id="album_uuid"></download-album-modal>
    </div>
</template>
