<script type="text/ecmascript-6">
import { mapGetters } from 'vuex';

export default {

    /**
     * Computed these properties.
     */
    computed: {
        ...mapGetters({
            user: 'getProfile',
        }),
    },

    methods: {
        /** Delete Song from Playlist
         *
         */
        deleteAccount() {
            axios.delete('/api/mmn/user/delete/account').then((response) => {
                $('#acc_delete_modal').modal('hide');
                this.$store.dispatch('authLogout').then(this.$router.push('/'));
            }).catch((error) => {

            });
        },
        /**
         * Close Modal
         */
        closeModal() {
            this.$nextTick(() => {
                $('#acc_delete_modal').modal('hide');
            });
        }

    }
}
</script>
<template>
    <div class="ms_save_modal">
        <div id="acc_delete_modal" class="modal centered-modal" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="fa_icon form_close"></i>
                        </button>
                        <div class="modal-body">
                            <h1>Are You sure to Delete this account? You can't undo this action.</h1>
                            <!-- <h1>{{ user.name }}</h1> -->
                            <div class="clr_modal_btn">
                                <a href="#" @click.prevent="deleteAccount">Confirm To Delete</a>
                                <a href="#" data-dismiss="modal">Close</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
