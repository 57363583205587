<script type="text/ecmascript-6">
    import Layout from '../../layouts/Index.vue'
    import MainHeader from '../../layouts/Header.vue'

    export default {
        name: 'register',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Register',
            }
        },

        /**
         * Injecting Parent Validator
         * Don't remove it.
         *
         * https://baianat.github.io/vee-validate/concepts/injections.html
         * https://vuejs.org/v2/api/#provide-inject
         */
        inject: [ '$validator' ],

        /**
         * The component's data.
         */
        data: () => ({
            'action':'register',
            'name':'',
            'email':'',
            'password':'',
            'password_confirmation':'',
        }),

        computed: {
            authErrors(){
                return this.$store.getters.authErrors;
            }
        },


        /**
         * Created the component.
         */
        created() {

        },


        methods: {
            register: function () {
                this.$validator.validateAll().then((result) => {
                    if (!result){
                        this.$toast.error({ title: 'Error', message: "Please, check your inputs." });
                        throw new Error("Please, check your inputs.");
                    }else {
                        const { action, name, email, password, password_confirmation } = this;

                        this.$store.dispatch('authRequest', { action, name, email, password, password_confirmation })
                            .then(() => {
                                this.$router.push('/home')
                            }).catch(() => {
                            this.$setErrorsFromResponse(this.authErrors.all());
                        })
                    }
                }).catch(() => {

                });

            }
        }
    }
</script>

<template>
    <layout>

        <div class="ms_content_wrapper ms_login_wrapper padder_top90">

            <main-header/>

            <div class="ms_login">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col"></div>
                        <div class="col-md-9">
                            <div class="ms_register_img">
                                <img src="/images/register_img.png" alt="" class="img-fluid" />
                            </div>
                            <div class="ms_register_form">
                                <h2>Register / Sign Up</h2>

                                <div class="social-or">
                                    <a href="#" @click.prevent="logInWithFacebook('/')" class="ms_btn"><i class="fa fa-facebook-square" aria-hidden="true"></i> SIGN UP WITH FACEBOOK</a>
                                    <a href="#" @click.prevent="logInWithGoogle('/')" class="ms_btn"><i class="fa fa-google" aria-hidden="true"></i> SIGN UP WITH GOOGLE</a>
                                </div>

                                <form method="POST" @submit.prevent="register()">
                                    <div class="form-group">
                                        <input type="text" name="name" v-model="name" v-validate="'required'" placeholder="Enter Your Name" :class="hasError('name')">
                                        <span class="form_icon"><i class="fa_icon form-user" aria-hidden="true"></i></span>
                                        <span v-show="errors.has('name')" class="invalid-feedback text-left text-white" role="alert">
                                            <strong>{{ errors.first('name') }}</strong>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <input type="text" name="email" v-model="email" v-validate="'required|email'" placeholder="Enter Your Email" :class="hasError('email')">
                                        <span class="form_icon"><i class="fa_icon form-envelope" aria-hidden="true"></i></span>
                                        <span v-show="errors.has('email')" class="invalid-feedback text-left text-white" role="alert">
                                            <strong>{{ errors.first('email') }}</strong>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <input type="password" name="password" v-model="password" v-validate="'required|min:6'" placeholder="Enter Password" :class="hasError('password')">
                                        <span class="form_icon"><i class="fa_icon form-lock" aria-hidden="true"></i></span>
                                        <span v-show="errors.has('password')" class="invalid-feedback text-left text-white" role="alert">
                                            <strong>{{ errors.first('password') }}</strong>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <input type="password" name="password_confirmation" v-model="password_confirmation" v-validate="'required'" placeholder="Confirm Password" class="form-control">
                                        <span class="form_icon"><i class=" fa_icon form-lock" aria-hidden="true"></i></span>
                                    </div>
                                    <button type="submit" class="ms_btn">register now</button>
                                </form>

                                <p>Already Have An Account? <router-link to="/login" class="ms_modal hideCurrentModel">login here</router-link></p>
                            </div>


                        </div>
                        <div class="col"></div>

                        <br>

                    </div>
                </div>
            </div>

        </div>


    </layout>
</template>
