<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'events',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Events',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            current_events: [],
            upcoming_events: [],
            past_events: [],
        }),


        /**
         * Created the component.
         */
        created() {
            this.fetchEvents();
        },


        methods: {
            /**
             *  Get the events.
             */
            async fetchEvents() {
                axios.get(`/api/mmn/events`).then((response) => {
                    this.current_events = response.data.current_events;
                    this.upcoming_events = response.data.upcoming_events;
                    this.past_events = response.data.past_events;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <div class="ms_content_wrapper ms_profile padder_top80">

            <main-header/>

            <!--<div class="header_padder_top"></div>-->
            <div class="ms_fea_album_slider">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ms_heading">
                        <h1>Current Events </h1>
                        <span class="veiw_all"><router-link to="/events/current-events">view more</router-link></span>
                    </div>
                </div>


                <div class="col-lg-4 col-md-4 col-6" v-for="current_event in current_events">
                    <div class="ms_genres_box">
                        <router-link :to="{ name: 'Event', params: { id : current_event.id}}">
                            <img :src="current_event.image" alt="" class="img-fluid" />
                        </router-link>
                        <div class="ms_box_overlay_on">
                            <div class="ovrly_text_div">
                                <span class="ovrly_text1"><router-link :to="{ name: 'Event', params: { id : current_event.id}}">{{ current_event.name }}</router-link></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!current_events.length" class="col-lg-12 text-center padder_bottom60" style="color: #fff">
                    No Event List
                </div>


            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="ms_heading">
                        <h1>Upcoming Events </h1>
                        <span class="veiw_all"><router-link to="/events/upcoming-events">view more</router-link></span>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-6" v-for="upcoming_event in upcoming_events">
                    <div class="ms_genres_box">
                        <router-link :to="{ name: 'Event', params: { id : upcoming_event.id}}">
                            <img :src="upcoming_event.image" alt="" class="img-fluid" />
                        </router-link>
                        <div class="ms_box_overlay_on">
                            <div class="ovrly_text_div">
                                <span class="ovrly_text1"><router-link :to="{ name: 'Event', params: { id : upcoming_event.id}}">{{ upcoming_event.name }}</router-link></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!upcoming_events.length" class="col-lg-12 text-center padder_bottom60" style="color: #fff">
                    No Event List
                </div>

            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="ms_heading">
                        <h1>Past Events </h1>
                        <span class="veiw_all"><router-link to="/events/past-events">view more</router-link></span>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4 col-6" v-for="past_event in past_events">
                    <div class="ms_genres_box">
                        <router-link :to="{ name: 'Event', params: { id : past_event.id}}">
                            <img :src="past_event.image" alt="" class="img-fluid" />
                        </router-link>
                        <div class="ms_box_overlay_on">
                            <div class="ovrly_text_div">
                                <span class="ovrly_text1"><router-link :to="{ name: 'Event', params: { id : past_event.id}}">{{ past_event.name }}</router-link></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!past_events.length" class="col-lg-12 text-center padder_bottom60" style="color: #fff">
                    No Event List
                </div>

            </div>
            </div>

        </div>


    </layout>
</template>
