<script type="text/ecmascript-6">
    import Cookies from 'js-cookie'
    import {mapGetters} from 'vuex';

    export default {
        props:{
            parent:{
                type:Number,
            },
            comments:{
                type:Object,
            },
            video:{
                type:Object,
            },
            fetchComment:{
                type: Function
            },
            type:{
                type:String,
                default:'video'
            }
        },
        data: () => ({
            comment: null,
            parent_comment:null,
            reply_loading : true,
            reply : []
        }),

        computed: {
            ...mapGetters({
                isAuthenticated: 'isAuthenticated',
            }),
        },

        methods: {
            submitForm(){
                let self=this;
                this.$nextTick(() => {
                    $(".reply-box.open_box").removeClass('open_box');
                })

                let data = null;
                if(this.type == 'live'){
                    data ={
                        'live_id' : this.$route.params.id,
                        'comment_body':self.comment,
                        'comment_parent': Cookies.get('comment_parent_id'),
                    }
                }else{
                    data ={
                        'video_id' : this.$route.params.id,
                        'comment_body':self.comment,
                        'comment_parent': Cookies.get('comment_parent_id'),
                    }
                }
                // console.log(data);
                axios.post(`/api/v2/comment`,data).then((response) => {
                    this.comment=null;
                    this.comments.reply_no++;
                    // this.video.cmt_count++;
                    this.fetchReply(Cookies.get('comment_parent_id'));
                }).catch((error) => {
                });

            },

            fetchReply(id){
                axios.get(`/api/v2/comment/${id}/reply`).then((response) => {
                    this.reply=response.data.data;
                    this.reply_loading=false;
                }).catch((error) => {
                    this.reply_loading=false;
                });
            }
        }
    }
</script>

<template>
    <div class="form-inline mt-3" style="position: relative;">
        <div class="mb-auto" style="width:10%">
            <img :src="comments.user.avatar? comments.user.avatar:'/images/svg/Profile.svg'" class="img-fluid rounded-circle" width="50">
            <!-- <img src="/images/logo.png" class="img-fluid rounded-circle" width="50"> -->
        </div>
        <div class="pl-3" style="width:75%">
            <h6>{{comments.user.name}}</h6>
            <p class="pr-4 mb-1">{{comments.comment_body | trunc(90)}}</p>

            <div :id="'accordion'+comments.id">
                <p style="font-size:12px;"><a href="javascript" @click.prevent="fetchReply(comments.id)" class="mr-2" data-toggle="collapse" :data-target="'#collapse'+comments.id" aria-expanded="true" :aria-controls="'collapse'+comments.id">Views</a><span class="text-danger"> {{comments.reply_no}} Reply</span></p>

                <div v-if="comments.reply_no > 0" :id="'collapse'+comments.id" class="collapse pt-2" aria-labelledby="headingOne" :data-parent="'#accordion'+comments.id">
                    <div class="row pl-4" v-for="(reply,index) in reply">
                        <div style="width:10%">
                            <img :src="reply.user.avatar? reply.user.avatar:'/images/svg/Profile.svg'" class="img-fluid rounded-circle" width="50">
                            <!-- <img src="/images/logo.png" class="img-fluid rounded-circle" width="50"> -->
                        </div>
                        <div class="pl-2" style="width:90%">
                            <h6>{{reply.user.name}}</h6>
                            <p>{{reply.comment_body | trunc(90)}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-auto text-right" style="width: 15%">
            <a href="javascript:;" class="reply_comment"><i class="fa icon_sharer"></i> Reply</a>
            <div class="reply-box">
                <form @submit.prevent="submitForm()">
                    <input type="hidden" id="parent-comment" :data-id="parent">
                    <textarea v-model="comment" class="comment-input d-block" placeholder="Add a write comment" :readonly="!isAuthenticated" style="height: 35px"></textarea>
                    <a href="javascript:;" :class="{'disabled': !isAuthenticated}" class="btn video-btn btn-secondary float-right mt-2" @click.prevent="submitForm()">Comment</a>
                </form>
            </div>
        </div>
    </div>
</template>

<style>
    .comment-input{
        border-top: 0;
        border-left: 0;
        border-right: 0;
        padding: 3px;
        border-radius: 0;
        background: transparent;
        width: 100%;
        color: #fff;
    }
    .reply-box{
        position: absolute;
        top: -10px;
        width: 350px;
        right: 70px;
        background: #28282E;
        padding: 15px 20px;
        display: none;
    }
    .reply-box::after{
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 15px;
        right: -9px;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        border-left: 9px solid #28282E;
    }
    .reply-box.open_box{
        display: block;
    }
</style>
