<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import DownloadModal from './DownloadModal';

    export default {

        components: { DownloadModal },

        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                song_id: 'getSongId'
            }),
        },

        methods: {
            /**
             * Buy music
             */
            buy() {
                axios.post('/api/mmn/user/buy_song', { id: this.song_id }).then((response) => {
                    this.$toast.success({ title: 'Success', message: response.data.message });
                    this.$store.dispatch('setRemainedAmount', response.data.remained_amount);
                    this.closeModal();
                    this.showDownloadModal();

                }).catch((error) => {
                    let message = error.response.data.message;
                    if (error.response.data.status === 102) {
                        this.$toast.warn({ title: 'Warning', message: message });
                    }else if (error.response.data.status === 103) {
                        this.$toast.error({ title: 'Error', message: message });
                    }

                    this.closeModal();
                });
            },

            /**
             * Close Modal
             */
            closeModal() {
                this.$nextTick(() => {
                    $('#buyModal').modal('hide');
                });
            },

            /**
             * Show download Modal
             */
            showDownloadModal() {
                this.$nextTick(() => {
                    $('#downloadModal').modal('show');
                });
            },

        }
    }
</script>

<template>
    <div>
        <div class="ms_save_modal">
            <div id="buyModal" class="modal centered-modal" role="dialog">
                <div class="modal-dialog">
                    <!-- Modal content-->
                    <div class="modal-content">
                        <div class="modal-content">
                            <button type="button" class="close" data-dismiss="modal">
                                <i class="fa_icon form_close"></i>
                            </button>
                            <div class="modal-body">
                                <h1>Are you sure you want to buy ?</h1>
                                <div class="clr_modal_btn">
                                    <a href="#" @click.prevent="buy">Buy</a>
                                    <a href="#" data-dismiss="modal">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <download-modal :song_id="song_id"></download-modal>
    </div>
</template>
