<script type="text/ecmascript-6">

    import { swiper, swiperSlide } from 'vue-awesome-swiper';

    export default {
        name: 'collections',

        props: {
            data:{
                type:Object,
            }
        },

        components: {
            swiper,
            swiperSlide,
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: false,
            swiperOption: {
                slidesPerView: 8,
                spaceBetween: 15,
                slidesPerGroup: 4,
                // loop: true,
                speed: 500,
                navigation: {
                    nextEl: '.swiper-button-next-collection',
                    prevEl: '.swiper-button-prev-collection',
                },
                breakpoints: {
                    2900: {
                        slidesPerView: 8,
                    },
                    1900: {
                        slidesPerView: 8,
                    },
                    1800: {
                        slidesPerView: 8,
                    },
                    1400: {
                        slidesPerView: 8,
                    },
                    1124: {
                        slidesPerView: 6,
                    },
                    1024: {
                        slidesPerView: 6,
                    },
                    992: {
                        slidesPerView: 6,
                    },
                    834: {
                        slidesPerView: 5,
                    },
                    768: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                    },
                    640: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                    },
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    },
                    375: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    }
                },
            },
            start:false,
            end:false,
        }),


        /**
         * Created the component.
         */
        created() {
        },

        mounted(){
            let _this = this;
            this.$nextTick(() => {
                _this.swiper.on('slideChange', function () {
                    if(!_this.isBeginning){
                        $('.swiper-button-prev-collection').removeClass('d-none');
                    }else{
                        $('.swiper-button-prev-collection').addClass('d-none');
                    }
                    if(!_this.isEnd){
                        $('.swiper-button-next-collection').removeClass('d-none');
                    }else{
                        $('.swiper-button-next-collection').addClass('d-none');
                    }
                });
            });
        },

        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper
            },
            isBeginning() {
                return this.swiper.isBeginning;
            },
            isEnd() {
                return this.swiper.isEnd;
            },
        },

        methods: {
            /**
             *  Get the collections.
             */
            async fetchCollections() {
                axios.get('/api/mmn/featured-collections').then((response) => {
                    this.swiper.slideTo();
                    this.start = this.isBeginning;
                    this.end = this.isEnd;
                    console.log(this.start+" and "+this.end);
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

        }

    }
</script>

<template>
    <div class="ms_radio_wrapper" v-show="data.data.length > 0">
        <div class="ms_heading">
            <h1>{{data.title}}</h1>
            <!-- <span class="veiw_all"><router-link to="/collections">view more</router-link></span> -->
        </div>

        <div class="row" v-show="loading">
            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>
        </div>

        <div v-show="!loading" class="ms_feature_slider swiper-container">
            <swiper :options="swiperOption"  ref="mySwiper">
                <!-- slides -->
                <template v-for="collection in data.data">
                    <swiper-slide>
                        <router-link tag="a" :to="{ name: 'collection', params: { id: collection.uuid }}" :title="collection.name">
                            <div class="ms_rcnt_box">
                                <div class="ms_rcnt_box_img">
                                    <img :src="collection.image" alt="" alt="" onError="this.onerror=null;this.src='/images/album/collection_playlist.png';">
                                    <!-- <img v-lazy="collection.image" alt=""> -->
                                    <div class="ms_main_overlay">
                                        <div class="ms_box_overlay"></div>
                                        <!-- <div class="ms_play_icon">
                                            <router-link :to="{ name: 'collection', params: { id: collection.uuid }}" :title="collection.name">
                                                <img src="/images/svg/play.svg" alt="">
                                            </router-link>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="ms_rcnt_box_text">
                                    <h3><router-link tag="a" :to="{ name: 'collection', params: { id: collection.uuid }}">{{ collection.name }}</router-link>
                                    </h3>
                                </div>
                            </div>
                        </router-link>
                    </swiper-slide>
                </template>

            </swiper>

        </div>
        <!-- Add Arrows -->
        <template v-if="data.data.length > 8">
            <div class="swiper-button-next-collection slider_nav_next"></div>
            <div class="swiper-button-prev-collection slider_nav_prev d-none"></div>
        </template>

    </div>
</template>
