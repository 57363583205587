export default {
  player: state => state.player,
  isPlay: state => state.isPlay,
  playerCurrentTime: state => state.playerCurrentTime,
  playerDuration: state => state.playerDuration,
  playerTracks: state => state.playerTracks,
  playerCurrentTrack: state => state.playerCurrentTrack,
  playerOriginalTracks: state => state.playerOriginalTracks,
  playerSeeking: state => state.playerSeeking,
};
