<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'event-by-category',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Events',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            title: '',
            events: [],
            links: {
                first: null,
                last: null,
                next: null,
                prev: null
            },
            meta: {
                current_page: 1,
                from: 1,
                last_page: 1,
                per_page: 1,
                to: 1,
                total: 1,
            },
        }),


        /**
         * Created the component.
         */
        created() {
            this.links.next = `/api/mmn/events/${this.$route.params.slug}`;
            this.fetchEvents();
        },


        methods: {
            /**
             *  Get the events.
             */
            async fetchEvents() {
                if (this.loading === true && this.links.first) {
                    this.$toast.warn({ title: 'Warning', message: 'Loading ...' });
                    return;
                }

                if (this.links.next === null) {
                    return;
                }

                this.loading = true;

                axios.get(this.links.next).then((response) => {
                    this.title = response.data.title;
                    this.events.push(...response.data.events);
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },
        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <div class="ms_content_wrapper ms_genres_single ms_profile">

            <main-header/>

            <div class="header_padder_top"></div>


            <div class="row" id="video">
                <div class="col-lg-12">
                    <div class="ms_heading">
                        <h1>{{ title }} </h1>
                    </div>
                </div>

                <div v-for="event in events" class="col-lg-4 col-md-4 col-6">
                    <div class="ms_genres_box">
                        <router-link :to="{ name: 'Event', params: { id : event.id}}">
                            <img :src="event.image" alt="" class="img-fluid" />
                        </router-link>
                        <div class="ms_box_overlay_on">
                            <div class="ovrly_text_div">
                                <span class="ovrly_text1"><router-link :to="{ name: 'Event', params: { id : event.id}}">{{ event.name }}</router-link></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!events.length" class="col-lg-12 text-center padder_bottom60" style="color: #fff">
                    No Event List
                </div>

            </div>

            <div class="more_loading" v-show="loading">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
            </div>

            <div class="ms_view_more padder_bottom20" v-show="meta.current_page !== meta.last_page">
                <a href="#" @click.prevent="fetchEvents()" class="ms_btn">view more</a>
            </div>

        </div>


    </layout>
</template>
