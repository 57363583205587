<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'albums',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Artists',
            }
        },


        /**
         * The component's data.
         */
        data: () => ({
            artists: [],
            album_id : null,
            category: {
                name : null,
            },
            url: '',
            loading: true,
            links: {
                first: null,
                last: null,
                next: null,
                prev: null
            },
            meta: {
                current_page: 1,
                from: 1,
                last_page: 1,
                per_page: 1,
                to: 1,
                total: 1,
            },
        }),


        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                isAuthenticated: 'isAuthenticated'
            }),
        },


        /**
         * Created the component.
         */
        created() {
            this.links.next = `/api/mmn/artist-by-category/${this.$route.params.id}`;
            this.fetchArtistCategory();
            this.fetchArtists();
        },


        methods: {
            /**
             *  Get the artist.
             */
            async fetchArtists() {

                if (this.loading === true && this.links.first) {
                    this.$toast.warn({ title: 'Warning', message: 'Loading ...' });
                    return;
                }

                if (this.links.next === null) {
                    return;
                }

                this.loading = true;

                axios.get(this.links.next).then((response) => {
                    this.artists.push(...response.data.data);
                    this.links = response.data.links;
                    this.meta = response.data.meta;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },


            /**
             *  Get the category.
             */
            async fetchArtistCategory() {
                axios.get(`/api/mmn/artist-category/${this.$route.params.id}`).then((response) => {
                    this.category = response.data;
                }).catch((error) => {
                });
            },
        }
    }
</script>

<template>
    <layout>

        <!---Main Content Start--->
        <div class="ms_content_wrapper ms_genres_single ms_profile padder_top100">

            <main-header/>

            <div class="padder_bottom30">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="ms_heading">
                            <h1>{{ category.name }}</h1>
                        </div>
                    </div>

                    <div v-for="artist in artists" class="col-lg-2 col-md-2 col-6">
                        <div class="ms_rcnt_box marger_bottom30">
                            <div class="ms_rcnt_box_img">
                                <img :src="artist.image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/album/album.jpg';">
                                <div class="ms_main_overlay">
                                    <div class="ms_box_overlay"></div>
                                    <div class="ms_play_icon">
                                        <router-link :to="{ name: 'artist', params: { id : artist.uuid }}" :title="artist.name_eng">
                                            <img src="/images/svg/play.svg" alt="">
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="ms_rcnt_box_text">
                                <h3><router-link :to="{ name: 'artist', params: { id : artist.uuid }}">{{ artist.name_eng }}</router-link></h3>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="more_loading" v-show="loading">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>

                <div class="ms_view_more padder_bottom20" v-show="meta.current_page !== meta.last_page">
                    <a href="#" @click.prevent="fetchArtists()" class="ms_btn">view more</a>
                </div>

            </div>
        </div>


    </layout>
</template>
