<script type="text/ecmascript-6">

import { mapGetters } from 'vuex';
import QrcodeVue from 'qrcode.vue'


export default {
    /**
     * Computed these properties.
     */
    components: {
        QrcodeVue,
    },
    data() {
        return {
            loading: false,
            qrCode: "",
            subscription_plans: [],
            plan_id: "",
        }
    },
    computed: {
        ...mapGetters({
            isAuthenticated: 'isAuthenticated',
            user: 'getProfile',
        }),
        qrValue() {
            return `https://api.qrserver.com/v1/create-qr-code/?data=${ this.qrCode }&amp;size=100x100`
        }
    },

    mounted() {

        axios.get('/api/v2/get-subscription').then((response) => {
            this.subscription_plans = response.data.data
            // this.$toast.success({ title: 'Success', message: response.data.message });
            // this.$store.dispatch('setSubscribe', response.data);

        }).catch((error) => {
            console.log(error);
            this.$toast.warn({ title: 'Warning !!', message: error.response.data.message });
            this.closeModal();
        });

    },

    methods: {
        /**
         * Subscribe
         */
        // subscribe_price_id: this.plan
        subscribeWithKpay() {

            this.loading = true
            axios.post('/api/v2/payment/kbzpay/create-order-web', { "item": { "id": this.plan_id, "type": "subscription" } }).then((response) => {
                // console.log(response);
                let resp = response.data.response.Response;
                let params = {
                    appid: "kp1c1bf4af979e4e1b835ca9bab89295",
                    merch_code: "70150001",
                    nonce_str: response.data.nonce_str,
                    prepay_id: resp.prepay_id,
                    qrCode: resp.qrCode,
                    timestamp: Math.floor(Date.now() / 1000),
                    sign: response.data.new_sign
                }

                // this.$toast.success({ title: 'Success', message: response.data.message });
                // this.$store.dispatch('setSubscribe', response.data);

                this.closeModal();
                this.showQRModal(resp.qrCode);

                this.loading = false

            }).catch((error) => {
                this.$toast.warn({ title: 'Warning !!', message: error.response.data.message });
                this.closeModal();
            });

        },
        subscribeWithCoins() {
            axios.post('/api/mmn/subscribe', { plan_id: this.plan_id, streaming_quality: this.user.streaming_quality }).then((response) => {
                this.$toast.success({ title: 'Success', message: response.data.message });
                this.$store.dispatch('setSubscribe', response.data);
                this.closeModal();

            }).catch((error) => {
                this.$toast.warn({ title: 'Warning !!', message: error.response.data.message });
                this.closeModal();
            });
        },
        subscribeWithMpt() {
            axios.post('/api/v2/payment/mpt/create-order-web', { "item": { "id": this.plan_id, "type": "subscription" } }).then((response) => {
                window.location.href=response.data.url;                
            }).catch((error) => {
                this.$toast.warn({ title: 'Warning !!', message: error.response.data.message });
                this.closeModal();
            });
        },
        routeToEditSubscriptionPage() {
            this.$router.push('/user/edit-renewal');
            this.closeModal();
        },

        /**
         * Close Modal
         */
        closeModal() {
            this.$nextTick(() => {
                $('#subscribeModal').modal('hide');
            });

            this.$nextTick(() => {
                $('#paymentModal').modal('hide');
            });
        },

        showQRModal(qrCode) {

            this.qrCode = qrCode
            this.$nextTick(() => {
                $('#qrModal').modal('show');
            });
        },

        showPaymentModal() {
            this.$nextTick(() => {
                $('#paymentModal').modal('show');
                $('#subscribeModal').modal('hide');
            });
        }

    }
}
</script>

<template>
    <div class="ms_save_modal" v-if="isAuthenticated">
        <div id="subscribeModal" class="modal  centered-modal" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="fa_icon form_close"></i>
                        </button>
                        <div class="modal-body">

                            <!--                            <div class="form-group">-->
                            <!--                                <select class="form-control" id="exampleFormControlSelect1" v-model="plan">-->
                            <!--                                    <option v-for="price in prices" :value="price.id">{{ price.label }} - {{ price.amount }} Ks</option>-->
                            <!--                                </select>-->
                            <!--                            </div>-->

                            <!-- case 1 -->
                            <!-- <div v-if="user.subscribe && user.auto_renewal">
                                <h1>Do you want to cancel subscription ?</h1>
                                <br>

                                <div class="clr_modal_btn">
                                    <a href="#" @click.prevent="routeToEditSubscriptionPage()">Yes, I am.</a>
                                    <a href="#" data-dismiss="modal">No</a>
                                </div>
                            </div>

                            <div v-if="user.subscribe && !user.auto_renewal">
                                <h1>Do you want to active auto subscription?</h1>
                                <br>

                                <div class="clr_modal_btn">
                                    <a href="#" @click.prevent="routeToEditSubscriptionPage()">Yes, I am.</a>
                                    <a href="#" data-dismiss="modal">No</a>
                                </div>
                            </div> -->

                            <div v-if="!user.subscribe">
                                <h1 v-if="loading">Please wait while loading....</h1>
                                <h1 v-else>Please choose subscription plan</h1>

                                <div class="subscription_plan_container">
                                    <div v-for="subscription_plan in subscription_plans"
                                        class="subscription_plan_list_item">
                                        <input type="radio" :id="subscription_plan.id" :value="subscription_plan.id"
                                            v-model="plan_id" class="" />
                                        <label :for="subscription_plan.id">{{ subscription_plan.label }} : {{
                                            subscription_plan.amount }} MMK</label>
                                    </div>
                                </div>


                                <br>
                                <div v-show="!loading" class="clr_modal_btn">
                                    <a href="#" @click.prevent="showPaymentModal()">
                                        subscribe
                                    </a>
                                    <a href="#" data-dismiss="modal"> cancel</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="qrModal" class="modal  centered-modal" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="fa_icon form_close"></i>
                        </button>
                        <div class="modal-body">
                            <div v-if="!user.subscribe">
                                <h1>QR Payment</h1>
                                <br>

                                <h2>Please open KBZPay Application and Scan this QR Code to complete payment</h2>
                                <div class="" style="margin-bottom:10px">
                                    <img id='qrcode'
                                        :src="qrValue"
                                        alt="" title="HELLO" width="200" height="200" />
                                </div>
                                <h2>After payment, you can close and refresh the page.</h2>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="paymentModal" class="modal  centered-modal" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="fa_icon form_close"></i>
                        </button>
                        <div class="modal-body">
                            <div v-if="!user.subscribe">
                                <h1 v-if="loading">Loading KBZPay QRCode....</h1>
                                <h1 v-else>Please choose payment method</h1>
                                <br>
                                <div v-show="!loading" class="row">
                                    <div class="col-md-4 payment_image_container">
                                        <a href="#" @click.prevent="subscribeWithMpt()">
                                            <img src="/payment/mpt.png" alt="" srcset="" width="100" height="100">
                                        </a>
                                    </div>
                                    <div class="col-md-4 payment_image_container">
                                        <a href="#" @click.prevent="subscribeWithKpay()">
                                            <img src="/payment/kbzpay.png" alt="" srcset="" width="100" height="100">
                                        </a>
                                    </div>


                                    <div class="col-md-4 payment_image_container">
                                        <a href="#" @click.prevent="subscribeWithCoins()">
                                            <div class="coins_image_container">
                                                <img src="/payment/coins.png" alt="" srcset="" width="50" height="50">
                                                <div class="amount">
                                                    My Coins <br>
                                                    {{ user.remained_amount }}
                                                </div>

                                            </div>
                                        </a>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
