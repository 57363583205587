<script type="text/ecmascript-6">
    import {bus} from "../../app";
    export default {
        props: {
            fetchAlbum:Function,
        },
       data:()=>({
            id:'',
            title:'',
            description:'',
            checked:false,
        }),
       created(){
        // console.log(this.title);
        bus.$on('playlist', (data) => {
            this.id=data.id;
            this.title=data.name;
            this.description=data.description;
            if(data.public){
                this.checked=true;
            }
        })
       },
        methods: {
            /**
             * Edit playlist
             */
            edit_playlist() {
                if(this.title != null) {
                    axios.post('/api/mmn/edit/my-play-list',{id:this.id,name:this.title,description:this.description,checked:this.checked}).then((response) => {
                        $('#edit_playlist').modal('hide');
                        this.$toast.success({ title: 'Success', message: response.data.message });
                        this.fetchAlbum();

                    }).catch((error) => {
                    });
                }
            },

            /**
             * Close Modal
             */
            closeModal() {
                this.$nextTick(() => {
                    $('#edit_playlist').modal('hide');
                });
            }

        }
    }
</script>
<template>
    <div class="ms_save_modal">
        <div id="edit_playlist" class="modal centered-modal" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="fa_icon form_close"></i>
                        </button>
                        <div class="modal-body">
                            <h1>Edit Playlist</h1>
                            <div class="row">
                                <div class="col-12 pull-left">
                                    <form @submit.prevent="edit_playlist()">
                                        <div class="form-group mt-2">
                                            <input type="text" v-model="title" class="form-control" name="name" style="border-radius: 10px" placeholder="Title .....">
                                        </div>
                                        <div class="form-group">
                                            <label>Description</label>
                                            <textarea v-model="description" class="form-control" rows="3" style="height: 70px;"></textarea>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 offset-0">
                                                <div class="form-group">
                                                    <input type="radio" id="public" v-model="checked" :value="true" checked name="checked" ><label for="public" style="margin-left: 5px;">Public</label>
                                                    <input type="radio" id="private" v-model="checked" :value="false" name="checked"><label for="private" style="margin-left: 5px">Private</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clr_modal_btn">
                                            <a href="#" @click.prevent="edit_playlist()" id="addplaylistalbumbtn" style="color: rgba(24,170,2,0.97)">Edit</a>
                                            <a href="#" data-dismiss="modal">Close</a>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
