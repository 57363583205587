<script type="text/ecmascript-6">

    export default {

        props: {
            show: {
                type: Boolean,
                default: false
            },
            url : {
                type: String
            },
            album_id : {
                type: String
            }
        },

        methods: {
            /**
             * Buy music
             */
            login() {
                this.closeModal();
                this.$router.push('/login');
            },

            /**
             * Close Modal
             */
            closeModal() {
                this.$nextTick(() => {
                    $('#loginModal').modal('hide');
                });
            }

        }
    }
</script>
<template>
    <div class="ms_save_modal">
        <div id="loginModal" class="modal centered-modal" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="fa_icon form_close"></i>
                        </button>
                        <div class="modal-body">
                            <h1>You need to login first!</h1>
                            <div class="clr_modal_btn">
                                <a href="#" @click.prevent="login" style="color:red">Login</a>
                                <a href="#" data-dismiss="modal" style="color:red">Close</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
