<script type="text/ecmascript-6">

    export default {

        methods: {
            /**
             * Buy music
             */
            routeToPaymentPage() {
                this.$router.push('/user/payment');
                this.closeModal();
            },

            /**
             * Close Modal
             */
            closeModal() {
                this.$nextTick(() => {
                    $('#insufficientToSubscribeModel').modal('hide');
                });
            },

        }
    }
</script>

<template>

    <div class="ms_save_modal">
        <div id="insufficientToSubscribeModel" class="modal  centered-modal" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="fa_icon form_close"></i>
                        </button>
                        <div class="modal-body">
                            <h1>You have unsufficient amount, please Top-up to subscribe.</h1>
                            <div class="clr_modal_btn">
                                <a href="#" @click.prevent="routeToPaymentPage()">Click here to redeem (Top Up)</a>
                                <a href="#" data-dismiss="modal">Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
