<script type="text/ecmascript-6">

    import { swiper, swiperSlide } from 'vue-awesome-swiper';
    import {mapGetters} from 'vuex';

    export default {
        name: 'album',
        props:{
            albums : {
                type: Array
            }
        },

        components: {
            swiper,
            swiperSlide,
        },

        /**
         * The component's data.
         */
        data: () => ({
            count: 0,
            // albums: [],
            loading: false,
            swiperOption: {
                slidesPerView: 8,
                slidesPerColumn: 1,
                spaceBetween: 15,
                slidesPerGroup: 4,
                // loop: true,
                speed: 500,
                navigation: {
                    nextEl: '.swiper-artist-album-next',
                    prevEl: '.swiper-artist-album-prev',
                },
                breakpoints: {
                    2900: {
                        slidesPerView: 8,
                    },
                    1900: {
                        slidesPerView: 8,
                    },
                    1800: {
                        slidesPerView: 8,
                    },
                    1400: {
                        slidesPerView: 8,
                    },
                    1124: {
                        slidesPerView: 6,
                    },
                    1024: {
                        slidesPerView: 6,
                    },
                    992: {
                        slidesPerView: 6,
                    },
                    834: {
                        slidesPerView: 5,
                    },
                    768: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                    },
                    640: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                    },
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    },
                    375: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    }
                },
            },
            show: false,
        }),


        /**
         * Created the component.
         */
        created() {
        },

        mounted(){
            let _this = this;
            this.$nextTick(() => {
                _this.swiper.on('slideChange', function () {
                    if(!_this.isBeginning){
                        $('.swiper-artist-album-prev').removeClass('d-none');
                    }else{
                        $('.swiper-artist-album-prev').addClass('d-none');
                    }
                    if(!_this.isEnd){
                        $('.swiper-artist-album-next').removeClass('d-none');
                    }else{
                        $('.swiper-artist-album-next').addClass('d-none');
                    }
                });
            });
        },


        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper
            },
            ...mapGetters({
                isAuthenticated: 'isAuthenticated',
                user : 'getProfile',
            }),
            isBeginning() {
                return this.swiper.isBeginning;
            },
            isEnd() {
                return this.swiper.isEnd;
            },
        },

        methods: {
            buyAlbum(uuid) {
                this.$store.dispatch('setAlbumUUid', uuid).then(() => {
                    this.$nextTick(() => {
                        $('#buyAlbumModal').modal('show');
                    });
                });
            },

        }

    }
</script>

<template>
    <div class="ms_radio_wrapper" v-show="albums.length > 0" style="position:relative;">
        <div class="ms_heading">
            <h1>Related Albums</h1>
            <span class="veiw_all" v-if="albums.length > 8">
                <router-link tag="a" :to="{ name: 'artistAlbums', params: { artist_id: $route.params.id }}">view more</router-link>
            </span>
        </div>

        <div class="row" v-show="loading">
            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>
        </div>

        <div v-show="!loading" class="ms_radio_slider swiper-container">
            <swiper :options="swiperOption" ref="mySwiper">
                <!-- slides -->
                <template v-for="album in albums">
                    <swiper-slide>

                        <div class="ms_rcnt_box">
                            <div class="ms_rcnt_box_img">
                                <img :src="album.image" alt="" onError="this.onerror=null;this.src='/images/album/Album.png';">
                                <div class="ms_main_overlay">
                                    <router-link tag="a" :to="{ name: 'Album', params: { uuid: album.uuid }}" :title="album.name_mm">
                                        <div class="ms_box_overlay"></div>
                                        <div class="ms_play_icon">
                                            <a href="javascript:;" @click.prevent="playAllSong(album.uuid)">
                                                <img src="/images/svg/play2.svg" alt="">
                                            </a>
                                        </div>
                                    </router-link>

                                    <div class="ms_more_icon" data-toggle="popup">
                                        <img src="/images/svg/more.svg" alt="">
                                    </div>
                                </div>
                            </div>
                            <ul class="more_option">
                                <template v-if="isAuthenticated">
                                    <li><a href="#" @click.prevent="saveAlbum(album.uuid)"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                    <li><a href="#" @click.prevent="syncAlbumFavourite(album.uuid)"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites </a></li>
                                    <li v-if="album.is_store && album.price > 0 && !album.is_bought">
                                        <a href="#" @click="buyAlbum(album.uuid)"><span class="opt_icon"><span class="fa fa-shopping-cart"></span></span>{{ album.price.toLocaleString() }} Kyat</a>
                                    </li>
                                </template>

                                <template v-else>
                                    <li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                    <li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites </a></li>
                                    <li v-if="album.is_store && album.price > 0">
                                        <a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-shopping-cart"></span></span>{{ album.price.toLocaleString() }} Kyat</a>
                                    </li>
                                </template>

                                <li><a :href="shareFacebookLink(album.image,'albums',album.name_mm,album.id,album.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share to facebook</a></li>
                                <li><a :href="shareTwitterLink(album.image,'albums',album.name_mm,album.id,album.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share to twitter</a></li>
                                <li><a href="#" @click.prevent="copyText('album/'+album.uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                            </ul>
                            <div class="ms_rcnt_box_text">
                                <h3><router-link tag="a" :to="{ name: 'Album', params: { uuid: album.uuid }}">{{ album.name_mm | trunc(15) }}</router-link></h3>
                                <p>
                                    <template v-if="album.artists.length > 3">
                                        <a href="javascript:;">Various Artists</a>
                                    </template>

                                    <template v-else>
                                        <template v-for="(artist, index1) in album.artists">
                                            <router-link :to="{ name : 'artist', params:{ id : artist.uuid }}">{{ artist.name_eng | trunc(10)}} <span v-if="isNotLast(album.artists, index1)">, </span></router-link>
                                        </template>
                                    </template>
                                </p>
                            </div>
                        </div>
                    </swiper-slide>
                </template>

            </swiper>

        </div>
        <!-- Add Arrows -->
        <div class="swiper-artist-album-next slider_nav_next" v-show="albums.length > 8"></div>
        <div class="swiper-artist-album-prev slider_nav_prev d-none" v-show="albums.length > 8"></div>

    </div>
</template>
