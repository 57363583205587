<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'notification',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                // if no subcomponents specify a metaInfo.title, this title will be used
                title: 'Notification',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            notification: {
                title : '',
                image : '',
            }
        }),


        /**
         * Created the component.
         */
        created() {
            this.fetchNotification();
        },


        methods: {
            /**
             *  Get the notification.
             */
            async fetchNotification() {
                axios.get(`/api/mmn/notification/${this.$route.params.id}`).then((response) => {
                    this.notification = response.data.data;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },
        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <div class="ms_content_wrapper ms_profile padder_top80">

            <main-header/>

            <div class="ms_radio_wrapper ms_purchase_wrapper">

            <!--- blog single section start --->
            <!-- <div class="ms_blog_single_wrapper"> -->
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="ms_blog_single" v-if="!loading">
                            <div class="blog_single_content">
                                <h3 class="ms_blog_title">{{ notification.title }}</h3>
                                <div v-html="notification.description"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </layout>
</template>
