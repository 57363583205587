<script type="text/ecmascript-6">
import { mapGetters } from 'vuex';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import Layout from '../../layouts/Index.vue'
import MainHeader from '../../layouts/Header.vue'
import DeepView from '../../components/Component/DeepView.vue'

export default {
    name: 'album',

    components: {
        swiper,
        swiperSlide,
        Layout,
        MainHeader,
        DeepView
    },

    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: this.album.name_eng,
        }
    },

    /**
     * The component's data.
     */
    data: () => ({
        songEndPoint: 15,
        album_loading: true,
        song_loading: true,
        related_album_loading: true,
        album_image_loading: true,
        recommended_videos_loading:true,
        album: {
            songs: [],
            artists: [],
            feats: [],
            view_count: 0,
            album_name: '',
            album_eng: '',
            image: '',
            sub_menu: {
                free: false,
                streaming: false,
                store: true,
            }
        },
        swiperOption: {
            slidesPerView: 8,
            slidesPerColumn: 1,
            spaceBetween: 15,
            slidesPerGroup: 4,
            loop: false,
            speed: 1500,
            navigation: {
                nextEl: '.swiper-button-next-related',
                prevEl: '.swiper-button-prev-related',
            },
            breakpoints: {
                2900: {
                    slidesPerView: 8,
                },
                1900: {
                    slidesPerView: 8,
                },
                1800: {
                    slidesPerView: 8,
                },
                1400: {
                    slidesPerView: 8,
                },
                1124: {
                    slidesPerView: 6,
                },
                1024: {
                    slidesPerView: 6,
                },
                992: {
                    slidesPerView: 6,
                },
                834: {
                    slidesPerView: 5,
                },
                768: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    slidesPerGroup: 1,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    slidesPerGroup: 1,
                }
            },
        },
        relatedAlbums: [],
        recommended_videos:[],
        linkData: {
            campaign: 'album 123',
            channel: 'facebook',
            feature: 'dashboard',
            stage: 'new user',
            tags: ['tag1', 'tag2', 'tag3'],
            alias: '',
            data: {
                'custom_bool': true,
                'custom_int': Date.now(),
                'custom_string': 'hello',
                '$og_title': 'Title',
                '$og_description': 'Description',
                '$og_image_url': 'http://lorempixel.com/400/400'
            }
        },
        videoSwiperOption: {
            slidesPerView: 5,
            slidesPerColumn: 1,
            spaceBetween: 10,
            slidesPerGroup: 3,
            loop: false,
            speed: 1500,
            navigation: {
                nextEl: '.swiper-button-next-recommend-video',
                prevEl: '.swiper-button-prev-recommend-video',
            },
            breakpoints: {
                1800: {
                    slidesPerView: 5,
                },
                1400: {
                    slidesPerView: 5,
                },
                992: {
                    slidesPerView: 5,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    slidesPerGroup: 2,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    slidesPerGroup: 2,
                }
            },
        },
    }),


    /**
     * Computed these properties.
     */
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper;
        },
        swiperVideo() {
            return this.$refs.myVideoSwiper.swiper;
        },
        ...mapGetters({
            isPlay: 'isPlay',
            playerCurrentTrack: 'playerCurrentTrack',
            isAuthenticated: 'isAuthenticated',
        }),

        totalPlay: function() {
            let sum = 0;
            return this.album.songs.reduce((sum, song) => sum + song.play_count, 0);
        },

        songs: function() {
            return this.album.songs.slice(0, this.songEndPoint);
        },
        isBeginning() {
            return this.swiper.isBeginning;
        },
        isEnd() {
            return this.swiper.isEnd;
        },
    },
    mounted() {
        console.log(this.$refs);
        let self = this;
        this.$nextTick(() => {
            branch.link(self.linkData, function(err, link) {
                console.log(link);
            });
        });
        this.$nextTick(() => {
            self.swiper.on('slideChange', function () {
                if(!self.isBeginning){
                    $('.swiper-button-prev-related').removeClass('d-none');
                }else{
                    $('.swiper-button-prev-related').addClass('d-none');
                }
                if(!self.isEnd){
                    $('.swiper-button-next-related').removeClass('d-none');
                }else{
                    $('.swiper-button-next-related').addClass('d-none');
                }
            });
        });
    },

    /**
     * Created the component.
     */
    created() {
        this.fetchAlbum();
        this.fetchRelatedAlbum();
    },


    methods: {
        /**
         * Get the album.
         */
        fetchAlbum() {
            axios.get('/api/mmn/album/' + this.$route.params.uuid).then((response) => {
                this.album = response.data.data;
                this.linkData.data.$og_image_url = this.album.image;
                this.linkData.data.$og_title = this.album.name_mm;
                this.linkData.campaign = "album " + this.album.id;
                if (this.album.songs && this.album.songs.length > 0 && this.$route.query.autoplay === 'yes') {
                    this.handleClickTrack(this.album.songs[0], this.album.songs)
                }
                this.song_loading = false;
                this.album_loading = false;
                this.fetchRecommendedVideos(this.album.id);
            }).catch((error) => {
                this.song_loading = false;
                this.album_loading = false;
            });
        },

        /**
         * Get the related album.
         */
        fetchRelatedAlbum() {
            axios.get('/api/mmn/related_album/' + this.$route.params.uuid).then((response) => {
                this.relatedAlbums = response.data.data.slice(0, 15);
                this.related_album_loading = false;
            }).catch((error) => {
                this.related_album_loading = false;
            });
        },

        fetchRecommendedVideos(id) {
            axios.get('/api/mmn/video/'+id+'/by-album').then((response) => {
                this.recommended_videos = response.data.data;
                this.recommended_videos_loading = false;
            }).catch((error) => {
                this.recommended_videos_loading = false;
            });
        },

        /**
         *  Like the album.
         */
        likeAlbum() {
            axios.post(`/api/mmn/like/album/${this.album.uuid}`).then((response) => {
                this.album.is_like = response.data.is_like;
            }).catch((error) => {

            })
        },

        /**
         *  Favourite the album.
         */
        favouriteAlbum() {
            axios.post(`/api/mmn/favourite-sync/album/${this.album.uuid}`).then((response) => {
                this.album.is_favourite = response.data.is_favourite;
                if (this.album.is_favourite) {
                    this.album.favourite_count++;
                } else {
                    this.album.favourite_count--;
                }
            }).catch((error) => {

            })
        },

        /**
         *  Favourite the song.
         */
        // favouriteSong(song, song_id) {
        //     axios.post(`/api/mmn/favourite/song/${song_id}`).then((response) => {
        //         song.is_favourite = response.data.is_favourite;
        //     }).catch((error) => {

        //     })
        // },
        showPlaylistModal() {
            $('#playlistsModal').modal('show');
        },
        viewMoreSong() {
            this.song_loading = true;
            this.songEndPoint += 15;
            this.song_loading = false;
        }
    }
}

</script>
<style scoped>
    .dropdown-item {
    font-size: 14px;
    color: #777;
}
.dropdown-item span {
    margin-right: 5px;
}
</style>
<template>
    <layout>
        <deep-view :url="getDeepLink('albums',album.id,album.uuid)" :songs="songs"></deep-view>
        <!----Album Single Section Start---->
        <div style="background: #1E1E25;">
            <div class="ms_album_single_wrapper ms_profile">
                <main-header />
                <div class="album-cover-wrapper" :style="{ 'background-image': 'url(' + album.image + ')' }" style="filter: blur(120px);height:315px;position:absolute;width: 100%;background-size: cover; background-position:center; opacity: 0.4"></div>
                <div class="album-cover-wrapper" v-show="!song_loading">
                    <!-- <img :src="album.image" alt="placeholder+image"> -->
                    <div class="album_single_data">
                        <div class="album_single_img">
                            <div class="ms_rcnt_box_img">
                                <img :src="album.image" :alt="album.name_mm" onerror="this.src='/images/album/Album.png';">
                            </div>
                            <!-- <div class="load-album-image" v-show="album_loading"></div> -->
                            <!-- <img :src="album.image" :alt="album.name_mm" onError="this.onerror=null;this.src='/images/album/Album.png';" class="img-fluid"> -->
                        </div>
                        <div class="album_single_text">
                            <h2>{{ album.name_mm }}</h2>
                            <p class="singer_name">
                                <!-- <template v-if="album.artists.length > 4">
                                <a href="javascript:;">Various Artists</a>
                            </template> -->
                                <template>
                                    <div style="position: relative;">
                                        <template v-for="(artist, index1) in album.artists">
                                            <img class="mr-1" :src="artist.image ? artist.image : ''" :alt="artist.name_eng" onerror="this.onerror=null;this.src='/images/album/Artist.png';"style="border-radius: 30px;width: 23px;position: absolute;" :style="{'left':index1*12+'px'}">
                                        </template>
                                    </div>
                                    <div :style="{'margin-left':album.artists.length * 12+'px'}" style="padding-left: 20px;">
                                        <template v-for="(artist, index1) in album.artists.slice(0,2)">
                                            <router-link :to="{ name: 'artist', params: { id : artist.uuid }}" style="color:#fff">
                                                {{ artist.name_eng }}
                                                <span v-if="isNotLast(album.artists, index1)">, </span>
                                            </router-link>
                                        </template>
                                        <!-- <template v-if="album.artists.length > 2"> +2 more</template> -->
                                        <div class="btn-group dropright" v-if="album.artists.length + album.feats.length > 2 || album.feats.length > 0">
                                            <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background: transparent;border: none;color: #fff;padding-left: 0;">
                                                <span class="ml-2">
                                                    ,<template v-if="album.artists.length + album.feats.length > 2 ">+{{album.artists.length + album.feats.length - 2}}</template> more
                                                </span>
                                            </button>
                                            <div class="dropdown-menu album_artist_detail" style="background: #28282E;border-radius: 0px;">
                                                <a class="dropdown-item" style="color: #fff" href="#" v-for="(artist, index1) in album.artists">
                                                    <span class="opt_icon">
                                                        <img class="mr-1" :src="artist.image" :alt="artist.name_eng" onError="this.onerror=null;this.src='/images/album/Artist.png';" style="border-radius: 30px;width: 40px;">
                                                    </span>{{artist.name_eng}}
                                                </a>
                                                <p v-if="album.feats.length" class="dropdown-item">Feats :</p>
                                                <a class="dropdown-item" style="color: #fff" href="#" v-for="(artist, index1) in album.feats">
                                                    <span class="opt_icon">
                                                        <img class="mr-1" :src="artist.image" :alt="artist.name_eng" onError="this.onerror=null;this.src='/images/album/Artist.png';" style="border-radius: 30px;width: 40px;">
                                                    </span>{{artist.name_eng}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <!-- <template v-if="album.feats.length">
                                <br>
                                Feat:
                                <template v-for="(feat, index2) in album.feats">
                                    <router-link :to="{ name: 'artist', params: { id : feat.uuid }}">
                                        {{ feat.name_eng }}
                                        <span v-if="isNotLast(album.feats, index2)">, </span>
                                    </router-link>
                                </template>
                            </template> -->
                            </p>
                            <!-- <p style="margin-bottom:7px;">
                                <a href="#" class="album_date" style="font-size:12px;"><i class="fa icon_earphone_ic" style="margin-right: 5px;margin-left:1px;"></i> {{ (album.view_count + "" ).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} </a>
                            </p> -->
                            <p>
                                <a href="#" class="album_date" style="font-size:12px;margin-bottom: 5px;">
                                    <span style="margin-right:3px;"><img src="/images/svg/melo_icon_white.png" class="img-fluid" style="width:25px;margin-left:-1px;"></img></span>
                                    <!-- <span v-if="album.is_store">Store</span>
                                    <span v-if="album.is_streaming"> | Streaming</span>
                                    <span v-if="album.is_free"> | Free</span> -->
                                    <span class="album_date" v-show="album.release_date">| Released - {{ album.release_date}}</span>
                                </a>
                            </p>
                            <div class="album_feature">
                                <div>
                                    <template v-if="playerCurrentTrack != null && songs.findIndex(x => x.id === playerCurrentTrack.id) >= 0 && isPlay">
                                        <a @click.prevent="$store.dispatch(isPlay ? 'pause' : 'play')" href="javascript:;" class="custom_play_btn"><span class="fa icon_pause_btn"></span><span style="padding: 5px;">Pause</span></a>
                                    </template>
                                    <template v-else>
                                        <a @click.prevent="handleClickTrack(songs[0],songs)" href="javascript:;" class="custom_play_btn"><span class="fa fa fa-play"></span><span style="padding: 5px;">Play</span></a>
                                    </template>
                                    <div class="btn-group dropleft" style="padding-top: 4px;">
                                        <!-- <button type="button" data-toggle="dropdown" class="ml-2 custom_btn_style" aria-haspopup="true" aria-expanded="false" style="background: transparent;">
                                            <i class="fa icon_buy_dollar mr-1" style="margin-bottom:2px;"></i>
                                            Buy
                                        </button> -->
                                        <template v-if="!isAuthenticated">
                                            <a class="ml-2 custom_btn_style" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa icon_follower"></span></span> Subscribe</a>
                                        </template>
                                    </div>
                                    <template v-if="!isAuthenticated">
                                        <a href="#" data-toggle="modal" data-target="#loginModal" class="ml-2 custom_btn_style"><span class="opt_icon"><span :class="album.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span></span> {{album.favourite_count}}</a>
                                    </template>
                                    <template v-else>
                                        <a href="#" @click.prevent="favouriteAlbum()" class="ml-2 custom_btn_style"><span class="opt_icon"><span :class="album.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span></span> {{album.favourite_count}}</a>
                                    </template>
                                    <div class="btn-group dropright">
                                        <button type="button" data-toggle="dropdown" class="ml-2" aria-haspopup="true" aria-expanded="false" style="background: transparent;border: none;color: #fff">
                                            <span class="fa icon_album_menu_more" style="margin-top: 20px;"></span>
                                        </button>
                                        <div class="dropdown-menu album_more_detail" style="background: #28282E;border-radius: 0px;">
                                            <template v-if="isAuthenticated">
                                                <a href="#" @click.prevent="saveAlbum(album.uuid)" class="dropdown-item" style="color: #fff;font-size:12px;"><span class="opt_icon"><span class="fa fa-plus" style="font-size: 16px;"></span></span>Add To PlayList</a>
                                            </template>
                                            <template v-else>
                                                <a href="#" data-toggle="modal" data-target="#loginModal" class="dropdown-item" style="color: #fff;font-size:12px;"><span class="opt_icon"><span class="fa fa-plus" style="font-size: 16px;"></span></span> PlayList</a>
                                            </template>
                                            <a class="dropdown-item" style="color: #fff;font-size:12px;" :href="facebookLinkShare(album.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official" style="font-size: 16px;"></span></span>Share To Facebook</a>
                                            <a class="dropdown-item" style="color: #fff;font-size:12px;" :href="twitterLinkShare(album.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square" style="font-size: 16px;"></span></span>Share To Twitter</a>
                                            <a class="dropdown-item" style="color: #fff;font-size:12px;" href="#" @click.prevent="copyText('album/'+album.uuid)"><span class="opt_icon"><span class="fa fa-copy" style="font-size: 16px;"></span></span>Copy link</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!----Song List---->
                <!--  <div class="album-cover-wrapper">
                <div class="album_list_wrapper">
                </div>
            </div> -->
                <!----Song List---->
                <div class="album-track-wrapper album_inner_list" v-show="!song_loading">
                    <div data-v-e84545ce="" style="background: #1e1e25;position: absolute;top: 0;width: 100%;height: 100%;z-index: -1;left: 0;"></div>
                    <div class="album_list_wrapper album_track_content">
                        <h5 style="padding:20px;font-size: 18px;">Tracks<span style="font-size:11px;margin-left: 5px;">Total - {{ songs.length }} song<span v-if="songs.length > 1">s</span></span></h5>
                        <template v-if="!song_loading">
                            <ul class="track_ul" v-for="(song, key) in songs" :class="isPlay && playerCurrentTrack.id === song.id ? 'play_active_song' : ''">
                                <li style="padding-top: 10px;top:-5px;">
                                    <a href="javascript:" class="mmn-play" @click="handleClickTrack(song, album.songs)">
                                        <span class="play_no" style="position: absolute;top: 0;">{{ $_.padStart(key + 1, 0) }}</span>
                                        <div class="ms_bars for_track_ul" v-if="isPlay && playerCurrentTrack.id === song.id">
                                            <div class="bar"></div>
                                            <div class="bar"></div>
                                            <div class="bar"></div>
                                            <div class="bar"></div>
                                            <div class="bar"></div>
                                            <div class="bar"></div>
                                        </div>
                                        <span class="play_hover" v-else></span>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:" class="mmn-play" @click="handleClickTrack(song, album.songs)">
                                        <span class="song_title">
                                            {{ song.name_mm }}
                                        </span>
                                        <p style="font-size:10px;margin-bottom: 5px;">
                                            <a v-if="song.artists.length > 3" href="javascript:">
                                                <span>{{ song.artists[0].name_eng }}, {{ song.artists[1].name_eng }}, ...</span>
                                            </a>
                                            <template v-for="(artist, artist_index) in song.artists" v-else>
                                                <router-link :to="{ name: 'artist', params : {id : artist.uuid}}">{{ artist.name_eng }}
                                                    <span v-if="isNotLast(song.artists, artist_index)">, </span>
                                                </router-link>
                                            </template>
                                            <template v-if="song.feats.length">
                                                <a href="javascript:">, ft : </a>
                                                <template v-for="(feat, feat_index) in song.feats">
                                                    <router-link :to="{ name : 'artist', params:{ id : feat.uuid }}">{{ feat.name_eng }}
                                                        <span v-if="isNotLast(song.feats, feat_index)">, </span>
                                                    </router-link>
                                                </template>
                                            </template>
                                        </p>
                                    </a>
                                </li>
                                <li style="top: -10px;"><a href="javascript:">{{ song.duration }}</a></li>
                                <template v-if="isAuthenticated">
                                    <li class="text-center" style="top: -10px;"><a href="#" @click.prevent="favouriteSong(song,song.id)"><span :class="song.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span> </a></li>
                                    <!-- <li v-if="song.is_bought" class="text-center" style="top: -11px;">
                                        <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                        <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                            <span class="ms_close"><img src="/images/svg/download_1.svg" alt="" style="width:15px;"></span>
                                        </a>
                                    </li>
                                    <li v-else-if="album.is_store" class="text-center" style="top: -11px;">
                                        <template v-if="song.price > 0">
                                            <a href="javascript:" @click="buySong(song.id)"><i class="fa icon_buy_dollar"></i></a>
                                        </template>
                                        <template v-else-if="song.price === 0">
                                            <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                            <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                                <span class="ms_close"><img src="/images/svg/download_1.svg" alt="" style="width:15px;"></span>
                                            </a>
                                        </template>
                                    </li> -->
                                    <li class="text-center ms_more_icon" style="top: -11px;"><a href="javascript:"><span class="fa icon_menu_more"></span></a>
                                        <ul class="more_option album_more">
                                            <li style="width: 100% !important ;padding: 10px 20px;"><a href="#" @click.prevent="saveSong(song.id)"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                            <li style="width: 100% !important ;padding: 10px 20px;"><a href="javascript:;" @click.prevent="shareSongFacebook(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                            <li style="width: 100% !important ;padding: 10px 20px;"><a href="javascript:;" @click.prevent="shareSongTwitter(song.share_url)"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                            <li style="width: 100% !important ;padding: 10px 20px;"><a href="#" @click.prevent="copyText('album/'+album.uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                        </ul>
                                    </li>
                                </template>
                                <template v-else>
                                    <li class="text-center" style="top: -10px;"><a href="#" data-toggle="modal" data-target="#loginModal"><span class="fa fa-heart"></span> </a></li>
                                    <!-- <li v-if="album.is_store" class="text-center" style="top: -11px;">
                                        <a href="#" v-if="song.price > 0" data-toggle="modal" data-target="#loginModal"><i class="fa icon_buy_dollar"></i></a>
                                        <a href="#" v-else-if="song.price === 0" data-toggle="modal" data-target="#loginModal" class="song_download">
                                            <span class="ms_close"><img src="/images/svg/download_1.svg" alt="" style="width:15px;"></span>
                                        </a>
                                    </li>
                                    <li v-else class="text-center" style="top: -11px;">
                                        <a href="#" v-if="song.price > 0" data-toggle="modal" data-target="#loginModal"><i class="fa icon_buy_dollar"></i></a>
                                        <a href="#" v-else data-toggle="modal" data-target="#loginModal" class="song_download">
                                            <span class="ms_close"><img src="/images/svg/download_1.svg" alt="" style="width:15px;"></span>
                                        </a>
                                    </li> -->
                                    <li class="text-center ms_more_icon" style="top: -11px;">
                                        <a href="javascript:"><span class="fa icon_menu_more"></span></a>
                                        <ul class="more_option album_more">
                                            <li style="width: 100% !important ;padding: 10px 20px;"><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                            <li style="width: 100% !important ;padding: 10px 20px;"><a href="javascript:;" @click.prevent="shareSongFacebook(song.share_url)"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                            <li style="width: 100% !important ;padding: 10px 20px;"><a href="javascript:;" @click.prevent="shareSongTwitter(song.share_url)"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                            <li style="width: 100% !important ;padding: 10px 20px;"><a href="#" @click.prevent="copyText('album/'+album.uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                        </ul>
                                    </li>
                                </template>
                            </ul>
                        </template>
                        <div class="ms_view_more padder_bottom30" style="margin-top:40px;" v-if="songEndPoint<=album.songs.length">
                            <a href="#" @click.prevent="viewMoreSong()" class="ms_btn">view more</a>
                        </div>
                    </div>
                </div>
                <div v-show="song_loading" style="height: 100vh;align-items: center;display: flex;margin-top: -10%;">
                    <div class="more_loading mt-5">
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                    </div>
                </div>
            </div>
            <!---Main Content Start--->
            <div class="ms_content_wrapper" v-if="relatedAlbums.length && !related_album_loading">
                <!----Featured Albumn Section Start---->
                <div class="ms_radio_wrapper mt-5" style="margin-top: 0px;">
                    <div class="ms_heading">
                        <h1>Related Album</h1>
                    </div>
                    <div class="row" v-show="related_album_loading">
                        <div class="col-md-2" style="display: inline-block">
                            <div class="load-music-box"></div>
                        </div>
                        <div class="col-md-2" style="display: inline-block">
                            <div class="load-music-box"></div>
                        </div>
                        <div class="col-md-2" style="display: inline-block">
                            <div class="load-music-box"></div>
                        </div>
                        <div class="col-md-2" style="display: inline-block">
                            <div class="load-music-box"></div>
                        </div>
                        <div class="col-md-2" style="display: inline-block">
                            <div class="load-music-box"></div>
                        </div>
                        <div class="col-md-2" style="display: inline-block">
                            <div class="load-music-box"></div>
                        </div>
                    </div>
                    <div v-show="!related_album_loading" class="ms_related_album_slider swiper-container">
                        <swiper :options="swiperOption" ref="mySwiper">
                            <!-- slides -->
                            <template v-for="album in relatedAlbums">
                                <swiper-slide>
                                    <div class="ms_rcnt_box">
                                        <div class="ms_rcnt_box_img">
                                            <img :src="album.image" alt="" onError="this.onerror=null;this.src='/images/album/Album.png';">
                                            <div class="ms_main_overlay">
                                                <router-link tag="a" :to="{ name: 'Album', params: { uuid: album.uuid }}" :title="album.name_mm">
                                                    <div class="ms_box_overlay"></div>
                                                </router-link>
                                                <div class="ms_more_icon" data-toggle="popup">
                                                    <img src="/images/svg/more.svg" alt="">
                                                </div>
                                                <div class="ms_play_icon">
                                                    <a href="javascript:;" @click.prevent="playAllSong(album.uuid)">
                                                        <img src="/images/svg/play2.svg" alt="">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="more_option">
                                            <template v-if="isAuthenticated">
                                                <li><a href="#" @click.prevent="saveAlbum(album.uuid)"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                                <li><a href="#" @click.prevent="syncAlbumFavourite(album.uuid)"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites </a></li>
                                                <li v-if="album.is_store && album.price > 0 && !album.is_bought">
                                                    <a href="#" @click="buyAlbum(album.uuid)"><span class="opt_icon"><span class="fa fa-shopping-cart"></span></span>{{ album.price.toLocaleString() }} Kyat</a>
                                                </li>
                                            </template>
                                            <template v-else>
                                                <li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                                <li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites </a></li>
                                                <li v-if="album.is_store && album.price > 0">
                                                    <a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-shopping-cart"></span></span>{{ album.price.toLocaleString() }} Kyat</a>
                                                </li>
                                            </template>
                                            <li><a :href="shareFacebookLink(album.image,'albums',album.name_mm,album.id,album.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share</a></li>
                                            <li><a :href="shareTwitterLink(album.image,'albums',album.name_mm,album.id,album.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share</a></li>
                                            <li><a href="#" @click.prevent="copyText('album/'+album.uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                        </ul>
                                        <div class="ms_rcnt_box_text">
                                            <h3>
                                                <router-link :to="{ name: 'Album', params: { uuid: album.uuid }}">{{ album.name_mm }}</router-link>
                                            </h3>
                                            <p>
                                                <template v-if="album.artists.length > 3">
                                                    <a href="javascript:">Various Artists</a>
                                                </template>
                                                <template v-else>
                                                    <template v-for="(artist, index1) in album.artists">
                                                        <router-link :to="{ name : 'artist', params:{ id : artist.uuid }}">{{ artist.name_eng }} <span v-if="isNotLast(album.artists, index1)">, </span></router-link>
                                                    </template>
                                                </template>
                                            </p>
                                        </div>
                                    </div>
                                </swiper-slide>
                            </template>
                        </swiper>
                    </div>
                    <!-- Add Arrows -->
                    <template v-if="relatedAlbums.length > 8">
                        <div class="swiper-button-next-related slider_nav_next"></div>
                        <div class="swiper-button-prev-related slider_nav_prev d-none"></div>
                    </template>
                </div>
                <!----Main div close---->
            </div>

            <!---Main Content Start--->
            <div class="ms_content_wrapper" v-if="recommended_videos.length && !recommended_videos_loading">
                <!----Featured Albumn Section Start---->
                <div class="ms_radio_wrapper mt-5" style="margin-top: 0px;">
                    <div class="ms_heading">
                        <h1>Recommended Video</h1>
                    </div>
                    <div class="row" v-show="recommended_videos_loading">
                        <div class="col-md-2" style="display: inline-block">
                            <div class="load-music-box"></div>
                        </div>
                        <div class="col-md-2" style="display: inline-block">
                            <div class="load-music-box"></div>
                        </div>
                        <div class="col-md-2" style="display: inline-block">
                            <div class="load-music-box"></div>
                        </div>
                        <div class="col-md-2" style="display: inline-block">
                            <div class="load-music-box"></div>
                        </div>
                        <div class="col-md-2" style="display: inline-block">
                            <div class="load-music-box"></div>
                        </div>
                        <div class="col-md-2" style="display: inline-block">
                            <div class="load-music-box"></div>
                        </div>
                    </div>
                    <div v-show="!recommended_videos_loading" class="ms_related_album_slider swiper-container">
                        <swiper :options="videoSwiperOption" ref="myVideoSwiper">
                            <!-- slides -->
                            <template v-for="video in recommended_videos">
                                <swiper-slide>
                                    <div class="ms_rcnt_box">
                                        <div class="ms_rcnt_box_img">
                                            <img :src="video.image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/album/album.jpg';">
                                            <div class="ms_main_overlay">
                                                <div class="ms_box_overlay"></div>
                                                <div class="ms_play_icon">
                                                    <router-link :to="{ name : 'Video', params : { id : video.id } }">
                                                        <img src="/images/svg/play2.svg" alt="">
                                                    </router-link>
                                                </div>
                                                <div class="ovrly_text_div">
                                                <span class="ovrly_text1">
                                                    <router-link :to="{ name : 'Video', params : { id : video.id } }">{{ video.name_mm }}</router-link>
                                                </span>
                                                    <span class="ovrly_text2"><router-link :to="{ name : 'Video', params : { id : video.id } }">view video</router-link></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ms_rcnt_box_text">
                                            <h3><router-link :to="{ name : 'Video', params : { id : video.id } }">{{ video.name_mm }}</router-link></h3>
                                            <p>
                                                <template v-if="video.artists.length > 3">
                                                    <a href="javascript:;">Various Artists</a>
                                                </template>

                                                <template v-else>
                                                    <template v-for="(artist, index1) in video.artists">
                                                        <router-link :to="{ name : 'artist', params:{ id : artist.uuid }}">{{ artist.name_eng }} <span v-if="isNotLast(video.artists, index1)">, </span></router-link>
                                                    </template>
                                                </template>
                                            </p>
                                            <p>
                                                <span>
                                                    <template v-if="video.views >= 1000 && video.views < 1000000">
                                                        <span v-if="video.views % 1000 > 100">{{(video.views/1000).toFixed(1)}}</span>
                                                        <span v-else>{{(video.views/1000).toFixed(0)}}</span>K
                                                    </template>
                                                    <template v-else-if="video.views >= 1000000">
                                                        <span v-if="video.views % 1000000 > 100000">{{(video.views/1000000).toFixed(1)}}</span>
                                                        <span v-else>{{(video.views/1000000).toFixed(0)}}</span>M</template>
                                                    <template v-else>
                                                        {{video.views}}
                                                    </template>
                                                    view<template v-if="video.views > 0">s</template>
                                                </span>
                                                <span class="mt-2 ml-1" style="font-size: 26px;">.</span>
                                                {{video.created_at}}
                                            </p>
                                        </div>
                                    </div>
                                </swiper-slide>
                            </template>
                        </swiper>
                    </div>
                    <!-- Add Arrows -->
                    <template v-if="relatedAlbums.length > 8">
                        <div class="swiper-button-next-recommend-video slider_nav_next"></div>
                        <div class="swiper-button-prev-recommend-video slider_nav_prev"></div>
                    </template>
                </div>
                <!----Main div close---->
            </div>

        </div>
    </layout>
</template>
