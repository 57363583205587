<script type="text/ecmascript-6">
    //import Slick from 'vue-slick';
    //import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import carousel from 'vue-owl-carousel'

    export default {

        props: {
            data:{
                type:Array,
            }
        },

        components: {carousel},

        /**
         * The component's data.
         */
        data: () => ({
            loading: false,
            slickOptions: {
                center: true,
                items:2,
                loop:true,
                margin:10,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        centerMode: false,
                        centerPadding: '0px',
                    }
                }]
            }
        }),


        /**
         * Created the component.
         */

        methods: {
            next() {
                this.$refs.slick.next();
            },

            prev() {
                this.$refs.slick.prev();
            },

            reInit() {
                // Helpful if you have to deal with v-for to update dynamic lists
                this.$refs.slick.reSlick()
            },

        }
    }
</script>

<template>
    <div class="custom-carousel">
        <!--
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" v-show="!loading">
            <ol class="carousel-indicators">
                <li v-for="(slider, index) in data" data-target="#carouselExampleIndicators" :data-slide-to="index" :class="index === 0 ? 'active' : ''"></li>
            </ol>
            <div class="carousel-inner">
                <div v-for="(slider, index) in data" :class="index === 0 ? 'carousel-item active' : 'carousel-item'">
                    <a :href="slider.slider_link">
                        <img class="d-block w-100" :src="slider.slider_image" :alt="slider.name">
                    </a>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
        -->
        <div v-if="!loading && data && data.length" style="height:auto" >
            <carousel
                :autoplay="true" 
                :nav="false"
                :center="true"
                :mouseDrag="true"
                :items="2.1"
                :loop="true"
                :responsive="{0:{items:1},768:{items:1.5},834:{items:2},1200:{items:2.1},1400:{items:2.1},2400:{items:2.1}}"
                >
                 <template v-slot:prev>
                    <span class="carousel-prev slider_nav_prev" style="left:5%"></span>
                    <div class="prev-custom">
                        <!-- <i class="fa fa-arrow-left"/> -->
                    </div>
                  </template>
                  <template v-slot:next>
                  <span class="carousel-next slider_nav_next" style="right:5%"></span>
                    <div class="next-custom">
                        <!-- <i class="fa fa-arrow-right"/> -->
                    </div>
                  </template>
                <div
                    v-for="(slider, index) in data"
                    class="align-middle h-100"
                    style="padding: 0px 2px;">
                    <a :href="slider.link">
                        <img class="d-block w-100 h-100" :src="slider.image" :alt="slider.name"
                        >
                    </a>
                </div>
            </carousel>
        </div>
        <div class="row">
            <div class="load-slide" v-show="loading"></div>
        </div>
        <!-- <div class="customPrevBtn" style="height: 100%;width: 26%;position: absolute;top: 0;left:0;z-index: 1;background-image: radial-gradient(circle,#ffffff1a 65%,rgba(51, 51, 51, 0.97) 90%);"></div>
        <div class="customNextBtn" style="height: 100%;width: 26%;position: absolute;top: 0;right:0;z-index: 1;background-image: radial-gradient(circle,#ffffff1a 65%,rgba(51, 51, 51, 0.97) 90%);"></div> -->
    </div>
</template>
<style>
.owl-item {
    height: 100%!important;
}

.slick-active{
    z-index: 1;
}
.owl-theme .owl-dots{
    position: absolute;
    left:0;
    right: 0;
    bottom: 0;
}
.owl-theme .owl-nav{
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
}
.owl-nav .owl-prev{
    float: left;
}
.owl-nav .owl-next{
    float: right;
}
.custom-carousel{
    position: relative;
}
.custom-carousel div span{
    display:block !important;
}
.owl-carousel .owl-item {
    height: 400px;
}
.prev-custom{
    position: absolute;
    width: 26.3%;
    left: 0;
    top: 0;
    z-index: 2; 
    background: linear-gradient(90deg, #000 0.9%, rgba(0, 0, 0, 0) 100%);
    /*transform: rotate(-180deg);*/
    cursor: pointer;
    height: 100%;
}
.next-custom{
    position: absolute;
    width: 26.3%;
    right: 0;
    top: 0;
    z-index: 2; 
    background: linear-gradient(-90deg, #000 0.9%, rgba(0, 0, 0, 0) 100%);
    /*transform: rotate(-180deg);*/
    cursor: pointer;
    height: 100%;
}
@media(max-width:992px) {
    .next-custom,.prev-custom{
        display: none;
    }
}
</style>
