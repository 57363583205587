export default {
  authRequest: (state) => {
    state.status = 'loading';
  },

  authSuccess: (state, access_token) => {
    state.status = 'success';
    state.access_token = access_token;
    state.hasLoadedOnce = true;
  },

  authError: (state, err) => {
    let errors = err.errors ? err.errors:{};
    if(err.error === "invalid_credentials") {
      errors.email = ['The user credentials were incorrect.'];
    }

    state.status = 'error';
    state.hasLoadedOnce = true;
    state.errors.record(errors);
  },

  authLogout: (state) => {
    state.access_token = '';
  },
};
