<script type="text/ecmascript-6">

    export default {

        props: {
            show: {
                type: Boolean,
                default: false
            },
            data:{
                type:String,
            }
        },

        methods: {

            /**
             * Close Modal
             */
            closeModal() {
                this.$nextTick(() => {
                    $('#loginModal').modal('hide');
                });
            }

        }
    }
</script>
<template>
    <div class="ms_save_modal">
        <div id="descriptionModal" class="modal centered-modal" role="dialog" style="margin-bottom: 20px;">
            <div class="modal-dialog" style="max-height:70vh;">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="fa_icon form_close"></i>
                        </button>
                        <div class="modal-body overflow-auto" style="text-align:left;max-height:50vh;overflow-y:auto;">
                            <h1 class="mb-2">About :</h1>
                            <p class="text-white" style="white-space: pre-line;" v-html="data"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.modal-content{
    border-radius:0 !important;
}
.ms_clear_modal .modal-dialog, .ms_save_modal .modal-dialog{
    max-width:60%;
    background:#28282E;
}
.modal .modal-dialog{
    top:0 !important;
    transform:translate(-50%, 120px) !important;
}
/* 100% Image Width on Smaller Screens */
    @media only screen and (max-width: 700px){
        .ms_clear_modal .modal-dialog, .ms_save_modal .modal-dialog{
            max-width:100%;
        }
    }
</style>
