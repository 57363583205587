<script>
    import { swiper, swiperSlide } from 'vue-awesome-swiper';
    import {mapGetters} from 'vuex';

    export default {
        name: "Recent",

        props: {
            data:{
                type:Array,
            }
        },

        components: {
            swiper,
            swiperSlide,
        },

        /**
         * Created the component.
         */
        created() {
            if (this.isAuthenticated) {
                // this.fetchRecentPlay();
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            count: 0,
            songs: [],
            loading: false,
            swiperOption: {
                slidesPerView: 8,
                slidesPerColumn: 1,
                spaceBetween: 15,
                slidesPerGroup: 4,
                // loop: true,
                speed: 500,
                navigation: {
                    nextEl: '.swiper-button-next-recent',
                    prevEl: '.swiper-button-prev-recent',
                },
                breakpoints: {
                    2900: {
                        slidesPerView: 8,
                    },
                    1900: {
                        slidesPerView: 8,
                    },
                    1800: {
                        slidesPerView: 8,
                    },
                    1400: {
                        slidesPerView: 8,
                    },
                    1124: {
                        slidesPerView: 6,
                    },
                    1024: {
                        slidesPerView: 6,
                    },
                    992: {
                        slidesPerView: 6,
                    },
                    834: {
                        slidesPerView: 5,
                    },
                    768: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                    },
                    640: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                    },
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    },
                    375: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    }
                },
            },
            show: false,
        }),
        mounted(){
            let _this = this;
            this.$nextTick(() => {
                _this.swiper.on('slideChange', function () {
                    if(!_this.isBeginning){
                        $('.swiper-button-prev-recent').removeClass('d-none');
                    }else{
                        $('.swiper-button-prev-recent').addClass('d-none');
                    }
                    if(!_this.isEnd){
                        $('.swiper-button-next-recent').removeClass('d-none');
                    }else{
                        $('.swiper-button-next-recent').addClass('d-none');
                    }
                });
            });
        },

        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper
            },
            ...mapGetters({
                isPlay: 'isPlay',
                playerCurrentTrack: 'playerCurrentTrack',
                isAuthenticated: 'isAuthenticated'
            }),
            isBeginning() {
                return this.swiper.isBeginning;
            },
            isEnd() {
                return this.swiper.isEnd;
            },
        },

        methods: {
            /**
             *  Get recent play songs.
             */
            async fetchRecentPlay() {
                axios.get('/api/mmn/recent-play').then((response) => {
                    this.songs = response.data.data;
                    this.swiper.slideTo();
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

        }

    }
</script>

<template>
    <div class="ms_radio_wrapper" v-show="data.length > 0">
        <div class="ms_heading">
            <h1>Recently Played</h1>
            <!-- <span class="veiw_all">
                <router-link to="/recent">view more</router-link>
            </span> -->
        </div>

        <div class="row" v-show="loading">
            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>
        </div>

        <div v-show="!loading" class="ms_radio_slider swiper-container">
            <swiper :options="swiperOption" ref="mySwiper">
                <template v-for="song in data">
                    <swiper-slide>
                        <div class="ms_rcnt_box">
                            <div class="ms_rcnt_box_img">
                                <img :src="song.image" alt="" alt="" onError="this.onerror=null;this.src='/images/album/Album.png';">
                                <div class="ms_main_overlay">
                                    <div class="ms_box_overlay"></div>

                                    <div class="ms_play_icon">
                                        <a href="javascript:;" class="mmn-play" @click="playAllSong(song.uuid)">
                                            <img src="/images/svg/play2.svg" alt="">
                                        </a>
                                    </div>
                                    <div class="ms_more_icon" data-toggle="popup">
                                        <img src="/images/svg/more.svg" alt="">
                                    </div>
                                </div>
                            </div>
                            <ul class="more_option">
                                <template v-if="isAuthenticated">
                                    <li><a href="#" @click.prevent="saveSong(song.id)"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                    <li><a href="#" @click.prevent="favouriteSong(song,song.id)"><span class="opt_icon"><span :class="song.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span></span>Add To Favourites </a></li>
                                </template>

                                <template v-else>
                                    <li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                    <li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites </a></li>
                                </template>

                                <li><a href="javascript:;" @click.prevent="shareSongFacebook(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                <li><a href="javascript:;" @click.prevent="shareSongTwitter(song.share_url)"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                <li><a href="#" @click.prevent="copyText('album/'+song.uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                            </ul>
                            <div class="ms_rcnt_box_text">
                                <h3><router-link tag="a" :to="{ name: 'Album', params: { uuid: song.uuid }}">{{ song.name }}</router-link></h3>
                                <p>
                                    {{song.description}}
                                    <!-- <template v-if="song.artists.length > 3">
                                        <a href="javascript:;">Various Artists</a>
                                    </template>

                                    <template v-else>
                                        <template v-for="(artist, index1) in song.artists">
                                            <router-link :to="{ name : 'artist', params:{ id : artist.uuid }}">{{ artist.name_eng }} <span v-if="isNotLast(song.artists, index1)">, </span></router-link>
                                        </template>
                                    </template> -->
                                </p>
                            </div>
                        </div>
                    </swiper-slide>
                </template>
            </swiper>

        </div>
        <!-- Add Arrows -->
        <template v-if="data.length > 8">
            <div class="swiper-button-next-recent slider_nav_next"></div>
            <div class="swiper-button-prev-recent slider_nav_prev d-none"></div>
        </template>

    </div>
</template>
