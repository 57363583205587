<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import {bus} from "../../app";

    export default {
        name: "PlaylistAlbumModal",
        data:()=>({
            my_playlist:[],
            loading:true,
            save_id:null,
            status:false,
        }),
        computed: {
            ...mapGetters({
                isAuthenticated: 'isAuthenticated',
                album_uuid:'getAlbumUUid',
            }),
        },
        created(){
            this.getMyPlaylist();
            this.getEmitData();
        },
        methods:{
            /**
             * Get Playlist
             */
            getMyPlaylist()
            {
                if(this.isAuthenticated)
                {
                    axios.get('/api/mmn/my-play-lists').then((response) => {
                        this.my_playlist = response.data.data;
                        this.status=response.data.status;
                       this.loading=false;
                    }).catch((error) => {
                        this.album_loading = false;
                    });
                }
            },
            /**
             * Get Playlist Form
             */
            GetPlaylistAlbumForm()
            {
                $('#playlistAlbumModal').modal('hide');
                $('#playlistCreateAlbumModal').modal('show');

            },
            checkAlbum(playlist_id)
            {
                $('.savealbum').removeAttr('style');
                $('#song_ab'+playlist_id+'').removeAttr('style');
                $('#song_ab'+playlist_id+'').attr('style','background-color: grey;');
                this.save_id=playlist_id;

                this.postAlbumToMyList();
            },

            postAlbumToMyList() {

                if(this.save_id!=null)
                {
                    axios.post('/api/mmn/save/my-play-list/album',{playlist_id:this.save_id,uuid:this.album_uuid}).then((response) => {
                        this.$toast.success({ title: 'Success', message: response.data.message });
                        this.save_id=null;
                        $('.savealbum').removeAttr('style');
                        this.loading=false;
                    }).catch((error) => {
                        this.loading = false;
                    });
                    $('#playlistAlbumModal').modal('hide');
                }
            },
            closeAlbumModal(){
                this.save_id=null;
                $('.savealbum').removeAttr('style');
                $('#playlistAlbumModal').modal('hide');
            },
            getEmitData()
            {
                bus.$on("my_playlist",(data)=>{
                    this.my_playlist=data;
                })
            }
        }
    }
</script>

<template>
    <div class="ms_save_modal">
        <div id="playlistAlbumModal" class="modal" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-content">
                        <button type="button" class="close" @click.prevent="closeAlbumModal()">
                            <i class="fa_icon form_close"></i>
                        </button>
                        <div class="modal-body">

                            <h1 style="">Add to Playlist</h1>
                            <a href="#" @click.prevent="GetPlaylistAlbumForm()" class="pull-right" style="font-size: 15px; color: white; border: 2px solid #ed1557;padding:5px 10px; border-radius: 10px; opacity: 1;margin: 10px 0;display: inline-block;background: #14182b;">
                                <i class="fa fa-plus"></i> New Playlist
                            </a>
                            <!--
                            <hr style="margin-top: 30px;background-color: whitesmoke">
                            -->
                            <div v-if="my_playlist.length > 0" class="container mt-1 custom-selected" style="">
                                <!--------Custom Playlist---------->
                                <div v-for="item in my_playlist" @click="checkAlbum(item.id)"  class="row p-2 mt-2 savealbum custom-div"  :id="'song_ab'+item.id">
                                    <div class="col-3">
                                        <img :src="item.image" alt="" class="pull-left img-fluid rounded-circle">
                                    </div>
                                    <div class="col-9" style="padding-top: 10px">
                                        <span class="pull-left" style="font-size: 18px; color: white;">{{item.name}}</span><br>
                                        <p style="font-size:12px;float: left;">
                                        {{item.songs.length}} Song<span v-if="item.songs.length > 1">s</span>
                                        </p>
                                    </div>
                                </div>
                                <!----------End---------->
                            </div>
                            <div v-else style="margin: 50px auto;">
                                <h5 class="text-center mt-2" style="font-size: 15px">No Playlist ......</h5>
                            </div>
                            <div class="clr_modal_btn">
                                <!--<a href="#" @click.prevent="postAlbumToMyList()" style="color: rgba(24,170,2,0.97)">Add</a>
                                <a href="#" @click.prevent="closeAlbumModal()">Close</a>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .custom-selected{
        height: 300px;
        overflow-y: scroll;
        margin-bottom: 20px;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .custom-selected::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    .custom-div{
        border-radius: 5px;
        border:1px solid;
    }
    .custom-div:hover{
        background-color: grey;
        cursor: pointer;
    }
</style>