<script type="text/ecmascript-6">

    export default {

         data: () => ({
            deep_link: {
                url: null,
            },
        }),

        methods:{
             getDeeplinkData(type, uuid) {
                axios.get(`/api/mmn/deeplink/${type}/${uuid}`).then((response) => {
                    this.deep_link.url = response.data;
                }).catch((error) => {
                    this.loading = false;
                });
        }
        },

        created() {

            this.$nextTick(() => {
                // Scrollbar
                $(".ms_nav_wrapper").mCustomScrollbar({
                    theme:"minimal"
                });

                $(".ms_nav_close").on('click', function() {
                    $(".ms_sidemenu_wrapper").toggleClass('open_menu');
                });


                if (this.$route.name == 'Album') {
                    this.getDeeplinkData('albums', this.$route.params.uuid);
                } else if (this.$route.name == 'artist') {
                    this.getDeeplinkData('artists', this.$route.params.id);
                } else if (this.$route.name == 'playlist-detail') {
                    this.getDeeplinkData('playlists', this.$route.params.id);
                } else if (this.$route.name == 'my-play-list') {
                    this.getDeeplinkData('user-playlists', this.$route.params.id);
                }
                else {
                    this.deep_link.url = "https://melomm.app.link/KTCSS9W6t5";
                }

            });
        }
    }
</script>

<template>
    <div class="ms_sidemenu_wrapper">
        <div class="ms_nav_close">
            <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        <div class="ms_sidemenu_inner">
            <div class="ms_logo_inner">
                <div class="ms_logo">
                    <router-link to="/"><img src="/img/logo_new.png" alt="" class="img-fluid" style="width:60px;"/></router-link>
                </div>
                <div class="ms_logo_open">
                    <router-link to="/"><img src="/img/footer_new.png" alt="" class="img-fluid"style="width:100px;"/></router-link>
                </div>
                <br>

            </div>
            <div class="ms_nav_wrapper">
                <ul>
                    <li>
                        <router-link to="/" exact>
                        <span class="nav_icon">
							<span class="icon icon_discover"></span>
						</span>
                            <span class="nav_text">
							discover
						</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/albums" exact>
                        <span class="nav_icon">
							<span class="icon icon_albums"></span>
						</span>
                            <span class="nav_text">
							Albums
						</span>
                        </router-link>
                    </li>
                    <!-- <li>
                        <router-link to="/videos" title="Videos" exact>
						<span class="nav_icon">
							<span class="icon icon_video"></span>
						</span>
                        <span class="nav_text">
							videos
						</span>
                    </router-link>
                    </li> -->
                    <li>
                        <router-link to="/artists" title="Artists" exact>
						<span class="nav_icon">
							<span class="icon icon_artists"></span>
						</span>
                        <span class="nav_text">
							artists
						</span>
                    </router-link>
                    </li>
                    <li>
                        <router-link to="/playlist" title="Playlist">
                        <span class="nav_icon">
                            <span class="icon icon_fe_playlist"></span>
                        </span>
                            <span class="nav_text">
                            playlist
                        </span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/all-collections" title="Collections" exact>
                        <span class="nav_icon">
                            <span class="icon icon_music"></span>
                        </span>
                        <span class="nav_text">
                            Collection
                        </span>
                    </router-link>
                    </li>
                    <li>
                        <router-link to="/genres" title="Genres" exact>
						<span class="nav_icon">
							<span class="icon icon_genres"></span>
						</span>
                            <span class="nav_text">
							genres
						</span>
                        </router-link>
                    </li>
                    <!-- <li>
                        <router-link to="/events" title="Events">
						<span class="nav_icon">
							<span class="icon icon_tracks"></span>
						</span>
                            <span class="nav_text">
							events
						</span>
                        </router-link>
                    </li> -->

                    <li>
                        <router-link to="/videos" title="Videos">
                        <span class="nav_icon">
                            <span class="icon_vdos"></span>
                        </span>
                            <span class="nav_text">
                            videos
                        </span>
                        </router-link>
                    </li>

                    <!-- <li>
                        <router-link to="/tv" title="TV">
                        <span class="nav_icon">
                            <span class="fa icon_melo_tv" style="width:25px;height:25px"></span>
                        </span>
                            <span class="nav_text">
                            TV
                        </span>
                        </router-link>
                    </li> -->

                    <li>
                        <router-link to="/lives" title="Live">
                        <span class="nav_icon">
                            <span class="icon icon_video"></span>
                        </span>
                            <span class="nav_text">
                            live
                        </span>
                        </router-link>
                    </li>

                </ul>

                <ul class="nav_downloads">
                    <li>
                        <span class="nav_text">
                            My Library
                        </span>
                    </li>
                    <li><router-link to="/user/purchased" title="Purchased">
						<span class="nav_icon">
							<span class="icon icon_purchased"></span>
						</span>
                        <span class="nav_text">
							purchased
						</span>
                    </router-link>
                    </li>
                    <li>
                        <router-link to="/user/favourite" title="Favourites">
						<span class="nav_icon">
							<span class="icon icon_favourite"></span>
						</span>
                        <span class="nav_text">
							favourites
						</span>
                    </router-link>
                    </li>
                    <li>
                        <router-link to="/me/followed-artists" title="Followed Artists">
                        <span class="nav_icon">
                            <span class="fa icon_follow_artist" style="width:25px;height:25px"></span>
                        </span>
                            <span class="nav_text">
                            Follow Artists
                        </span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/my-play-lists" title="MyPlaylist">
						<span class="nav_icon">
							<span class="icon icon_fe_playlist"></span>
						</span>
                            <span class="nav_text">
							My PlayLists
						</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/recent" title="History">
						<span class="nav_icon">
							<span class="icon icon_history"></span>
						</span>
                        <span class="nav_text">
							history
						</span>
                    </router-link>
                    </li>
                </ul>

                <ul class="nav_downloads">
                    <!-- <li class="open_app_li">
                        <span class="nav_icon">
							<span class="fa icon_enter_app" style="width:25px;height:25px"></span>
						</span>
                        <span class="nav_text">
                                <a href="https://melomm.app.link/KTCSS9W6t5">Open App</a>
                        </span>
                    </li> -->
                    <li>
                        <a :href="deep_link.url">
                        <span class="nav_icon">
							<span class="icon_enter_app" style="width:25px;height:25px"></span>

						</span>
                        <span class="nav_text">
                             Open App
                        </span>
                    </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .icon_enter_app {
        width: 25px;
        height: 25px;
        display: inline-block;
        vertical-align: middle;
        background-image: url('/images/svg/enter-outline.svg');
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: -2px;
    }
</style>


