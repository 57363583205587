<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'
    import {mapGetters} from 'vuex';

    export default {
        name: 'artists',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Artists',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            artist_categories: [],
            recommend_artists: [],
            artists:[],
        }),

        computed:{
             ...mapGetters({
                isAuthenticated: 'isAuthenticated'
            }),
        },


        /**
         * Created the component.
         */
        created() {
            this.fetchTopAlbums();
        },


        methods: {
            /**
             *  Get the artists.
             */
            async fetchTopAlbums() {
                // axios.get(`/api/v2/artist-by-category`).then((response) => {
                //     this.artist_categories = response.data;
                //     this.loading = false;
                // }).catch((error) => {
                //     this.loading = false;
                // });

                axios.get(`/api/v2/artist/top`).then((response) => {
                    this.recommend_artists = response.data.data;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });

                axios.get('/api/mmn/featured-artists').then((response) => {
                    this.artists = response.data.data;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <!----<div class="ms_content_wrapper ms_profile padder_top100">---->
        <div class="ms_content_wrapper ms_profile padder_top80">
            <main-header/>
            <div class="ms_featured_slider row">
                    <div class="col-lg-12">
                        <div class="ms_heading">
                            <h1>Top Artists</h1>
                        </div>
                    </div>
                    <div v-for="art in recommend_artists" class="cs-lg-col-8 cs-md-col-5 col-md-2 col-6">
                        <router-link tag="a" :to="{ name: 'artist', params: { id : art.uuid }}" :title="art.name_en">
                            <div class="ms_rcnt_box marger_bottom30">
                                <div class="ms_rcnt_box_img ms_rcnt_box_img_rounded">
                                    <img :src="art.image" class="img-fluid" alt="" onError="this.onerror=null;this.src='/images/album/Artist.png';">
                                    <div class="ms_main_overlay">
                                        <div class="ms_box_overlay"></div>
                                        <div class="ms_play_icon">
                                            <a href="javascript:;" @click.prevent="playAllArtistSong(art.id)">
                                                <img src="/images/svg/play2.svg" alt="">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="ms_rcnt_box_text text-center">
                                    <h3><router-link tag="a" :to="{ name: 'artist', params: { id : art.uuid }}">{{ art.name_en }}</router-link></h3>
                                    <p style="font-size:12px">
                                     {{art.follower_counts}} Follower<span v-if="art.follower_counts > 1">s</span>
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </div>
            </div>
            <div class="ms_featured_slider padder_bottom30 row" v-if="isAuthenticated">
                <div class="col-lg-12">
                    <div class="ms_heading">
                        <h1>Recommended Artists</h1>
                        <span class="veiw_all">
                                <!-- <router-link tag="a" :to="{ name: 'artistByCategory', params: { id: category.id }}" :title="category.artist_name_eng">view more</router-link> -->
                            </span>
                    </div>
                </div>

                <div v-for="artist in artists" class="cs-lg-col-8 cs-md-col-5 col-md-2 col-6">
                    <router-link tag="a" :to="{ name: 'artist', params: { id : artist.uuid }}" :title="artist.artist_name_eng">
                        <div class="ms_rcnt_box marger_bottom30">
                            <div class="ms_rcnt_box_img ms_rcnt_box_img_rounded">
                                <img :src="artist.image" class="img-fluid" alt="" onError="this.onerror=null;this.src='/images/album/collection_playlist.png';">
                                <div class="ms_main_overlay">
                                    <div class="ms_box_overlay"></div>
                                    <div class="ms_play_icon">
                                        <a href="javascript:;" @click.prevent="playAllArtistSong(artist.id)">
                                            <img src="/images/svg/play2.svg" alt="">
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="ms_rcnt_box_text ">
                                <h3><router-link tag="a" :to="{ name: 'artist', params: { id : artist.uuid }}">{{ artist.name_eng }}</router-link></h3>
                                <p style="font-size:12px">
                                 {{artist.follower_counts}} Follower<span v-if="artist.follower_counts > 1">s</span>
                                </p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

        </div>

    </layout>
</template>
