import { mapGetters } from 'vuex';
import _ from 'lodash';


const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/login");
};

export default {
    /**
     * The component's data.
     */
    data: () => ({
        download_songs: [],
        album_songs:[],
        artist_songs:[],
        user_playlist_songs:[],
        playlist_songs:[],
        // my_playlist:[],
    }),


    /**
     * Computed these properties.
     */
    computed: {
        ...mapGetters({
            isPlay: 'isPlay',
            playerTracks: 'playerTracks',
            playerCurrentTrack: 'playerCurrentTrack',
        }),
    },

    methods: {
        implode(object, key) {
             let pluck_arr = this.$_.map(object, key);

             return this.$_.join(pluck_arr, ',');
        },


        isLast(array, key) {

            if (array.length !== undefined) {
                return key === (array.length - 1);
            }

            return true;
        },


        isNotLast(array, key) {

            if (array.length !== undefined) {
                return key !== (array.length - 1);
            }

            return false;
        },
         /**
         * Play all song in album
         */
        playAllSong(uuid)
        {
            axios.get('/api/mmn/album/' + uuid).then((response) => {
                    this.album_songs = response.data.data;
                    this.handleClickTrack(this.album_songs.songs[0], this.album_songs.songs) 
                }).catch((error) => {
                });
        },
        playAllArtistSong(id)
        {
            axios.get('/api/v2/song/' + id+'/by-artist').then((response) => {
                    this.artist_songs = response.data.data;
                    this.handleClickTrack(this.artist_songs[0], this.artist_songs) 
                }).catch((error) => {
                });
        },
        playPlaylistSong(id)
        {
            axios.get('/api/mmn/playlist/' + id).then((response) => {
                    this.playlist_songs = response.data.songs;
                    this.handleClickTrack(this.playlist_songs[0], this.playlist_songs) 
                }).catch((error) => {
                });
        },
        playUserPlaylistSong(id)
        {
            axios.get('/api/mmn/my-play-lists/' + id).then((response) => {
                    this.user_playlist_songs = response.data.songs;
                    if(this.user_playlist_songs.length > 0){
                        this.handleClickTrack(this.user_playlist_songs[0], this.user_playlist_songs) 
                    }else{
                        this.$toast.warn({ title: 'Warning', message: 'Your playlist have no songs.' });
                    }
                }).catch((error) => {
                });
        },

        handleClickTrack(trackData, newTracks) {
            if (_.isEqual(newTracks, this.playerTracks)) {
                this.playTrack(trackData);
            }else {
                this.playTrack(trackData);
                this.$store.dispatch('setPlayerTracks', newTracks);
            }
        },

        playQueueTrack(trackData) {
            this.playTrack(trackData);
        },

        playTrack(trackData) {
            if (this.$store.getters.isAuthenticated) {
                if (this.playerCurrentTrack && this.playerCurrentTrack.id === trackData.id) {
                    this.$store.dispatch(this.isPlay ? 'pause' : 'play');
                } else {
                    this.$store.dispatch('setPlayerCurrentTrack', trackData);
                }
            } else {
                this.$router.push('/login')
            }
        },

        playCount(trackData) {
            axios.post(`/api/mmn/song-play/${trackData.id}`);
        },

        getAlbumLink(id) {
            return '/album/' + id;
        },

        syncAlbumFavourite(uuid) {
            axios.post(`/api/mmn/favourite/album/${uuid}`).then((response) => {
                this.$toast.success({ title: 'Success', message: response.data.message });
            }).catch((error) => {
                this.$toast.error({ title : 'Failed', message: 'Something want wrong!'});
            })
        },


        syncSongFavourite(id) {
            axios.post(`/api/mmn/favourite/song/${id}`).then((response) => {
                this.$toast.success({ title: 'Success', message: response.data.message });
            }).catch((error) => {
                this.$toast.error({ title : 'Failed', message: 'Something want wrong!'});
            })
        },


        downloadSong(song_id) {
            song_id = parseInt(song_id);

            if (this.download_songs.includes(song_id)) {
                this.$toast.warn({ title: 'Warning', message: 'Downloading ...' });
                return;
            }

            this.download_songs.push(song_id);

            axios.post(`/api/mmn/download-song/${song_id}`).then(response => {
                let link = document.createElement('a');
                link.href = response.data;
                link.target = '_blank';
                link.download = '';
                document.body.appendChild(link);
                link.click();
                link.remove();

                this.removeArr(song_id);

            }).catch((error) => {
                this.removeArr(song_id);
            })
        },

        removeArr(value) {
            var index = this.download_songs.indexOf(value);

            if (index > -1) {
                this.download_songs.splice(index, 1);
            }

            return false;
        },

        shareSongFacebook(url) {
            window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url), '_blank');
        },
        shareSongTwitter(url) {
            window.open('http://www.twitter.com/intent/tweet?url=' + encodeURIComponent(url), '_blank');
        },

        shareTwitterLink(image,type,title,id,uuid) {
            let url=this.getLink(image,type,title,id,uuid);
            return 'http://www.twitter.com/intent/tweet?url=' + encodeURIComponent(url);
        },
        getLink(image,type,title,id,uuid){
            let type_link='Playlist';
            let web_link='playlist';
            if(type=='albums'){
                type_link="Album";
                web_link='album';
            }else if(type=='artists'){
                type_link="Artist";
                web_link='artist';
            }
            return 'https://melomm.com/'+web_link+'/'+uuid;
            // return 'https://melomm.app.link/KTCSS9W6t5?$deep_link='+web_link+'/'+uuid+'&&$deeplink_path='+type+'/' + id+'&&$ios_deeplink_path='+type+'/'+id+'&&$og_image_url='+image+'&&$og_title='+encodeURIComponent(title+' | '+type_link);
        },
        getDeepLink(type,id,uuid){
            let type_link='Playlist';
            let web_link='playlist';
            if(type=='albums'){
                type_link="Album";
                web_link='album';
            }else if(type=='artists'){
                type_link="Artist";
                web_link='artist';
            }
            return 'https://melomm.app.link/KTCSS9W6t5?$deep_link='+web_link+'/'+uuid+'&&$deeplink_path='+type+'/' + id+'&&$ios_deeplink_path='+type+'/'+id;
        },
        shareFacebookLink(image,type,title,id,uuid) {
            let url=this.getLink(image,type,title,id,uuid);
            return 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
        },

        textCopy(image,type,title,id,uuid){
            let url=this.getLink(image,type,title,id,uuid);
            this.copyText(url);
        },

        facebookLinkShare(url){
            return 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
        },
        twitterLinkShare(url) {
            return 'http://www.twitter.com/intent/tweet?url=' + encodeURIComponent(url);
        },

        copyText(url) {
            const el = document.createElement('textarea');
            el.value = this.asset(url);
            // el.value = url;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

            this.$toast.success({ title: 'Success', message: 'Copied !!' });
        },

        asset(path) {
            path = path.replace(/^(\/)/,"");
            return window.location.origin + '/' + path;
        },

        /**
         *  Buy the album.
         */
        buyAlbum(uuid) {
            this.$store.dispatch('setAlbumUUid', uuid).then(() => {
                this.$nextTick(() => {
                    $('#buyAlbumModal').modal('show');
                });
            });
        },

        /**
         *  Buy the song.
         */
        buySong(id) {
            this.$store.dispatch('setSongId', id).then(() => {
                this.$nextTick(() => {
                    $('#buyModal').modal('show');
                });
            });
        },

        /**
         * login with facebook by javascript
         * @param  {object} from to decide where user came from.
         * @return {void}
         */
        logInWithFacebook(from) {
            let scope = "email";

            FB.login(response => {
                if (response.authResponse) {
                    FB.api('/me', {fields: 'name, email'} , res => {
                        console.log(res)
                        const action = 'facebook-login', token = response.authResponse.accessToken;
                        const { name, email, id } = res;

                        this.$store.dispatch('authRequest', { action, name, email, social_id: id })
                            .then(() => {
                                this.$toast.success({ title:'Success', message: 'You have been successfully logged in!' });
                                this.$router.push(from);
                            })
                    })
                } else {
                    this.$toast.error({ title: 'Error', message: 'Something went wrong and please try again.' });
                }
            }, {
                scope: scope,
                return_scopes: true
            });

            return false;
        },
        logInWithGoogle(from) {
            let scope = "email";
            Vue.googleAuth().directAccess()
            Vue.googleAuth().signIn(function (googleUser) {
                if (googleUser) {
                    console.log(googleUser);
                    const action = 'google-login', token = googleUser.getAuthResponse().access_token;
                    this.$store.dispatch('authRequest', { action, token })
                        .then(() => {
                            this.$toast.success({ title:'Success', message: 'You have been successfully logged in!' });
                            this.$router.push(from);
                        })

                } else {
                    this.$toast.error({ title: 'Error', message: 'Something went wrong and please try again.' });
                }
            }.bind(this), {
                scope: scope,
                return_scopes: true
            });

            return false;
        },

        hasError($field) {
            return this.errors.has($field) ? 'form-control is-invalid' : 'form-control';
        },

        scrollIt(id) {
            let element = document.getElementById(id);
            let ms_header = document.getElementById('ms_header');
            window.scrollTo({
                'behavior': 'smooth',
                'left': 0,
                'top': element.offsetTop - (ms_header.clientHeight + 20)
            });
        },

        /**
         *  Save Song.
         */
        saveSong(uuid) {
            this.$store.dispatch('setSongId', uuid).then(() => {
                this.$nextTick(() => {
                    $('#playlistSongModal').modal('show');
                });
            });
        },
        /**
         *  Save Album.
         */
        saveAlbum(uuid) {
            this.$store.dispatch('setAlbumUUid', uuid).then(() => {
                this.$nextTick(() => {
                    $('#playlistAlbumModal').modal('show');
                });
            });
        },
        /**
         *  Favourite the song.
         */
        favouriteSong(song, song_id) {
            axios.post(`/api/mmn/favourite/song/${song_id}`).then((response) => {
                song.is_favourite = response.data.is_favourite;
                this.$toast.success({ title:'Success', message: response.data.message });
            }).catch((error) => {

            })
        },

        deleteHistory(){
            axios.post(`/api/v2/recent-search/clear-all`).then((response) => {
                this.$store.dispatch('searchHistory');
                this.$toast.success({ title:'Success', message: response.data.message });
            }).catch((error) => {

            })
        }

    }
}
