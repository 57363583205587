<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'
    import CommentShow from '../components/Component/Comment.vue';
    import VideoPlayer from '../components/Component/Video.vue';
    import {mapGetters} from 'vuex';
    import { swiper, swiperSlide } from 'vue-awesome-swiper';

    export default {
        name: 'video',

        components: {
            Layout,
            MainHeader,
            CommentShow,
            swiper,
            swiperSlide,
            VideoPlayer
        },

        metaInfo() {
            return {
                // if no subcomponents specify a metaInfo.title, this title will be used
                title: this.video.name_en,

                meta: [

                    // OpenGraph data (Most widely used)
                    {property: 'og:title', content: `${this.video.name_eng} - Melo`},

                    // Should the the same as your canonical link, see below.
                    {property: 'og:url', content: this.video.image},
                    {property: 'og:image', content: this.video.image},

                    // Twitter card
                    {name: 'twitter:title', content: `${this.video.name_eng} - Melo`},
                    // Your twitter handle, if you have one.
                    {name: 'twitter:image:src', content: this.video.image},

                    // Google / Schema.org markup:
                    {itemprop: 'name', content: `${this.video.name_eng} - Melo`},
                    {itemprop: 'image', content: this.video.image}
                ],
            }
        },

        /**
         * Computed these properties.
         */
        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper
            },
            ...mapGetters({
                user : 'getProfile',
                isAuthenticated: 'isAuthenticated',
            }),
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            comments:[],
            comment_loading:true,
            comment_body:null,
            video: {
                name_mm: "",
                name_eng: "",
                link : false,
                desc : '',
                image : '',
            },
            other_videos: [],
            more_videos: [],
            recommended_videos: [],
            recommended_playlists: [],
            swiperOption: {
                slidesPerView: 8,
                spaceBetween: 6,
                slidesPerGroup: 4,
                loop: true,
                speed: 500,
                navigation: {
                    nextEl: '.swiper-button-next-more-video',
                    prevEl: '.swiper-button-prev-more-video',
                },
                breakpoints: {
                    2900: {
                        slidesPerView: 5,
                    },
                    1900: {
                        slidesPerView: 5,
                    },
                    1800: {
                        slidesPerView: 5,
                    },
                    1400: {
                        slidesPerView: 5,
                    },
                    992: {
                        slidesPerView: 6,
                    },
                    768: {
                        slidesPerView: 6,
                        spaceBetween: 5,
                    },
                    640: {
                        slidesPerView: 6,
                        spaceBetween: 5,
                    },
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 5,
                        slidesPerGroup: 1,
                    },
                    375: {
                        slidesPerView: 2,
                        spaceBetween: 5,
                        slidesPerGroup: 1,
                    }
                },
            },
        }),

        destroyed() {
            this.$nextTick(() => {
                $('.player_mid').css('display','block');
            });
        },
        mounted() {
            let self=this;
            this.$nextTick(() => {
                $('.player_mid').css('display','none');
            });
        },

        /**
         * Created the component.
         */
        created() {
            this.fetchVideos();
        },


        methods: {
            /**
             *  Get the new videos.
             */
            async fetchVideos() {
                axios.get(`/api/v2/video/${this.$route.params.id}`).then((response) => {
                    this.video = response.data.video;
                    this.more_videos = response.data.more_videos;
                    this.recommended_videos = response.data.recommended_videos;
                    this.recommended_playlists = response.data.recommended_playlists;
                    this.loading = false;
                    this.viewCount();
                    this.fetchComment();
                }).catch((error) => {
                    this.loading = false;
                });
            },
            async fetchComment() {
                axios.get(`/api/v2/comment/${this.$route.params.id}/video`).then((response) => {
                    this.comments=response.data.data;
                    this.comment_loading=false;
                }).catch((error) => {
                    this.comment_loading=false;
                });
            },
            viewCount(){
                axios.get(`/api/mmn/video/${this.$route.params.id}/increment`).then((response) => {
                }).catch((error) => {
                });
            },
            submitForm(){
                if(this.isAuthenticated){
                    let data = {
                        'comment_body':this.comment_body,
                        'video_id':this.$route.params.id
                    }
                    axios.post(`/api/v2/comment`,data).then((response) => {
                        this.comment_body=null;
                        this.video.cmt_count++;
                        this.fetchComment();
                    }).catch((error) => {
                    });
                }
            },

            likeVideo(){
                axios.post(`/api/v2/video/${this.$route.params.id}/like`).then((response) => {
                        if(response.data.islike){
                            this.video.islike=true;
                            this.$toast.success({ title: 'Success', message:'Liked !!' });
                        }else{
                            this.video.islike=false;
                            this.$toast.success({ title: 'Success', message:'Unliked !!' });
                        }
                    }).catch((error) => {
                        this.$toast.error({ title: 'Error', message: 'Like Error' });
                    });
            }

        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <div class="ms_content_wrapper ms_profile padder_top50">

            <main-header/>

            <div class="ms_fea_album_slider padder_bottom30" id="videos" v-show="!loading">
                <div class="row mb-5">
                    <div class="d-flex justify-content-center w-100">
                        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 border-bottom-video pb-3">
                            <div id="video" v-if="!loading && video.youtube_link">
                                <!-- <video-player :video="video"></video-player> -->
                                <iframe width="100%" height="420" id="youtube" :src="'https://www.youtube.com/embed/'+video.youtube_link" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                            </div>
                            <div>
                                <h5>{{ video.name_mm }} </h5>
                                <p class="mt-2 mb-0">
                                    <template v-for="(artist, index1) in video.artists">
                                        <router-link :to="{ name : 'artist', params:{ id : artist.uuid }}" style="font-size:12px;">{{ artist.name_en }} <span v-if="isNotLast(video.artists, index1)">, </span>
                                        </router-link>
                                    </template>
                                </p>
                            </div>
                            <!-- <div class="more-options float-right mb-4">
                                <a href="javascript:;" class="btn video-btn mr-3"> <span v-if="video.cmt_count > 0">{{video.cmt_count}}</span> Comments</a>
                                <template v-if="isAuthenticated">
                                    <a href="javascript:;" @click.prevent="likeVideo()" class="btn video-btn mr-3" :class="{'liked':video.islike}"><i class="fa fa-thumbs-up"></i> <span v-if="video.islike">Liked</span><span v-else>Likes</span></a>
                                </template>
                                <template v-else>
                                    <a href="javascript:;" data-toggle="modal" data-target="#loginModal" class="btn video-btn mr-3" :class="{'liked':video.islike}"><i class="fa fa-thumbs-up"></i> Likes</a>
                                </template>
                                <div class="d-inline-block dropleft">
                                    <button type="button" data-toggle="dropdown" class="btn video-btn" aria-haspopup="true" aria-expanded="false" style="background: transparent;">
                                        <i class="fa icon_sharer" style="margin-bottom:2px;"></i>
                                        Share
                                    </button>
                                    <div class="dropdown-menu album_subscribe_detail" style="background: #28282E;border-radius: 0px;">
                                        <a class="dropdown-item" style="color: #fff;font-size:12px;padding: 10px 25px;" :href="facebookLinkShare(video.share_url)" target="_blank"><span class="opt_icon mr-2"><span class="fa fa-facebook-official" style="font-size: 16px;"></span></span>Share To Facebook</a>
                                        <a class="dropdown-item" style="color: #fff;font-size:12px;padding: 10px 25px;" :href="twitterLinkShare(video.share_url)" target="_blank"><span class="opt_icon mr-2"><span class="fa fa-twitter-square" style="font-size: 16px;"></span></span>Share To Twitter</a>
                                        <a class="dropdown-item" style="color: #fff;font-size:12px;padding: 10px 25px;" href="#" @click.prevent="copyText(video.share_url)"><span class="opt_icon mr-2"><span class="fa fa-copy" style="font-size: 16px;"></span></span>Copy link</a>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="d-flex w-100 justify-content-center">
                        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 border-bottom-video pb-3 pt-2">
                            <h5>Description :</h5>
                            <p>
                                {{video.description}}
                            </p>
                        </div>
                    </div>
                    <!-- <div class="d-flex justify-content-center w-100" >
                        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 border-bottom" id="comments">
                            <div class="row">
                                <div class="col-lg-7 col-md-9 col-sm-12 col-12 pt-1 pb-3 form-inline">
                                    <div style="width:10%">
                                        <img :src="user.image? user.image:'/images/svg/Profile.svg'" class="img-fluid rounded-circle" width="50">
                                    </div>
                                    <div class="pl-2 pt-3" style="width:90%">
                                        <form @submit.prevent="submitForm()">
                                            <textarea v-model="comment_body" class="comment-input d-block" placeholder="Add a write comment" :readonly="!isAuthenticated" style="height: 35px"></textarea>
                                            <input type="text" v-model="comment_body" class="comment-input d-block" placeholder="Add a write comment" :readonly="!isAuthenticated">
                                            <a href="javascript:;" :class="{'disabled': !isAuthenticated}" @click.prevent="submitForm()" class="btn video-btn float-right mt-2 btn-secondary" >Comment</a>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="row comments-show">
                                <div class="col-lg-7 col-md-11 col-sm-12 col-12 pt-1 pb-3">
                                    <div v-for="(comment,key) in comments" >
                                        <comment-show :parent="comment.id" :comments="comment" :fetchComment="fetchComment" :video="video"></comment-show>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>


                    <!--
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">

                        <div class="row">
                            <div class="col-lg-12">
                                <div style="color: #ff4865;">OTHER VIDEOS</div>

                                <div class="ms_genres_box" v-for="other_video in other_videos">
                                    <img :src="other_video.image" alt="" class="img-fluid" />
                                    <div class="ms_main_overlay">
                                        <div class="ms_box_overlay"></div>
                                        <div class="ms_play_icon">
                                            <router-link :to="{ name : 'Video', params : { id : other_video.id } }">
                                                <img src="/images/svg/play.svg" alt="">
                                            </router-link>
                                        </div>
                                        <div class="ovrly_text_div">
                                    <span class="ovrly_text1">
                                        <router-link :to="{ name : 'Video', params : { id : other_video.id } }">{{ other_video.name_mm }}</router-link>
                                    </span>
                                            <span class="ovrly_text2"><router-link :to="{ name : 'Video', params : { id : other_video.id } }">view video</router-link></span>
                                        </div>
                                    </div>
                                    <div class="ms_box_overlay_on">
                                        <div class="ovrly_text_div">
                                            <span class="ovrly_text1"><router-link :to="{ name : 'Video', params : { id : other_video.id } }">{{ other_video.name_mm }}</router-link></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div-->

                    <div class="row mt-3" v-if="more_videos.length > 0">
                        <div class="col-lg-12 p-lg-0">
                            <div class="ms_heading">
                                <h1>More Videos</h1>
                            </div>
                        </div>
                        <div v-show="!loading" class="col-12 row ms_radio_slider swiper-container">
                            <swiper :options="swiperOption" ref="mySwiper">
                            <!-- slides -->
                            <template v-for="(video, key) in more_videos">
                                <swiper-slide>
                                        <div class="ms_rcnt_box">
                                            <div class="ms_rcnt_box_img video_resize">
                                                <img :src="video.image" alt="" class="img-fluid m-video-img" onError="this.onerror=null;this.src='/images/album/album.jpg';">
                                                <div class="ms_main_overlay">
                                                    <div class="ms_box_overlay"></div>
                                                    <div class="ms_play_icon">
                                                        <router-link :to="{ name : 'Video', params : { id : video.id } }">
                                                            <img src="/images/svg/play2.svg" alt="">
                                                        </router-link>
                                                    </div>
                                                    <div class="ovrly_text_div">
                                                    <span class="ovrly_text1">
                                                        <router-link :to="{ name : 'Video', params : { id : video.id } }">{{ video.name_mm }}</router-link>
                                                    </span>
                                                        <span class="ovrly_text2"><router-link :to="{ name : 'Video', params : { id : video.id } }">view video</router-link></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ms_rcnt_box_text">
                                                <h3><router-link :to="{ name : 'Video', params : { id : video.id } }">{{ video.name_mm }}</router-link></h3>
                                                <p>
                                                    <template v-if="video.artists.length > 3">
                                                        <a href="javascript:;">Various Artists</a>
                                                    </template>

                                                    <template v-else>
                                                        <template v-for="(artist, index1) in video.artists">
                                                            <router-link :to="{ name : 'artist', params:{ id : artist.uuid }}">{{ artist.name_en }} <span v-if="isNotLast(video.artists, index1)">, </span></router-link>
                                                        </template>
                                                    </template>
                                                </p>
                                                <p>
                                                    <span>
                                                        <template v-if="video.views >= 1000 && video.views < 1000000">
                                                            <span v-if="video.views % 1000 > 100">{{(video.views/1000).toFixed(1)}}</span>
                                                            <span v-else>{{(video.views/1000).toFixed(0)}}</span>K
                                                        </template>
                                                        <template v-else-if="video.views >= 1000000">
                                                            <span v-if="video.views % 1000000 > 100000">{{(video.views/1000000).toFixed(1)}}</span>
                                                            <span v-else>{{(video.views/1000000).toFixed(0)}}</span>M</template>
                                                        <template v-else>
                                                            {{video.views}}
                                                        </template>
                                                        view<template v-if="video.views > 0">s</template>
                                                    </span>
                                                    <span class="mt-2 ml-1" style="font-size: 26px;">.</span>
                                                    {{video.created_at}}
                                                </p>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                </template>
                            </swiper>
                        </div>
                        <!-- Add Arrows -->
                        <div class="swiper-button-next-more-video slider_nav_next"></div>
                        <div class="swiper-button-prev-more-video slider_nav_prev"></div>
                    </div>

                    <div class="row mt-3" v-if="recommended_videos.length > 0">
                        <div class="col-lg-12 p-lg-0">
                            <div class="ms_heading">
                                <h1>Recommended Videos</h1>
                                <span class="veiw_all"><router-link :to="{ name: 'recommended-videos'}">view more</router-link></span>
                            </div>
                        </div>
                        <div class="col-12 row">
                            <div class="cs-lg-col-5 col-md-3 col-6" v-for="video in recommended_videos">
                                <div class="ms_rcnt_box marger_bottom30">
                                    <div class="ms_rcnt_box_img video_resize">
                                        <img :src="video.image" alt="" class="img-fluid m-video-img" onError="this.onerror=null;this.src='/images/album/album.jpg';">
                                        <div class="ms_main_overlay">
                                            <div class="ms_box_overlay"></div>
                                            <div class="ms_play_icon">
                                                <router-link :to="{ name : 'Video', params : { id : video.id } }">
                                                    <img src="/images/svg/play2.svg" alt="">
                                                </router-link>
                                            </div>
                                            <div class="ovrly_text_div">
                                            <span class="ovrly_text1">
                                                <router-link :to="{ name : 'Video', params : { id : video.id } }">{{ video.name_mm }}</router-link>
                                            </span>
                                                <span class="ovrly_text2"><router-link :to="{ name : 'Video', params : { id : video.id } }">view video</router-link></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ms_rcnt_box_text">
                                        <h3><router-link :to="{ name : 'Video', params : { id : video.id } }">{{ video.name_mm }}</router-link></h3>
                                        <p>
                                            <template v-if="video.artists.length > 3">
                                                <a href="javascript:;">Various Artists</a>
                                            </template>

                                            <template v-else>
                                                <template v-for="(artist, index1) in video.artists">
                                                    <router-link :to="{ name : 'artist', params:{ id : artist.uuid }}">{{ artist.name_en }} <span v-if="isNotLast(video.artists, index1)">, </span></router-link>
                                                </template>
                                            </template>
                                        </p>
                                        <p>
                                            <span>
                                                <template v-if="video.views >= 1000 && video.views < 1000000">
                                                    <span v-if="video.views % 1000 > 100">{{(video.views/1000).toFixed(1)}}</span>
                                                    <span v-else>{{(video.views/1000).toFixed(0)}}</span>K
                                                </template>
                                                <template v-else-if="video.views >= 1000000">
                                                    <span v-if="video.views % 1000000 > 100000">{{(video.views/1000000).toFixed(1)}}</span>
                                                    <span v-else>{{(video.views/1000000).toFixed(0)}}</span>M</template>
                                                <template v-else>
                                                    {{video.views}}
                                                </template>
                                                view<template v-if="video.views > 0">s</template>
                                            </span>
                                            <span class="mt-2 ml-1" style="font-size: 26px;">.</span>
                                            {{video.created_at}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3" v-if="recommended_playlists.length > 0">
                        <div class="col-lg-12 p-lg-0">
                            <div class="ms_heading">
                                <h1>Recommended Playlists</h1>
                                <span class="veiw_all"><router-link :to="{ name: 'recommended-video-playlists'}">view more</router-link></span>
                            </div>
                        </div>
                        <div class="col-12 row">
                            <div class="cs-lg-col-5 col-md-3 col-6" v-for="video in recommended_playlists">
                                <div class="ms_rcnt_box marger_bottom30">
                                    <div class="ms_rcnt_box_img">
                                        <img :src="video.image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/album/album.jpg';">
                                        <div class="ms_main_overlay">
                                            <div class="ms_box_overlay"></div>
                                            <div class="ms_play_icon">
                                                <router-link :to="{ name : 'Video', params : { id : video.video_id }}">
                                                    <img src="/images/svg/play2.svg" alt="">
                                                </router-link>
                                            </div>
                                            <div class="ovrly_text_div">
                                            <span class="ovrly_text1">
                                                <router-link :to="{ name : 'Video', params : { id : video.video_id } }">{{ video.name_mm }}</router-link>
                                            </span>
                                                <span class="ovrly_text2"><router-link :to="{ name : 'Video', params : { id : video.video_id } }">view video</router-link></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ms_rcnt_box_text">
                                        <h3><router-link :to="{ name : 'Video', params : { id : video.video_id } }">{{ video.name_mm }}</router-link></h3>
                                        
                                        <p>
                                            <span>
                                                {{video.video_count}}
                                                video<template v-if="video.video_count > 0">s</template>
                                            </span>
                                            <span class="mt-2 ml-1" style="font-size: 26px;">.</span>
                                            {{video.created_at}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                <!-- </div> -->
            </div>

            <div class="row" v-show="loading">
                <div style="height: 100vh;align-items: center;display: flex;margin-top: -10%;">
                    <div class="more_loading">
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                    </div>
                </div>
            </div>

        </div>


    </layout>
</template>

<style>

    #video {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        border-bottom: 5px solid #14182A;

    }
    .border-bottom-video{
        border-bottom:1px solid #333;
    }
    #video iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .video_resize{
        display:flex;
        align-items:center;
        height: 121px;
    }
    .video-btn{
        font-size: 12px; 
        color: #fff; 
        border: 2px solid #fff;
        padding:5px 25px; 
        border-radius: 5px; 
        /*opacity: 0.7;*/
        margin-bottom: 5px;
        display: inline-block;
        cursor: pointer;
    }
    .liked{
        background:#ff2150;
        border-color:#ff2150;
    }
    #comments .comment-input{
        border-top: 0;
        border-left: 0;
        border-right: 0;
        padding: 3px;
        border-radius: 0;
        background: transparent;
        width: 100%;
        color: #fff;
    }

    .ms_rcnt_box_img img.m-video-img{
        transform: scale(1.4);
    }
    .ms_rcnt_box:hover .ms_rcnt_box_img img.m-video-img {
        transform: scale(1.5);
    }
</style>
