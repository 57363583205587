import Toastr from './Toastr'
import './assets/toastr.css'


const CxltToastr = {
    install: function (Vue, options) {
        if (!options) {
            options = {}
        }
        let showedToastrs = [];
        var previousToast;

        function showToast(obj, type) {

            let lastToastrs = showedToastrs[showedToastrs.length - 1];

            if (lastToastrs !== undefined && lastToastrs.$data.show === false) {
                previousToast = undefined;
            }

            if (shouldExit(options, obj)) {
                return;
            }

            const ToastrComponent = Vue.extend(Toastr);
            var propsData = Object.assign(options, obj, {
                type: type
            })

            let component = new ToastrComponent({
                el: document.createElement('div'),
                propsData
            })

            previousToast = obj.message;
            showedToastrs.push(component)

            return component
        }

        function shouldExit(options, map) {
            if (options.preventDuplicates) {
                if (map.message === previousToast) {
                    return true;
                } else {
                    previousToast = map.message;
                }
            }
            return false;
        }

        Vue.prototype.$toast = {
            success(obj) {
                return showToast(obj, 'success')
            },
            info(obj) {
                return showToast(obj, 'info')
            },
            warn(obj) {
                return showToast(obj, 'warning')
            },
            error(obj) {
                return showToast(obj, 'error')
            },
            removeAll() {
                showedToastrs.forEach(c => {
                    c.hideToastr()
                });
                previousToast = undefined;
                showedToastrs = []
            }
        }
    }
}

export default CxltToastr