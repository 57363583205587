<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'purchased-songs',

        components: {
            Layout,
            MainHeader,
        },


        metaInfo() {
            return {
                title: 'Purchase Songs',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            songs_loading: true,
            songs: [],
            download_btn_loading : false,
            search_data:[],
            songs_name:[],
            artists_name:[],
            search_artist:'',
            search:'',
        }),


        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                isPlay: 'isPlay',
                playerCurrentTrack: 'playerCurrentTrack',
                isAuthenticated: 'isAuthenticated'
            }),
            searchFilter() {
                this.search_data=this.songs;
                if(this.search!='')
                {
                    this.search_data=this.search_data.filter(song => {
                        if(song.name_eng!=null){
                            return song.name_eng.toLowerCase().includes(this.search.toLowerCase())
                        }else{
                            return song.name_mm.toLowerCase().includes(this.search.toLowerCase())
                        }
                      });
                }
                if(this.search_artist!=''){
                    this.search_data=this.search_data.filter(song => {
                        let flag=false;
                        let self=this;
                        $.each(song.artists,function(key,value){
                            if(value.name_eng!=null){
                            flag=value.name_eng.toLowerCase().includes(self.search_artist.toLowerCase());
                            }else{
                                flag=value.name_mm.toLowerCase().includes(self.search_artist.toLowerCase());
                            }
                        })
                        return flag;
                      });
                }
                return this.search_data;
            },
        },


        /**
         * Created the component.
         */
        created() {
            this.fetchSongs();
        },


        methods: {
            /**
             *  Get the favourite songs.
             */
            async fetchSongs() {
                axios.get(`/api/mmn/purchased-songs`).then((response) => {
                    this.songs = response.data.data;
                    let song_name=[];
                    let artist_name=[];
                    $.each(this.songs, function(key, song) {
                            song_name.push(song.name_eng);
                            $.each(song.artists,function(index,artist){
                                artist_name.push(artist.name_eng);
                            });
                       });
                    this.songs_name=song_name;
                    this.artists_name=artist_name;
                    this.songs_loading = false;
                }).catch((error) => {
                    this.songs_loading = false;
                });
            },

        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <div class="ms_album_single_wrapper ms_profile" style="padding-top:0px;">

            <main-header/>

            <!----Free Download Css Start---->
            <div class="ms_content_wrapper ms_free_download ms_purchase_wrapper padder_top50 padder_bottom30">
                <div class="row justify-content-center d-flex" style="width:100%">
                    <div class="input-group mb-3 col-md-4 col-12">
                        <input type="text" v-model="search_artist" class="form-control" list="artist" placeholder="Artists....">
                          <datalist id="artist">
                            <option v-for="artist in artists_name">{{artist}}</option>
                          </datalist>
                          <div class="input-group-prepend">
                            <button class="btn" type="submit" style="border-radius: 0px 5px 5px 0px;background:#ff2150;">
                                <img src="/images/svg/search.svg" alt="">
                            </button>
                          </div>
                    </div>
                    <div class="input-group mb-3 col-md-4 col-12">
                        <input type="text" v-model="search" class="form-control" list="song" placeholder="Song....">
                          <datalist id="song">
                            <option v-for="song in songs_name">{{song}}</option>
                          </datalist>
                          <div class="input-group-prepend">
                            <button class="btn" type="submit" style="border-radius: 0px 5px 5px 0px;background:#ff2150;">
                                <img src="/images/svg/search.svg" alt="">
                            </button>
                          </div>
                    </div>
                </div>
                <div class="ms_heading text-center">
                    <h1 class="mt-2 mb-2">Your Purchased Songs</h1>
                </div>

                <div class="album-track-wrapper album_inner_list">
                    <div class="album_list_wrapper album_track_content" v-show="!songs_loading">

                        <ul class="track_ul" v-if="songs.length" v-for="(song, key) in searchFilter" :class="isPlay && playerCurrentTrack.id === song.id ? 'play_active_song' : ''">
                            <li style="padding-top: 10px;top:-5px;">
                                <a href="javascript:;" class="mmn-play" @click="handleClickTrack(song, songs)">
                                    <span class="play_no" style="position: absolute;top: 0;">{{ $_.padStart(key + 1, 0) }}</span>
                                    <div class="ms_bars for_track_ul" v-if="isPlay && playerCurrentTrack.id === song.id">
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                    </div>
                                    <span class="play_hover" v-else></span>
                                </a>
                            </li>

                            <li><a href="javascript:;" class="mmn-play" @click="handleClickTrack(song, songs)"><span class="song_title">{{ song.name_mm }}</span>
                            <p style="font-size:10px;margin-bottom: 5px;">
                                <a v-if="song.artists.length > 3" href="javascript:">
                                    <span>{{ song.artists[0].name_eng }}, {{ song.artists[1].name_eng }}, ...</span>
                                </a>
                                <template v-for="(artist, artist_index) in song.artists" v-else>
                                    <router-link :to="{ name: 'artist', params : {id : artist.uuid}}">{{ artist.name_eng }}
                                        <span v-if="isNotLast(song.artists, artist_index)">, </span>
                                    </router-link>
                                </template>
                                <template v-if="song.feats.length">
                                    <a href="javascript:">, ft : </a>
                                    <template v-for="(feat, feat_index) in song.feats">
                                        <router-link :to="{ name : 'artist', params:{ id : feat.uuid }}">{{ feat.name_eng }}
                                            <span v-if="isNotLast(song.feats, feat_index)">, </span>
                                        </router-link>
                                    </template>
                                </template>
                            </p>
                            </a></li>

                            <li style="top: -10px;"><a href="#">{{ song.duration }}</a></li>
                            <li class="text-center" style="top: -10px;"><a href="#" @click.prevent="favouriteSong(song,song.id)"><span :class="song.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span> </a></li>
                            <li class="text-center" style="top: -11px;">
                                <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                    <span><img src="/images/svg/download_1.svg" alt="" style="width:15px;"></span>
                                </a>
                            </li>

                            <li class="text-center ms_more_icon" style="top: -11px;"><a href="javascript:"><span class="fa icon_menu_more"></span></a>
                                <ul class="more_option album_more">
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#" @click.prevent="saveSong(song.id)"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                    <li style="width: 100% !important ;padding: 10px 10px;"><router-link :to="{ name: 'Album', params: { uuid: song.album_uuid }}"><span class="opt_icon"><img src="/images/svg/go_to_album.svg" style="width:18px;margin-right:7px;"/></span>Go To Album </router-link></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongFacebook(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongTwitter(song.share_url)"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#" @click.prevent="copyText('album/'+song.album_uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                </ul>
                            </li>
                        </ul>

                        <ul v-else>
                            <li class="w-100 text-center"><a href="#">No Songs</a></li>
                        </ul>
                    </div>

                    <div class="more_loading" v-show="songs_loading">
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                    </div>

                </div>
            </div>

        </div>


    </layout>
</template>

<style scoped>
    .ms_free_download.ms_purchase_wrapper .album_list_wrapper>ul>li {
        width: 20%;
    }

    .ms_free_download.ms_purchase_wrapper .album_list_wrapper>ul>li:first-child {
        width: 4%;
    }

    .ms_free_download.ms_purchase_wrapper .album_list_wrapper>ul>li:nth-child(5),
    .ms_free_download.ms_purchase_wrapper .album_list_wrapper>ul>li:nth-child(6),
    .ms_free_download.ms_purchase_wrapper .album_list_wrapper>ul>li:nth-child(7),
    .ms_free_download.ms_purchase_wrapper .album_list_wrapper>ul>li:nth-child(8) {
        width: 9%;
    }
</style>
