<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'event',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                // if no subcomponents specify a metaInfo.title, this title will be used
                title: this.event.name,

                meta: [

                    // OpenGraph data (Most widely used)
                    {property: 'og:title', content: `${this.event.name} - Melo`},

                    // Should the the same as your canonical link, see below.
                    {property: 'og:url', content: this.event.image},
                    {property: 'og:image', content: this.event.image},

                    // Twitter card
                    {name: 'twitter:title', content: `${this.event.name} - Melo`},
                    // Your twitter handle, if you have one.
                    {name: 'twitter:image:src', content: this.event.image},

                    // Google / Schema.org markup:
                    {itemprop: 'name', content: `${this.event.name} - Melo`},
                    {itemprop: 'image', content: this.event.image}
                ],
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            event: {
                title : '',
                image : '',
            },
            latest_events : []
        }),


        /**
         * Created the component.
         */
        created() {
            this.fetchEvent();
        },


        methods: {
            /**
             *  Get the event.
             */
            async fetchEvent() {
                axios.get(`/api/mmn/event/${this.$route.params.id}`).then((response) => {
                    this.event = response.data.event;
                    this.latest_events = response.data.latest_events;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },
        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <div class="ms_content_wrapper ms_profile padder_top80">

        <main-header/>

            <!--- blog single section start --->
            <div class="ms_blog_single_wrapper">
                <div class="row">
                    <div class="col-lg-9 col-md-9">
                        <div class="ms_blog_single" v-if="event">
                            <div class="blog_single_img">
                                <img :src="event.image" alt="" class="img-fluid">
                            </div>
                            <div class="blog_single_content">
                                <h3 class="ms_blog_title">{{ event.name }}</h3>
                                <div class="ms_post_meta">
                                    <ul>
                                        <li>{{ event.start_date }} - {{ event.end_date }} / </li>
                                        <li>{{ event.location }} </li>
                                    </ul>
                                </div>
                                <div v-html="event.desc"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <!----Sidebar Start---->
                        <div class="ms_sidebar">
                            <!--Feature Post-->
                            <div class="widget widget_recent_entries">
                                <h2 class="widget-title">Latest Events</h2>
                                <ul>
                                    <li v-for="latest_event in latest_events">
                                        <div class="recent_cmnt_img">
                                            <img :src="latest_event.image" width="50" alt="">
                                        </div>
                                        <div class="recent_cmnt_data">
                                            <h4><router-link :to="{ name: 'Event', params: { id : latest_event.id}}">{{ latest_event.name }}</router-link></h4>
                                            <span>{{ latest_event.start_date }} - {{ latest_event.end_date }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </layout>
</template>
