<script type="text/ecmascript-6">
    import Albums from './NewRelease';
    import FeaturedArtists from './FeaturedArtists';
    import RecommendedPlaylist from './RecommendedPlaylist';
    import Genres from "./Genres";
    import Collections from "./Collections";

    export default {
        name: 'sections',

        props: {
            data:{
                type:Array,
            }
        },

        components: {
            Albums,
            FeaturedArtists,
            RecommendedPlaylist,
            Genres,
            Collections,
        },

        /**
         * The component's data.
         */
        data: () => ({
            
        }),


        /**
         * Created the component.
         */
        created() {
        //  console.log(this.data);
        },

        methods: {
            
        }

    }
</script>

<template>
    <div v-show="data.length > 0">
        <template v-for="(value,key) in data">
            <albums v-if="value.type=='album'" :data="value" :key="key"></albums>
            <featured-artists v-if="value.type=='artist'" :data="value"></featured-artists>
            <genres v-if="value.type=='genre'" :data="value"></genres>
            <recommended-playlist v-if="value.type=='playlist'" :data="value"></recommended-playlist>
            <collections v-if="value.type=='collection'" :data="value"></collections>
        </template>
    </div>
</template>
