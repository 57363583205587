<script type="text/ecmascript-6">

    export default {

        props: {
            album_id : {
                type: String
            }
        },

        methods: {
            /**
             * Route to purchased album page.
             */
            gotoPurchaseAlbumPage() {
                this.$router.push(`/user/purchased-album/${this.album_id}`);
                this.closeModal();
            },

            /**
             * Close Modal
             */
            closeModal() {
                this.$nextTick(() => {
                    $('#downloadAlbumModal').modal('hide');
                    $('#buyModal').modal('hide');
                    $('#buyAlbumModal').modal('hide');
                });
            },
        }
    }
</script>

<template>
    <!---- Modal---->
    <div class="ms_save_modal">
        <div id="downloadAlbumModal" class="modal centered-modal" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="fa_icon form_close"></i>
                        </button>
                        <div class="modal-body">
                            <h1>Buying successful, do you want to go Album Download page now ?</h1>
                            <div class="clr_modal_btn">
                                <a href="#" @click.prevent="gotoPurchaseAlbumPage()">Goto Download Album</a>
                                <a href="#" data-dismiss="modal">Later</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
