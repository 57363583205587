<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'artists',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Artists',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            followed_artists: [],
        }),


        /**
         * Created the component.
         */
        created() {
            this.fetchFollowArtist();
        },


        methods: {
            /**
             *  Get the artists.
             */
            async fetchFollowArtist() {
                axios.get(`/api/v2/me/followed-artists`).then((response) => {
                    this.followed_artists = response.data.data;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <!----<div class="ms_content_wrapper ms_profile padder_top100">---->
        <div class="ms_content_wrapper ms_profile padder_top80">
            <main-header/>
            <div class="ms_featured_slider row" v-show="!loading">
                <div class="col-lg-12">
                    <div class="ms_heading">
                        <h1>Follow Artists</h1>
                    </div>
                </div>
                <div v-for="art in followed_artists" v-if="followed_artists.length > 0" class="cs-lg-col-8 cs-md-col-5 col-md-2 col-6">
                    <router-link tag="a" :to="{ name: 'artist', params: { id : art.uuid }}" :title="art.name_en">
                        <div class="ms_rcnt_box marger_bottom30">
                            <div class="ms_rcnt_box_img ms_rcnt_box_img_rounded">
                                <img :src="art.image" class="img-fluid" alt="" onError="this.onerror=null;this.src='/images/album/Artist.png';">
                                <div class="ms_main_overlay">
                                    <div class="ms_box_overlay"></div>
                                    <div class="ms_play_icon">
                                        <a href="javascript:;" @click.prevent="playAllArtistSong(art.id)">
                                            <img src="/images/svg/play2.svg" alt="">
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="ms_rcnt_box_text text-center">
                                <h3><router-link tag="a" :to="{ name: 'artist', params: { id : art.uuid }}">{{ art.name_en }}</router-link></h3>
                                <p style="font-size:12px">
                                 {{art.follower_counts}} Follower<span v-if="art.follower_counts > 1">s</span>
                                </p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div v-else>
                    <h5 class="text-center">No Followed Artists ........</h5>
                </div>
            </div>

            <div style="height:100vh;align-items: center;display: flex;margin-top: -10%" v-show="loading">
                <div class="more_loading mt-5" >
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>
            </div>
        </div>

    </layout>
</template>
