export const dictionary = {
    en: {
        messages:{
            max: function (field, ref) {
                let length = ref[0];
                return ("Please type within "+length+" characters.");
            },

            digits: function (field, ref) {
                let length = ref[0];
                return ("Please type within "+length+" characters.");
            },

            required: (field) => field+' required',

            email: () => 'Invalid email format.',
            number: () => 'Only numbers without space.'
        }
    }
};