<script type="text/ecmascript-6">
    import Cookies from 'js-cookie'
    import {mapGetters} from 'vuex';

    export default {
        props:{
            video:{
                type:Object,
            },
        },
        data: () => ({
            player: null,
        }),

        computed: {
            ...mapGetters({
                isAuthenticated: 'isAuthenticated',
            }),
            sortedArray(){
                return this.video.link.sort((a, b) => a.label - b.label );
            }
        },

        created(){
            var links=[];
            $.each(this.sortedArray,function(key , value){
                var obj={};
                obj.src=value.link;
                obj.type="video/mp4";
                obj.label=value.label;
                links.unshift(obj);
            })
            console.log(this.video)
            let self = this;
            this.$nextTick(() => {
                self.player=videojs('my-player', {
                    controls: true,
                    preload: true,
                    plugins: {
                        videoJsResolutionSwitcher: {
                          default: 'low',
                          dynamicLabel: true
                        }
                      }
                  }, function(){
                    var v_player= this;
                    // Add dynamically sources via updateSrc method
                    v_player.updateSrc(links);
                      
                  })
            });
            
        },

        methods: {
        }
    }
</script>

<template>
    <video
    id="my-player"
    class="video-js vjs-16-9"
    controls
    preload="auto"
    :poster="video.image ? video.image : '/images/social-cover-image-v3.jpg'"
    data-setup='{}'>
</video>
</template>

<style>
    .vjs-resolution-button-label{
        right: 50%;
        top: 50%;
        position: absolute;
        transform: translate(50%, -50%);
    }
</style>
