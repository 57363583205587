<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'Playlist',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Playlist',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            playlist: [],
        }),


        /**
         * Computed these properties.
         */
        computed: {

        },


        /**
         * Created the component.
         */
        created() {
            this.fetchPlaylist();
        },


        methods: {
            /**
             *  Get the playlist.
             */
            async fetchPlaylist() {
                axios.get('/api/v2/playlist').then((response) => {
                    this.playlist = response.data;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },
        }
    }
</script>

<template>
    <layout>

        <!---Main Content Start--->
        <div class="ms_content_wrapper ms_genres_single ms_profile padder_top80">

            <main-header/>

            <div class="ms_fea_album_slider padder_bottom30" id="playlists">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="ms_heading">
                            <h1>Playlist</h1>
                        </div>
                    </div>

                    <div v-for="item in playlist" class="cs-lg-col-8 cs-md-col-5 col-md-2 col-6">
                        <div class="ms_rcnt_box" style="margin-bottom: 30px;">
                            <div class="ms_rcnt_box_img">
                                <img :src="item.image" alt="" onError="this.onerror=null;this.src='/images/album/collection_playlist.png';">
                                <div class="ms_main_overlay">
                                    <router-link tag="a" :to="{ name: 'playlist-detail', params: { id: item.uuid }}" :title="item.name_mm">
                                        <div class="ms_box_overlay"></div>
                                    </router-link>
                                    <div class="ms_play_icon">
                                        <a href="javascript:;" @click.prevent="playPlaylistSong(item.uuid)">
                                            <img src="/images/svg/play2.svg" alt="">
                                        </a>
                                    </div>
                                    <div class="ms_more_icon" data-toggle="popup">
                                        <img src="/images/svg/more.svg" alt="">
                                    </div>
                                </div>
                            </div>
                            <ul class="more_option">
                                <li><a :href="facebookLinkShare(item.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share to facebook</a></li>
                                <li><a :href="twitterLinkShare(item.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share to twitter</a></li>
                                <li><a href="#" @click.prevent="copyText('playlist/'+item.uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                            </ul>
                            <div class="ms_rcnt_box_text">
                                <h3><router-link tag="a" :to="{ name: 'playlist-detail', params: { id: item.uuid }}">{{ item.name_mm }}</router-link></h3>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="more_loading" v-show="loading">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>

            </div>
        </div>


    </layout>
</template>
