<script type="text/ecmascript-6">
    import { Howl } from 'howler';
    import {mapGetters} from 'vuex';
    import secondsToTime from '../utils/secondsToTime';

    export default {

        /**
         * The component's data.
         */
        data: () => ({
            is_loading: false,
            is_repeat: false,
            is_shuffle: false,
            close_player: true,
        }),

        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                player: 'player',
                isPlay: 'isPlay',
                playerCurrentTime: 'playerCurrentTime',
                playerDuration: 'playerDuration',
                playerTracks: 'playerTracks',
                playerCurrentTrack: 'playerCurrentTrack',
                playerSeeking: 'playerSeeking',
                playerOriginalTracks : 'playerOriginalTracks',
                isAuthenticated: 'isAuthenticated'
            }),
            loading() {
                if (this.player) {
                    return this.player.state() !== 'loaded';
                }

                return false;
            },

            closePlayer() {
                return !this.playerCurrentTrack && this.$route.name !== 'Video';
            }
        },

        watch: {
            isPlay(nextIsPlay, prevIsPlay) {
                if (nextIsPlay !== prevIsPlay && this.player) {
                    this.handlePlayPause();
                }
            },
            playerCurrentTime(nextPlayerCurrentTime, prevPlayerCurrentTime) {
                if (nextPlayerCurrentTime !== prevPlayerCurrentTime && this.playerSeeking) {
                    this.player.seek(nextPlayerCurrentTime);
                    this.$store.dispatch('setPlayerSeeking', false);
                }
            },
            playerCurrentTrack(nextCurrentTrack, prevCurrentTrack) {

                if (nextCurrentTrack && !this.$_.isEqual(nextCurrentTrack, prevCurrentTrack)) {

                    if (this.player) {
                        this.player.pause();
                        this.$store.dispatch('pause');
                        this.$store.dispatch('setPlayerCurrentTime', 0);
                        this.$store.dispatch('setPlayerDuration', 0);
                    }

                    setTimeout(() => {
                        const player = new Howl({
                            src: nextCurrentTrack.audio,
                            html5: true,
                            volume: 1.0,
                            onend: () => {
                                this.handleChangeTrack('next');
                            },
                            onload: () => {
                                this.is_loading = false;
                            },
                        });

                        this.$store.dispatch('player', player);

                        this.$store.dispatch('play');

                        this.player.on('play', () => {
                            setInterval(() => {
                                if (this.player && this.player.playing()) {
                                    this.$store.dispatch('setPlayerCurrentTime', Math.round(this.player.seek()) || 0);
                                    if (Math.round(this.player.duration()) !== this.playerDuration) {
                                        this.$store.dispatch('setPlayerDuration', Math.round(this.player.duration()) || 0);
                                    }
                                }
                            }, 50);
                        });

                        this.is_loading = this.player.state() !== 'loaded';

                    }, 100);
                } else if (prevCurrentTrack && !nextCurrentTrack) {
                    this.$store.dispatch('pause');
                    this.$store.dispatch('setPlayerCurrentTime', 0);
                    this.$store.dispatch('setPlayerDuration', 0);
                    this.player.pause();
                }



            },
        },

        created() {
            if(localStorage.is_repeat) {
                this.is_repeat = localStorage.is_repeat;
            }

            if(localStorage.is_shuffle) {
                this.is_shuffle = localStorage.is_shuffle;
            }

            this.getRotationDegrees();

            this.$nextTick(() => {

                // Queue Scrollbar
                $(".jp_queue_list_inner").mCustomScrollbar({
                    theme:"minimal",
                    setHeight:345
                });

                // on click player list
                $(".play-left-arrow").on('click', function() {
                    $(".pha-jp-type-playlist").toggleClass('open_list');
                });

                $(".ms_player_close").on('click', function() {
                    $(".ms_player_wrapper").toggleClass("close_player");
                    $("body").toggleClass("main_class")
                });

                $('.jp_queue_cls').on('click', function(e) {
                    $('#playlist-wrap').hide();
                });
            });
        },

        mounted() {
            if(this.playerCurrentTrack && !this.isPlay) {
                if (!this.player) {
                    const player =  new Howl({
                        src : this.playerCurrentTrack.audio,
                        html5: true,
                        volume: 1.0,
                        onend: () => {
                            this.handleChangeTrack('next');
                        },
                        onload: () => {
                            this.is_loading = false;
                        },
                    });

                    this.$store.dispatch('player', player);
                }

                this.player.on('play', () => {
                    setInterval(() => {
                        if (this.player && this.player.playing()) {
                            this.$store.dispatch('setPlayerCurrentTime', Math.round(this.player.seek()) || 0);
                            if (Math.round(this.player.duration()) !== this.playerDuration) {
                                this.$store.dispatch('setPlayerDuration', Math.round(this.player.duration()) || 0);
                            }
                        }
                    }, 50);
                });

            }

        },

        destroyed() {
            if (this.player && !this.playerCurrentTrack) {
                this.$store.dispatch('pause');
                this.$store.dispatch('player', null);
                this.$store.dispatch('setPlayerCurrentTime', 0);
                this.$store.dispatch('setPlayerDuration', 0);
            }
        },


        methods: {
            handlePlayPause() {
                if (this.isPlay) {
                    if (this.playerDuration > 0 && (this.playerCurrentTime === this.playerDuration)) {
                        this.player.seek(0);
                        this.$store.dispatch('setPlayerCurrentTime', 0);
                        this.$store.dispatch('setPlayerDuration', 0);
                    }
                    this.player.play();
                } else {
                    this.player.pause();
                }
            },

            getRotationDegrees() {
                this.$nextTick(() => {
                    var self = this;
                    $(document).ready(function () {
                        $('.knob-wrapper').mousedown(function() {
                            $(window).mousemove(function(e) {
                                self.calculateRotationDegrees();
                            });

                            return false;
                        }).mouseup(function() {
                            self.calculateRotationDegrees();
                            $(window).unbind("mousemove");
                        });
                    })

                });
            },

            calculateRotationDegrees() {
                this.$nextTick(() => {
                    var self = this;
                    var matrix = $('.knob').css("-webkit-transform") ||
                        obj.css("-moz-transform")    ||
                        obj.css("-ms-transform")     ||
                        obj.css("-o-transform")      ||
                        obj.css("transform");
                    if(matrix !== 'none') {
                        var values = matrix.split('(')[1].split(')')[0].split(',');
                        var a = values[0];
                        var b = values[1];
                        var angle = Math.round(Math.atan2(b, a) * (180/Math.PI));
                    } else { var angle = 0; }
                    var angle1 = (angle < 0) ? angle + 360 : angle;

                    var volume = angle1 / 270;

                    if (volume > 1) {
                        self.player.volume(1);
                    } else if (volume <= 0) {
                        self.player.volume(0);
                    } else {
                        self.player.volume(volume);
                    }
                });

            },

            handleRepeat() {
                localStorage.is_repeat = this.is_repeat = !this.is_repeat;
            },

            handleShuffle() {
                localStorage.is_shuffle = this.is_shuffle = !this.is_shuffle;

                if (this.is_shuffle) {
                    var shuffleTracks = this.$_.shuffle(this.playerOriginalTracks);
                    this.$store.dispatch('setShuffleTracks', shuffleTracks);
                } else {
                    this.$store.dispatch('setOriginalTracks');
                }

            },

            handleChangeTrack(direction) {
                const self = this;
                const currentTrackID = this.playerCurrentTrack.id;
                this.playerTracks.forEach((track, index) => {
                    if (currentTrackID === track.id) {
                        let nextIndex = 0;
                        if (direction === 'next') {
                            nextIndex = index + 1;
                            if (nextIndex < self.playerTracks.length) {
                                this.playTrack(self.playerTracks[nextIndex]);
                            }else if (this.is_repeat && nextIndex === self.playerTracks.length) {
                                this.playTrack(self.playerTracks[0]);
                            }
                        } else if (direction === 'previous') {
                            nextIndex = index - 1;
                            if (nextIndex >= 0) {
                                this.$store.dispatch('setPlayerCurrentTrack', self.playerTracks[nextIndex]);
                            }
                        }
                    }
                });
            },
            calculateSeekOnClick(e) {

                const element = e.currentTarget;

                const rect = element.getBoundingClientRect();

                const offset = {
                    top: rect.top + document.body.scrollTop,
                    left: rect.left + document.body.scrollLeft
                };

                const x = e.pageX - offset.left,
                    w = element.clientWidth,
                    percent = 100 * x / w;

                const currentTime = Math.floor((this.playerDuration / 100) * percent);

                this.player.seek(currentTime);

                if (!this.isPlay) {
                    this.$store.dispatch('play');
                    this.player.play();
                }
            },
            secondsToTime,

            /**
             *  Favourite the song.
             */
            favouriteSong(song_id) {
                axios.post(`/api/mmn/favourite/song/${song_id}`).then((response) => {
                    this.playerCurrentTrack.is_favourite = response.data.is_favourite;
                    this.$toast.success({ title: 'Success', message: response.data.message });
                }).catch((error) => {

                })
            },


        }

    }
</script>

<template>
    <div :class="{ ms_player_wrapper : true, 'close_player' : closePlayer}">
        <div class="ms_player_close" v-show="close_player">
            <i class="fa fa-angle-up" aria-hidden="true"></i>
        </div>
        <div class="player_mid">
            <div class="audio-player">
                <div id="jquery_jplayer_1" class="jp-jplayer" style="width: 0px; height: 0px;"><img id="jp_poster_0" style="width: 0px; height: 0px; display: none;">
                    <audio id="jp_audio_0" preload="metadata" src="#"></audio>
                </div>
                <div id="jp_container_1" :class="{'jp-audio' : 'true', 'jp-state-playing' : isPlay, 'jp-state-looped' : is_repeat, 'jp-state-shuffled' : is_shuffle }" role="application"
                     aria-label="media player">
                    <div class="player_left">
                        <div class="ms_play_song">
                            <div class="play_song_name">
                                <a href="javascript:void(0);" id="playlist-text">
                                    <div class="jp-now-playing flex-item" v-if="playerCurrentTrack && $route.name !== 'Video'">
                                        <div class="jp-track-name"><span class="que_img"><img
                                                :src="playerCurrentTrack.album_image"></span>
                                            <div class="que_data">{{ playerCurrentTrack.name_mm}}
                                                <div class="jp-artist-name">
                                                    <template v-for="(artist, index) in playerCurrentTrack.artists">
                                                        {{ artist.name_eng }} {{ isNotLast(playerCurrentTrack.artists, index) ? ', ' : ''}}
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <span class="play-left-arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
                    </div>

                    <!----Right Queue---->
                    <div class="jp_queue_wrapper">
                        <span class="que_text" id="myPlaylistQueue"><i class="fa fa-angle-up" aria-hidden="true"></i> queue</span>
                        <div id="playlist-wrap" class="jp-playlist">
                            <div class="jp_queue_cls"><i class="fa fa-times" aria-hidden="true"></i></div>
                            <h2>queue</h2>
                            <div class="jp_queue_list_inner">
                                <ul style="">
                                    <template v-for="song in playerTracks">
                                        <li :class="playerCurrentTrack.id === song.id ? 'jp-playlist-current' : ''" @click="playQueueTrack(song)">
                                            <div><a href="javascript:;" class="jp-playlist-item-remove"
                                                    style="display: none;">×</a><a href="javascript:;"
                                                                                   class="jp-playlist-item"
                                                                                   tabindex="0"><span
                                                    class="que_img">
                                                <img :src="song.album_image" alt="" onError="this.onerror=null;this.src='/images/album/Album.png';"/></span>
                                                <div class="que_data">{{ song.name_mm }}
                                                    <span class="jp-artist">by
                                                            <template v-if="song.artists.length > 3">
                                                                Various Artists
                                                            </template>

                                                            <template v-else>
                                                                <template v-for="(artist, index1) in song.artists">{{ artist.name_eng }} <span v-if="isNotLast(song.artists, index1)">, </span></template>
                                                            </template>
                                                        </span>
                                                </div>
                                            </a>
                                                <div class="action"><span class="que_more"><img
                                                        src="/images/svg/more.svg"></span><span
                                                        class="que_close"><img src="/images/svg/close.svg"></span>
                                                </div>
                                            </div>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                            <div class="jp_queue_btn">
                            </div>
                        </div>
                    </div>

                    <div class="jp-type-playlist pha-jp-type-playlist">

                        <div class="play_song_options pha-play_song_options">
                            <ul>
                            <template v-if="isAuthenticated">
                                <!-- <li><a href="javascript:void(0);" @click.prevent="downloadSong(playerCurrentTrack.id)"><span class="song_optn_icon"><i class="ms_icon icon_download"></i></span>download now</a></li> -->
                                <li><a href="Javascript:;" @click.prevent="favouriteSong(playerCurrentTrack.id)"><span class="song_optn_icon"><i class="ms_icon icon_fav"></i></span>Add To Favourites</a></li>
                                <li><a href="javascript:void(0);" @click.prevent="saveSong(playerCurrentTrack.id)"><span class="song_optn_icon"><i class="ms_icon icon_playlist"></i></span>Add To Playlist</a></li>
                            </template>
                            <template v-else>
                                <!-- <li><a href="javascript:void(0);" data-toggle="modal" data-target="#loginModal"><span class="song_optn_icon"><i class="ms_icon icon_download"></i></span>download now</a></li> -->
                                <li><a href="Javascript:;" data-toggle="modal" data-target="#loginModal"><span class="song_optn_icon"><i class="ms_icon icon_fav"></i></span>Add To Favourites</a></li>
                                <li><a href="javascript:void(0);" data-toggle="modal" data-target="#loginModal"><span class="song_optn_icon"><i class="ms_icon icon_playlist"></i></span>Add To Playlist</a></li>
                            </template>
                                <li><a href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="song_optn_icon"><i class="ms_icon icon_share"></i></span>Share</a>
                                <div class="dropdown-menu" v-if="playerCurrentTrack">
                                    <a class="dropdown-item" :href="shareFacebookLink('/song/' + playerCurrentTrack.album_uuid+'/'+playerCurrentTrack.id)" target="_blank" style="color:black;"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a>
                                    <a class="dropdown-item" :href="shareTwitterLink('/song/' + playerCurrentTrack.album_uuid+'/'+playerCurrentTrack.id)" target="_blank" style="color:black;"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a>
                                </div>
                                </li>
                            </ul>

                        </div>

                        <div class="jp-gui jp-interface flex-wrap pha-jp-gui">
                            <div class="jp-controls flex-item pha-jp-controls">
                                <button class="jp-previous" tabindex="0" @click="handleChangeTrack('previous')">
                                    <i class="ms_play_control"></i>
                                </button>
                                <div class="player-loading" v-show="loading"></div>
                                <button v-show="!loading" class="jp-play" @click="$store.dispatch(isPlay ? 'pause' : 'play')" tabindex="0">
                                    <i class="ms_play_control"></i>
                                </button>
                                <button class="jp-next" tabindex="0" @click="handleChangeTrack('next')">
                                    <i class="ms_play_control"></i>
                                </button>
                            </div>
                            <div class="jp-progress-container flex-item pha-jp-progress-container">
                                <div class="jp-time-holder">
                                    <span class="jp-current-time" role="timer" aria-label="time">{{secondsToTime(playerCurrentTime)}}</span>
                                    <span class="jp-duration" role="timer"
                                          aria-label="duration">{{ secondsToTime(playerDuration) }}</span>
                                </div>
                                <div class="jp-progress">
                                    <div class="jp-seek-bar" @click="calculateSeekOnClick" style="width: 100%;">
                                        <div class="jp-play-bar" :style="{ width: `${(((playerCurrentTime / playerDuration) * 100) || 0)}%` }">
                                            <div class="bullet">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="jp-volume-controls flex-item pha-jp-volume-controls">
                                <div class="widget knob-container">
                                    <div class="knob-wrapper-outer">
                                        <div class="knob-wrapper">
                                            <div class="knob-mask">
                                                <div class="knob d3"><span></span></div>
                                                <div class="handle"></div>
                                                <div class="round">
                                                    <img src="/images/svg/volume.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <input></input> -->
                                    </div>
                                </div>
                            </div>
                            <div class="jp-toggles flex-item">
                                <button class="jp-shuffle" @click.prevent="handleShuffle()" tabindex="0" title="Shuffle">
                                    <i class="ms_play_control"></i></button>
                                <button class="jp-repeat" @click.prevent="handleRepeat()" tabindex="0" title="Repeat"><i class="ms_play_control"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--main div-->
    </div>
</template>

<style>
    .pha-jp-gui {
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .pha-jp-controls {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pha-jp-progress-container {
        max-width: 100%
    }
    .player-loading {
        width: 30px;
        height: 30px;
        background-color: #fff;
        border-radius: 100%;
        -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
        animation: sk-scaleout 1.0s infinite ease-in-out;
        display: inline-block;
    }
    .pha-play_song_options {
        width: 100%;
        float: none;
        margin-right: 0px;
        position: static;
    }
    .pha-jp-type-playlist {
        display: flex;
        align-items: center;
        position: static;
        width: auto!important;
    }
    .pha-jp-progress-container {
        max-width: 100%!important;
    }
    .pha-jp-volume-controls {
        display: flex;
        justify-content: center;
    }
    @-webkit-keyframes sk-scaleout {
        0% { -webkit-transform: scale(0) }
        100% {
            -webkit-transform: scale(1.0);
            opacity: 0;
        }
    }
    @keyframes sk-scaleout {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
        } 100% {
              -webkit-transform: scale(1.0);
              transform: scale(1.0);
              opacity: 0;
          }
    }
</style>


