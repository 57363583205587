import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import Meta from 'vue-meta'


Vue.use(Router);
Vue.use(Meta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true
})

const router = Vue.router = new Router({
    linkActiveClass: 'active',
    mode: 'history',
    routes,
    scrollBehavior: () => ({ y: 0 }),
});

router.beforeEach ((to, from, next) => {

    if (process.env.NODE_ENV === 'production') {

    }

    next();

});

export default router
