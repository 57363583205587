import Vue from 'vue';
import App from './components/App.vue';
import router from './routes';
import store from './store';
import helper from './utils';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import GoogleAuth from 'vue-google-oauth'
import './validators'
import './bootstrap';
import './toast';
import VueLazyLoad from 'vue-lazyload';

window.Vue = Vue;
export const bus=new Vue();

if (process.env.MIX_APP_ENV === 'production') {
    Vue.config.devtools = false;
    Vue.config.debug = false;
    Vue.config.silent = true;
}

Vue.config.errorHandler = function (err, vm, info) {
    console.error(err);
};

Vue.use(VueAwesomeSwiper);
Vue.use(VueLazyLoad, {
    preLoad: 1.3,
    error: 'dist/error.png',
    loading: 'images/loader.gif',
    attempt: 1
  })

Vue.use(GoogleAuth, { client_id: '520412401676-18lksvvojdfne9cjk947r0benbbs6pch.apps.googleusercontent.com' })
Vue.googleAuth().load()

Vue.prototype.$http = axios.create();

Vue.mixin(helper);
Vue.filter('trunc', function (value,len=20) {
  if (!value) return ''
   _.trim(value);
    return _.truncate(value, {'length': len});
})

new Vue({
    el: '#root',

    router,

    store,

    beforeCreate() {
        this.$store.commit('INITIALISE_STORE');

        if (this.$store.getters.isAuthenticated) {
            this.$store.dispatch('userRequest');
            this.$store.dispatch('searchHistory');
        }
    },

    render: h => h(App)
});
