<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'
    
    export default {
        name: 'MeloLives',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Lives',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            data:[],
        }),
        computed: {
        },

        /**
         * Created the component.
         */
        created() {
            this.fetchLiveData();
        },


        methods: {
            /**
             *  Get the Live Data.
             */
            async fetchLiveData() {
                axios.get(`/api/v2/live-melo`).then((response) => {
                    this.data = response.data.data;
                   
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <!----<div class="ms_content_wrapper ms_profile padder_top100">---->
        <div class="ms_content_wrapper ms_profile padder_top50">
            <main-header/>
            <div class="ms_featured_slider row">
                <div class="col-lg-12">
                    <div class="ms_heading">
                        <h1>Lives</h1>
                    </div>
                </div>
                <div class="col-md-12 col-12">
                    <div class="row">
                        <div class="col-md-3 col-12" v-for="video in data">
                            <div class="ms_rcnt_box marger_bottom30">
                                <div class="ms_rcnt_box_img">
                                    <img :src="video.image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/album/album.jpg';">
                                    <div class="ms_main_overlay">
                                        <router-link tag="a" :to="{ name: 'live', params: { id: video.id }}" v-if="video.has_live">
                                            <div class="ms_box_overlay"></div>
                                        </router-link>
                                        <a href="javascript:;" v-else>
                                            <div class="ms_box_overlay"></div>
                                        </a>
                                    </div>
                                </div>
                                
                                <div class="ms_rcnt_box_text">
                                    <h3 v-if="video.has_live"><router-link :to="{ name : 'live', params : { id : video.id } }">{{ video.name }}</router-link></h3>
                                    <h3 v-else><a href="javascript:;">{{ video.name }}</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </layout>
</template>
<style>
.video-js .vjs-big-play-button{
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
</style>
