<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'videos',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Videos',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            videos: [],
            playlists: [],
        }),


        /**
         * Created the component.
         */
        created() {
            this.fetchVideos();
            this.fetchPlaylist();
        },


        methods: {
            /**
             *  Get the videos.
             */
            async fetchVideos() {
                axios.get(`/api/v2/cms/new-release-video`).then((response) => {
                    this.videos = response.data;

                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

            async fetchPlaylist() {
                axios.get(`/api/v2/cms/recommended-video-playlist`).then((response) => {
                    this.playlists = response.data;

                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <div class="ms_content_wrapper ms_profile padder_top50">

            <main-header/>
            <div class="ms_fea_album_slider padder_bottom30" id="videos">
                <div class="row" v-show="!loading" id="m-video">
                    <div class="col-lg-12">
                        <div class="ms_heading">
                            <h1>Music Videos</h1>
                            <span class="veiw_all"><router-link :to="{ name: 'music-videos'}">view more</router-link></span>
                        </div>
                    </div>

                    <div class="cs-lg-col-5 col-md-2 col-6" v-for="video in videos">
                        <div class="ms_rcnt_box marger_bottom30">
                            <div class="ms_rcnt_box_img video_resize">
                                <img :src="video.image" alt="" class="img-fluid m-video-img" onError="this.onerror=null;this.src='/images/album/album.jpg';">
                                <div class="ms_main_overlay">
                                    <router-link :to="{ name : 'Video', params : { id : video.id } }">
                                        <div class="ms_box_overlay"></div>
                                    </router-link>
                                    <div class="ms_play_icon">
                                        <router-link :to="{ name : 'Video', params : { id : video.id } }">
                                            <img src="/images/svg/play2.svg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="ovrly_text_div">
                                    <span class="ovrly_text1">
                                        <router-link :to="{ name : 'Video', params : { id : video.id } }">{{ video.name_mm }}</router-link>
                                    </span>
                                        <span class="ovrly_text2"><router-link :to="{ name : 'Video', params : { id : video.id } }">view video</router-link></span>
                                    </div>
                                </div>
                            </div>
                            <div class="ms_rcnt_box_text">
                                <h3><router-link :to="{ name : 'Video', params : { id : video.id } }">{{ video.name_mm }}</router-link></h3>
                                <p>
                                    <template>
                                        <a href="javascript:;">{{video.str_artists}}</a>
                                    </template>

                                    <!-- <template v-else>
                                        <template v-for="(artist, index1) in video.artists">
                                            <router-link :to="{ name : 'artist', params:{ id : artist.uuid }}">{{ artist.name_en }} <span v-if="isNotLast(video.artists, index1)">, </span></router-link>
                                        </template>
                                    </template> -->
                                </p>
                                <p>
                                    <span>
                                        <template v-if="video.views >= 1000 && video.views < 1000000">
                                            <span v-if="video.views % 1000 > 100">{{(video.views/1000).toFixed(1)}}</span>
                                            <span v-else>{{(video.views/1000).toFixed(0)}}</span>K
                                        </template>
                                        <template v-else-if="video.views >= 1000000">
                                            <span v-if="video.views % 1000000 > 100000">{{(video.views/1000000).toFixed(1)}}</span>
                                            <span v-else>{{(video.views/1000000).toFixed(0)}}</span>M</template>
                                        <template v-else>
                                            {{video.views}}
                                        </template>
                                        view<template v-if="video.views > 0">s</template>
                                    </span>
                                    <span class="mt-2 ml-1" style="font-size: 26px;">.</span>
                                    {{video.created_at}}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row" v-show="!loading">
                    <div class="col-lg-12">
                        <div class="ms_heading">
                            <h1>Video Playlists</h1>
                            <span class="veiw_all"><router-link :to="{ name: 'video-playlists'}">view more</router-link></span>
                        </div>
                    </div>
                    <div class="cs-lg-col-5 col-md-2 col-6" v-for="video in playlists">
                        <div class="ms_rcnt_box marger_bottom30">
                            <div class="ms_rcnt_box_img">
                                <img :src="video.image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/album/album.jpg';">
                                <div class="ms_main_overlay">
                                    <div class="ms_box_overlay"></div>
                                    <div class="ms_play_icon">
                                        <router-link :to="{ name : 'Video', params : { id : video.id } }">
                                            <img src="/images/svg/play2.svg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="ovrly_text_div">
                                    <span class="ovrly_text1">
                                        <router-link :to="{ name : 'Video', params : { id : video.id } }">{{ video.name_mm }}</router-link>
                                    </span>
                                        <span class="ovrly_text2"><router-link :to="{ name : 'Video', params : { id : video.video_id } }">view video</router-link></span>
                                    </div>
                                </div>
                            </div>
                            <div class="ms_rcnt_box_text">
                                <h3><router-link :to="{ name : 'Video', params : { id : video.id } }">{{ video.name_mm }}</router-link></h3>
                                <p>
                                    <span>
                                        {{video.video_count}}
                                        video<template v-if="video.video_count > 0">s</template>
                                    </span>
                                    <span class="mt-2 ml-1" style="font-size: 26px;">.</span>
                                    {{video.created_at}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-show="loading">
                    <div style="height: 100vh;align-items: center;display: flex;margin-top: -10%;">
                        <div class="more_loading">
                            <div class="bar"></div>
                            <div class="bar"></div>
                            <div class="bar"></div>
                            <div class="bar"></div>
                            <div class="bar"></div>
                            <div class="bar"></div>
                            <div class="bar"></div>
                            <div class="bar"></div>
                            <div class="bar"></div>
                            <div class="bar"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </layout>
</template>

<style>
    /*#m-video .ms_rcnt_box_img img.m-video-img{
        transform: scale(1.4);
    }
    #m-video .ms_rcnt_box:hover .ms_rcnt_box_img img.m-video-img {
        transform: scale(1.5);
    }*/
    .video_resize{
        display:flex;
        align-items:center;
        height: 121px;
    }
</style>
