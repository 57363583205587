<script type="text/ecmascript-6">
import Layout from '../layouts/Index.vue'
import MainHeader from '../layouts/Header.vue'
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
    name: 'Payment Status',

    components: {
        Layout,
        MainHeader,
        swiper,
        swiperSlide,
    },

    metaInfo() {
        return {
            title: 'Payment Status',
        }
    },

    /**
     * The component's data.
     */
    data: () => ({
        loading: true,
        status: "",
        token: document.head.querySelector('meta[name="csrf-token"]'),
    }),


    /**
     * Computed these properties.
     */
    computed: {

    },


    /**
     * Created the component.
     */
    mounted() {

    },

    created() {
        this.fetchOrder();
    },

    methods: {
        fetchOrder() {
            axios.post('/api/mmn/payment/checkOrder', { order_id: this.$route.query.merchantOrderId }).then((response) => {
                this.status = response.data.status
            }).catch((error) => {
            });
        }
    }
}
</script>

<template>
    <layout>

        <!---Main Content Start--->
        <div class="ms_content_wrapper ms_genres_single ms_profile padder_top50">

            <main-header />

            <div class="ms_fea_album_slider">
                <div class="pay_main_header">
                    <h3 style="font-size:20px">Thank you for using Melo Music Application!</h3>
                </div>
                <div class="row justify-content-center text-center text-white" style="font-size: 14px;font-weight: 600;">
                    <div class="col-md-12">
                        <p v-if="status">
                            Payment : {{ status }} <br><br>
                            Melo Music အားအသုံးပြုခြင်းအတွက် ကျေးဇူးတင်ရှိပါသည်။ ဝယ်ယူမှုနှင့်သက်ဆိုင်သော အကြောင်းကြားစာ
                            မကြာမီရောက်ရှိပါလိမ့်မည်။
                        </p>
                        <p v-else>
                            Payment : Order number မရှိပါ။ <br><br>
                            Melo Music အားအသုံးပြုခြင်းအတွက် ကျေးဇူးတင်ရှိပါသည်။
                        </p>
                    </div>
                </div>
                <!--<div class="pay_heading">
                    <h1>Choose Your Amount</h1>
                </div>-->
                <div class="row justify-content-center" id="amount_row" style="padding:40px;">
                    <div class="ms_rcnt_box col-md-4 payment_method" v-for="(image, index) in images"
                        v-if="image.type == 'amount'">
                        <div class="ms_rcnt_box_img select_amount" :data-amount="image.for" @click.prevent="checkAmount"
                            :data-type="image.type">
                            <img :src="image.image" alt="" :data-amount="image.for">
                        </div>
                        <div class="ms_rcnt_box_text" style="text-align: center;">
                            <input type="radio" id="1000" name="amount" v-model="amount" value="1000" checked
                                style="display:none">
                        </div>
                    </div>
                </div>
            </div>


        </div>


    </layout>
</template>
<style scoped>
.pay_main_header h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 26px;
    color: #fff;
    margin: 20px 0;
}

.pay_heading {
    width: 100%;
    display: inline-block;
    margin: 30px 0;
    justify-content: center;
    display: flex;
}

.pay_heading h1 {
    font-size: 24px;
    position: relative;
    padding-bottom: 10px;
    text-transform: capitalize;
    text-align: center;
}

.pay_heading h1::after {
    width: 170px;
    height: 3px;
    content: "";
    position: absolute !important;
    bottom: 0;
    left: calc(58% - 100px);
    z-index: 0;
    background: #ff2150;
}

.select_hover {
    border: 4px solid #ff2150;
}

.select_plan_btn {
    background-color: #ff2150;
    color: #fff;
    padding: 3px 0;
    display: inline-block;
    text-transform: capitalize;
    width: 170px;
    text-align: center;
    line-height: 32px;
    border-radius: 20px;
    margin-left: 15px;
}

.ms_btn_next {
    background-color: #ff2150;
    color: #fff;
    padding: 10px 0;
    display: inline-block;
    text-transform: capitalize;
    width: 220px;
    text-align: center;
    line-height: 32px;
    border-radius: 25px;
    margin-left: 15px;
    font-size: 24px;
}</style>
