<script type="text/ecmascript-6">
import { mapGetters } from 'vuex';
import Layout from '../layouts/Index.vue'
import MainHeader from '../layouts/Header.vue'

export default {
    name: 'profile',

    metaInfo() {
        return {
            title: 'Profile',
        }
    },

    components: {
        Layout,
        MainHeader,
    },

    /**
     * Computed these properties.
     */
    computed: {
        ...mapGetters({
            isAuthenticated: 'isAuthenticated',
            user: 'getProfile',
        }),
    },

    methods: {
        notification() {
            axios.post('/api/v2/notification/toggle').then((response) => {
                console.log(response.data);
                this.user.notification = response.data.user.notification;
            }).catch((error) => {
            });
        }
    }

}
</script>

<template>
    <layout>

        <div class="padder_top80 ms_profile">

            <main-header />

            <!----Edit Profile Wrapper Start---->
            <div class="ms_profile_wrapper">
                <!-- <h1>Account Information</h1> -->
                <div class="ms_profile_box" style="position:relative;">
                    <!-- <a href="javascript:;" @click.prevent="$router.go(-1)" style="position: absolute;top:10px;right:10px;">
                        <img src="/img/close-profile.png" class="img-fluid" style="width:30px;"></img>
                    </a> -->
                    <div class="ms_heading mb-4" style="position: relative;">
                        <div>
                            <h4 style="text-align: left;">Account Information</h4>
                        </div>
                        <div>
                            <router-link to="/user/edit-profile" class="ms_btn edit-profile">Edit Profile</router-link>
                        </div>
                    </div>

                    <div class="ms_profile_list">
                        <div class="row">
                            <div class="col-md-4">
                                <img :src="user.image ? user.image : '/img/avatar/admin.jpg'" class="img-fluid">
                                <div v-if="user.has_mpt_subscription"
                                    style="display: flex; align-items: center; justify-content: center;">
                                    <span class="opt_icon" style=""><img src="/images/svg/Preminum.svg"
                                            style="width: 20px;"></span>
                                    <div style="padding-left: 10px;">
                                        <span style="text-transform:none; color:green;font-weight:bold">Premium User </span>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-8 ms_profile_card">
                                <ul style="width: 100%">
                                    <li>User ID <span>: {{ user.uuid }}</span> </li>
                                    <li>Name <span> : {{ user.name }}</span></li>
                                    <li>Email <span>: {{ user.email }}</span></li>
                                    <li>Phone <span>: {{ user.phone }}</span></li>
                                    <li>Premium User <span>: {{ user.has_mpt_subscription ? "Yes" : "No" }}</span></li>

                                </ul>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="mt-1" style="padding: 0px 20px">
                                <h6>My Coins</h6>
                                <a href="#" class="ms_profile_btn">
                                    <img class="sub_icon" src="/images/svg/deposit.png">
                                    <!-- <span class="fa fa-dollar sub_icon bg-warning"></span> -->
                                    {{ user.remained_amount.toLocaleString() }} MMK</a>
                            </div>
                            <div class="mt-1" style="padding: 0px 20px">
                                <h6>Streaming Quality</h6>
                                <a href="#" class="ms_profile_btn">
                                    <img class="sub_icon" src="/images/svg/Speaker.png">
                                    <!-- <span class="fa fa-dollar sub_icon bg-primary"></span> -->
                                    {{ user.streaming_quality === 256 ? 'High' : 'Low' }}</a>
                            </div>
                            <div class="mt-1" style="padding: 0px 20px">
                                <h6>Notification</h6>
                                <a href="javascript:;" @click.prevent="notification()" class="ms_profile_btn"
                                    v-bind:class="{ 'on': user.notification, 'off': !user.notification }">
                                    <img class="noti" src="/images/svg/Speaker.png">
                                    {{ user.notification ? 'Yes' : 'No' }}</a>
                            </div>
                        </div>
                        <div class="row" style="padding: 40px 0;">
                            <h4 style="width: 100%;margin-bottom: 30px;">My Library</h4>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-md-3">
                                        <router-link to="/recent" title="History">
                                            <div class="ms_profile_library_card">
                                                <div class="ms_library_img">
                                                    <span class="nav_icon">
                                                        <img src="/images/svg/recent.png">
                                                    </span>
                                                </div>
                                                <div class="ms_library_text">
                                                    <p>Recently Played</p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                    <div class="col-12 col-md-3">
                                        <router-link to="/user/favourite" title="Favourites">
                                            <div class="ms_profile_library_card">
                                                <div class="ms_library_img">
                                                    <img src="/images/svg/Favorite.png">
                                                </div>
                                                <div class="ms_library_text">
                                                    <p>My Favourties</p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                    <div class="col-12 col-md-3">
                                        <router-link to="/me/followed-artists" title="Follow Artists">
                                            <div class="ms_profile_library_card">
                                                <div class="ms_library_img">
                                                    <img src="/images/svg/follow_artist.png">
                                                </div>
                                                <div class="ms_library_text">
                                                    <p>Follow Artists</p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                    <div class="col-12 col-md-3">
                                        <router-link to="/my-play-lists" title="MyPlaylist">
                                            <div class="ms_profile_library_card">
                                                <div class="ms_library_img">
                                                    <img src="/images/svg/playlist.svg">
                                                </div>
                                                <div class="ms_library_text">
                                                    <p>My Playlists</p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                    <!-- <div class="col-12 col-md-4 cs-lg-col-5">
                                        <router-link to="/user/purchased" title="Purchased">
                                            <div class="ms_profile_library_card">
                                                <div class="ms_library_img">
                                                    <img src="/images/svg/purchase.png">
                                                </div>
                                                <div class="ms_library_text">
                                                    <p>My Purchased</p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div> -->
                                </div>
                                <div class="row justify-content-center">
                                    <router-link to="/user/edit-renewal" class="ms_btn">Subscription History</router-link>
                                </div>
                                <div class="row justify-content-center">
                                    <router-link to="/user/account/delete" class="ms_btn">Delete Account</router-link>
                                </div>
                            </div>
                        </div>
                        <!-- <ul>
                            <li>ID  <span>- {{ user.uuid }}</span></li>
                            <li>Your Name  <span>- {{ user.name }}</span></li>
                            <li>Email  <span>- {{ user.email }}</span></li>
                            <li>Phone   <span>- {{ user.phone }}</span></li>
                            <li>Your Balance      <span>- {{ user.remained_amount.toLocaleString() }} MMK</span></li>
                            <li>Streaming Quality  <span>- {{ user.streaming_quality === 256 ? 'High Quality' : 'Low Quality' }}</span></li>
                            <li>Premium Subscribe<span>- {{ user.subscribe ? `Active ( ${user.left_day} ) Days`  : 'Unactive' }}</span></li>
                        </ul>
                        <router-link to="/user/change-password" class="ms_btn" v-show="!user.type">Change Password</router-link>
                        <router-link to="/user/edit-profile" class="ms_btn">Edit Your Account Information</router-link>
                        <router-link to="/user/edit-renewal" class="ms_btn">Edit Subscription And History</router-link> -->
                    </div>

                </div>
            </div>
        </div>


    </layout>
</template>
<style >
.ms_library_img img {
    width: 80px !important;
    border-radius: 0 !important;
}

.sub_icon {
    position: absolute;
    top: -2px;
    left: -5px;
    width: 30px !important;
    height: 30px;
    border-radius: 0px !important;
}

.ms_profile_btn.off {
    background: gainsboro !important;
}

.on {
    padding-left: 15px !important;
    padding-right: 35px !important;
}

.noti {
    position: absolute;
    top: -2px;
    width: 30px !important;
    height: 30px;
    border-radius: 0px !important;
}

.off .noti {
    left: -5px;
}

.on .noti {
    right: 0px;
}

.edit-profile {
    position: absolute;
    top: 0;
    right: 0;
}

@media only screen and (max-width: 600px) {
    .ms_profile_box {
        padding: 50px 20px;
    }

    .ms_profile_wrapper {
        margin-top: 0;
    }

    .ms_profile_box {
        width: 90%;
    }

    .edit-profile {
        float: right;
    }
}
</style>
