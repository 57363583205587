<script type="text/ecmascript-6">

    import { swiper, swiperSlide } from 'vue-awesome-swiper';

    export default {
        name: 'recommended-playlist',

        props: {
            data:{
                type:Object,
            }
        },

        components: {
            swiper,
            swiperSlide,
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: false,
            swiperOption: {
                slidesPerView: 8,
                spaceBetween: 15,
                slidesPerGroup: 4,
                // loop: true,
                speed: 500,
                navigation: {
                    nextEl: '.swiper-button-next-playlist',
                    prevEl: '.swiper-button-prev-playlist',
                },
                breakpoints: {
                    2900: {
                        slidesPerView: 8,
                    },
                    1900: {
                        slidesPerView: 8,
                    },
                    1800: {
                        slidesPerView: 8,
                    },
                    1400: {
                        slidesPerView: 8,
                    },
                    1124: {
                        slidesPerView: 6,
                    },
                    1024: {
                        slidesPerView: 6,
                    },
                    992: {
                        slidesPerView: 6,
                    },
                    834: {
                        slidesPerView: 5,
                    },
                    768: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                    },
                    640: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                    },
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    },
                    375: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    }
                },
            },
        }),


        /**
         * Created the component.
         */
        created() {
        },

        mounted(){
            let _this = this;
            this.$nextTick(() => {
                _this.swiper.on('slideChange', function () {
                    if(!_this.isBeginning){
                        $('.swiper-button-prev-playlist').removeClass('d-none');
                    }else{
                        $('.swiper-button-prev-playlist').addClass('d-none');
                    }
                    if(!_this.isEnd){
                        $('.swiper-button-next-playlist').removeClass('d-none');
                    }else{
                        $('.swiper-button-next-playlist').addClass('d-none');
                    }
                });
            });
        },


        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper
            },
            isBeginning() {
                return this.swiper.isBeginning
            },
            isEnd() {
                return this.swiper.isEnd
            },
        },

        methods: {
            /**
             *  Get the recommended playlist.
             */
            async fetchRecommendedPlaylist() {
                axios.get('/api/mmn/recommended-playlist-9').then((response) => {
                    this.swiper.slideTo();
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

        }

    }
</script>

<template>
    <div class="ms_radio_wrapper" v-show="data.data.length > 0">
        <div class="ms_heading">
            <h1>{{data.title}}</h1>
            <!-- <span class="veiw_all"><router-link to="/recommended-playlist">view more</router-link></span> -->
        </div>

        <div class="row" v-show="loading">
            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>

            <div class="col-md-2" style="display: inline-block">
                <div class="load-music-box"></div>
            </div>
        </div>

        <div v-show="!loading" class="ms_radio_slider swiper-container recommended_playlist_swiper">
            <swiper :options="swiperOption" ref="mySwiper">
                <!-- slides -->
                <template v-for="item in data.data">
                    <swiper-slide>
                        <div class="ms_rcnt_box">
                            <div class="ms_rcnt_box_img">
                                <img :src="item.image" alt="" alt="" onError="this.onerror=null;this.src='/images/album/collection_playlist.png';">
                                <!-- <img v-lazy="item.image" alt=""> -->
                                <div class="ms_main_overlay">
                                    <router-link tag="a" :to="{ name: 'playlist-detail', params: { id: item.uuid }}" :title="item.name_mm">
                                        <div class="ms_box_overlay"></div>
                                    </router-link>
                                    <div class="ms_play_icon">
                                        <a href="javascript:;" @click.prevent="playPlaylistSong(item.uuid)">
                                            <img src="/images/svg/play2.svg" alt="">
                                        </a>
                                    </div>
                                    <div class="ms_more_icon" data-toggle="popup">
                                        <img src="/images/svg/more.svg" alt="">
                                    </div>
                                </div>
                            </div>
                            <ul class="more_option">
                                <li><a :href="shareFacebookLink(item.image,'playlists',item.name,item.id,item.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share to facebook</a></li>
                                <li><a :href="shareTwitterLink(item.image,'playlists',item.name,item.id,item.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share to twitter</a></li>
                                <li><a href="#" @click.prevent="copyText('playlist/'+item.uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                            </ul>

                            <div class="ms_rcnt_box_text">
                                <h3><router-link tag="a" :to="{ name: 'playlist-detail', params: { id: item.uuid }}">{{ item.name }}</router-link></h3>
                            </div>
                        </div>
                    </swiper-slide>
                </template>

            </swiper>

        </div>
        <!-- Add Arrows -->
        <template v-if="data.data.length > 8">
            <div class="swiper-button-next-playlist slider_nav_next"></div>
            <div class="swiper-button-prev-playlist slider_nav_prev d-none"></div>
        </template>

    </div>
</template>
