import Cookies from 'js-cookie'

export default {
    authRequest: ({commit, dispatch}, payload) => {
        let actionUrl = '/api/mmn/login';
        let remember = payload.remember ? payload.remember : false;
        let data = {
            'email':payload.email,
            'password':payload.password
        }

        if(payload.action === 'register'){
            actionUrl='/api/mmn/register';
            data = {
                'name':payload.name,
                'email':payload.email,
                'password':payload.password,
                'password_confirmation':payload.password_confirmation
            }
        }
        if(payload.action === 'password-reset'){
            actionUrl='/api/mmn/password/reset';
            data = {
                'token':payload.token,
                'email':payload.email,
                'password':payload.password,
                'password_confirmation':payload.password_confirmation
            }
        }
        if(payload.action === 'facebook-login'){
            actionUrl='/api/v2/auth/facebook';
            data = {
                'email': payload.email,
                'name': payload.name,
                'social_id': payload.social_id,
            }
        }
        if(payload.action === 'google-login'){
            actionUrl='/api/v2/auth/google';
            data = {
                'token': payload.token,
            }
        }

        if(payload.action === 'mpt-login'){
            actionUrl='/api/v2/auth/phone/verify-otp';
            data = {
                'phone': payload.phone,
                'otp' : payload.otp
            }
        }
        return new Promise((resolve, reject) => {
            commit('authRequest');
            axios.post(actionUrl, data)
                .then((resp) => {
                    let access_token = 'Bearer ' + resp.data.access_token;
                    if(payload.action === 'facebook-login') {
                        access_token = 'Bearer ' + resp.data.token;
                    }
                    else if(payload.action === 'google-login') {
                        access_token = 'Bearer ' + resp.data.token;
                    }
                    else if(payload.action === 'mpt-login') {
                        access_token = 'Bearer ' + resp.data.token;
                    }
                    Cookies.set('access_token', access_token, { expires: remember ? 365 : 1 });
                    axios.defaults.headers.common['Authorization'] = access_token;
                    commit('authSuccess', access_token);
                    dispatch('userRequest');
                    dispatch('searchHistory');
                    if (payload.action === 'register' || payload.action === 'login') {
                        commit('REMOVE_TRACKS');
                    }
                    resolve(access_token);
                })
                .catch((error) => {
                    commit('authError', error.response.data);
                    Cookies.remove('access_token');
                    reject(error);
                })
        })
    },

    authLogout: ({commit, dispatch}) => {
        Cookies.remove('access_token');

        commit('REMOVE_TRACKS');

        return new Promise((resolve, reject) => {
            axios.post('/api/mmn/logout')
                .then((resp) => {
                    commit('authLogout');
                    resolve();
                })
                .catch((err) => {
                    commit('authError', err.response.data);
                    reject(err);
                });
        })
    }
};
