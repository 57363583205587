<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'edit-renewal',

        metaInfo() {
            return {
                title: 'Edit Auto Subscription And History',
            }
        },

        components: {
            Layout,
            MainHeader,
        },

        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                isAuthenticated: 'isAuthenticated',
                user : 'getProfile',
            }),
        },

        /**
         * Created the component.
         */
        created() {
            $('#subscribeModal').modal('hide');
            if (this.isAuthenticated) {
                this.getHistories();
            }
        },

        data: () => ({
            auto_renewal: '',
            showModal: false,
            histories: [],
        }),

        methods: {
            cancelMPTSubscription() {
                axios.post('/api/v2/payment/mpt/unsubscribe', { auto_renewal : this.auto_renewal }).then((response) => {
                    this.$toast.success({ title: 'Success', message: response.data.message });
                    this.closeModal();
                    location.reload();
                }).catch((error) => {
                    this.$toast.warn({ title: 'Warning !!', message: error.response.data.message });
                    this.closeModal();
                });
            },

            getHistories() {
                axios.get('/api/mmn/subscribe-history').then((response) => {
                    this.histories = response.data.data;
                }).catch((error) => {
                    this.$toast.warn({ title: 'Warning !!', message: error.response.data.message });
                });
            },

            /**
             * Close Modal
             */
            closeModal() {
                this.$nextTick(() => {
                    $('#renew').modal('hide');
                });
            },
        }

    }
</script>

<style scoped>
.auto-subscription {
    color: #fff;
}
.active {
    background: #dc3545;
}
.ms_profile_list ul li {
    margin-bottom: 0;
    color: #888;
    font-size: 15px;
}
.sub-left {
    width: 80%;
    float: left;
}
.new-sub {
    margin-bottom: 5px;
}
.sub-right {
    width: 20%;
    float: left;
}
.sub-price {
    color: #ff2150;
    margin-top: 10px;
    font-size: 16px;
}
@media (max-width: 425px) {
    .ms_profile_list ul li {
        font-size: 12px;
    }
    .sub-price {
        margin-top: 13px;
        font-size: 14px;
    }
}
</style>

<template>
    <layout v-if="isAuthenticated">

        <div class="padder_top80 ms_profile">

            <main-header/>

            <!----Edit Subscription Wrapper Start---->
            <div class="ms_profile_wrapper">
                <h1>Subscription Status</h1>
                <div class="ms_profile_box">

                    <div class="ms_profile_list">
                        <p v-if="user.has_mpt_subscription">Your Subscription will end on {{ user.end_date }}.</p>
                        <p v-else>Your subscription is ended.</p>

                        <div v-if="user.has_mpt_subscription" class="d-none d-sm-block">
                            <div class="btn-group auto-subscription" role="group" aria-label="Subscription Update Buttons">
                                <a data-toggle="modal" data-target="#renew" class="btn btn-outline-danger">Cancel Subscription</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!----Subscription History Wrapper Start---->
            <div class="ms_profile_wrapper">
                <h1>Subscription History</h1>
                <div class="ms_profile_box">

                    <div class="ms_profile_list">

                        <ul class="list-group" v-if="histories.length > 0">
                            <li class="list-group-item" v-for="history in histories">
                                <div class="sub-left">
                                    <div class="new-sub">Subscription သက်တမ်းတိုးခြင်း </div>
                                    <div class="sub-date">Date - {{ history.purchased_date }}</div>
                                </div>
                                <div class="sub-right">
                                    <div class="text-center sub-price">-{{ history.price }}Ks</div>
                                </div>
                            </li>
                        </ul>

                        <p v-else>You have no subscription history.</p>

                    </div>

                </div>
            </div>

            <!-- Modal -->
            <div class="ms_save_modal">
                <div id="renew" class="modal  centered-modal" role="dialog">
                    <div class="modal-dialog">
                        <!-- Modal content-->
                        <div class="modal-content">
                            <div class="modal-content">
                                <button type="button" class="close" data-dismiss="modal">
                                    <i class="fa_icon form_close"></i>
                                </button>
                                <div class="modal-body">
                                    <h1 v-if="auto_renewal">Are you sure you want to subscribe ?</h1>
                                    <h1 v-else>Are you sure you want to cancel subscription ?</h1>
                                    <br>

                                    <h2 v-if="auto_renewal"><i class="fa fa-check-circle" aria-hidden="true"></i> You can cancel anytime.</h2>
                                    <h2 v-else>
                                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                                        <span v-if="user.subscribe">Your Subscription will not be end until {{ user.end_date }}.</span>
                                        <span v-else>Your subscription is ended.</span>
                                    </h2>

                                    <div class="clr_modal_btn">
                                        <a href="#"
                                           @click.prevent="cancelMPTSubscription()">

                                            <span v-if="auto_renewal">Subscribe</span>
                                            <span v-else>Confirm</span>

                                        </a>
                                        <a href="#" data-dismiss="modal"> Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </layout>
</template>
