<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'video-by-category',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Videos',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            videos: [],
            category: {
                name : null,
            },
            url: '',
            loading: true,
            links: {
                first: null,
                last: null,
                next: null,
                prev: null
            },
            meta: {
                current_page: 1,
                from: 1,
                last_page: 1,
                per_page: 1,
                to: 1,
                total: 1,
            },
        }),


        /**
         * Created the component.
         */
        created() {
            this.links.next = `/api/mmn/video-by-category/${this.$route.params.id}`;
            this.fetchVideoCategory();
            this.fetchVideos();
        },


        methods: {
            /**
             *  Get videos.
             */
            async fetchVideos() {
                if (this.loading === true && this.links.first) {
                    this.$toast.warn({ title: 'Warning', message: 'Loading ...' });
                    return;
                }

                if (this.links.next === null) {
                    return;
                }

                axios.get(this.links.next).then((response) => {
                    this.videos.push(...response.data.data);
                    this.links = response.data.links;
                    this.meta = response.data.meta;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },


            /**
             *  Get category.
             */
            async fetchVideoCategory() {
                axios.get(`/api/mmn/video-category/${this.$route.params.id}`).then((response) => {
                    this.category = response.data;
                }).catch((error) => {
                });
            },
        }
    }
</script>

<template>
    <layout>

        <!---Main Content Start--->
        <div class="ms_content_wrapper ms_profile padder_top50">

            <main-header/>

            <div class="ms_fea_album_slider padder_bottom30" id="videos">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="ms_heading">
                            <h1>{{ category.name }}</h1>
                        </div>
                    </div>

                    <div v-for="video in videos" class="cs-lg-col-5 col-md-2 col-6">
                        <div class="ms_rcnt_box marger_bottom30">
                            <div class="ms_rcnt_box_img">
                                <img :src="video.image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/album/album.jpg';">
                                <div class="ms_main_overlay">
                                    <div class="ms_box_overlay"></div>
                                    <div class="ms_play_icon">
                                        <router-link :to="{ name : 'Video', params : { id : video.id } }">
                                            <img src="/images/svg/play2.svg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="ovrly_text_div">
                                <span class="ovrly_text1">
                                    <router-link :to="{ name : 'Video', params : { id : video.id } }">{{ video.name_mm }}</router-link>
                                </span>
                                        <span class="ovrly_text2"><router-link :to="{ name : 'Video', params : { id : video.id } }">view video</router-link></span>
                                    </div>
                                </div>
                            </div>
                            <div class="ms_rcnt_box_text">
                                <h3><router-link :to="{ name : 'Video', params : { id : video.id } }">{{ video.name_mm }}</router-link></h3>
                                <p>
                                    <template v-if="video.artists.length > 3">
                                        <a href="javascript:;">Various Artists</a>
                                    </template>

                                    <template v-else>
                                        <template v-for="(artist, index1) in video.artists">
                                            <router-link :to="{ name : 'artist', params:{ id : artist.uuid }}">{{ artist.name_eng }} <span v-if="isNotLast(video.artists, index1)">, </span></router-link>
                                        </template>
                                    </template>
                                </p>
                                <p>
                                    <span>
                                        <template v-if="video.views >= 1000 && video.views < 1000000">
                                            <span v-if="video.views % 1000 > 100">{{(video.views/1000).toFixed(1)}}</span>
                                            <span v-else>{{(video.views/1000).toFixed(0)}}</span>K
                                        </template>
                                        <template v-else-if="video.views >= 1000000">
                                            <span v-if="video.views % 1000000 > 100000">{{(video.views/1000000).toFixed(1)}}</span>
                                            <span v-else>{{(video.views/1000000).toFixed(0)}}</span>M</template>
                                        <template v-else>
                                            {{video.views}}
                                        </template>
                                        view<template v-if="video.views > 0">s</template>
                                    </span>
                                    <span class="mt-2 ml-1" style="font-size: 26px;">.</span>
                                    {{video.created_at}}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="more_loading" v-show="loading">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>

                <div class="ms_view_more padder_bottom20" v-show="meta.current_page !== meta.last_page">
                    <a href="#" @click.prevent="fetchVideos()" class="ms_btn">view more</a>
                </div>

            </div>
        </div>


    </layout>
</template>
