export default {
    userRequest: ({commit, dispatch}) => {
        commit('USER_REQUEST');
        axios.get('/api/mmn/user')
            .then((resp) => {
                commit('USER_SUCCESS', resp.data.data);
                dispatch('deviceTokenRequest')
            })
            .catch((err) => {
                commit('USER_ERROR');
                // if resp is unauthorized, logout, to
                dispatch('authLogout')
            })
    },
    deviceTokenRequest: ({commit, dispatch}) => {
        let device_token=Cookies.get('fb_device_token');
        axios.post(`/api/v2/device-token/${device_token}`,{'device':'web'}).then((response) => {
                console.log(response.data);
            }).catch((error) => {

            })
    },
    setRemainedAmount: ({commit, dispatch}, remained_amount) => {
        commit('USER_REMAINED_AMOUNT', remained_amount);
    },
    setQuality: ({commit, dispatch}, streaming_quality) => {
        commit('USER_STREAMING_QUALITY', streaming_quality);
    },
    setSubscribe: ({commit, dispatch}, data) => {
        commit('USER_SUBSCRIBE', true);
        commit('USER_REMAINED_AMOUNT', data.remained_amount);
    },
    setAlbumUUid: ({commit, dispatch}, uuid) => {
        commit('USER_ALBUM_UUID', uuid);
    },
    setSongId: ({commit, dispatch}, song_id) => {
        commit('USER_SONG_ID', song_id);
    },

    searchHistory: ({commit,dispatch}) => {
        axios.get('/api/v2/recent-search').then((response) => {
                    commit('SEARCH_HISTORY',response.data.data);
                }).catch((error) => {
                });
    }
}
