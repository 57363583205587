import Vue from 'vue'
import VeeValidate from 'vee-validate'
import { Validator } from 'vee-validate'

import * as custom from './custom';
import { dictionary } from './messages';


Validator.localize(dictionary);

Validator.extend('phone', custom.phone);
Validator.extend('number', custom.number);
Validator.extend('letter',custom.letter);


/**
 * Injecting Parent Validator
 * Need to add inject code at the component.
 * inject: [ '$validator' ],
 *
 * https://baianat.github.io/vee-validate/concepts/injections.html
 * https://vuejs.org/v2/api/#provide-inject
 */
Vue.use(VeeValidate, { inject: false });

Vue.prototype.$setErrorsFromResponse = function(errorResponse) {

    // only allow this function to be run if the validator exists
    if(!this.hasOwnProperty('$validator')) {
        return;
    }

    // clear errors
    this.$validator.errors.clear();

    // check if errors exist
    if(!errorResponse.hasOwnProperty('errors')) {
        return;
    }

    let errorFields = Object.keys(errorResponse.errors);

    // insert laravel errors
    errorFields.map(field => {
        let errorString = errorResponse.errors[field].join(', ');
        this.$validator.errors.add({ field: field, msg : errorString});
    });
};
