<script type="text/ecmascript-6">
import { mapGetters } from 'vuex';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import Layout from '../layouts/Index.vue'
import MainHeader from '../layouts/Header.vue'
import Albums from '../pages/Artist/Album.vue'
import DescriptionModal from '../components/Modals/DescriptionModal.vue';
import DeepView from '../components/Component/DeepView.vue'

export default {
    name: 'artist',

    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: this.artist.name_eng,

            meta: [

                // OpenGraph data (Most widely used)
                { property: 'og:title', content: `${this.artist.name_eng} - Melo` },

                // Should the the same as your canonical link, see below.
                { property: 'og:url', content: this.artist.image },
                { property: 'og:image', content: this.artist.image },

                // Twitter card
                { name: 'twitter:title', content: `${this.artist.name_eng} Melo` },
                // Your twitter handle, if you have one.
                { name: 'twitter:image:src', content: this.artist.image },

                // Google / Schema.org markup:
                { itemprop: 'name', content: 'Feeling - Melo' },
                { itemprop: 'image', content: this.artist.image }
            ],
        }
    },

    components: {
        Layout,
        MainHeader,
        Albums,
        DescriptionModal,
        swiper,
        swiperSlide,
        DeepView
    },


    /**
     * The component's data.
     */
    data: () => ({
        loading: true,
        songLoading: false,
        artist: [],
        songs: [],
        albums: [],
        related_albums: [],
        related_playlists: [],
        albumLoading: false,
        endPoint: 8,
        relatedLoading: false,
        relatedEndPoint: 8,
        songEndPoint: 10,
        artist_id: null,
        links: {
            first: null,
            last: null,
            next: null,
            prev: null
        },
        meta: {
            current_page: 1,
            from: 1,
            last_page: 1,
            per_page: 1,
            to: 1,
            total: 1,
        },
        swiperOption: {
            slidesPerView: 8,
            slidesPerColumn: 1,
            spaceBetween: 15,
            slidesPerGroup: 4,
            // loop: true,
            speed: 500,
            navigation: {
                nextEl: '.swiper-button-next-playlist',
                prevEl: '.swiper-button-prev-playlist',
            },
            breakpoints: {
                2900: {
                    slidesPerView: 8,
                },
                1900: {
                    slidesPerView: 8,
                },
                1800: {
                    slidesPerView: 8,
                },
                1400: {
                    slidesPerView: 8,
                },
                1124: {
                    slidesPerView: 6,
                },
                1024: {
                    slidesPerView: 6,
                },
                992: {
                    slidesPerView: 6,
                },
                834: {
                    slidesPerView: 5,
                },
                768: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    slidesPerGroup: 1,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    slidesPerGroup: 1,
                }
            },
        },
    }),


    /**
     * Computed these properties.
     */
    computed: {
        ...mapGetters({
            isPlay: 'isPlay',
            playerCurrentTrack: 'playerCurrentTrack',
            isAuthenticated: 'isAuthenticated'
        }),
        swiper() {
            return this.$refs.mySwiper.swiper
        },
        artist_img() {
            return this.artist.image;
        },
        isBeginning() {
            return this.swiper.isBeginning;
        },
        isEnd() {
            return this.swiper.isEnd;
        },

    },

    mounted() {
        let _this = this;
        this.$nextTick(() => {
            _this.swiper.on('slideChange', function () {
                if (!_this.isBeginning) {
                    $('.swiper-button-prev-playlist').removeClass('d-none');
                } else {
                    $('.swiper-button-prev-playlist').addClass('d-none');
                }
                if (!_this.isEnd) {
                    $('.swiper-button-next-playlist').removeClass('d-none');
                } else {
                    $('.swiper-button-next-playlist').addClass('d-none');
                }
            });
        });
    },
    /**
     * Created the component.
     */
    created() {
        this.fetchArtist();
    },


    methods: {
        /**
         *  Get the artist.
         */
        async fetchArtist() {
            this.loading = true;
            axios.get(`/api/mmn/artist/${this.$route.params.id}`).then((response) => {
                this.artist = response.data.artist;
                this.links.next = "/api/v2/song/" + this.artist.id + "/by-artist";
                this.fetchSongByArtist();
                this.albums = response.data.albums;
                this.related_albums = response.data.related_albums;
                this.related_playlists = response.data.related_playlists;
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
            });
        },

        async fetchSongByArtist() {
            this.songLoading = true;
            if (this.loading === true && this.links.first) {
                this.$toast.warn({ title: 'Warning', message: 'Loading ...' });
                return;
            }

            if (this.links.next === null) {
                return;
            }

            // this.loading = true;

            axios.get(this.links.next).then((response) => {
                this.songs.push(...response.data.data);
                this.links = response.data.meta.pagination.links;
                this.meta = response.data.meta.pagination;
                this.songLoading = false;
                console.log(this.meta);
                // this.loading = false;
            }).catch((error) => {
                // this.loading = false;
                this.songLoading = false;
            });
        },

        /**
         *  Follow the artist.
         */
        followArtist() {
            axios.post(`/api/v2/follow-artist`, { artist_id: this.artist.id }).then((response) => {
                this.artist.is_follow = response.data.followed;
                this.artist.follower_counts = response.data.artist.follower_counts;
            }).catch((error) => {

            })
        },

        /**
         *  Like the album.
         */
        likeAlbum(id) {
            axios.post(`/api/mmn/like/album/${id}`).then((response) => {
                this.album.is_like = response.data.is_like;
            }).catch((error) => {

            })
        },

        /**
         *  Favourite the album.
         */
        favouriteAlbum(id) {
            axios.post(`/api/mmn/favourite-sync/album/${id}`).then((response) => {
                this.album.is_favourite = response.data.is_favourite;
            }).catch((error) => {

            })
        },

        /**
         *  Favourite the song.
         */
        favouriteSong(song, song_id) {
            axios.post(`/api/mmn/favourite/song/${song_id}`).then((response) => {
                song.is_favourite = response.data.is_favourite;
            }).catch((error) => {

            })
        },

        showPlaylistModal() {
            $('#playlistsModal').modal('show');
        },
        viewMore() {
            this.albumLoading = true;
            this.endPoint = this.endPoint + 24;
            this.albumLoading = false;
        },
        viewMoreRelated() {
            this.relatedLoading = true;
            this.relatedEndPoint = this.relatedEndPoint + 24;
            this.relatedLoading = false;
        },
        viewMoreSong() {
            this.songLoading = true;
            this.songEndPoint = this.songEndPoint + 10;
            this.songLoading = false;
        },
        bgImage(image) {
            return 'background-image: url("' + image + '")';
        },

    },
    filters: {
        // trunc(str, len = 150) {
        //   if(str.length>150)
        //   {
        //       _.trim(str);
        //   return _.truncate(str, {'length': len});
        //   }
        //   return str;
        // },
    },
}
</script>

<template>
    <layout>
        <deep-view :url="getDeepLink('artists', artist.id, artist.uuid)" :songs="songs"></deep-view>
        <description-modal :data="artist.description"></description-modal>
        <!----Album Single Section Start---->
        <div class="ms_album_single_wrapper ms_profile artist">

            <main-header />

            <div class="album-cover-wrapper " :style="bgImage(artist.image)"
                style="filter: blur(120px);height:230px;position:absolute;width: 100%;background-size: cover; opacity: 0.4;background-position: center; "
                v-show="!loading"></div>

            <div class="album-cover-wrapper" v-show="!loading">
                <div class="album_single_data">
                    <div class="album_single_img">
                        <div class="ms_rcnt_box">
                            <div class="ms_rcnt_box_img" style="border-radius:300px;">
                                <img :src="artist.image" alt="" class="img-fluid"
                                    onError="this.onerror=null;this.src='/images/album/Artist.png';"
                                    style="border-radius: 300px;">
                            </div>
                        </div>
                    </div>
                    <div class="album_single_text">
                        <div class="title_container">
                            <h2>{{ artist.name_eng }} <span v-if="artist.is_verified"> <svg
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-6 h-6" width="20" height="20">
                                    <path stroke-linecap="round" stroke-linejoin="round" fill="#FF2150"
                                        d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg></span></h2>
                            
                        </div>

                        <p v-show="artist.follower_counts > 0"
                            style="font-size:11px;margin-bottom: 0.5rem;color: #fff;"><span class="fa icon_follower"
                                style="font-size:18px;margin-right: 10px;"></span>{{ artist.follower_counts }}
                            Follower<span v-if="artist.follower_counts > 1">s</span></p>
                        <p v-show="meta.total > 0" style="font-size:11px;margin-bottom: 0.5rem;color: #cbcbcb;"><span
                                class="fa fa fa-music" style="font-size:18px;margin-right: 10px;"></span>{{ meta.total
                            }} Song<span v-if="meta.total > 1">s</span></p>
                        <!--<p v-else>No followers yet!</p>-->
                        <div class="d-flex">
                            <span v-if="artist.description" class="fa fa icon_about"
                                style="font-size:18px;margin-right: 5px;color: #fff;"></span>
                            <div class="description" v-html="artist.description"></div>
                            <a v-if="artist.description.length > 150 && artist.description" href="#description-artist"
                                data-toggle="modal" data-target="#descriptionModal" class="read-more">Read more <i
                                    class="fa fa-chevron-right"></i></a>
                        </div>


                        <!--<a @click.prevent="playAllArtistSong(artist.id)" href="javascript:;" class="custom_btn_style"><span class="fa fa fa-play"></span><span style="padding: 5px;">Play</span></a>-->
                        <div class="album_feature">
                            <template
                                v-if="playerCurrentTrack != null && songs.findIndex(x => x.id === playerCurrentTrack.id) >= 0 && isPlay">
                                <a @click.prevent="$store.dispatch(isPlay ? 'pause' : 'play')" href="javascript:;"
                                    class="custom_play_btn"><span class="fa icon_pause_btn"></span><span
                                        style="padding: 5px;">Pause</span></a>
                            </template>
                            <template v-else>
                                <a @click.prevent="handleClickTrack(songs[0], songs)" href="javascript:;"
                                    class="custom_play_btn"><span class="fa fa fa-play"></span><span
                                        style="padding: 5px;">Play</span></a>
                            </template>

                            <div class="btn-group dropright" style="padding-top: 4px;">
                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    class="custom_btn_style ml-2" style="background: transparent;">
                                    <span :class="'fa icon_sharer'"></span> Share
                                </button>
                                <div class="dropdown-menu album_more_detail"
                                    style="background: #28282E;border-radius: 0px;">
                                    <a class="dropdown-item" style="color: #fff;font-size:12px;"
                                        :href="facebookLinkShare(artist.share_url)" target="_blank"><span
                                            class="opt_icon" style="margin-right:10px;"><span
                                                class="fa fa-facebook-official"></span></span>Share To Facebook</a>
                                    <a class="dropdown-item" style="color: #fff;font-size:12px;"
                                        :href="twitterLinkShare(artist.share_url)" target="_blank"><span
                                            class="opt_icon" style="margin-right:10px;"><span
                                                class="fa fa-twitter-square"></span></span>Share To Twitter</a>
                                    <a class="dropdown-item" style="color: #fff;font-size:12px;" href="#"
                                        @click.prevent="copyText('artist/' + artist.uuid)"><span class="opt_icon"
                                            style="margin-right:10px;"><span class="fa fa-copy"></span></span>Copy
                                        link</a>
                                </div>
                            </div>
                            <a v-if="isAuthenticated" @click.prevent="followArtist()" href="#" id="artist-follow"
                                class="custom_btn_style ml-2" style="min-width:120px;"><span
                                    class="fa fa icon_follower"></span><span style="padding: 5px;">{{ artist.is_follow ?
            'Following' : 'Follow' }}</span></a>
                            <a v-else href="#" data-toggle="modal" data-target="#loginModal"
                                class="custom_btn_style ml-2" style="min-width:120px;"><span
                                    class="fa icon_follower"></span><span style="padding: 5px;">Follow</span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>


            <!--            song lists -->
            <div class="album-track-wrapper album_inner_list" v-show="!loading">
                <div data-v-e84545ce=""
                    style="background: #1e1e25;position: absolute;top: 0;width: 100%;height: 100%;z-index: -1;left: 0;">
                </div>
                <div class="album_list_wrapper album_track_content">
                    <h5 style="padding:20px;" v-if="!loading">Tracks</h5>
                    <!-- <template v-if="!songLoading"> -->
                    <ul class="track_ul" v-for="(song, key) in songs"
                        :class="isPlay && playerCurrentTrack.id === song.id ? 'play_active_song' : ''">
                        <li style="padding-top: 10px;top:-4px;">
                            <a href="javascript:" class="mmn-play" @click="handleClickTrack(song, songs)">
                                <span class="play_no" style="position: absolute;top: 0;">{{ $_.padStart(key + 1, 0)
                                    }}</span>
                                <div class="ms_bars for_track_ul" v-if="isPlay && playerCurrentTrack.id === song.id">
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                </div>
                                <span class="play_hover" v-else></span>
                            </a>
                        </li>

                        <li><a href="javascript:" class="mmn-play" @click="handleClickTrack(song, songs)"><span
                                    class="song_title">{{ song.name_mm }}</span>
                                <p style="font-size:10px;margin-bottom: 5px;">
                                    <a v-if="song.artists.length > 3" href="javascript:">
                                        <span>{{ song.artists[0].name_en }}, {{ song.artists[1].name_en }}, ...</span>
                                    </a>
                                    <template v-for="(artist, artist_index) in song.artists" v-else>
                                        <router-link :to="{ name: 'artist', params: { id: artist.uuid } }">{{
            artist.name_en }}
                                            <span v-if="isNotLast(song.artists, artist_index)">, </span>
                                        </router-link>
                                    </template>
                                    <template v-if="song.feats.length">
                                        <a href="javascript:">, ft : </a>
                                        <template v-for="(feat, feat_index) in song.feats">
                                            <router-link :to="{ name: 'artist', params: { id: feat.uuid } }">{{
            feat.name_en }}
                                                <span v-if="isNotLast(song.feats, feat_index)">, </span>
                                            </router-link>
                                        </template>
                                    </template>
                                </p>
                            </a></li>

                        <li style="top: -10px;"><a href="javascript:">{{ song.duration }}</a></li>

                        <template v-if="isAuthenticated">
                            <li class="text-center" style="top: -10px;"><a href="#"
                                    @click.prevent="favouriteSong(song, song.id)"><span
                                        :class="song.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span> </a></li>

                            <li v-if="song.is_purchased" class="text-center" style="top: -11px;">
                                <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;"
                                    v-show="download_songs.includes(song.id)"></i>
                                <a href="#" @click.prevent="downloadSong(song.id)" class="song_download"
                                    v-show="!download_songs.includes(song.id)"
                                    style="margin-top:0px;margin-bottom: 10px; ">
                                    <span class="ms_close"><img src="/images/svg/download_1.svg" alt=""
                                            style="width:15px;"></span>
                                </a>
                            </li>

                            <li v-else class="text-center" style="top: -11px;">
                                <template v-if="song.prices.name > 0">
                                    <a href="javascript:" @click="buySong(song.id)"><i
                                            class="fa icon_buy_dollar"></i></a>
                                </template>

                                <template v-else-if="song.prices.name === '0'">
                                    <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;"
                                        v-show="download_songs.includes(song.id)"></i>
                                    <a href="#" @click.prevent="downloadSong(song.id)" class="song_download"
                                        v-show="!download_songs.includes(song.id)"
                                        style="margin-top:0px;margin-bottom: 10px; ">
                                        <span class="ms_close"><img src="/images/svg/download_1.svg" alt=""
                                                style="width:15px;"></span>
                                    </a>
                                </template>
                            </li>

                            <li class="text-center ms_more_icon" style="top:-11px;"><a href="javascript:"><span
                                        class="fa icon_menu_more"></span></a>
                                <ul class="more_option album_more">
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#"
                                            @click.prevent="saveSong(song.id)"><span class="opt_icon"><span
                                                    class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                    <li style="width: 100% !important ;padding: 10px 10px;"><router-link
                                            :to="{ name: 'Album', params: { uuid: song.album_uuid } }"><span
                                                class="opt_icon"><img src="/images/svg/go_to_album.svg"
                                                    style="width:18px;margin-right:7px;" /></span>Go
                                            To Album </router-link></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;"
                                            @click.prevent="shareSongFacebook(song.share_url)" target="_blank"><span
                                                class="opt_icon"><span
                                                    class="fa fa-facebook-official"></span></span>Share To
                                            Facebook</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;"
                                            @click.prevent="shareSongTwitter(song.share_url)" target="_blank"><span
                                                class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share
                                            To Twitter</a>
                                    </li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#"
                                            @click.prevent="copyText('album/' + song.album_uuid)"><span
                                                class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a>
                                    </li>
                                </ul>
                            </li>

                            <!-- <li></li> -->
                        </template>

                        <template v-else>
                            <li class="text-center" style="top: -10px;"><a href="#" data-toggle="modal"
                                    data-target="#loginModal"><span class="fa fa-heart"></span> </a></li>

                            <li class="text-center" style="top: -11px;">
                                <a href="#" v-if="song.prices.name > 0" data-toggle="modal" data-target="#loginModal"><i
                                        class="fa icon_buy_dollar"></i></a>
                                <a href="#" v-else-if="song.prices.name === '0'" data-toggle="modal"
                                    data-target="#loginModal" class="song_download"
                                    style="margin-top:0px;margin-bottom: 10px; ">
                                    <span class="ms_close"><img src="/images/svg/download_1.svg" alt=""
                                            style="width:15px;"></span>
                                </a>
                            </li>

                            <li class="text-center ms_more_icon" style="top: -11px;">
                                <a href="javascript:"><span class="fa icon_menu_more"></span></a>
                                <ul class="more_option album_more">
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#"
                                            data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span
                                                    class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                    <li style="width: 100% !important ;padding: 10px 10px;"><router-link
                                            :to="{ name: 'Album', params: { uuid: song.album_uuid } }"><span
                                                class="opt_icon"><img src="/images/svg/go_to_album.svg"
                                                    style="width:18px;margin-right:7px;" /></span>Go To Album
                                        </router-link></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;"
                                            @click.prevent="shareSongFacebook(song.share_url)" target="_blank"><span
                                                class="opt_icon"><span
                                                    class="fa fa-facebook-official"></span></span>Share To Facebook</a>
                                    </li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;"
                                            @click.prevent="shareSongTwitter(song.share_url)" target="_blank"><span
                                                class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share
                                            To Twitter</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#"
                                            @click.prevent="copyText(song.share_url)"><span class="opt_icon"><span
                                                    class="fa fa-copy"></span></span>Copy link</a></li>
                                </ul>
                            </li>

                            <!-- <li></li> -->
                        </template>

                    </ul>
                    <!-- </template>
                    <template v-else> -->

                    <!-- </template> -->
                    <template v-if="!songLoading">
                        <div class="ms_view_more padder_bottom30" style="margin-top:40px;"
                            v-show="meta.current_page !== meta.total_pages">
                            <a href="#" @click.prevent="fetchSongByArtist()" class="ms_btn">view more</a>
                        </div>
                    </template>
                    <template v-else>
                        <div class="padder_bottom30" style="margin-top:40px;">
                            <div class="more_loading">
                                <div class="bar"></div>
                                <div class="bar"></div>
                                <div class="bar"></div>
                                <div class="bar"></div>
                                <div class="bar"></div>
                                <div class="bar"></div>
                                <div class="bar"></div>
                                <div class="bar"></div>
                                <div class="bar"></div>
                                <div class="bar"></div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <div v-if="loading" style="height:100vh;align-items: center;display: flex;margin-top: -10%">
                <div class="more_loading mt-5">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>
            </div>
            <!--End Relate Albums -->

            <!--  <div id="description-artist" class="col-md-12" v-show="!loading && artist.description && artist.description.length > 80">
                <h2 style="margin-top: 20px; text-align: center; font-size: 22px;color: #fff;padding-bottom: 10px;">About {{ artist.name_eng }}</h2>
                <p class="text-white" style="white-space: pre-line;" v-html="artist.description"></p>
            </div> -->
        </div>
        <div class="ms_content_wrapper mt-5">
            <!-- Albums -->
            <Albums :albums="related_albums"></Albums>
        </div>

        <div class="ms_content_wrapper" v-if="related_playlists.length > 0">
            <div class="ms_radio_wrapper" style="position:relative;">
                <div class="ms_heading">
                    <h1>Related Playlists</h1>
                    <span class="veiw_all" v-if="related_playlists.length > 8">
                        <router-link :to="{ name: 'artistPlaylists', params: { artist_id: artist.uuid } }">view
                            more</router-link>
                    </span>
                </div>
                <div class="ms_radio_slider swiper-container recommended_playlist_swiper">
                    <swiper :options="swiperOption" ref="mySwiper">
                        <!-- slides -->
                        <template v-for="item in related_playlists">
                            <swiper-slide>
                                <div class="ms_rcnt_box">
                                    <div class="ms_rcnt_box_img">
                                        <img :src="item.image" alt=""
                                            onError="this.onerror=null;this.src='/images/album/collection_playlist.png';">
                                        <div class="ms_main_overlay">
                                            <router-link tag="a"
                                                :to="{ name: 'playlist-detail', params: { id: item.uuid } }"
                                                :title="item.name_mm">
                                                <div class="ms_box_overlay"></div>
                                            </router-link>
                                            <div class="ms_play_icon">
                                                <a href="javascript:;" @click.prevent="playPlaylistSong(item.uuid)">
                                                    <img src="/images/svg/play2.svg" alt="">
                                                </a>
                                            </div>
                                            <div class="ms_more_icon" data-toggle="popup">
                                                <img src="/images/svg/more.svg" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <ul class="more_option">
                                        <li><a :href="shareFacebookLink(item.image, 'playlists', item.name_mm, item.id, item.uuid)"
                                                target="_blank"><span class="opt_icon"><span
                                                        class="fa fa-facebook-official"></span></span>Share to
                                                facebook</a></li>
                                        <li><a :href="shareTwitterLink(item.image, 'playlists', item.name_mm, item.id, item.uuid)"
                                                target="_blank"><span class="opt_icon"><span
                                                        class="fa fa-twitter-square"></span></span>Share to twitter</a>
                                        </li>
                                        <li><a href="#" @click.prevent="copyText('playlist/'+item.uuid)"><span
                                                    class="opt_icon"><span class="fa fa-copy"></span></span>Copy
                                                link</a></li>
                                    </ul>

                                    <div class="ms_rcnt_box_text">
                                        <h3><router-link tag="a"
                                                :to="{ name: 'playlist-detail', params: { id: item.uuid }}">{{
                                                item.name_mm }}</router-link></h3>
                                    </div>
                                </div>
                            </swiper-slide>
                        </template>
                    </swiper>
                </div>
                <!-- Add Arrows -->
                <template v-if="related_playlists.length > 8">
                    <div class="swiper-button-next-playlist slider_nav_next"></div>
                    <div class="swiper-button-prev-playlist slider_nav_prev d-none"></div>
                </template>
            </div>
        </div>


    </layout>
</template>
<style>
.description p {
    margin-bottom: 0 !important;
}

.title_container {
    display: flex;
}

.badge {
    background-color: transparent;
    color: #ff2150;
    border: 1px solid #fff;
    border-radius: 10px;
}
</style>