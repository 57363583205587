<script type="text/ecmascript-6">

    export default {

        methods: {
            /** Delete Song from Playlist
             *
             */
            deletePlaylit()
            {
                axios.delete('/api/mmn/user/delete/my-play-list/'+this.$route.params.id).then((response)=>{
                    $('#confirmModal').modal('hide');
                    this.$router.push('/my-play-lists');
                }).catch((error)=>{

                });
            },
            /**
             * Close Modal
             */
            closeModal() {
                this.$nextTick(() => {
                    $('#confirmModal').modal('hide');
                });
            }

        }
    }
</script>
<template>
    <div class="ms_save_modal">
        <div id="confirmModal" class="modal centered-modal" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="fa_icon form_close"></i>
                        </button>
                        <div class="modal-body">
                            <h1>Are You sure to Delete this Playlist?</h1>
                            <div class="clr_modal_btn">
                                <a href="#" @click.prevent="deletePlaylit()">Confirm To Delete</a>
                                <a href="#" data-dismiss="modal">Close</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
