<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'favourite',

        components: {
            Layout,
            MainHeader,
        },


        metaInfo() {
            return {
                title: 'Purchased Albums',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            songs_loading: true,
            albums_loading: true,
            songs: [],
            albums: [],
            artists_name:[],
            download_btn_loading : false,
            albums_name:[],
            search:'',
            search_artist:'',
            search_data:[],
        }),


        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                isPlay: 'isPlay',
                playerCurrentTrack: 'playerCurrentTrack',
                isAuthenticated: 'isAuthenticated'
            }),
            filteredList() {
                this.search_data=this.albums;
                if(this.search!='')
                {
                    this.search_data=this.search_data.filter(album => {
                        if(album.name_eng!=null){
                            return album.name_eng.toLowerCase().includes(this.search.toLowerCase())
                        }else{
                            return album.name_mm.toLowerCase().includes(this.search.toLowerCase())
                        }
                      });
                }
                if(this.search_artist!=''){
                    this.search_data=this.search_data.filter(album => {
                        let flag=false;
                        let self=this;
                        $.each(album.artists,function(key,value){
                            if(value.name_eng!=null){
                                flag=value.name_eng.toLowerCase().includes(self.search_artist.toLowerCase());
                            }else{
                                flag=value.name_mm.toLowerCase().includes(self.search_artist.toLowerCase());
                            }
                        })
                        return flag;
                      });
                }
                return this.search_data;
            },
        },


        /**
         * Created the component.
         */
        created() {
            this.fetchAlbums();
        },


        methods: {
            /**
             *  Get the purchased albums.
             */
            async fetchAlbums() {
                axios.get(`/api/mmn/purchased-albums`).then((response) => {
                    this.albums = response.data.data;
                    let album_name=[];
                    let artist_name=[];
                    $.each(this.albums, function(key, album) {
                            album_name.push(album.name_eng);
                            $.each(album.artists,function(index,artist){
                                artist_name.push(artist.name_eng);
                            });
                       });
                    this.albums_name=album_name;
                    this.artists_name=artist_name;
                    this.albums_loading = false;
                }).catch((error) => {
                    this.albums_loading = false;
                });
            },
        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <div class="ms_content_wrapper ms_profile">

            <main-header/>

            <!----Free Download Css Start---->
            <div class="ms_content_wrapper ms_free_download ms_purchase_wrapper padder_top50 padder_bottom30">
                <div class="row justify-content-center d-flex" style="width:100%">
                    <div class="input-group mb-3 col-md-4 col-12">
                        <input type="text" v-model="search_artist" class="form-control" list="artist" placeholder="Artists....">
                          <datalist id="artist">
                            <option v-for="artist in artists_name">{{artist}}</option>
                          </datalist>
                          <div class="input-group-prepend">
                            <button class="btn" type="submit" style="border-radius: 0px 5px 5px 0px;background:#ff2150;">
                                <img src="/images/svg/search.svg" alt="">
                            </button>
                          </div>
                    </div>
                    <div class="input-group mb-3 col-md-4 col-12">
                        <input type="text" v-model="search" class="form-control" list="album" placeholder="Album....">
                          <datalist id="album">
                            <option v-for="album in albums_name">{{album}}</option>
                          </datalist>
                          <div class="input-group-prepend">
                            <button class="btn" type="submit" style="border-radius: 0px 5px 5px 0px;background:#ff2150;">
                                <img src="/images/svg/search.svg" alt="">
                            </button>
                          </div>
                    </div>
                </div>
                <div class="ms_heading">
                    <h1>Your Purchased Albums</h1>
                </div>

                <div class="album_inner_list">
                    <div class="album_list_wrapper purchased_albums" v-show="!albums_loading">
                        <ul class="album_list_name">
                            <li>#</li>
                            <li>Artwork</li>
                            <li>Album Title</li>
                            <li>Artist</li>
                            <li class="text-center">Action</li>
                        </ul>

                        <ul v-if="albums.length" v-for="(album, key) in filteredList">
                            <li><router-link :to="{ name: 'Album', params: { uuid: album.uuid }}">
                                <span class="play_no">{{ $_.padStart(key + 1, 2, 0) }}</span>
                                <span class="play_hover"></span></router-link>
                            </li>
                            <li><router-link :to="{ name: 'Album', params: { uuid: album.uuid }}">
                                <img :src="album.image" alt="" width="100" onError="this.onerror=null;this.src='/images/album/Album.png';"></router-link>
                            </li>
                            <li><router-link :to="{ name: 'Album', params: { uuid: album.uuid }}">{{ album.name_mm }}</router-link></li>
                            <li>
                                <template v-if="album.artists.length > 3">
                                    <a href="javascript:;">Various Artists</a>
                                </template>

                                <template v-else>
                                    <template v-for="(artist, index1) in album.artists">
                                        <router-link :to="{ name: 'artist', params: { id : artist.uuid}}">{{ artist.name_eng }} <span v-if="isNotLast(album.artists, index1)">, </span>
                                        </router-link>
                                    </template>

                                </template>
                            </li>
                            <li class="text-center">
                                <router-link :to="{ name: 'user-purchased-album', params :{ uuid: album.uuid }}" class="ms_btn" style="color:#ffffff !important;">Go to Album</router-link>
                            </li>
                        </ul>

                        <ul v-else>
                            <li class="w-100 text-center"><a href="#">No Albums</a></li>
                        </ul>

                    </div>

                    <div class="more_loading" v-show="albums_loading">
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                    </div>

                </div>

            </div>

        </div>


    </layout>
</template>

<style scoped>
    .ms_free_download.ms_purchase_wrapper .album_list_wrapper>ul>li {
        width: 16%;
    }

    .ms_free_download.ms_purchase_wrapper .purchased_albums>ul>li {
        width: 19%;
    }
</style>
