export default {
  USER_REQUEST: (state) => {
    state.status = 'loading';
  },
  USER_SUCCESS: (state, resp) => {
    state.status = 'success';
    Vue.set(state, 'profile', resp);
  },
  USER_ERROR: (state) => {
    state.status = 'error';
  },
  USER_REMAINED_AMOUNT: (state, remained_amount) => {
    state.profile.remained_amount = remained_amount;
  },
  USER_STREAMING_QUALITY: (state, streaming_quality) => {
    state.profile.streaming_quality = streaming_quality;
  },
  USER_SUBSCRIBE: (state, subscribe) => {
    state.profile.subscribe = subscribe;
  },
  USER_ALBUM_UUID: (state, uuid) => {
    state.album_uuid = uuid;
  },
  USER_SONG_ID: (state, song_id) => {
    state.song_id = song_id;
  },
  
  SEARCH_HISTORY: (state,history) => {
    state.history = history;
  }
}
