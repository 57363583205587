
<script type="text/ecmascript-6">
import Layout from '../../layouts/Index.vue'
import MainHeader from '../../layouts/Header.vue'
import MMPhoneNumber from 'myanmar-phonenumber'

export default {
    name: 'phone-login',

    components: {
        Layout,
        MainHeader,
    },

    metaInfo() {
        return {
            title: 'Login With Phone',
        }
    },

    /**
     * Injecting Parent Validator
     * Don't remove it.
     *
     * https://baianat.github.io/vee-validate/concepts/injections.html
     * https://vuejs.org/v2/api/#provide-inject
     */
    inject: ['$validator'],


    /**
     * The component's data.
     */
    data: () => ({
        action: 'requestOtp',
        loading: false,
        showPhoneInput: true,
        showVerifyOtp: false,
        phone: '',
        otp: '',
        otp1: '',
        otp2: '',
        otp3: '',
        otp4: '',
        otpArr: [],
        mobilePrefixList: [],
        phonePrefix: '',
        operator: '',
        inputsLength: '',
        time: 60,//Initialize with 60s
        timer: null,//Placeholder for the interval
    }),

    computed: {
        authErrors() {
            return this.$store.getters.authErrors;
        },
        formattedTime() {
            // Format the time as mm:ss
            const minutes = Math.floor(this.time / 60);
            const seconds = this.time % 60;
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        },

        isDisabled() {

        }
    },

    watch: {
        phonePrefix: {
            handler(newPrefix, oldPrefix) {
                const operator = this.mobilePrefixList.find(list => list.numbers.find(num => num === newPrefix))

                this.operator = operator
                if(this.operator.type === "mpt") {
                    this.inputsLength = 4
                }else{
                    this.inputsLength = 6
                }
            }
        },
        phone: {
            handler(newPhone, oldPhone) {
                let phone = MMPhoneNumber.normalizeInput(this.phone);
                phone = phone.substring(1);
                let phonePrefix = phone.substring(0, 3);
                const operator = this.mobilePrefixList.find(list => list.numbers.find(num => num === phonePrefix))
                this.operator = operator
            }
        },
        showVerifyOtp: {
            handler(newValue, oldValue) {
                if(newValue) {
                    this.startTimer();
                }
            }
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.getMobilePrefix();
        })
    },


    methods: {
        getMobilePrefix() {
            axios.get('/api/mmn/mobile-prefix-list').then((response) => {
               this.mobilePrefixList = response.data.data
            }).catch((error) => {
                console.log("mobile-prefix-list error", error);
            })
        },

        requestOtp: function () {
            this.$validator.validateAll().then((result) => {
                if (!result) {
                    this.$toast.error({ title: 'Error', message: "Please, check your inputs." });
                    throw new Error("Please, check your inputs.");
                } else {
                    const valid = MMPhoneNumber.isValidMMPhoneNumber(this.phone);
                    if (!valid) {
                        this.$toast.error({ title: 'Error', message: "Invalid MPT Phone Number!" });
                        throw new Error("Please, check your inputs.");
                    } else {
                        let phone = MMPhoneNumber.normalizeInput(this.phone);
                        phone = phone.substring(1);
                        this.phone = '95' + phone;
                        this.loading = true;
                        this.showPhoneInput = false;
                        this.showVerifyOtp = true;
                        this.phonePrefix = phone.substring(0, 3);
                        axios.post('/api/v2/auth/phone/request-otp', { "phone": this.phone}).then((response) => {
                            this.$toast.success({ title: 'OTP sent', message: "OTP Sent! Please check your phone!" });

                        }).catch((error) => {
                            this.phone = "";
                            this.$toast.warn({ title: 'Warning !!', message: "Something went wrong. Please try again." });
                        });

                    }
                }
            }).catch(() => {

            });
        },

        resendOtp: function () {
            let phone = MMPhoneNumber.normalizeInput(this.phone);
                phone = phone.substring(1);
                this.phone = '95' + phone;
                this.loading = true;
                this.showPhoneInput = false;
                this.showVerifyOtp = true;
                this.phonePrefix = phone.substring(0, 3);
                this.time = 60;
                this.startTimer();
                axios.post('/api/v2/auth/phone/request-otp', { "phone": this.phone}).then((response) => {
                    this.$toast.success({ title: 'OTP sent', message: "OTP Sent! Please check your phone!" });

                }).catch((error) => {
                    this.phone = "";
                    this.$toast.warn({ title: 'Warning !!', message: "Something went wrong. Please try again." });
                });
        },

        verifyOtp: function () {
            const action = 'mpt-login';
            let { phone, otp } = this;
             otp = [...this.otpArr].join('');

            this.$store.dispatch('authRequest', { action, phone, otp })
                .then(() => {
                    this.$toast.success({ title: 'Success', message: 'You have been successfully logged in!' });
                    this.$router.push("/home");
                }).catch(() => {
                    this.$toast.error({ title: 'Error', message: "OTP Validation Failed" });
                })
        },

        handleEnter: function (e, i) {
            const otpInputs = this.$refs.otpContainer.getElementsByTagName("*");
            const keyPressed = e.key
            const matched = keyPressed.match(/^[0-9]$/);
            if(i > 0 && (keyPressed === "Backspace" || keyPressed === "Delete")) {
                setTimeout(() => {
                    otpInputs[i-1].focus();
                }, 50)
            }else if(i < this.inputsLength-1 && matched) {
                setTimeout(() => {
                    otpInputs[i+1].focus();
                }, 50)
            }
        },

        startTimer() {
            this.timer = setInterval(() => {
                if (this.time > 0) {
                    this.time--;
                }else {
                    this.stopTimer();
                }
            }, 1000);
        },
        stopTimer() {
            clearInterval(this.timer);
        }
    },
    beforeDestroy() {
        this.stopTimer(); // Cleanup the timer when the component is destroyed
    }
}

</script>

<template>
    <layout>

        <div class="ms_content_wrapper ms_login_wrapper padder_top90">

            <main-header />


            <div class="login_modal">
            <div class="login_modal_card">
                <div class="login_modal_card_wrapper">
                    <div class="login_modal_card_left">
                        <div class="login_modal_card_left_content">
                            <h2>Melo To Your Heart</h2>
                            <p>
                                ဂီတအမျိုးအစားများစွာနဲ့ အနုပညာရှင်တွေရဲ့
                                တေးသီချင်းပေါင်းများစွာက သင့်အနားမှာ
                                အမြဲရှိနေမှာပါ။
                            </p>
                        </div>
                    </div>
                    <div class="login_modal_card_right">
                        <div class="login_modal_card_right_content">
                        <!-- showPhoneInput -->
                            <div v-if="showPhoneInput" class="phone_input_wrapper">
                                <p>Enter Phone Number</p>

                                <form method="POST" @submit.prevent="requestOtp()">
                                    <div class="input_div input_phone">
                                        <input type="text" v-model="phone" v-validate="'required|number'" name="phone"
                                            placeholder="09xxxxxxxxx" :class="hasError('phone')">
                                        </input>

                                        <img v-if="operator.type === 'mpt'" src="/images/mpt.svg" class="mpt_logo"></img>
                                        <img v-else-if="operator.type === 'atom'" src="/images/ATOM.svg" class="mpt_logo"></img>
                                        <img v-else-if="operator.type === 'mytel'" src="/images/mytel.svg" class="mpt_logo"></img>
                                        <img v-else-if="operator.type === 'ooredoo'" src="/images/Ooredoo_logo.svg" class="mpt_logo"></img>
                                    </div>
                                     <span v-show="errors.has('phone')" class="text-left text-white phone_number_err"role="alert">
                                        <strong>{{ errors.first('phone') }}</strong>
                                    </span>

                                    <p class="otp_info">We will send a verification code to this number</p>

                                    <button type="submit" class="btn btn-primary btn-block otp_btn">Continue</button>
                                </form>

                                <div class="continue_with">
                                    <p class="continue_text">Or continue with</p>
                                </div>

                                <div class="input_wrapper">
                                    <a href="#" @click.prevent="logInWithGoogle('/')" class="input_div"><img src="/images/google.svg" class="logo"></img><p>Continue with Google</p></a>
                                    <a href="#" @click.prevent="logInWithFacebook('/')" class="input_div input_fb"><img src="/images/facebook_logo.png" class="logo"></img><p>Continue with Facebook</p></a>
                                </div>
                            </div>

                            <!-- showVerifyOtp -->
                            <div v-if="showVerifyOtp" class="">
                                <h2 class="otp_title">OTP Verification</h2>
                                <p class="otp_info otp_info_upper">Please enter the code that we sent to {{this.phone}}</p>
                                <form method="POST" @submit.prevent="verifyOtp()">
                                    <div v-if="operator.type === 'mpt'" class="form-group otp_input_wrapper" ref="otpContainer">
                                        <input @keyup="(e) => handleEnter(e, 0)" type="text" v-model="otpArr[0]" v-validate="'required|number'" name="otp"
                                            placeholder="" maxlength="1" :class="hasError('phone')">

                                        <input @keyup="(e) => handleEnter(e, 1)" type="text" v-model="otpArr[1]" v-validate="'required|number'" name="otp"
                                            placeholder="" maxlength="1" :class="hasError('phone')">

                                        <input @keyup="(e) => handleEnter(e, 2)" type="text" v-model="otpArr[2]" v-validate="'required|number'" name="otp"
                                            placeholder="" maxlength="1" :class="hasError('phone')">

                                        <input @keyup="(e) => handleEnter(e, 3)" type="text" v-model="otpArr[3]" v-validate="'required|number'" name="otp"
                                            placeholder="" maxlength="1" :class="hasError('phone')">
                                    </div>
                                    <div v-else class="form-group otp_input_wrapper" ref="otpContainer">
                                        <input @keyup="(e) => handleEnter(e, 0)" type="text" v-model="otpArr[0]" v-validate="'required|number'" name="otp"
                                            placeholder="" maxlength="1" :class="hasError('phone')">

                                        <input @keyup="(e) => handleEnter(e, 1)" type="text" v-model="otpArr[1]" v-validate="'required|number'" name="otp"
                                            placeholder="" maxlength="1" :class="hasError('phone')">

                                        <input @keyup="(e) => handleEnter(e, 2)" type="text" v-model="otpArr[2]" v-validate="'required|number'" name="otp"
                                            placeholder="" maxlength="1" :class="hasError('phone')">

                                        <input @keyup="(e) => handleEnter(e, 3)" type="text" v-model="otpArr[3]" v-validate="'required|number'" name="otp"
                                            placeholder="" maxlength="1" :class="hasError('phone')">

                                        <input @keyup="(e) => handleEnter(e, 4)" type="text" v-model="otpArr[4]" v-validate="'required|number'" name="otp"
                                            placeholder="" maxlength="1" :class="hasError('phone')">

                                        <input @keyup="(e) => handleEnter(e, 5)" type="text" v-model="otpArr[5]" v-validate="'required|number'" name="otp"
                                            placeholder="" maxlength="1" :class="hasError('phone')">
                                    </div>
                                    <span v-if="formattedTime !=='0:00'">Resend code in <span class="resend_timer">{{formattedTime}}</span></span>
                                    <span v-else class="resend_timer" @click="resendOtp()">Resend Now</span>
                                    <button type="submit" class="otp_verify_btn">Continue</button>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>


    </layout>
</template>

<style scoped>
p {
    margin: 0;
}
.login_modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    /*This is not the correct way to set the z-index but I have to due to previous developers */
    z-index: 1000;
}
.login_modal_card {
    width: 80%;
    height: 600px;
    background-color: #22222d;
    border-radius: 1rem;
}
.login_modal_card_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}
.login_modal_card_left {
    width: 50%;
    background-image: url(/images/login_modal_left_bg.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_modal_card_left h2 {
    color: white !important;
    font-size: 2.5rem;
}
.login_modal_card_left_content {
    padding: 0 5rem 0px 5rem;
}
.login_modal_card_left_content p {
    color: white;
    margin-top: 2rem;
}

.login_modal_card_right {
    width: 50%;
    display: flex;
    padding: 3.8rem 3.8rem 0 3.8rem;
    justify-content: center;
}

.login_modal_card_right_content {
    width: 100%;
}

.logo {
    width: 2rem;
    height: 2rem;
}

.mpt_logo {
    width: 2.5rem;
    height: 2.5rem;
    margin-top: 0.3rem;
    margin-left: 0.3rem;
}

.phone_logo {
    width: 2rem;
    height: 2rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input_wrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.input_div {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem 0.5rem 0.8rem;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}

.router_link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.input_div:hover {
    opacity: 0.85;
}

.input_div p {
    margin: 0 auto;
    font-size: 1.05rem;
    color: #000
}

.input_phone {
    background-color: transparent;
    border: 1px solid #fff;
}

.input_fb {
    background-color: #1877f2;
}

.input_fb p {
    color: #fff;
}

.input_phone p {
    color: #808080;
}

.terms_policy {
    padding: 0rem 1rem 0rem 1rem;
    margin-top: 2rem;
}
.terms_policy p {
    color: #fff;
    font-size: 1rem;
    text-align: center;
}

.terms_policy p span {
    color: #ff2150;
}

.phone_input_wrapper {
    color: #fff;
    font-size: 1.05rem;
    font-weight: bold;
}
.phone_input_wrapper > p {
    margin-bottom: 2rem;
}
.input_phone input {
    background-color: transparent;
    color: #cdcdcd!important;
}

.is-invalid {
    border: none!important;
}

.input_phone input:focus {
    outline: none;
    box-shadow: none;
    border: none;
    border-color: none;
}

.phone_number_err {
    width: 100%;
    height: 100%;
    color: #ff2150!important;
    font-size: 0.8rem;
}

.otp_info {
    width: 100%;
    height: 100%;
    color: #cdcdcd!important;
    font-size: 0.85rem;
    margin-top: 0.8rem;
    margin-bottom: 2rem;
}

.otp_btn{
    background-color: #ff2150;
    border-color: transparent;
    border-radius: 0.8rem;
    padding: 0.5rem 1.5rem 0.5rem 0.8rem;
}

.continue_with{
    width: 100%;
    height: 2px;
    background-color: #cdcdcd;
    border-radius: 0.8rem;
    position: relative;
    margin-top: 2.8rem;
    margin-bottom: 3.5rem;
}

.continue_text {
    position: absolute;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    top: 50%;
    left: 50%;
    color: #cdcdcd;
    transform: translate(-50%, -50%);
    background-color: #22222d;
    font-size: 1rem;
}

.otp_input_wrapper {
    width: 100%;
    display: flex;
    gap: 1.2rem;
}

.otp_input_wrapper input {
    color: #FFF;
    background-color: #808080;
    width: 20%;
    max-width: 90px;
    height: 3.5rem;
    border: 1px solid #FFF;
    border-radius: 0.8rem;
}

.otp_title {
    font-size: 1.2rem;
    color: #FFF;
    margin-bottom: 1.2rem;
}
.otp_info_upper {
    margin-bottom: 1rem;
}

.otp_verify_btn {
    width: 100%;
    border: none;
    border-radius: 0.8rem;
    background-color: #ff2150;
    color: #fff;
    padding: 0.8rem 0rem 0.8rem 0rem;
    text-decoration: none;
    outline: none;
    margin-top: 2rem;
}

.otp_verify_btn:hover {
    background-color: transparent;
    color: #cdcdcd;
    border: 1px solid #cdcdcd;
}

.resend_timer {
   color: #ff2150;
   cursor: pointer;
}

@media screen and (max-width: 1000px) {
   .login_modal_card_left {
        display: none;
   }
   .login_modal_card_right {
        width: 100%;
        padding: 3.8rem 2rem 0 2rem;
    }
}
</style>
