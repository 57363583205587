<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'weekly-top-20',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Top Songs Today',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            songs: [],
        }),


        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                isAuthenticated: 'isAuthenticated',
                getTracksLoading: 'getTracksLoading',
                isPlay: 'isPlay',
                playerCurrentTrack: 'playerCurrentTrack'
            }),
        },


        /**
         * Created the component.
         */
        created() {
            this.fetchWeeklyTop20();
        },


        methods: {
            /**
             *  Get the weekly top 20 songs.
             */
            async fetchWeeklyTop20() {
                axios.get(`/api/v2/song/weekly-top`).then((response) => {
                    this.songs = response.data;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

            /**
             *  Favourite the song.
             */
            favouriteSong(song, song_id) {
                axios.post(`/api/mmn/favourite/song/${song_id}`).then((response) => {
                    song.is_favourite = response.data.is_favourite;
                }).catch((error) => {

                })
            },
        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <div class="ms_album_single_wrapper ms_profile">

            <main-header/>

            <div class="album_single_data ms_purchase_wrapper padding_left_md_40">
                <div class="ms_heading">
                    <h1>Top Charts</h1>
                </div>

                <!----Song List---->
                <div class="album_inner_list row">
                    <div v-for="(song, key) in this.songs" class="col-12 padding_right40 padding_right_xs_0" :key="key">
                        <div :class="isPlay && playerCurrentTrack.id === song.id ? 'ms_weekly_box ms_active_play' : 'ms_weekly_box'">
                            <div class="weekly_left">
                                <span class="w_top_no">
                                    {{ $_.padStart(key + 1, 2, 0) }}
                                    <i class="fa fa-arrow-up text-success align-middle" style="font-size: 11px" v-if="song.previous_state === 0"></i>
                                    <i class="fa fa-arrow-down text-danger align-middle" style="font-size: 11px" v-else-if="song.previous_state != 0 && song.current_state > song.previous_state"></i>
                                    <i class="fa fa-arrow-up text-success align-middle" style="font-size: 11px" v-else-if="song.previous_state != 0 && song.current_state < song.previous_state"></i>
                                    <span class="align-middle" style="font-size: 11px" v-else>-</span>
                                </span>
                                <div class="w_top_song">
                                    <div class="w_tp_song_img" @click.prevent="handleClickTrack(song, songs)">
                                        <a href="javascript:;" class="mmn-play" :data-id="song.id" >
                                            <img :src="song.album_image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/album/Album.png';">
                                            <div class="ms_song_overlay">
                                            </div>
                                            <div class="ms_play_icon">
                                                <div v-if="isPlay && playerCurrentTrack.id === song.id" class="ms_bars">
                                                    <div class="bar"></div>
                                                    <div class="bar"></div>
                                                    <div class="bar"></div>
                                                    <div class="bar"></div>
                                                    <div class="bar"></div>
                                                    <div class="bar"></div>
                                                </div>
                                                <img v-else src="/images/svg/play.svg" alt="">
                                            </div>
                                        </a>

                                    </div>
                                    <div class="w_tp_song_name">
                                        <h3><a href="javascript:;" class="mmn-play" @click.prevent="handleClickTrack(song, songs)">{{ song.name_mm }}</a></h3>
                                        <p class="text-white text-left">
                                            <template v-if="song.artists.length > 3">
                                                <a href="javascript:;">Various Artists</a>
                                            </template>

                                            <template v-else>
                                                <template v-for="(artist, index1) in song.artists">
                                                    <router-link :to="{ name: 'artist', params: { id : artist.uuid }}">{{ artist.name_en }} <span v-if="isNotLast(song.artists, index1)">, </span></router-link>
                                                </template>
                                            </template>

                                            <template v-if="song.feats.length > 0">
                                                <a href="javascript:;">, ft : </a>
                                                <template v-for="(feat_artist, index2) in song.feats">
                                                    <router-link :to="{ name: 'artist', params: { id: feat_artist.uuid }}">{{ feat_artist.name_en }}
                                                        <span v-if="isNotLast(song.feats, index2)">, </span>
                                                    </router-link>
                                                </template>
                                            </template>

                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="weekly_right">
                                    <span class="ms_more_icon" data-other="1">
										<img src="/images/svg/more.svg" alt="">
									</span>
                                <span :class="song.state.class" style="bottom: 26%;right: 0;position: absolute;" v-html="song.state.number"></span>
                            </div>

                            <ul class="more_option">
                                <li><router-link :to="{ name: 'Album', params: { uuid: song.album_uuid }}"><span class="opt_icon"><img src="/images/svg/go_to_album.svg" style="width:18px;margin-right:7px;"/></span>Go To Album </router-link></li>
                                <template v-if="isAuthenticated">
                                    <li><a href="#" @click.prevent="favouriteSong(song, song.id)"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites </a></li>

                                    <li v-if="song.is_bought">
                                        <a href="javascript:;" v-show="download_songs.includes(song.id)">
                                            <span class="opt_icon"><span class="fa fa-circle-o-notch fa-spin fa-3x"></span></span>Downloading
                                        </a>
                                        <a href="#" @click.prevent="downloadSong(song.id)" v-show="!download_songs.includes(song.id)">
                                            <span class="opt_icon"><span class="icon icon_dwn"></span></span>Download</a>
                                    </li>
                                    <li v-else-if="song.is_store" class="text-center">
                                        <a href="#" @click.prevent="buySong(song.id)">
                                            <span class="opt_icon"><span class="fa fa-shopping-cart"></span></span>{{ song.price }} Kyat</a>
                                    </li>
                                    <li v-else-if="song.is_free">
                                        <a href="javascript:;" v-show="download_songs.includes(song.id)">
                                            <span class="opt_icon"><span class="fa fa-circle-o-notch fa-spin fa-3x"></span></span>Downloading
                                        </a>
                                        <a href="#" @click.prevent="downloadSong(song.id)" v-show="!download_songs.includes(song.id)">
                                            <span class="opt_icon"><span class="icon icon_dwn"></span></span>Download</a>
                                    </li>
                                </template>

                                <template v-else>
                                    <li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites</a></li>
                                    <li v-if="song.is_free">
                                        <a href="#" data-toggle="modal" data-target="#loginModal">
                                            <span class="opt_icon"><span class="icon icon_dwn"></span></span>Download Now</a>
                                    </li>
                                    <li v-else-if="song.is_store">
                                        <a href="#" data-toggle="modal" data-target="#loginModal">
                                            <span class="opt_icon"><span class="fa fa-shopping-cart"></span></span>{{ song.price }} Kyat</a>
                                    </li>
                                </template>

                                <li><a :href="shareFacebookLink(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share to facebook</a></li>
                                <li><a :href="shareTwitterLink(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share to twitter</a></li>
                                <li><a href="#" @click.prevent="copyText('album/' + song.album_uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                            </ul>

                        </div>
                        <!-- <div class="ms_divider"></div> -->
                    </div>

                    <div class="more_loading" v-show="loading">
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                    </div>

                </div>

            </div>

        </div>


    </layout>
</template>
