<script type="text/ecmascript-6">
import { mapGetters } from 'vuex';

export default {
    name: 'landing',

    metaInfo: {
        title: 'Melo Music'
    },

    data: () => ({
        artists: [],
        loading: true,
    }),

    computed: {
        ...mapGetters({
            isAuthenticated: 'isAuthenticated'
        }),

        topSixArtists: function () {
            return this.artists.splice(0, 6)
        }
    },
    mounted() {
        this.getTopArtists()
    },

    methods: {

        getTopArtists() {
            axios.get('/api/v2/artist/top').then((response) => {
                this.artists = response.data.data;
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
            });
        }
    }


}
</script>

<template>
    <!-- <layout>

        <div class="ms_content_wrapper ms_profile">

            <main-header/>

            <div class="header_padder_top"></div>

            <div v-show="!loading">
                <div v-for="(value,key) in data">
                    <component v-bind:is="key" :data="value"></component>
                </div>
            </div>
            <div style="height:100vh;align-items: center;display: flex;margin-top: -10%;" v-show="loading">
                <div class="more_loading">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>
            </div>
        </div>


    </layout> -->
    <div>
        <header id="header" class="fixed-top  header-transparent">
            <div class="container d-flex align-items-center justify-content-between">

                <div class="logo">
                    <h1><a href="index.html"><img src="/img/Logo.png" alt="" class="src"></a></h1>

                </div>

                <nav id="navbar" class="navbar">
                    <ul>
                        <li><a class="nav-link scrollto active" href="">Premium</a></li>
                        <li><a class="nav-link scrollto" href="/support">Support</a></li>
                        <li><a class="getstarted scrollto" href="/register">Register</a></li>
                        <li><a class="nav-link scrollto" href="/login">SignIn</a></li>

                    </ul>
                    <i class="bi bi-list mobile-nav-toggle"></i>
                </nav>

            </div>
        </header>

        <section id="hero" class="d-flex align-items-center">

            <div class="container">
                <div class="row">
                    <div class="col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1"
                        data-aos="fade-up">
                        <div>
                            <h1><span class="textgradien">Melo</span> To <br>
                                your <span class="textgradien"> Heart</span>
                            </h1>
                            <h2>With Melo Music, you can download and enjoy wide varieties of songs from classic traditional
                                songs to epic trending music genres.</h2>
                            <a href="#" class="get-started-btn">Listen</a><br> <br>
                            <a href="https://apps.apple.com/us/app/melo-music/id1478494134"><img src="/img/app.png" alt=""
                                    class="sr"></a>
                            <a href="https://play.google.com/store/apps/details?id=com.melomm.melomusic&hl=en&gl=US"><img
                                    src="/img/android.png" alt="" class="sr"></a>

                        </div>
                    </div>
                    <div class="col-lg-6 d-lg-flex flex-lg-column align-items-center order-1 order-lg-2 hero-img"
                        data-aos="fade-up">
                        <img src="/img/Icon Symbol WOB T 1.png" class="img-fluid leftphoto" alt="" width="300px">
                    </div>

                </div>
            </div>

        </section>

        <main id="main">

            <!-- ======= App Features Section ======= -->
            <section id="features" class="features">
                <div class="container">
                    <div class="section-title">
                        <h2><a class="getstarted scrollto" href="#order"><img src="/assets/img/Arrow.png" alt=""></a></h2>

                    </div>
                    <div class="section-title">

                        <h2>Take A Look At <span class="textgradien">Melo</span> </h2>
                        <div class="vdocontainer">
                            <iframe class="responsive-iframe"
                                src="https://www.youtube.com/embed/OQz7ZrfFxTc?si=uqLfZLpHbYSY0Pk9"></iframe>
                        </div>
                    </div>

                </div>
            </section><!-- End App Features Section -->

            <!-- ======= Artist Section ======= -->
            <section id="team" class="team section-bg">
                <div class="container">

                    <div class="section-title">
                        <h2>Our Top <span class="textgradien">Artists</span></h2>

                    </div>

                    <div class="row">

                        <div v-for="artist in topSixArtists" class="col-lg-2 d-flex align-items-stretch">
                            <div class="member">
                                <div class="member-img">
                                    <img :src="artist.image" class="img-fluid" alt="">
                                </div>
                                <div class="member-info">
                                    <h5>{{ artist.name_en }}</h5>
                                </div>
                            </div>
                        </div>




                    </div>

                </div>
            </section><!-- End Artist Section -->

            <!-- ======= Testimonials Section ======= -->
            <!-- End Testimonials Section -->
            <section id="details" class="details">
                <div class="container">


                    <div class="row content">
                        <div class="col-md-4 order-1 order-md-2" data-aos="fade-right">
                            <img src="/img/Listening happy music-cuate 1.png" class="img-fluid" alt="">
                        </div>
                        <div class="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
                            <h3>Personalized <span class="textgradien">Playlists</span> </h3>

                            <p class="fst">
                                No Wi-Fi? No problem. Download your favorite tracks and enjoy them on the go, Anytime,
                                Anywhere.
                            </p>

                        </div>
                    </div>

                    <div class="row content">
                        <div class="col-md-4" data-aos="fade-left">
                            <img src="/img/Playlist-cuate 1.png" class="img-fluid" alt="">
                        </div>
                        <div class="col-md-8 pt-4" data-aos="fade-up">
                            <h3>Offline <span class="textgradien">Listening</span> </h3>
                            <p class="fst">
                                No Wi-Fi? No problem. Download your favorite tracks and enjoy them on the go, Anytime,
                                Anywhere.
                            </p>

                        </div>
                    </div>


                    <div class="row content">
                        <div class="col-md-4 order-1 order-md-2" data-aos="fade-right">
                            <img src="/img/Headphone-cuate 2.png" class="img-fluid" alt="">
                        </div>
                        <div class="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
                            <h3>Discover <span class="textgradien"> New Music</span></h3>
                            <p class="fst">
                                No Wi-Fi? No problem. Download your favorite tracks and enjoy them on the go, Anytime,
                                Anywhere.
                            </p>

                        </div>
                    </div>



                </div>
            </section><!-- End Details Section -->

            <!-- ======= Gallery Section ======= -->


            <!-- ======= Pricing Section ======= -->
            <section id="pricing" class="pricing">
                <div class="container">

                    <div class="section-title">
                        <h2>Join <span class="textgradien">Melo’s Premium</span></h2>

                    </div>

                    <div class="row align-items-center">

                        <div class="col-lg-4 box2">
                            <h3>Melo's Free</h3>

                            <ul>
                                <li><img src="/img/Close Square.svg" alt=""> Ad Free Music
                                    Streaming</li>
                                <li><img src="/img/Close Square.svg" alt=""> High Audio Quality
                                </li>
                                <li><img src="/img/Close Square.svg" alt=""> Download To Listen
                                    Offline</li>
                                <li><img src="/img/Close Square.svg" alt=""> Visible Lyrics</li>
                                <li><img src="/img/Close Square.svg" alt=""> Own Playlist Creation
                                </li>
                            </ul>

                        </div>

                        <div class="col-lg-4 box">
                            <h3 class="textgradien">Melo's Premium</h3>

                            <ul>
                                <li><img src="/img/Tick Square.svg" alt=""> Ad Free Music Streaming
                                </li>
                                <li><img src="/img/Tick Square.svg" alt=""> High Audio Quality</li>
                                <li><img src="/img/Tick Square.svg" alt=""> Download To Listen
                                    Offline</li>
                                <li><img src="/img/Tick Square.svg" alt=""> Visible Lyrics</li>
                                <li><img src="/img/Tick Square.svg" alt=""> Own Playlist Creation
                                </li>
                            </ul>

                        </div>

                    </div>

                </div>
            </section><!-- End Pricing Section -->


            <section id="Payments" class="Payments">
                <div class="container">

                    <div class="section-title">
                        <h2>Available Payments for Premium</h2>

                    </div>

                    <div class="row p-4 pay">
                        <div class="col-sm-3 col-lg-1 ">
                            <img src="/img/Kpay.png" alt="">
                        </div>
                        <div class="col-sm-3 col-lg-1 ">
                            <img src="/img/Mpt.png" alt="">
                        </div>
                        <div class="col-sm-3 col-lg-1 ">
                            <img src="/img/coin.png" alt="">
                        </div>
                        <div class="col-sm-3 col-lg-1 ">
                            <img src="/img/apple pay.png" alt="">
                        </div>
                        <div class="col-sm-3 col-lg-1 ">
                            <img src="/img/cbpay.png" alt="">
                        </div>
                    </div>



                </div>
            </section>

            <section id="order" class="counts section-bg">
                <div class="container">

                    <div class="row">

                        <div class="col-lg-3 col-md-6">
                            <div class="count-box">
                                <h5>Daily &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp; &nbsp;
                                    99 KS</h5> <br>
                                <p class="textgradien"><b>"For a single day, enjoy uninterrupted music, just like
                                        clockwork."</b></p><br>
                                <p>Available Payments:</p><br>
                                <img src="/img/Mpt.png" width="40px">&nbsp;<img src="/img/coin.png" alt="" width="40px">
                                <img src="/img/apple pay.png" alt="" width="40px">
                                <br><br>
                                <p style="text-align: center;">(Auto Renewal)</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
                            <div class="count-box">
                                <h5>Weekly &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    599 KS</h5> <br>
                                <p class="textgradien"><b>"Seven full days of ad-free, premium music streaming."</b></p>
                                <br><br>
                                <p>Available Payments:</p><br>
                                <img src="/img/Mpt.png" width="40px">&nbsp;<img src="/img/coin.png" alt=""
                                    width="40px"><br><br>
                                <p style="text-align: center;">(Auto Renewal)</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                            <div class="count-box">
                                <h5>Monthly&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    1,999 KS</h5> <br>
                                <p class="textgradien"><b>"A month of unlimited music at your command."</b></p><br><br>
                                <p>Available Payments:</p><br>
                                <img src="/img/Kpay.png" alt="" width="40px">
                                <img src="/img/Mpt.png" width="40px">
                                <img src="/img/cbpay.png" alt="" width="40px">
                                <img src="/img/coin.png" alt="" width="40px">
                                <img src="/img/apple pay.png" alt="" width="40px">
                                <br><br><br>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
                            <div class="count-box">
                                <h5>Yearly &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    19,999 KS</h5> <br>
                                <p class="textgradien"><b>"Commit to a year of premium music streaming."</b></p><br><br>
                                <p>Available Payments:</p><br>
                                <img src="/img/Kpay.png" alt="" width="40px">
                                <img src="/img/Mpt.png" width="40px">
                                <img src="/img/cbpay.png" alt="" width="40px">
                                <img src="/img/coin.png" alt="" width="40px">
                                <img src="/img/apple pay.png" alt="" width="40px">
                                <br><br><br>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            <!-- ======= Frequently Asked Questions Section ======= -->
            <!-- End Frequently Asked Questions Section -->

            <!-- ======= Contact Section ======= -->


        </main><!-- End #main -->


        <!-- ======= Footer ======= -->
        <footer id="footer">

            <div class="footer-top">
                <div class="container">
                    <div class="row">

                        <!-- <div class="col-lg-3 col-md-6 footer-links">
                            <h4>Information</h4>
                            <ul>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">FAQ</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Blog</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Support</a></li>

                            </ul>
                        </div>

                        <div class="col-lg-3 col-md-6 footer-links">
                            <h4>Melo</h4>
                            <ul>

                                <li><i class="bx bx-chevron-right"></i> <a href="#">About us</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Careers</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Contact Us</a></li>

                            </ul>
                        </div> -->

                        <div class="col-lg-3 col-md-6 footer-links">
                            <h4>Legal</h4>
                            <ul>
                                <li><i class="bx bx-chevron-right"></i> <a href="https://melomm.com/terms_and_conditions">Terms &
                                        Conditions</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="https://melomm.com/privacy_policy">Privacy
                                        Policy</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="https://melomm.com/terms_of_use">Terms of Use</a>
                                </li>
                                <li><i class="bx bx-chevron-right"></i> <a href="https://melomm.com/faq">FAQs</a>
                                </li>

                            </ul>
                        </div>

                        <div class="col-lg-3 col-md-6 footer-links">

                            <ul>
                                <li><i class="bi bi-geo-alt-fill" style="margin-bottom: auto;"></i> &nbsp;[A Division Of
                                    Music Industry Development Co.,Ltd]<br>
                                    No (737),Su Paung Yone Street,(37) Quarter North Dagon Township,</li>
                                <li><i class="bi bi-telephone-fill"></i> &nbsp;09 2 6227 7337, 09-262277447</li>
                                <li><i class="bi bi-envelope-fill"></i>&nbsp;support@melomm.com</li>

                            </ul>
                        </div>



                    </div>
                    <hr class="hr" />
                </div>
            </div>

            <div class="container">

                <div class="copyright">
                    <img src="/img/Logo.png" width="124px" height="47">&nbsp;&nbsp; 2023 <strong><span
                            style="color: #ff2150;">MID</span></strong>. All Rights Reserved
                </div>
                <div class="credits">
                    <div class="social-links">
                        <a href="#" class="twitter"><i class="bx bxl-youtube"></i></a>
                        <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                        <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                        <a href="https://www.tiktok.com/@melomusicmm" class="tiktok"><i class="bx bxl-tiktok"></i></a>

                    </div>
                </div>
            </div>
        </footer><!-- End Footer -->
    </div></template>
