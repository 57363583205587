import Cookies from 'js-cookie'
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import Errors from '../../../modules/errors';

const state = {
  access_token: Cookies.get('access_token') || '',
  status: '',
  hasLoadedOnce: false,
  errors: new Errors()
}

export default {
  state,
  getters,
  actions,
  mutations,
}
