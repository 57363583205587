import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  status: '',
  profile: {},
  album_uuid: null, // For user buy the album,
  song_id: null, // For user buy the song
  history:null,
};

export default {
  state : state,
  getters,
  actions,
  mutations,
};
