<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'Faq',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'FAQs',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            body: '',
        }),


        /**
         * Created the component.
         */
        created() {
            if (!sessionStorage.getItem("isShowAds")) {
                sessionStorage.setItem("isShowAds", true);
            }
            this.fetchTermsOfUse();
        },

        methods: {
            /**
             *  Get terms and conditions.
             */
            async fetchTermsOfUse() {
                axios.get('/api/mmn/faq').then((response) => {
                    this.body = response.data;
                }).catch((error) => {

                });
            },
        }
    }
</script>

<template>
    <layout>

        <!---Main Content Start--->
        <div class="ms_content_wrapper ms_profile padder_top80">

            <main-header/>

            <div class="ms_radio_wrapper ms_purchase_wrapper">
                <div class="ms_heading">
                    <h1>FAQs</h1>
                </div>
                <div class="row">
                    <div class="container" v-html="body"></div>
                </div>
            </div>
        </div>


    </layout>
</template>
