<script type="text/ecmascript-6">

    import {mapGetters} from 'vuex';

    export default {
        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                user : 'getProfile',
            }),
        },

        methods: {
            /**
             * Buy music
             */
            changeQuality() {
                axios.post('/api/mmn/streaming_quality', { user_streaming_quality : this.user.user_streaming_quality }).then((response) => {
                    this.$toast.success({ title: 'Success', message: response.data.message });
                    this.closeModal();

                }).catch((error) => {
                    this.closeModal();
                });
            },

            /**
             * Close Modal
             */
            closeModal() {
                this.$nextTick(() => {
                    $('#myQuality').modal('hide');
                });
            },

        }
    }
</script>

<template>

    <div class="ms_save_modal">
        <div id="myQuality" class="modal  centered-modal" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal">
                        <i class="fa_icon form_close"></i>
                    </button>
                    <div class="modal-body">
                        <h1>Streaming Quality Options</h1>
                        <br>

                        <form method="POST" @submit.prevent="changeQuality()">
                            <div class="save_input_group">
                                <select name="user_streaming_quality" v-model="user.user_streaming_quality" id="quality" class="form-control">
                                    <option value="HIGH">High Quality</option>
                                    <option value="MEDIUM">Medium Quality</option>
                                    <option value="LOW">Low Quality</option>
                                </select>
                            </div>
                            <br>
                            <button class="save_btn" type="submit">Save</button>
                            <button class="save_btn" type="button" data-dismiss="modal">Cancel</button>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
