<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import { swiper, swiperSlide } from 'vue-awesome-swiper';
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'favourite',

        components: {
            swiper,
            swiperSlide,
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Purchased',
            }
        },


        /**
         * The component's data.
         */
        data: () => ({
            songs_loading: true,
            albums_loading: true,
            cover: {
                url: "/images/Purchase_Song.png"
            },
            songs: [],
            albums: [],
            swiperOption: {
                slidesPerView: 8,
                slidesPerColumn: 1,
                spaceBetween: 15,
                slidesPerGroup: 4,
                loop: true,
                speed: 500,
                navigation: {
                    nextEl: '.swiper-button-next-purchase-album',
                    prevEl: '.swiper-button-prev-purchase-album',
                },
                breakpoints: {
                    2900: {
                        slidesPerView: 8,
                    },
                    1900: {
                        slidesPerView: 8,
                    },
                    1800: {
                        slidesPerView: 8,
                    },
                    1400: {
                        slidesPerView: 8,
                    },
                    1124: {
                        slidesPerView: 6,
                    },
                    1024: {
                        slidesPerView: 6,
                    },
                    992: {
                        slidesPerView: 6,
                    },
                    834: {
                        slidesPerView: 5,
                    },
                    768: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                    },
                    640: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                    },
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    },
                    375: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    }
                },
            },
        }),


        /**
         * Computed these properties.
         */
        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper
            },
            ...mapGetters({
                isPlay: 'isPlay',
                playerCurrentTrack: 'playerCurrentTrack',
                isAuthenticated: 'isAuthenticated'
            }),
            isBeginning() {
                return this.swiper.isBeginning;
            },
            isEnd() {
                return this.swiper.isEnd;
            },
        },

        mounted(){
            let _this = this;
            this.$nextTick(() => {
                _this.swiper.on('slideChange', function () {
                    if(!_this.isBeginning){
                        $('.swiper-button-prev-purchase-album').removeClass('d-none');
                    }else{
                        $('.swiper-button-prev-purchase-album').addClass('d-none');
                    }
                    if(!_this.isEnd){
                        $('.swiper-button-next-purchase-album').removeClass('d-none');
                    }else{
                        $('.swiper-button-next-purchase-album').addClass('d-none');
                    }
                });
            });
        },

        /**
         * Created the component.
         */
        created() {
            this.fetchCover();
            this.fetchSongs();
            this.fetchAlbums();
        },


        methods: {
            /**
             *  Get the favourite songs.
             */
            async fetchCover(){
                axios.get(`/api/v2/cover-img/purchase`).then((response) => {
                    this.cover = response.data;
                }).catch((error) => {
                    this.cover.url = '/images/Purchase_Song.png';
                })
            },

            async fetchSongs() {
                axios.get(`/api/mmn/purchased-songs-5`).then((response) => {
                    this.songs = response.data.data;
                    this.songs_loading = false;
                }).catch((error) => {
                    this.songs_loading = false;
                });
            },

            /**
             *  Get the favourite albums.
             */
            async fetchAlbums() {
                axios.get(`/api/mmn/purchased-albums`).then((response) => {
                    this.albums = response.data.data;
                    this.albums_loading = false;
                }).catch((error) => {
                    this.albums_loading = false;
                });
            },

            bgImage(image){
                return 'background-image: url("' + image + '")';
            }
        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <div class="ms_album_single_wrapper ms_profile">

            <main-header/>
            <div class="album-cover-wrapper" :style="bgImage(cover.url)" style="filter: blur(120px);height:320px;position:absolute;width: 100%;background-size: cover; background-position:center;opacity: 0.4;" v-show="!songs_loading"></div>
            <!----Free Download Css Start---->
            <div class="album-cover-wrapper" v-show="!songs_loading">
                <div class="album_single_data">
                    <div class="album_single_img">
                        <img :src="cover.url" alt="" class="img-fluid">
                    </div>
                    <div class="album_single_text">
                        <h2>Your Purchased Songs</h2>

                        <div class="album_feature">
                            <div class="btn-group">
                                <template v-if="playerCurrentTrack != null && songs.findIndex(x => x.id === playerCurrentTrack.id) >= 0 && isPlay">
                                    <a @click.prevent="$store.dispatch(isPlay ? 'pause' : 'play')" href="javascript:;" class="custom_play_btn"><span class="fa icon_pause_btn"></span><span style="padding: 5px;">Pause</span></a>
                                </template>
                                <template v-else>
                                    <a @click.prevent="handleClickTrack(songs[0],songs)" href="javascript:;" class="custom_play_btn"><span class="fa fa fa-play"></span><span style="padding: 5px;">Play</span></a>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="ms_content_wrapper ms_free_download ms_purchase_wrapper"> -->
            <div v-show="!songs_loading">
                <div class="album-track-wrapper album_inner_list" >
                 <div data-v-e84545ce="" style="background: #1e1e25;position: absolute;top: 0;width: 100%;height: 100%;z-index: -1;left: 0;"></div>
                    <div class="album_list_wrapper album_track_content">
                        <h5 style="padding:20px;">Tracks<span style="float: right;padding-top: 10px;padding-right: 10px;font-size:14px;"><router-link to="/user/purchased-songs">view more ></router-link></span></h5>
                        <ul class="track_ul" v-if="songs.length" v-for="(song, key) in songs" :class="isPlay && playerCurrentTrack.id === song.id ? 'play_active_song' : ''" v-show="!songs_loading">
                            <li style="padding-top: 10px;top:-5px;">
                                <a href="javascript:;" class="mmn-play" @click="handleClickTrack(song, songs)">
                                    <span class="play_no" style="position: absolute;top: 0;">{{ $_.padStart(key + 1, 0) }}</span>
                                    <div class="ms_bars for_track_ul" v-if="isPlay && playerCurrentTrack.id === song.id">
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                    </div>
                                    <span class="play_hover" v-else></span>
                                </a>
                            </li>

                            <li><a href="javascript:;" class="mmn-play" @click="handleClickTrack(song, songs)"><span class="song_title">{{ song.name_mm }}</span>
                                <p style="font-size:10px;margin-bottom: 5px;">
                                    <a v-if="song.artists.length > 3" href="javascript:">
                                        <span>{{ song.artists[0].name_eng }}, {{ song.artists[1].name_eng }}, ...</span>
                                    </a>
                                    <template v-for="(artist, artist_index) in song.artists" v-else>
                                        <router-link :to="{ name: 'artist', params : {id : artist.uuid}}">{{ artist.name_eng }}
                                            <span v-if="isNotLast(song.artists, artist_index)">, </span>
                                        </router-link>
                                    </template>
                                    <template v-if="song.feats.length">
                                        <a href="javascript:">, ft : </a>
                                        <template v-for="(feat, feat_index) in song.feats">
                                            <router-link :to="{ name : 'artist', params:{ id : feat.uuid }}">{{ feat.name_eng }}
                                                <span v-if="isNotLast(song.feats, feat_index)">, </span>
                                            </router-link>
                                        </template>
                                    </template>
                                </p>
                            </a></li>

                            <li style="top: -10px;"><a href="#">{{ song.duration }}</a></li>
                            <li class="text-center" style="top: -11px;"><a href="#" @click.prevent="favouriteSong(song,song.id)"><span :class="song.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span> </a></li>
                            <li class="text-center" style="top: -11px;">
                                <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                    <span class="ms_close"><img src="/images/svg/download_1.svg" alt="" style="width: 15px;"></span>
                                </a>
                            </li>
                            <li class="text-center ms_more_icon" style="top: -11px;"><a href="javascript:"><span class="fa icon_menu_more"></span></a>
                                <ul class="more_option album_more">
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#" @click.prevent="saveSong(song.id)"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                    <li style="width: 100% !important ;padding: 10px 10px;"><router-link :to="{ name: 'Album', params: { uuid: song.album_uuid }}"><span class="opt_icon"><img src="/images/svg/go_to_album.svg" style="width:18px;margin-right:7px;"/></span>Go To Album </router-link></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongFacebook(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongTwitter(song.share_url)"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#" @click.prevent="copyText('album/'+song.album_uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                </ul>
                            </li>
                        </ul>

                        <ul v-else>
                            <li class="w-100 text-center"><a href="#">No Songs</a></li>
                        </ul>
                    </div>

                    

                </div>

                <!-- <div v-if="songs.length" class="ms_view_more">
                    <router-link to="/user/purchased-songs" class="ms_btn">view more</router-link>
                </div> -->
            </div>

            <div style="height:100vh;align-items: center;display: flex;margin-top: -10%;" v-show="songs_loading">
                <div class="more_loading">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>
            </div>


            <!----Free Download Css Start---->
            <div class="ms_radio_wrapper padder_bottom30 mt-5" v-show="!songs_loading">
                <div class="ms_heading text-center">
                    <h1>Your Purchased Albums</h1>
                </div>

                <div v-show="!albums_loading" class="ms_radio_slider swiper-container">
                    <swiper :options="swiperOption" ref="mySwiper">
                        <!-- slides -->
                        <template v-for="album in albums">
                            <swiper-slide>

                                <div class="ms_rcnt_box">
                                    <div class="ms_rcnt_box_img">
                                        <img :src="album.image" alt="" onError="this.onerror=null;this.src='/images/album/Album.png';">
                                        <div class="ms_main_overlay">
                                            <router-link tag="a" :to="{ name: 'Album', params: { uuid: album.uuid }}" :title="album.name_mm">
                                                <div class="ms_box_overlay"></div>
                                                <div class="ms_play_icon">
                                                    <a href="javascript:;" @click.prevent="playAllSong(album.uuid)">
                                                        <img src="/images/svg/play2.svg" alt="">
                                                    </a>
                                                </div>
                                            </router-link>

                                            <div class="ms_more_icon" data-toggle="popup">
                                                <img src="/images/svg/more.svg" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <ul class="more_option">
                                        <template v-if="isAuthenticated">
                                            <li><a href="#" @click.prevent="saveAlbum(album.uuid)"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                            <li><a href="#" @click.prevent="syncAlbumFavourite(album.uuid)"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites </a></li>
                                            <li v-if="album.is_store && album.price > 0 && !album.is_bought">
                                                <a href="#" @click="buyAlbum(album.uuid)"><span class="opt_icon"><span class="fa fa-shopping-cart"></span></span>{{ album.price.toLocaleString() }} Kyat</a>
                                            </li>
                                        </template>

                                        <template v-else>
                                            <li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                            <li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites </a></li>
                                            <li v-if="album.is_store && album.price > 0">
                                                <a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-shopping-cart"></span></span>{{ album.price.toLocaleString() }} Kyat</a>
                                            </li>
                                        </template>

                                        <li><a :href="shareFacebookLink('/album/' + album.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share to facebook</a></li>
                                        <li><a :href="shareTwitterLink('/album/' + album.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share to twitter</a></li>
                                        <li><a href="#" @click.prevent="copyText('/album/' + album.uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                    </ul>
                                    <div class="ms_rcnt_box_text">
                                        <h3><router-link tag="a" :to="{ name: 'Album', params: { uuid: album.uuid }}">{{ album.name_mm}}</router-link></h3>
                                        <p>
                                            <template v-if="album.artists.length > 3">
                                                <a href="javascript:;">Various Artists</a>
                                            </template>

                                            <template v-else>
                                                <template v-for="(artist, index1) in album.artists">
                                                    <router-link :to="{ name : 'artist', params:{ id : artist.uuid }}">{{ artist.name_eng }} <span v-if="isNotLast(album.artists, index1)">, </span></router-link>
                                                </template>
                                            </template>
                                        </p>
                                    </div>
                                </div>
                            </swiper-slide>
                        </template>

                    </swiper>

                </div>
                <!-- Add Arrows -->
                <div class="swiper-button-next-purchase-album slider_nav_next"></div>
                <div class="swiper-button-prev-purchase-album slider_nav_prev d-none"></div>
            </div>

        </div>


    </layout>
</template>

<style scoped>
    .ms_free_download.ms_purchase_wrapper .album_list_wrapper>ul>li {
        width: 20%;
    }

    .ms_free_download.ms_purchase_wrapper .album_list_wrapper>ul>li:first-child {
        width: 4%;
    }

    .ms_free_download.ms_purchase_wrapper .album_list_wrapper>ul>li:nth-child(5),
    .ms_free_download.ms_purchase_wrapper .album_list_wrapper>ul>li:nth-child(6),
    .ms_free_download.ms_purchase_wrapper .album_list_wrapper>ul>li:nth-child(7),
    .ms_free_download.ms_purchase_wrapper .album_list_wrapper>ul>li:nth-child(8) {
        width: 9%;
    }

    .ms_free_download.ms_purchase_wrapper .purchased_albums>ul>li {
        width: 19% !important;
    }
</style>
