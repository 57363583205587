import Home from "../pages/Home/Index.vue";
import Album from "../pages/Album/Index";
import NewReleases from "../pages/NewReleases";
import TopAlbums from "../pages/TopAlbums";
import Albums from "../pages/Albums";
import Genres from "../pages/Genres";
import Genre from "../pages/Genre";
import Playlist from "../pages/Playlist";
import PlaylistDetail from "../pages/PlaylistDetail";
import RecommendPlaylist from "../pages/RecommendPlaylist";
import WeeklyTop20 from "../pages/WeeklyTop20";
import Artist from "../pages/Artist";
import RelativeAlbum from "../pages/Artist/RelativeAlbum.vue";
import RelativePlaylist from "../pages/Artist/RelativePlaylist.vue";
import Artists from "../pages/Artists";
import FollowArtist from "../pages/FollowArtist.vue";
import Login from "../pages/Auth/Login";
import PhoneLogin from "../pages/Auth/PhoneLogin";
import Register from "../pages/Auth/Register";
import PasswordEmail from "../pages/Auth/Password/Email";
import PasswordReset from "../pages/Auth/Password/Reset";
import ArtistByCategory from "../pages/ArtistByCategory";
import Profile from "../pages/Profile";
import Song from "../pages/Song.vue";
import Live from "../pages/Live.vue";
import MeloLives from "../pages/MeloLives.vue";
import MeloLive from "../pages/MeloLive.vue";

import store from "../store/index";
import Recent from "../pages/Recent";
import Favourite from "../pages/Favourite";
import Purchased from "../pages/Purchased";
import PurchasedSongs from "../pages/PurchasedSongs";
import PurchasedAlbums from "../pages/PurchasedAlbums";
import PurchasedAlbum from "../pages/PurchasedAlbum";
import ChangePassword from "../pages/ChangePassword";
import FavouriteSongs from "../pages/FavouriteSongs";
import FavouriteAlbums from "../pages/FavouriteAlbums";
import EditProfile from "../pages/EditProfile";
import EditRenewal from "../pages/EditRenewal";
import Collection from "../pages/Collection";
import Collections from "../pages/Collections";
import AllCollections from "../pages/CollectionAll.vue";
import Payment from "../pages/Payment";
import SupportGuide from "../pages/SupportGuide";
import Videos from "../pages/Videos";
import Video from "../pages/Video";
import MusicVideos from "../pages/Video/MusicVideos.vue";
import VideoPlaylists from "../pages/Video/VideoPlaylists.vue";
import VideosByCategory from "../pages/VideosByCategory";
import RecommendedVideos from "../pages/Video/RecommendedVideos.vue";
import RecommendedVideoPlaylists from "../pages/Video/RecommendedPlaylists.vue";
import Event from "../pages/Event";
import Notification from "../pages/Notification";
import UpcomingNotification from "../pages/UpcomingNotification";
import Events from "../pages/Events";
import EventByCategory from "../pages/EventByCategory";
import Search from "../pages/Search";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";
import MyPlaylists from "../pages/MyPlaylist/MyPlaylists";
import MyPlaylistDetail from "../pages/MyPlaylist/MyPlaylistSongs";
import Landing from "../pages/Home/Landing";
import PaymentStatus from "../pages/PaymentStatus";
import TermsOfUse from "../pages/TermsOfUse";
import Faq from "../pages/Faq";
import DeleteAccount from "../pages/DeleteAccount";

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/home");
};

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/login");
};

const checkDeepLinkRedirect = (to, from, next) => {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get("type");
    const uuid = urlParams.get("uuid");

    if (!type || type == "live" || type == "song") {
        next();
        return;
    }

    next("/" + type + "/" + uuid);
};

export default [
    {
        path: "/",
        name: "Home",
        component: Home,
        beforeEnter: checkDeepLinkRedirect,
    },

    {
        path: "/home",
        name: "home",
        component: Home,
        beforeEnter: checkDeepLinkRedirect,
    },
    {
        path: "/album/:uuid",
        name: "Album",
        component: Album,
        // beforeEnter: ifAuthenticated,
    },
    {
        path: "/song/:uuid/:id",
        name: "Song",
        component: Song,
        beforeEnter: ifAuthenticated,
    },
    {
        path: "/new-releases",
        name: "new-releases",
        component: NewReleases,
    },

    {
        path: "/top-albums",
        name: "top-albums",
        component: TopAlbums,
        beforeEnter: ifAuthenticated,
    },

    {
        path: "/albums",
        name: "albums",
        component: Albums,
        // beforeEnter: ifAuthenticated,
    },

    {
        path: "/genres",
        name: "genres",
        component: Genres,
    },
    {
        path: "/genre/:id",
        name: "genre",
        component: Genre,
    },

    {
        path: "/playlist",
        name: "playlist",
        component: Playlist,
        // beforeEnter: ifAuthenticated,
    },

    {
        path: "/playlist/:id",
        name: "playlist-detail",
        component: PlaylistDetail,
        beforeEnter: ifAuthenticated,
    },

    {
        path: "/recommended-playlist",
        name: "recommended-playlist",
        component: RecommendPlaylist,
    },

    {
        path: "/collections",
        name: "collections",
        component: Collections,
        beforeEnter: ifAuthenticated,
    },

    {
        path: "/all-collections",
        name: "all-collections",
        component: AllCollections,
        // beforeEnter: ifAuthenticated,
    },

    {
        path: "/collection/:id",
        name: "collection",
        component: Collection,
    },

    {
        path: "/music/top_chart",
        name: "top chart",
        component: WeeklyTop20,
    },

    {
        path: "/artist/:id",
        name: "artist",
        component: Artist,
        // beforeEnter: ifAuthenticated,
    },
    {
        path: "/albums/artist/:artist_id",
        name: "artistAlbums",
        component: RelativeAlbum,
    },
    {
        path: "/playlists/artist/:artist_id",
        name: "artistPlaylists",
        component: RelativePlaylist,
    },
    {
        path: "/artist/:id",
        name: "artist",
        component: Artist,
        beforeEnter: ifAuthenticated,
    },

    {
        path: "/artists",
        name: "artists",
        component: Artists,
        // beforeEnter: ifAuthenticated,
    },

    {
        path: "/artist-by-category/:id",
        name: "artistByCategory",
        component: ArtistByCategory,
    },

    {
        path: "/video/:id",
        name: "Video",
        component: Video,
    },

    {
        path: "/videos",
        name: "videos",
        component: Videos,
        // beforeEnter: ifAuthenticated,
    },

    {
        path: "/music-videos",
        name: "music-videos",
        component: MusicVideos,
    },

    {
        path: "/video-playlists",
        name: "video-playlists",
        component: VideoPlaylists,
    },

    {
        path: "/lives",
        name: "lives",
        component: MeloLives,
        // beforeEnter: ifAuthenticated,
    },

    {
        path: "/live/:id",
        name: "live",
        component: MeloLive,
    },

    {
        path: "/tv",
        name: "tv",
        component: Live,
        beforeEnter: ifAuthenticated,
    },

    {
        path: "/video-by-category/:id",
        name: "videoByCategory",
        component: VideosByCategory,
    },

    {
        path: "/recommended/videos",
        name: "recommended-videos",
        component: RecommendedVideos,
    },

    {
        path: "/recommended/video/playlists",
        name: "recommended-video-playlists",
        component: RecommendedVideoPlaylists,
    },

    {
        path: "/notification/:id",
        name: "Notification",
        component: Notification,
    },

    {
        path: "/upcoming-notification/:id",
        name: "UpcomingNotification",
        component: UpcomingNotification,
    },

    {
        path: "/event/:id",
        name: "Event",
        component: Event,
    },

    {
        path: "/events",
        name: "events",
        component: Events,
    },

    {
        path: "/events/:slug",
        name: "eventByCategory",
        component: EventByCategory,
    },

    {
        path: "/search",
        name: "Search",
        component: Search,
    },

    {
        path: "/support_guide",
        name: "supportGuide",
        component: SupportGuide,
    },

    {
        path: "/terms_and_conditions",
        name: "TermsAndConditions",
        component: TermsAndConditions,
    },
    {
        path: "/terms_of_use",
        name: "TermsOfUse",
        component: TermsOfUse,
    },
    {
        path: "/faq",
        name: "Faq",
        component: Faq,
    },

    {
        path: "/privacy_policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
    },

    {
        path: "/user/profile",
        name: "user-profile",
        component: Profile,
        beforeEnter: ifAuthenticated,
    },

    {
        path: "/user/edit-profile",
        name: "edit-profile",
        component: EditProfile,
        beforeEnter: ifAuthenticated,
    },
    {
        path: "/me/followed-artists",
        name: "followed-artists",
        component: FollowArtist,
        // beforeEnter: ifAuthenticated,
    },

    {
        path: "/user/change-password",
        name: "change-password",
        component: ChangePassword,
        beforeEnter: ifAuthenticated,
    },

    {
        path: "/user/edit-renewal",
        name: "edit-renewal",
        component: EditRenewal,
        beforeEnter: ifAuthenticated,
    },

    {
        path: "/recent",
        name: "recent",
        component: Recent,
        // beforeEnter: ifAuthenticated,
    },

    {
        path: "/user/favourite",
        name: "user-favourite",
        component: Favourite,
        // beforeEnter: ifAuthenticated,
    },

    {
        path: "/user/favourite-songs",
        name: "user-favourite-songs",
        component: FavouriteSongs,
        beforeEnter: ifAuthenticated,
    },

    {
        path: "/user/favourite-albums",
        name: "user-favourite-albums",
        component: FavouriteAlbums,
        beforeEnter: ifAuthenticated,
    },

    {
        path: "/user/purchased",
        name: "user-purchased",
        component: Purchased,
        // beforeEnter: ifAuthenticated,
    },

    {
        path: "/user/purchased-songs",
        name: "user-purchased-songs",
        component: PurchasedSongs,
        beforeEnter: ifAuthenticated,
    },

    {
        path: "/user/purchased-albums",
        name: "user-purchased-albums",
        component: PurchasedAlbums,
        beforeEnter: ifAuthenticated,
    },

    {
        path: "/user/purchased-album/:uuid",
        name: "user-purchased-album",
        component: PurchasedAlbum,
        beforeEnter: ifAuthenticated,
    },

    {
        path: "/user/payment",
        name: "user-payment",
        component: Payment,
        beforeEnter: ifAuthenticated,
    },

    {
        path: "/login",
        name: "login",
        component: Login,
        beforeEnter: ifNotAuthenticated,
    },

    {
        path: "/register",
        name: "register",
        component: Register,
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: "/login/phone",
        name: "phone-login",
        component: PhoneLogin,
        beforeEnter: ifNotAuthenticated,
    },

    {
        path: "/password/reset/",
        name: "password-email",
        component: PasswordEmail,
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: "/password/reset/:token",
        name: "password-reset",
        component: PasswordReset,
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: "/my-play-lists",
        name: "my-play-lists",
        component: MyPlaylists,
        // beforeEnter: ifAuthenticated,
    },
    {
        path: "/my-play-list/:id",
        name: "my-play-list",
        component: MyPlaylistDetail,
        // beforeEnter: ifAuthenticated,
    },
    {
        path: "/payment/dinger/card/status",
        name: "payment-status",
        component: PaymentStatus,
    },
    {
        path: "/payment/dinger/online/status",
        name: "payment-status",
        component: PaymentStatus,
    },
    {
        path: "/user/account/delete",
        name: "delete-account",
        component: DeleteAccount,
        beforeEnter: ifAuthenticated,
    },
];
