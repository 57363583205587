<script type="text/ecmascript-6">

    export default {

        props: {
            song_id : {
                require : true,
            }
        },

        methods: {
            /**
             * Close the modal.
             */
            closeModal() {
                this.$nextTick(() => {
                    $('#downloadModal').modal('hide');
                });
            },
        }
    }
</script>

<template>
    <!---- Modal---->
    <div class="ms_save_modal">
        <div id="downloadModal" class="modal  centered-modal" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="fa_icon form_close"></i>
                        </button>
                        <div class="modal-body">
                            <h1>Buying successful, are you sure you want to download now ?</h1>
                            <div class="clr_modal_btn">
                                <a href="#" v-show="download_songs.includes(song_id)">Downloading ... !</a>
                                <a href="#" @click.prevent="downloadSong(song_id)" v-show="!download_songs.includes(song_id)">Download Now</a>
                                <a href="#" data-dismiss="modal">Later</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
