<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'purchased-album',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Purchased Album',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            album_loading: true,
            album: {
                songs: [],
                artists: [],
                feats:[],
                view: {},
                sub_menu: {
                    free : false,
                    streaming : false,
                    store : true,
                }
            },
        }),


        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                isPlay: 'isPlay',
                playerCurrentTrack: 'playerCurrentTrack',
                isAuthenticated: 'isAuthenticated'
            }),
        },


        /**
         * Created the component.
         */
        created() {
            this.fetchAlbum();
        },

        methods: {
            /**
             * Get purchased album.
             */
            fetchAlbum() {
                axios.get(`/api/mmn/purchased-album/${this.$route.params.uuid}`).then((response) => {
                    this.album = response.data.data;
                    this.album_loading = false;
                }).catch((error) => {
                    this.album_loading = false;
                });
            },

        }
    }
</script>

<template>
    <layout>

        <!---Main Content Start--->
        <div style="background: #1E1E25;">
            <div class="ms_album_single_wrapper ms_profile">

                <main-header/>
                <div class="album-cover-wrapper" :style="{ 'background-image': 'url(' + album.image + ')' }" style="filter: blur(120px);height:315px;position:absolute;width: 100%;background-size: cover; background-position:center; opacity: 0.4"></div>

                <div class="ms_free_download ms_purchase_wrapper album-cover-wrapper">
                    <!-- <div class="ms_heading">
                        <h1>Your Purchased Album ( {{ album.name_mm }} )</h1>
                    </div> -->

                    <div class="album_single_data">
                        <div class="album_single_img">
                            <img :src="album.image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/album/Album.png';">
                        </div>
                        <div class="album_single_text">
                            <h2>{{ album.name_mm }}</h2>
                            <p class="singer_name">By -
                                <template v-for="(artist, index1) in album.artists">
                                    <router-link :to="{ name: 'artist', params: { id : artist.uuid }}">
                                        {{ artist.name_eng }}
                                        <span v-if="isNotLast(album.artists, index1)">, </span>
                                    </router-link>
                                </template>

                                <template v-if="album.feats.length">
                                    <br>
                                    Feat:
                                    <template v-for="(feat, index2) in album.feats">
                                        <router-link :to="{ name: 'artist', params: { id : feat.uuid }}">
                                            {{ feat.name_eng }}
                                            <span v-if="isNotLast(album.feats, index2)">, </span>
                                        </router-link>
                                    </template>

                                </template>
                            </p>
                            <div class="album_feature">
                                <a href="#" class="album_date">{{ album.songs.length }} song</a>
                                <a href="#" class="album_date">Released {{ album.release_date}}</a>
                                <span v-if="album.is_streaming" style="font-size: 15px; color: #ed1557; border: 2px solid #ed1557;padding:5px; border-radius: 10px; opacity: 0.7;"><i class="fa fa-play-circle" style="vertical-align: middle;"></i> mPlay</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="album-track-wrapper album_inner_list">
                    <div data-v-e84545ce="" style="background: #1e1e25;position: absolute;top: 0;width: 100%;height: 100%;z-index: -1;left: 0;"></div>
                    <div class="album_list_wrapper album_track_content">
                        <h5 style="padding:20px;font-size: 18px;">Tracks<span style="font-size:11px;margin-left: 5px;">Total - {{ album.songs.length }} song<span v-if="album.songs.length > 1">s</span></span></h5>
                        <template>
                            <ul class="track_ul" v-for="(song, key) in album.songs" :class="isPlay && playerCurrentTrack.id === song.id ? 'play_active_song' : ''">
                                <li style="padding-top: 10px;top:-5px;">
                                    <a href="javascript:" class="mmn-play" @click="handleClickTrack(song, album.songs)">
                                        <span class="play_no" style="position: absolute;top: 0;">{{ $_.padStart(key + 1, 0) }}</span>
                                        <div class="ms_bars for_track_ul" v-if="isPlay && playerCurrentTrack.id === song.id">
                                            <div class="bar"></div>
                                            <div class="bar"></div>
                                            <div class="bar"></div>
                                            <div class="bar"></div>
                                            <div class="bar"></div>
                                            <div class="bar"></div>
                                        </div>
                                        <span class="play_hover" v-else></span>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:" class="mmn-play" @click="handleClickTrack(song, album.songs)">
                                        <span class="song_title">
                                            {{ song.name_mm }}
                                        </span>
                                        <p style="font-size:10px;margin-bottom: 5px;">
                                            <a v-if="song.artists.length > 3" href="javascript:">
                                                <span>{{ song.artists[0].name_eng }}, {{ song.artists[1].name_eng }}, ...</span>
                                            </a>
                                            <template v-for="(artist, artist_index) in song.artists" v-else>
                                                <router-link :to="{ name: 'artist', params : {id : artist.uuid}}">{{ artist.name_eng }}
                                                    <span v-if="isNotLast(song.artists, artist_index)">, </span>
                                                </router-link>
                                            </template>
                                            <template v-if="song.feats.length">
                                                <a href="javascript:">, ft : </a>
                                                <template v-for="(feat, feat_index) in song.feats">
                                                    <router-link :to="{ name : 'artist', params:{ id : feat.uuid }}">{{ feat.name_eng }}
                                                        <span v-if="isNotLast(song.feats, feat_index)">, </span>
                                                    </router-link>
                                                </template>
                                            </template>
                                        </p>
                                    </a>
                                </li>
                                <li style="top: -10px;"><a href="javascript:">{{ song.duration }}</a></li>
                                <template v-if="isAuthenticated">
                                    <li class="text-center" style="top: -10px;"><a href="#" @click.prevent="favouriteSong(song,song.id)"><span :class="song.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span> </a></li>
                                    <li v-if="song.is_bought" class="text-center" style="top: -11px;">
                                        <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                        <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                            <span class="ms_close"><img src="/images/svg/download_1.svg" alt="" style="width:15px;"></span>
                                        </a>
                                    </li>
                                    <li v-else-if="album.is_store" class="text-center" style="top: -11px;">
                                        <template v-if="song.price > 0">
                                            <a href="javascript:" @click="buySong(song.id)"><i class="fa icon_buy_dollar"></i></a>
                                        </template>
                                        <template v-else-if="song.price === 0">
                                            <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                            <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                                <span class="ms_close"><img src="/images/svg/download_1.svg" alt="" style="width:15px;"></span>
                                            </a>
                                        </template>
                                    </li>
                                    <li class="text-center ms_more_icon" style="top: -11px;"><a href="javascript:"><span class="fa icon_menu_more"></span></a>
                                        <ul class="more_option album_more">
                                            <li style="width: 100% !important ;padding: 10px 10px;"><a href="#" @click.prevent="saveSong(song.id)"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                            <li style="width: 100% !important ;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongFacebook(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                            <li style="width: 100% !important ;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongTwitter(song.share_url)"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                            <li style="width: 100% !important ;padding: 10px 10px;"><a href="#" @click.prevent="copyText(song.share_url)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                        </ul>
                                    </li>
                                </template>
                                <template v-else>
                                    <li class="text-center" style="top: -10px;"><a href="#" data-toggle="modal" data-target="#loginModal"><span class="fa fa-heart"></span> </a></li>
                                    <li v-if="album.is_store" class="text-center" style="top: -11px;">
                                        <a href="#" v-if="song.price > 0" data-toggle="modal" data-target="#loginModal"><i class="fa icon_buy_dollar"></i></a>
                                        <a href="#" v-else-if="song.price === 0" data-toggle="modal" data-target="#loginModal" class="song_download">
                                            <span class="ms_close"><img src="/images/svg/download_1.svg" alt="" style="width:15px;"></span>
                                        </a>
                                    </li>
                                    <li v-else class="text-center" style="top: -11px;">
                                        <a href="#" v-if="song.price > 0" data-toggle="modal" data-target="#loginModal"><i class="fa icon_buy_dollar"></i></a>
                                        <a href="#" v-else data-toggle="modal" data-target="#loginModal" class="song_download">
                                            <span class="ms_close"><img src="/images/svg/download_1.svg" alt="" style="width:15px;"></span>
                                        </a>
                                    </li>
                                    <li class="text-center ms_more_icon" style="top: -11px;">
                                        <a href="javascript:"><span class="fa icon_menu_more"></span></a>
                                        <ul class="more_option album_more">
                                            <li style="width: 100% !important ;padding: 10px 10px;"><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                            <li style="width: 100% !important ;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongFacebook(song.share_url)"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                            <li style="width: 100% !important ;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongTwitter(song.share_url)"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                            <li style="width: 100% !important ;padding: 10px 10px;"><a href="#" @click.prevent="copyText(song.share_url)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                        </ul>
                                    </li>
                                </template>
                            </ul>
                        </template>

                    </div>
                </div>
            </div>
        </div>


    </layout>
</template>
