var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.data.length > 0),expression:"data.data.length > 0"}],staticClass:"ms_radio_wrapper"},[_c('div',{staticClass:"ms_heading"},[_c('h1',[_vm._v(_vm._s(_vm.data.title))])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"row"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"ms_radio_slider swiper-container"},[_c('swiper',{ref:"mySwiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.data.data),function(genre){return [_c('swiper-slide',[_c('router-link',{attrs:{"to":{ name: 'genre', params: { id: genre.uuid }},"title":genre.name}},[_c('div',{staticClass:"ms_rcnt_box"},[_c('div',{staticClass:"ms_rcnt_box_img"},[_c('img',{attrs:{"src":genre.image,"alt":"","alt":"","onError":"this.onerror=null;this.src='/images/album/collection_playlist.png';"}}),_vm._v(" "),_c('div',{staticClass:"ms_main_overlay"},[_c('div',{staticClass:"ms_box_overlay"})])]),_vm._v(" "),_c('div',{staticClass:"ms_rcnt_box_text"},[_c('h3',[_c('router-link',{attrs:{"tag":"a","to":{ name: 'genre-detail', params: { id: genre.uuid }}}},[_vm._v(_vm._s(genre.name))])],1)])])])],1)]})],2)],1),_vm._v(" "),(_vm.data.data.length > 8)?[_c('div',{staticClass:"swiper-button-next-genres slider_nav_next"}),_vm._v(" "),_c('div',{staticClass:"swiper-button-prev-genres slider_nav_prev d-none"})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2",staticStyle:{"display":"inline-block"}},[_c('div',{staticClass:"load-music-box"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2",staticStyle:{"display":"inline-block"}},[_c('div',{staticClass:"load-music-box"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2",staticStyle:{"display":"inline-block"}},[_c('div',{staticClass:"load-music-box"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2",staticStyle:{"display":"inline-block"}},[_c('div',{staticClass:"load-music-box"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2",staticStyle:{"display":"inline-block"}},[_c('div',{staticClass:"load-music-box"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2",staticStyle:{"display":"inline-block"}},[_c('div',{staticClass:"load-music-box"})])
}]

export { render, staticRenderFns }