<script type="text/ecmascript-6">
    import Layout from '../../../layouts/Index.vue'
    import MainHeader from '../../../layouts/Header.vue'

    export default {
        name: 'reset',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Reset Password',
            }
        },

        /**
         * Injecting Parent Validator
         * Don't remove it.
         *
         * https://baianat.github.io/vee-validate/concepts/injections.html
         * https://vuejs.org/v2/api/#provide-inject
         */
        inject: [ '$validator' ],


        /**
         * The component's data.
         */
        data: () => ({
            'action': 'password-reset',
            'email':'',
            'password':'',
            'password_confirmation':'',
            'token':''
        }),

        computed: {
            authErrors(){
                return this.$store.getters.authErrors;
            }
        },


        /**
         * Created the component.
         */
        created() {
            let token = this.$route.params.token;
            if(!token){
                this.$router.push('/');
            }
            this.token = token;
        },


        methods: {

            resetPassword: function () {
                this.$validator.validateAll().then((result) => {
                    if (!result){
                        this.$toast.error({ title: 'Error', message: "Please, check your inputs." });
                        throw new Error("Please, check your inputs.");
                    }else {
                        const { action, email, password, password_confirmation, token } = this;
                        this.$store.dispatch('authRequest', { action, email, password, password_confirmation, token })
                            .then(() => {
                                this.$router.push('/')
                            }).catch(() => {
                            this.$setErrorsFromResponse(this.authErrors.all());
                        });
                    }
                }).catch(() => {

                });
            },
        }
    }
</script>

<template>
    <layout>

        <div class="padder_top80 ms_profile">

            <main-header/>

            <!----Edit Profile Wrapper Start---->
            <div class="ms_profile_wrapper">
                <h1>Reset Password</h1>
                <div class="ms_profile_box">

                    <div class="ms_pro_form">

                        <form method="POST" @submit.prevent="resetPassword()">

                            <div class="form-group">
                                <label for="email">E-Mail Address</label>
                                <input type="text" v-model="email" v-validate="'required|email'" id="email" name="email" placeholder="Enter Your Email" :class="hasError('email')">
                                <span v-show="errors.has('email')" class="invalid-feedback text-left text-white" role="alert">
                                    <strong>{{ errors.first('email') }}</strong>
                                </span>
                            </div>

                            <div class="form-group">
                                <label for="email">Password</label>
                                <input type="password" name="password" v-model="password" v-validate="'required|min:6'" placeholder="Enter Password" :class="hasError('password')">
                                <span v-show="errors.has('password')" class="invalid-feedback text-left text-white" role="alert">
                                            <strong>{{ errors.first('password') }}</strong>
                                        </span>
                            </div>

                            <div class="form-group">
                                <label for="email">Confirmed Password</label>
                                <input type="password" name="password" v-model="password_confirmation" v-validate="'required'" placeholder="Enter Password" :class="hasError('password_confirmation')">
                                <span v-show="errors.has('password_confirmation')" class="invalid-feedback text-left text-white" role="alert">
                                            <strong>{{ errors.first('password_confirmation') }}</strong>
                                        </span>
                            </div>

                            <div class="pro-form-btn text-center marger_top15">
                                <button type="submit" class="ms_btn">Reset Password</button>
                                <router-link to="/" class="ms_btn">cancel</router-link>
                            </div>

                        </form>

                    </div>
                </div>
            </div>

        </div>


    </layout>
</template>
