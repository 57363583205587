<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'genre',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                // if no subcomponents specify a metaInfo.title, this title will be used
                title: this.genre.name,

                meta: [

                    // OpenGraph data (Most widely used)
                    {property: 'og:title', content: `${this.genre.name} - Melo`},

                    // Should the the same as your canonical link, see below.
                    {property: 'og:url', content: this.genre.image},
                    {property: 'og:image', content: this.genre.image},

                    // Twitter card
                    {name: 'twitter:title', content: `${this.genre.name} - Melo`},
                    // Your twitter handle, if you have one.
                    {name: 'twitter:image:src', content: this.genre.image},

                    // Google / Schema.org markup:
                    {itemprop: 'name', content: `${this.genre.name} - Melo`},
                    {itemprop: 'image', content: this.genre.image}
                ],
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            genre: {
                name: null,
                image: null,
            },
            songs: [],
        }),


        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                isAuthenticated: 'isAuthenticated',
                playerCurrentTrack: 'playerCurrentTrack',
                isPlay: 'isPlay'
            }),

            totalPlay: function () {
                let sum = 0;
                return this.songs.reduce((sum, song) => sum + song.play_count, 0);
            }
        },


        /**
         * Created the component.
         */
        created() {
            this.fetchSongs();
        },


        methods: {
            /**
             *  Get the songs.
             */
            async fetchSongs() {
                axios.get(`/api/mmn/genre/${this.$route.params.id}`).then((response) => {
                    this.genre = response.data.genre;
                    this.songs = response.data.songs;
                    //console.log(response.data.songs.findIndex(x => x.id === 1976));
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

            favouriteSong(song_id, index) {
                axios.post(`/api/mmn/favourite/song/${song_id}`).then((response) => {
                    this.songs.splice(index, 1)
                }).catch((error) => {

                })
            },
            shareSongFacebook(url) {
                window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.asset(url)), '_blank');
            },
            shareSongTwitter(url) {
                window.open('http://www.twitter.com/intent/tweet?url=' + encodeURIComponent(this.asset(url)), '_blank');
            },
        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <div class="ms_album_single_wrapper ms_profile">

            <main-header/>

            <div class="album-cover-wrapper" :style="{ 'background-image': 'url(' + genre.image + ')' }" style="filter: blur(120px);height:320px;position:absolute;width: 100%;background-size: cover;background-positon:center;opacity: 0.4;" v-show="!loading"></div>
            <div class="album-cover-wrapper" v-show="!loading">
                <div class="album_single_data">
                    <div class="album_single_img">
                        <img :src="genre.image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/album/collection_playlist.png';">
                    </div>
                    <div class="album_single_text">
                        <h2>{{ genre.name }}</h2>
                        <p><a href="#" class="album_date"><span class="fa fa fa-music" style="font-size:18px;margin-right: 8px;position: relative;"></span> {{ songs.length }} song<span v-if="songs.length > 1">s</span></a></p>
                        <div class="album_feature">
                            <!-- <p v-html="genre.description_eng"></p> -->
                            <!--<a href="javascript:;">Read more</a>-->

                            <!-- <a href="#" class="album_date"><i class="fa fa-headphones"></i> - {{ (totalPlay + "" ).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</a> -->

                            <div class="btn-group dropright mt-4">
                                <template v-if="songs.findIndex(x => x.id === playerCurrentTrack.id) >= 0 && isPlay">
                                    <a @click.prevent="$store.dispatch(isPlay ? 'pause' : 'play')" href="javascript:;" class="custom_play_btn"><span class="fa icon_pause_btn"></span><span style="padding: 5px;">Pause</span></a>
                                </template>
                                <template v-else>
                                    <a @click.prevent="handleClickTrack(songs[0],songs)" href="javascript:;" class="custom_play_btn"><span class="fa fa fa-play"></span><span style="padding: 5px;">Play</span></a>
                                </template>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <!----Song List---->
            <div class="album-track-wrapper album_inner_list" v-show="!loading">
                <div data-v-e84545ce="" style="background: #1e1e25;position: absolute;top: 0;width: 100%;height: 100%;z-index: -1;left: 0;"></div>
                <div class="album_list_wrapper album_track_content" v-show="!loading">
                    <h5 style="padding:20px;">Tracks<span style="font-size:11px;margin-left: 5px;">Total - {{ songs.length }} song<span v-if="songs.length > 1">s</span></span></h5>

                    <ul class="track_ul" v-for="(song, key) in songs" :class="isPlay && playerCurrentTrack.id === song.id ? 'play_active_song' : ''">
                        <li style="padding-top: 10px;top:-5px;">
                            <a href="javascript:;" class="mmn-play" @click="handleClickTrack(song, songs)">
                                <span class="play_no" style="position: absolute;top: 0;">{{ $_.padStart(key + 1, 0) }}</span>
                                <div class="ms_bars for_track_ul" v-if="isPlay && playerCurrentTrack.id === song.id">
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                </div>
                                <span class="play_hover" v-else></span>
                            </a>
                        </li>

                        <li><a href="javascript:;" class="mmn-play" @click="handleClickTrack(song, songs)">
                            <span class="song_title">
                                {{ song.name_mm }}
                            </span>
                            <p style="font-size:10px;margin-bottom: 5px;">
                                <a v-if="song.artists.length > 3" href="javascript:">
                                    <span>{{ song.artists[0].name_eng }}, {{ song.artists[1].name_eng }}, ...</span>
                                </a>
                                <template v-for="(artist, artist_index) in song.artists" v-else>
                                    <router-link :to="{ name: 'artist', params : {id : artist.uuid}}">{{ artist.name_eng }}
                                        <span v-if="isNotLast(song.artists, artist_index)">, </span>
                                    </router-link>
                                </template>
                                <template v-if="song.feats.length">
                                    <a href="javascript:">, ft : </a>
                                    <template v-for="(feat, feat_index) in song.feats">
                                        <router-link :to="{ name : 'artist', params:{ id : feat.uuid }}">{{ feat.name_eng }}
                                            <span v-if="isNotLast(song.feats, feat_index)">, </span>
                                        </router-link>
                                    </template>
                                </template>
                            </p>
                        </a></li>

                        <li style="top: -10px;"><a href="javascript:;">{{ song.duration }}</a></li>


                        <template v-if="isAuthenticated">
                            <li class="text-center" style="top: -10px;"><a href="#" @click.prevent="favouriteSong(song,song.id)" ><span :class="song.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span> </a></li>

                            <li v-if="song.is_bought" class="text-center" style="top: -10px;">
                                <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                    <span class="ms_close"><img src="/images/svg/download_1.svg" alt="" style="width:15px;"></span>
                                </a>
                            </li>

                            <li v-else-if="song.is_store" class="text-center" style="top: -10px;">
                                <a href="javascript:;" @click="buySong(song.id)"><i class="fa fa-dollar" style="background:#fff;padding:3px 5px;border-radius:10px;color:#333;font-size:11px;"></i></a>
                            </li>

                            <li v-else-if="song.is_free" class="text-center" style="top: -10px;">
                                <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                    <span class="ms_close"><img src="/images/svg/download_1.svg" alt="" style="width:15px;"></span>
                                </a>
                            </li>
                            <li v-else></li>
                            <li class="text-center ms_more_icon" style="top: -11px;"><a href="javascript:;"><span class="fa icon_menu_more"></span></a>
                                <ul class="more_option album_more">
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#" @click.prevent="saveSong(song.id)"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                    <li style="width: 100% !important ;padding: 10px 10px;"><router-link :to="{ name: 'Album', params: { uuid: song.album_uuid }}"><span class="opt_icon"><img src="/images/svg/go_to_album.svg" style="width:18px;margin-right:7px;"/></span>Go To Album </router-link></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongFacebook(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongTwitter(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#" @click.prevent="copyText('album/'+song.album_uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                </ul>
                            </li>

                        </template>

                        <template v-else>
                            <li class="text-center" style="top: -10px;"><a href="#" data-toggle="modal" data-target="#loginModal"><span class="fa fa-heart"></span> </a></li>

                            <li v-if="song.is_store" class="text-center" style="top: -10px;"><a href="#" data-toggle="modal" data-target="#loginModal"><i class="fa fa-dollar" style="background:#fff;padding:3px 5px;border-radius:10px;color:#333;font-size:11px;"></i></a></li>

                            <li v-else-if="song.is_free" class="text-center" style="top: -10px;">
                                <a href="#" data-toggle="modal" data-target="#loginModal" class="song_download">
                                    <span class="ms_close"><img src="/images/svg/download_1.svg" alt="" style="width:15px;"></span>
                                </a>
                            </li>

                            <li v-else></li>

                            <li class="text-center ms_more_icon" style="top: -11px;"><a href="javascript:;"><span class="fa icon_menu_more"></span></a>
                                <ul class="more_option album_more">
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                    <li style="width: 100% !important ;padding: 10px 10px;"><router-link :to="{ name: 'Album', params: { uuid: song.album_uuid }}"><span class="opt_icon"><img src="/images/svg/go_to_album.svg" style="width:18px;margin-right:7px;"/></span>Go To Album </router-link></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongFacebook(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongTwitter(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#" @click.prevent="copyText('album/'+song.album_uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                </ul>
                            </li>
                        </template>

                    </ul>

                </div>

            </div>
            <div style="height: 100vh;align-items: center;display: flex;margin-top: -10%;">
                <div class="more_loading" v-show="loading">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>
            </div>
        </div>


    </layout>
</template>
