<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'
    import { swiper, swiperSlide } from 'vue-awesome-swiper';

    export default {
        name: 'Payment',

        components: {
            Layout,
            MainHeader,
            swiper,
            swiperSlide,
        },

        metaInfo() {
            return {
                title: 'Payment',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            playlist: [],
            images:[],
            payment : 'coda_pay',
            amount : 1000,
            paymentMethods : {
                coda_pay : 'codaPay',
                paypal : 'paypal',
                mpu : 'mpu',
                cb_pay : 'cbpay'
            },
            mpu_url : 'https://www.mpu-ecommerce.com/Payment/Payment/pay',
            paypal_url : '/payment/paypal',
            mpu_form : {
                merchant_id : null,
                invoice_no : null,
                product_desc : null,
                currency : null,
                user_defined_1 : null,
                user_defined_2 : null,
                user_defined_3 : null,
                HashValue : null,
            },
            cb_form : {
                code : null,
                msg : null,
                merDqrCode : null,
                transExpiredTime : null,
                refNo : null,
                transRef : null,
            },
            txn_id : null,
            show : false,
            swiperOption: {
                slidesPerView: 3,
                spaceBetween: 25,
                loop: false,
                speed: 1500,
                navigation: {
                    nextEl: '.swiper-button-next-collection',
                    prevEl: '.swiper-button-prev-collection',
                },
                breakpoints: {
                    1800: {
                        slidesPerView: 3,
                    },
                    1400: {
                        slidesPerView: 3,
                    },
                    992: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                    },
                    480: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    375: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    }
                },
            },
            token : document.head.querySelector('meta[name="csrf-token"]'),
        }),


        /**
         * Computed these properties.
         */
        computed: {

        },


        /**
         * Created the component.
         */
        mounted() {
            
        },

        created(){
            this.fetchTopup();
        },

        methods: {
            fetchTopup(){
                axios.get('/api/v2/topup-image').then((response) => {
                    this.images=response.data;
                    let self=this;
                    this.$nextTick(() => {
                        $(".select_amount:first").addClass('select_hover');
                        $(".select_method:first").addClass('select_hover');
                        self.amount=$(".select_amount:first").data('amount');
                        self.payment=$(".select_method:first").data('payment');
                    });
                }).catch((error) => {
                    alert(error);
                });
            },

            routeToMethod() {
                this[this.paymentMethods[this.payment]]();
            },

            async codaPay() {
                axios.post('/api/mmn/payment/coda-pay', { amount: this.amount, type : 'InitTxn'}).then((response) => {

                    this.token = document.head.querySelector('meta[name="csrf-token"]');
                    this.txn_id = response.data.txn_id;

                    setTimeout(() => { document.getElementById('codaForm').submit(); }, 1000);

                }).catch((error) => {
                    alert(error);
                });
            },

            paypal() {
                axios.post('/api/mmn/payment/paypal', { amount : this.amount}).then((response) => {
                    location.href = response.data.url;
                }).catch((error) => {
                    this.$toast.error({ title: 'Error', message: error.response.data.message});
                });
            },

            cbpay() {
                axios.post('/api/mmn/payment/cbpay', { payment_service: 'cbpay', amount : this.amount}).then((response) => {
                    this.cb_form = response.data;
                    setTimeout(() => { document.getElementById('cbForm').submit();}, 1500);
                }).catch((error) => {
                    this.$toast.error({ title: 'Error', message: error.response.data.message});
                });

            },

            async mpu() {
                axios.post('/api/mmn/payment/mpu-pay', { payment_service: 'mpu', amount : this.amount}).then((response) => {
                    this.mpu_form = response.data;
                    setTimeout(() => { document.getElementById('mpuForm').submit(); }, 1500);
                }).catch((error) => {
                    this.$toast.error({ title: 'Error', message: error.response.data.message});
                });
            },
            checkAmount(event){
                if($(event.target.parentNode).data('type')=="amount"){
                    $("#amount_row .ms_rcnt_box_img").removeClass('select_hover');
                    this.amount=$(event.target.parentNode).data('amount');
                }else{
                    $("#method .ms_rcnt_box_img").removeClass('select_hover');
                    this.payment=$(event.target.parentNode).data('payment');
                }
                $(event.target.parentNode).addClass('select_hover');
        }

        }
    }
</script>

<template>
    <layout>

        <!---Main Content Start--->
        <div class="ms_content_wrapper ms_genres_single ms_profile padder_top50">

            <main-header/>

            <div class="ms_fea_album_slider">
                <div class="pay_main_header">
                    <h3 style="font-size:20px">TOP UP Payment Plan</h3>
                </div>
                <div class="row justify-content-center text-center text-white" style="font-size: 14px;font-weight: 600;">
                    <div class="col-md-12" >
                        <p>
                            melo music တွင်တေးသီးချင်း များဝယ်ယူနားဆင်ရန်အတွက် ကြိုတင်ငွေဖြည့်ထားရန်လိုအပ်ပါတယ်။
အကြိုတင်ငွေဖြည့်ရန်အတွက်အောက်ပါ ငွေဖြည့်အစီအစဉ်များကိုရွှေးချယ်ဝယ်ယူနိုင်ပါတယ်။
                        </p>
                    </div>
                </div>
                <!--<div class="pay_heading">
                    <h1>Choose Your Amount</h1>
                </div>-->
                <div class="row justify-content-center" id="amount_row" style="padding:40px;">
                    <div class="ms_rcnt_box col-md-4 payment_method" v-for="(image,index) in images" v-if="image.type=='amount'">
                        <div class="ms_rcnt_box_img select_amount" :data-amount="image.for" @click.prevent="checkAmount" :data-type="image.type">
                            <img :src="image.image" alt="" :data-amount="image.for">
                        </div>
                        <div class="ms_rcnt_box_text" style="text-align: center;">
                            <input type="radio" id="1000" name="amount" v-model="amount" value="1000" checked style="display:none">
                        </div>
                    </div>
                </div>
            </div>

            <div class="ms_fea_album_slider" style="margin-top:30px;">
                <div class="pay_heading ">
                    <h3 class="text-white" style="font-size:20px">Select Your Payment Method</h3>
                </div>
                <div class="row justify-content-center" id="method" style="padding:10px 40px">
                        <div class="ms_rcnt_box col-md-3 p-3 payment_method" v-for="(image,index) in images" v-if="image.type=='method'">
                            <div class="ms_rcnt_box_img select_method " :data-payment="image.for" @click.prevent="checkAmount" :data-type="image.type">
                                <img :src="image.image" alt="" :data-payment="image.for">
                            </div>
                            <div class="ms_rcnt_box_text text-center">
                                <input type="radio" id="coda_pay" value="coda_pay" v-model="payment" name="payment_service" style="display:none;"> 
                            </div>
                        </div>
                        
                    </div>

            </div>
            <!----Download Latest---->

            <form method="POST" action="/payment-codapay" id="codaForm" style="display: none;">
                <input type="text" name="_token" v-model="token">
                <input type="text" name="txn_id" v-model="txn_id" />
            </form>

            <form method="POST" action="/payment-cbpay" id="cbForm" style="display: none;">
                <input type="text" name="_token" v-model="token">
                <input type="text" name="amount" v-model="amount" />
                <input type="text" name="resp" v-model="cb_form" />

            </form>

            <form method="POST" :action="mpu_url" id="mpuForm" style="display: none;">
                <input type="text" id="merchantID" name="merchantID" v-model="mpu_form.merchant_id" />
                <input type="text" id="invoiceNo" name="invoiceNo" v-model="mpu_form.invoice_no" />
                <input type="text" id="productDesc" name="productDesc" v-model="mpu_form.product_desc" >
                <input type="text" id="amount" name="amount" v-model="mpu_form.amount" />
                <input type="text" id="currencyCode" name="currencyCode" v-model="mpu_form.currency" />
                <input type="text" id="userDefined1" name="userDefined1" v-model="mpu_form.user_defined_1" />
                <input type="text" id="userDefined2" name="userDefined2" v-model="mpu_form.user_defined_2" />
                <input type="text" id="userDefined3" name="userDefined3" v-model="mpu_form.user_defined_3" />
                <input type="text" id="hashValue" name="hashValue" v-model="mpu_form.HashValue" />
            </form>
        </div>

        <div class=" padder_bottom20" style="text-align: center;margin-top:30px;font-size:20px;">
            <a href="#" @click.prevent="routeToMethod()" class="ms_btn_next" id="view_more" style="font-size:20px !important;">TOP UP NOW</a>
        </div>

    </layout>
</template>
<style scoped>
.pay_main_header h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 26px;
    color: #fff;
    margin: 20px 0;
}
.pay_heading{
    width: 100%;
    display: inline-block;
    margin: 30px 0;
    justify-content: center;
    display: flex;
}
.pay_heading h1{
    font-size: 24px;
    position: relative;
    padding-bottom: 10px;
    text-transform: capitalize;
    text-align: center;
}
.pay_heading h1::after{
    width: 170px;
    height: 3px;
    content: "";
    position: absolute !important;
    bottom: 0;
    left: calc(58% - 100px);
    z-index: 0;
    background: #ff2150;
}
.select_hover{
    border: 4px solid #ff2150;
}
.select_plan_btn{
    background-color: #ff2150;
    color: #fff;
    padding:3px 0;
    display: inline-block;
    text-transform: capitalize;
    width: 170px;
    text-align: center;
    line-height: 32px;
    border-radius: 20px;
    margin-left: 15px;
}
.ms_btn_next{
    background-color: #ff2150;
    color: #fff;
    padding:10px 0;
    display: inline-block;
    text-transform: capitalize;
    width: 220px;
    text-align: center;
    line-height: 32px;
    border-radius: 25px;
    margin-left: 15px;
    font-size: 24px;
}
</style>
