<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import { swiper, swiperSlide } from 'vue-awesome-swiper';
    import Layout from '../../layouts/Index.vue';
    import MainHeader from '../../layouts/Header.vue';
    import {bus} from "../../app";
    import EditPlaylistModal from "../../components/Modals/EditPlaylist";
    import DescriptionModal from "../../components/Modals/DescriptionModal.vue";
    export default {
        name: "MyPlaylistSongs",
        components: {
            swiper,
            swiperSlide,
            Layout,
            MainHeader,
            EditPlaylistModal,
            DescriptionModal
        },
        data:()=>({
            album_loading: true,
            data_length:0,
            related_album_loading: true,
            album_image_loading : true,
            playlist:[],
            album: {
                data:[],
                songs:[]
            },
            playlist_description:null,
        }),
        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper
            },
            ...mapGetters({
                isPlay: 'isPlay',
                playerCurrentTrack: 'playerCurrentTrack',
                isAuthenticated: 'isAuthenticated',
                user : 'getProfile',
            }),
        },
        mounted(){
            this.fetchAlbum();
        },
        methods: {
            /**
             * Get the album.
             */
            fetchAlbum() {
                this.album_loading = true;
                axios.get('/api/mmn/my-play-lists/' + this.$route.params.id).then((response) => {
                    this.album = response.data;
                    this.data_length = this.album.songs.length - 1;
                    this.album_loading = false;
                    this.playlist_description=this.album.data.description;
                    bus.$emit('playlist',response.data.data);
                }).catch((error) => {
                    this.album_loading = false;
                });
            },
            /** Delete Song from Playlist
             *
             */
            deleteSaveSong(song_id)
            {
                axios.delete('/api/mmn/delete/my-play-list/'+this.$route.params.id+'/song/'+song_id).then((response)=>{
                    this.fetchAlbum();
                }).catch((error)=>{

                });
            },

            /** Add to Playlist
             *
             */
            addSongContinue()
            {
                this.$router.push('/');
            },
            checkUser(user_id)
            {
                if(this.isAuthenticated)
                {
                    if(user_id===this.user.id)
                    {
                        return true;
                    }
                }
                return false;
            }

        }
    }
</script>

<template>
    <layout>
    <description-modal :data="album.data.description"></description-modal>
    <EditPlaylistModal :fetchAlbum="fetchAlbum"></EditPlaylistModal>
        <!----Album Single Section Start---->
        <div class="ms_album_single_wrapper ms_profile playlist">

            <main-header/>

            <div class="album-cover-wrapper" :style="{ 'background-image': 'url(' + album.data.image + ')' }" style="filter: blur(120px);height:320px;position:absolute;width: 100%;background-size: cover; opacity: 0.4;background-position:center;" v-show="!album_loading"></div>
            <div class="album-cover-wrapper" v-show="!album_loading">
                <div class="album_single_data">
                    <div class="album_single_img">
                        <div class="load-album-image" v-show="album_loading"></div>
                        <img :src="album.data.image" alt="" v-show="!album_loading" class="img-fluid" >
                    </div>
                    <div class="album_single_text">
                        <h2>{{this.album.data.name}}</h2>
                        <p style="margin-bottom: 0.5rem">
                            <a href="#" class="album_date mt-2" style="font-size:12px;"><span class="fa fa fa-music" style="font-size:18px;margin-right: 8px;position: relative;"></span>{{ album.songs.length }} Song<span v-if="album.songs.length > 1">s</span></a>
                        </p>

                        <p style="margin-bottom: 0.5rem">
                            <a href="#" class="album_date mt-2" style="font-size:12px;" v-if="album.data.public"><i class="fa fa-globe" style="margin-right: 8px;font-size:18px;"></i> Public</a>
                            <a href="#" class="album_date mt-2" v-else style="font-size:12px;"><i class="fa fa-lock" style="margin-right: 8px;font-size:18px;"></i> Private</a>
                        </p>

                        <div class="d-flex">
                        <span v-if="album.data.description" class="fa fa icon_about" style="font-size:18px;margin-right: 5px;color: #fff;"></span>
                        <div class="description" v-html="album.data.description"></div>
                        <a v-if="album.data.description && playlist_description.length > 40" href="#description-artist" data-toggle="modal" data-target="#descriptionModal" class="read-more">Read more <i class="fa fa-chevron-right"></i></a>
                        </div>
                        <!-- <p style="font-size: 12px;">{{album.data.description}}</p> -->
                        <div class="album_feature mt-2">

                            <template v-if="playerCurrentTrack != null && album.songs.findIndex(x => x.id === playerCurrentTrack.id) >= 0 && isPlay">
                                <a @click.prevent="$store.dispatch(isPlay ? 'pause' : 'play')" href="javascript:;" class="custom_play_btn"><span class="fa icon_pause_btn"></span><span style="padding: 5px;">Pause</span></a>
                            </template>
                            <template v-else>
                                <a @click.prevent="handleClickTrack(album.songs[0],album.songs)" href="javascript:;" class="custom_play_btn"><span class="fa fa fa-play"></span><span style="padding: 5px;">Play All</span></a>
                            </template>

                            <template v-if="checkUser(album.data.user_id)">
                                <a href="#" data-toggle="modal" data-target="#confirmModal" class="custom_btn_style ml-2">
                                    <span class="fa fa-trash"></span> Delete All
                                </a>
                            </template>
                            <a href="#" data-toggle="modal" data-target="#edit_playlist" class="custom_btn_style ml-2">
                                <span class="fa fa-edit"></span> Edit
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!----Song List---->
            <div class="album-track-wrapper album_inner_list" v-show="!album_loading">
                <div data-v-e84545ce="" style="background: #1e1e25;position: absolute;top: 0;width: 100%;height: 100%;z-index: -1;left: 0;"></div>
                <div class="album_list_wrapper album_track_content">
                    <h5 style="padding:20px;">Tracks</h5>
                    <template v-if="this.album.songs.length >= 1">
                        <ul class="track_ul" v-for="(song, key) in this.album.songs" :class="isPlay && playerCurrentTrack.id === song.id ? 'play_active_song' : ''">
                            <li style="padding-top: 10px;top:-5px;">
                                <a href="javascript:" class="mmn-play" @click="handleClickTrack(song, album.songs)">
                                    <span class="play_no" style="position: absolute;top: 0;">{{ $_.padStart(key + 1, 0) }}</span>
                                    <div class="ms_bars for_track_ul" v-if="isPlay && playerCurrentTrack.id === song.id">
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                    </div>
                                    <span class="play_hover" v-else></span>
                                </a>
                            </li>

                            <li ><a href="javascript:" class="mmn-play" @click="handleClickTrack(song, album.songs)"><span class="song_title">{{ song.name_mm }}</span>
                                <p style="font-size:10px;margin-bottom: 5px;">
                                    <a v-if="song.artists.length > 3" href="javascript:">
                                        <span>{{ song.artists[0].name_eng }}, {{ song.artists[1].name_eng }}, ...</span>
                                    </a>
                                    <template v-for="(artist, artist_index) in song.artists" v-else>
                                        <router-link :to="{ name: 'artist', params : {id : artist.uuid}}">{{ artist.name_eng }}
                                            <span v-if="isNotLast(song.artists, artist_index)">, </span>
                                        </router-link>
                                    </template>
                                    <template v-if="song.feats.length">
                                        <a href="javascript:">, ft : </a>
                                        <template v-for="(feat, feat_index) in song.feats">
                                            <router-link :to="{ name : 'artist', params:{ id : feat.uuid }}">{{ feat.name_eng }}
                                                <span v-if="isNotLast(song.feats, feat_index)">, </span>
                                            </router-link>
                                        </template>
                                    </template>
                                </p>
                            </a></li>
                            <li style="top: -10px;"><a href="javascript:">{{ song.duration }}</a></li>

                            <template v-if="isAuthenticated">
                                <li class="text-center" style="top: -10px;"><a href="#" @click.prevent="favouriteSong(song,song.id)" ><span :class="song.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span> </a></li>

                                <li class="text-center" v-show="checkUser(album.data.user_id)" style="top: -10px;">
                                    <a href="#" @click.prevent="deleteSaveSong(song.id)" ><span class="fa fa-trash"></span></a>
                                </li>

                                <li class="text-center ms_more_icon" style="top: -11px;"><a href="javascript:"><span class="fa icon_menu_more"></span></a>
                                    <ul class="more_option album_more">
                                        <li style="width: 100% !important;padding: 10px 10px;" v-if="isAuthenticated"><a href="#" @click.prevent="saveSong(song.id)"  target="_blank"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist</a></li>
                                        <li style="width: 100% !important;padding: 10px 10px;" v-else><a href="#" data-toggle="modal" data-target="#loginModal"><span class="fa fa-plus"></span> Add To Playlist</a></li>
                                        <li style="width: 100% !important ;padding: 10px 10px;"><router-link :to="{ name: 'Album', params: { uuid: song.album_uuid }}"><span class="opt_icon"><img src="/images/svg/go_to_album.svg" style="width:18px;margin-right:7px;"/></span>Go To Album </router-link></li>
                                        <li style="width: 100% !important;padding: 10px 10px;"><a :href="shareFacebookLink(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                        <li style="width: 100% !important;padding: 10px 10px;"><a :href="shareTwitterLink(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                        <li style="width: 100% !important;padding: 10px 10px;"><a href="#" @click.prevent="copyText('/album/' + song.album_uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                    </ul>
                                </li>

                                <!-- <li v-if="song.is_bought" class="text-center" style="width:6%">
                                    <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                    <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                        <span class="ms_close"><img src="/images/svg/download.svg" alt=""></span>
                                    </a>
                                </li>

                                <li v-else class="text-center" style="width:6% !important">
                                    <template v-if="song.price > 0">
                                        <a href="javascript:" @click="buySong(song.id)" >{{ song.price }} Kyat</a>
                                    </template>

                                    <template v-else-if="song.price === 0">
                                        <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                        <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                            <span class="ms_close"><img src="/images/svg/download.svg" alt=""></span>
                                        </a>
                                    </template>
                                </li> -->
                            </template>

                        </ul>
                    </template>
                    <template v-else>
                        <h5 class="text-center mt-3" style="font-size: 18px">No Song List</h5>
                    </template>
                </div>
            </div>

            <div style="height: 100vh;align-items: center;display: flex;margin-top: -10%;" v-show="album_loading">
                <div class="more_loading">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>
            </div>
        </div>
    </layout>
</template>

<style scoped>

</style>