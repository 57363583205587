<script type="text/ecmascript-6">
import Layout from '../layouts/Index.vue'
import MainHeader from '../layouts/Header.vue'
import AccDeleteModal from '../components/Modals/AccDeleteModal.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'delete-account',

    metaInfo() {
        return {
            title: 'Delete Account',
        }
    },

    /**
     * Computed these properties.
     */
    computed: {
        ...mapGetters({
            isAuthenticated: 'isAuthenticated',
            user: 'getProfile',
        }),
    },

    components: {
        Layout,
        MainHeader,
        AccDeleteModal
    },
}
</script>

<template>
    <layout>

        <div class="padder_top80 ms_profile">

            <AccDeleteModal />

            <main-header />

            <div class="ms_profile_wrapper">
                <div class="ms_profile_box" style="position:relative;">

                    <div class="ms_heading mb-4" style="position: relative;">

                        <div v-if="user.has_mpt_subscription" class="alert alert-danger" role="alert">
                            You can't delete this account. Because this account has auto-renewal subscription (MPT).
                            <br />
                            To cancel an auto-renewal subscription, follow the steps below.
                            <br />
                            Please reload this page after canceling the auto-renewal subscription.
                        </div>

                        <div v-else-if="user.has_apple_subscription" class="alert alert-danger" role="alert">
                            You can't delete this account. Because this account has auto-renewal subscription (Apple).
                            <br />
                            To cancel an auto-renewal subscription, follow the steps below.
                            <br />
                            Please reload this page after canceling the auto-renewal subscription.
                        </div>

                        <div v-else class="alert alert-danger" role="alert">
                            Deleting your account will remove all of your information from our database.
                        </div>

                    </div>

                    <!-- <div v-if="user.has_mpt_subscription" class="shadow p-3 mb-5 bg-white rounded"
                        style="border-radius: 50px !important; background: #8c263f !important; color: white !important; padding: 26px !important;">
                        MPT Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of
                        classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin
                        professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words,
                        consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical
                        literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33
                        of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This
                        book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of
                        Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.

                        The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.
                        Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in
                        their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                    </div>

                    <div v-if="user.has_apple_subscription" class="shadow p-3 mb-5 bg-white rounded"
                        style="border-radius: 50px !important; background: #8c263f !important; color: white !important; padding: 26px !important;">
                        APPLE Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of
                        classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin
                        professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words,
                        consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical
                        literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33
                        of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This
                        book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of
                        Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.

                        The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.
                        Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in
                        their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                    </div> -->

                    <div class="ms_profile_list">
                        <div class="row" style="padding: 40px 0;">
                            <div class="col-12">
                                <button v-if="user.has_mpt_subscription || user.has_apple_subscription" type="submit"
                                    class="delete_account_button"
                                    style="border:0px; color:rgb(156, 156, 156); border-radius: 20px; cursor: not-allowed;"
                                    data-toggle="modal" data-target="#acc_delete_modal" disabled>
                                    Delete Account
                                </button>
                                <button v-else type="submit" class="ms_btn delete_account_button" style="border:0px;"
                                    data-toggle="modal" data-target="#acc_delete_modal">Delete
                                    Account</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </layout>
</template>
<style >
.alert {
    padding: 0.75rem 10.25rem !important;
}

.delete_account_button {
    height: 40px !important;
    width: 200px !important;
}
</style>
