<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'favourite-albums',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Favourite Albums',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            albums_loading: true,
            albums: [],
        }),


        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                isPlay: 'isPlay',
                playerCurrentTrack: 'playerCurrentTrack',
                isAuthenticated: 'isAuthenticated'
            }),
        },


        /**
         * Created the component.
         */
        created() {
            this.fetchAlbums();
        },


        methods: {

            /**
             *  Get the favourite albums.
             */
            async fetchAlbums() {
                axios.get(`/api/mmn/favourite-albums`).then((response) => {
                    this.albums = response.data.data;
                    this.albums_loading = false;
                }).catch((error) => {
                    this.albums_loading = false;
                });
            },

            favouriteAlbum(album_uuid, index) {
                axios.post(`/api/mmn/favourite-sync/album/${album_uuid}`).then((response) => {
                    this.albums.splice(index, 1)
                }).catch((error) => {

                })
            },

        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <div class="ms_content_wrapper ms_profile">

            <main-header/>

            <!----Free Download Css Start---->
            <div class="ms_free_download ms_purchase_wrapper padder_top50 padder_bottom30">
                <div class="ms_heading">
                    <h1>Your Favourite Albums</h1>
                </div>

                <div class="album_inner_list">

                    <div class="album_list_wrapper favourite_albums" v-show="!albums_loading">
                        <ul class="album_list_name">
                            <li>#</li>
                            <li>Artwork</li>
                            <li>Album Title</li>
                            <li>Artist</li>
                            <li class="text-center">Action</li>
                        </ul>

                        <ul v-if="albums.length" v-for="(album, key) in albums">
                            <li><router-link :to="{ name: 'Album', params: { uuid: album.uuid }}">
                                <span class="play_no">{{ $_.padStart(key + 1, 2, 0) }}</span>
                                <span class="play_hover"></span></router-link>
                            </li>
                            <li><router-link :to="{ name: 'Album', params: { uuid: album.uuid }}">
                                <img :src="album.image" alt="" width="100" onError="this.onerror=null;this.src='/images/album/Album.png';"></router-link>
                            </li>
                            <li><router-link :to="{ name: 'Album', params: { uuid: album.uuid }}">{{ album.name_mm }}</router-link></li>
                            <li>
                                <template v-if="album.artists.length > 3">
                                    <a href="javascript:;">Various Artists</a>
                                </template>

                                <template v-else>
                                    <template v-for="(artist, index1) in album.artists">
                                        <router-link :to="{ name: 'artist', params: { id : artist.uuid}}">{{ artist.name_eng }} <span v-if="isNotLast(album.artists, index1)">, </span>
                                        </router-link>
                                    </template>
                                </template>
                            </li>
                            <li class="text-center">
                                <a href="javascript:;" @click.prevent="favouriteAlbum(album.uuid, key)" title="Remove">
                                    <span class="ms_close"><img src="/images/svg/close.svg" alt=""></span></a>
                            </li>
                        </ul>

                        <ul v-else>
                            <li class="w-100 text-center"><a href="#">No Albums</a></li>
                        </ul>

                    </div>

                    <div class="more_loading" v-show="albums_loading">
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                    </div>

                </div>

            </div>

        </div>


    </layout>
</template>

<style>
    .ms_free_download.ms_purchase_wrapper .favourite_albums>ul>li {
        width: 19%;
    }
</style>
