<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'
    import { swiper, swiperSlide } from 'vue-awesome-swiper';

    export default {
        name: 'UpcomingNotification',

        components: {
            Layout,
            MainHeader,
            swiper,
            swiperSlide,
        },

        metaInfo() {
            return {
                // if no subcomponents specify a metaInfo.title, this title will be used
                title: 'Upcoming Notification',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            notification: {
                title : '',
                image : '',
            },
            next_noti:[],
            swiperOption: {
                slidesPerView: 2,
                spaceBetween: 15,
                slidesPerGroup: 1,
                // loop: true,
                speed: 500,
                navigation: {
                    nextEl: '.swiper-button-next-noti',
                    prevEl: '.swiper-button-prev-noti',
                },
                breakpoints: {
                    1200: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    375: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                        slidesPerGroup: 1,
                    }
                },
            },
            next_loading: true,

        }),
        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper
            },
        },


        /**
         * Created the component.
         */
        created() {
            this.fetchNotification();
            this.fetchNextNotification();
        },


        methods: {
            /**
             *  Get the notification.
             */
            async fetchNotification() {
                axios.get(`/api/mmn/notification/${this.$route.params.id}`).then((response) => {
                    this.notification = response.data.data;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },
            async fetchNextNotification() {
                this.next_loading = true;
                axios.get(`/api/v2/upcoming/next/${this.$route.params.id}`).then((response) => {
                    this.next_noti = response.data;
                    this.next_loading = false;
                    console.log(this.next_noti);
                }).catch((error) => {
                    this.next_loading = false;
                });
            },
            bgImage(image){
                return 'background-image: url("' + image + '")';
            },
            like(id){
                axios.post('/api/v2/like-notifications/'+id).then((response) => {
                    this.notification.isLiked = response.data.status;
                });
           },
           likeUpcoming(id,index){ 
                axios.post('/api/v2/like-notifications/'+id).then((response) => {
                    this.next_noti[index].intrest = response.data.count; 
                    this.next_noti[index].isLiked = response.data.status; 
                });
           },

        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <div class="ms_content_wrapper ms_profile padder_top80">

            <main-header/>

            <div class="album-cover-wrapper" :style="bgImage(notification.image)" style="filter: blur(5px);height:250px;position:absolute;width: 100%;background-size: cover; opacity: 0.4;background-position: center;" v-show="!loading"></div>
            <div class="album-cover-wrapper" v-show="!loading">
                <div class="album_single_data d-flex justify-content-center" >
                    <div class="album_single_img" style="width:360px;">
                        <div class="ms_rcnt_box">
                            <div class="ms_rcnt_box_img">
                                <img :src="notification.image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/album/album.jpg';" >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="album-track-wrapper album_inner_list" v-show="!loading" style="background:#1e1e25;position:relative;">
                <div data-v-e84545ce="" style="background: #1e1e25;position: absolute;top: 0;width: 100%;height: 100%;z-index: -1;left: 0;"></div>
                <div class="album_list_wrapper album_track_content d-flex justify-content-center">
                    <div class="ms_radio_wrapper ms_purchase_wrapper row" style="max-width: 720px;flex: 1;">
                        <div class="blog_single_content w-100">
                            <div class="noti_header_box">
                                <div style="flex: 1;">
                                    <h3 class="ms_blog_title">{{ notification.title }}</h3>
                                    <p>
                                        <span>
                                        <i class="fa fa-calendar"></i>
                                        {{moment(String(notification.schedule_date)).format('DD-MMMM-YYYY')}}
                                        </span>
                                        <span> , 
                                            <i class="fa fa-clock"></i>
                                            {{moment(String(notification.schedule_date)).format('h:mm A')}}
                                        </span>
                                    </p>
                                    <!-- <p>
                                        <span>
                                        {{notification.intrest}} interested
                                        </span>
                                        <span> , 
                                            {{notification.share_count}} shared
                                        </span>
                                    </p> -->
                                </div>
                                <div>
                                    <a href="javascript:;" @click.prevent="like(notification.id)" class="noti-upcoming-btn btn-interest"><span v-if="notification.isLiked">interested</span><span v-else>interest</span></a>
                                    <div class="d-inline-block dropleft">
                                        <a href="javascript:;" data-toggle="dropdown" class="noti-upcoming-btn btn-share ml-1" aria-haspopup="true" aria-expanded="false">
                                            Share
                                        </a>
                                        <div class="dropdown-menu album_more_detail noti_share" style="background: #28282E;border-radius: 0px;">
                                            <a class="dropdown-item" style="color: #fff;font-size:12px;padding: 0 20px;" href="javascript:;" @click.prevent="shareSongFacebook(notification.share_url)" target="_blank">Share To Facebook</a>
                                            <a class="dropdown-item" style="color: #fff;font-size:12px;padding: 0 20px;" href="javascript:;" @click.prevent="shareSongTwitter(notification.share_url)" target="_blank">Share To Twitter</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <h6 class="mb-3">Description :</h6>
                                <span v-html="notification.description" class="text-white"></span>
                            </div>
                        </div>
                        <div class="blog_single_content w-100 mt-2" v-show="!next_loading" v-if="next_noti.length > 0">
                             <div class="ms_heading">
                                <h1>Next</h1>
                            </div>
                            <div class="ms_feature_slider swiper-container">
                                <swiper :options="swiperOption"  ref="mySwiper">
                                    <!-- slides -->
                                    <template v-for="(noti,index) in next_noti" v-if="noti.id != $route.params.id">
                                        <swiper-slide>
                                            <router-link tag="a" :to="{ name: 'UpcomingNotification', params: { id: noti.id }}" :title="noti.name">
                                                <div class="noti-container noti_border">
                                                    <div class="noti-box d-block w-100">
                                                        <div class="d-block">
                                                            <div class="d-flex text-white">
                                                                <router-link tag="a" :to="{ name: 'UpcomingNotification', params: { id: noti.id }}" :title="noti.name">
                                                                    <img class="noti-image img-fluid upcoming-img" :src="noti.image" onError="this.onerror=null;this.src='/img/mmn-logo1.png';">
                                                                </router-link>
                                                                <div class="ml-2">
                                                                    <router-link tag="a" :to="{ name: 'UpcomingNotification', params: { id: noti.id }}" :title="noti.name" class="d-block">
                                                                        <span class="noti-header">{{ noti.name }}</span>
                                                                    </router-link>
                                                                    <p style="font-size: 12px;margin-bottom: 10px;line-height:0;margin-top:5px">
                                                                    <i class="fa fa-calendar"></i>
                                                                    {{moment(String(noti.schedule_date)).format('DD-MMMM-YYYY')}}</p>
                                                                    <p style="font-size: 12px;margin-bottom: 0px;line-height:0;">
                                                                    <i class="fa fa-clock"></i>
                                                                    {{moment(String(noti.schedule_date)).format('h:mm A')}}</p>
                                                                    <div class="noti-btn-group mt-2 mb-2">
                                                                        <a href="javascript:;" @click.prevent="likeUpcoming(noti.id,index)" class="noti-upcoming-btn btn-interest"><template><span>interest</span> </template><span class="ml-1" v-if="noti.intrest > 0">{{noti.intrest}}</span></a>
                                                                        <div class="d-inline-block dropleft">
                                                                            <a href="javascript:;" data-toggle="dropdown" class="noti-upcoming-btn btn-share ml-1" aria-haspopup="true" aria-expanded="false">
                                                                                Share
                                                                            </a>
                                                                            <div class="dropdown-menu album_more_detail noti_share" style="background: #28282E;border-radius: 0px;">
                                                                                <a class="dropdown-item" style="color: #fff;font-size:12px;padding: 0 20px;" href="javascript:;" @click.prevent="shareSongFacebook(noti.share_url)" target="_blank">Share To Facebook</a>
                                                                                <a class="dropdown-item" style="color: #fff;font-size:12px;padding: 0 20px;" href="javascript:;" @click.prevent="shareSongTwitter(noti.share_url)" target="_blank">Share To Twitter</a>
                                                                                <a class="dropdown-item" style="color: #fff;font-size:12px;padding: 0 20px;" href="javascript:;" @click.prevent="copyText('notification/'+noti.id)">Copy Link</a>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>                           
                                                            </div>
                                                            <!-- <i class="fa fa-circle" v-if="!notification.is_read"></i> -->
                                                            
                                                            <div class="noti-body">
                                                                <span class="noti-time" v-html="noti.description"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </swiper-slide>
                                    </template>

                                </swiper>

                            </div>
                            <!-- Add Arrows -->
                            <template v-if="next_noti.length > 2">
                                <div class="swiper-button-next-noti slider_nav_next"></div>
                                <div class="swiper-button-prev-noti slider_nav_prev d-none"></div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ms_radio_wrapper ms_purchase_wrapper">
                
            </div>

        </div>


    </layout>
</template>

<style>
    .noti_border{
        border:1px solid #fff;
        padding:10px;
        min-height:190px;
    }
    .noti_header_box{
        border-bottom: 1px solid;
        display: flex;
        align-items: center;
        margin-bottom: 1rem; 
    }
    .fa-clock::before {
        content: "\f017";
    }
</style>