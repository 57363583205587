<script type="text/ecmascript-6">
    import Sidebar from './Sidebar.vue'
    import Player from './Player.vue'
    import MainFooter from './Footer.vue'
    import Modal from './../components/Modals/LoginModal';
    import StreamingQualityModal from "../components/Modals/StreamingQualityModal";
    import BuyModal from "../components/Modals/BuyModal";
    import BuyAlbumModal from "../components/Modals/BuyAlbumModal";
    import SubscribeModal from "../components/Modals/SubscribeModal";
    import InsufficientToSubscribeModal from "../components/Modals/InsufficientToSubscribeModal";
    import AdsModal from "../components/Modals/AdsModal";
    import PlaylistCreateModal from "../components/Modals/PlaylistCreateModal";
    import PlaylistCreateAlbumModal from "../components/Modals/PlaylistCreateAlbumModal";
    import PlaylistSongModal from "../components/Modals/PlaylistSongModal";
    import PlaylistAlbumModal from "../components/Modals/PlaylistAlbumModal";
    import ConfirmModal from "../components/Modals/ConfirmModal";
    import PlaylistCreateSongModal from "../components/Modals/PlaylistCreateSongModal";

    export default {
        components: {
            AdsModal,
            InsufficientToSubscribeModal,
            SubscribeModal,
            BuyAlbumModal,
            BuyModal,
            StreamingQualityModal,
            Sidebar,
            Player,
            MainFooter,
            Modal,
            PlaylistCreateModal,
            PlaylistAlbumModal,
            ConfirmModal,
            PlaylistCreateSongModal,
            PlaylistCreateAlbumModal,
            PlaylistSongModal,
        },

        /**
         * The component's data.
         */
        data: () => ({
            ads : {},
        }),

        mounted() {
            this.$nextTick(() => {
                let self = this;
                if(this.$route.query.$deep_link){
                    this.$router.push(this.$route.query.$deep_link);
                    // console.log(this.$route.query.$deep_link);
                }else{
                    console.log("no query");
                }
            })
        },

        created() {
            if (!sessionStorage.getItem("isShowAds")) {
                this.getAds();
                sessionStorage.setItem("isShowAds", true);
            }
        },

        methods: {
            /**
             *  Get the ads.
             */
            getAds() {
                let ads_id = localStorage.getItem("ads_id") ? localStorage.getItem("ads_id") : 0;

                axios.get(`/api/mmn/ads/${ads_id}`).then((response) => {
                    this.ads = response.data;
                    if (!this.$_.isEmpty(this.ads)) {
                        this.showModal();
                    }
                }).catch((error) => {

                });
            },

            /**
             * Show Modal
             */
            showModal() {
                this.$nextTick(() => {
                    localStorage.setItem("ads_id", this.ads.id);
                    this.$nextTick(() => {
                        $('#adsModal').modal('show');
                    });
                });
            },
        }
    }
</script>

<template>
    <div style="background:#1e1e25;">

        <sidebar/>

        <playlist-create-modal></playlist-create-modal>

        <slot/>

        <main-footer/>

        <player/>

        <playlist-album-modal></playlist-album-modal>

        <confirm-modal></confirm-modal>

        <playlist-create-song-modal></playlist-create-song-modal>

        <playlist-create-album-modal></playlist-create-album-modal>

        <playlist-song-modal></playlist-song-modal>

        <modal></modal>

        <streaming-quality-modal/>

        <buy-modal/>

        <buy-album-modal/>

        <subscribe-modal/>

        <insufficient-to-subscribe-modal/>

        <ads-modal :ads="ads"></ads-modal>

    </div>
</template>

