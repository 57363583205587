<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'
    
    export default {
        name: 'Live',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'TV',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            video:null,
            data:[],
            src:null,
            flag:true,
        }),
        destroyed() {
            this.video.pause();
            this.$nextTick(() => {
                $('.player_mid').css('display','block');
            });
        },
        computed: {
        },
        mounted() {
            let self=this;
            this.$nextTick(() => {
                $('.player_mid').css('display','none');
            });
        },

        /**
         * Created the component.
         */
        created() {
            this.fetchLiveData();
        },


        methods: {
            /**
             *  Get the Live Data.
             */
            async fetchLiveData() {
                axios.get('/api/v2/live').then((response) => {
                    this.data = response.data.data;
                    if(response.data.data!=null){
                        this.flag=false
                    }else{
                        $('.video-js').addClass('d-none');
                    }
                   
                    this.video = videojs('tv',{
                        controls: true,
                        liveui:true,
                        poster:'/images/social-cover-image-v4.png',
                        errorDisplay: false,
                    });
                    this.video.src({
                          src: this.data.url,
                          type: 'application/x-mpegURL',
                        });
                    
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <!----<div class="ms_content_wrapper ms_profile padder_top100">---->
        <div class="ms_content_wrapper ms_profile padder_top50">
            <main-header/>
            <div class="ms_featured_slider row">
                    <div class="col-lg-12">
                        <div class="ms_heading">
                            <h1>TV</h1>
                        </div>
                    </div>
                    <div class="col-md-10 col-12">
                        <video id="tv" style="width: 100%;" class="video-js vjs-default-skin vjs-16-9" controls>
                            <!-- <source src="https://pyawsayar.com/archives/538?fbclid=IwAR0EMdNWwqRcv_C3Gs4IvcjqebEOl5t8S2Cx33TnlLbvOfBMCewqIbPjbkE" type="application/x-mpegURL"></source> -->
                        </video>
                        <div class="d-block mt-4">
                            <div class="ms_heading">
                                <h1>Description</h1>
                            </div>
                            <p v-html="data.description"></p>
                        </div>
                    </div>
            </div>

        </div>

    </layout>
</template>
<style>
.video-js .vjs-big-play-button{
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
</style>
