import Vue from 'vue';
import CxltToastr from '../components/Toastr/index'

let config = {
    position: 'top right',
    showDuration: 1000,
    hideDuration: 1000,
    timeOut: 4000,
    progressBar: true,
    showMethod: 'bounce',
    preventDuplicates: true
};

Vue.use(CxltToastr, config);