<script type="text/ecmascript-6">
import Layout from '../../layouts/Index.vue'
import MainHeader from '../../layouts/Header.vue'

export default {
    name: 'login',

    components: {
        Layout,
        MainHeader,
    },

    metaInfo() {
        return {
            title: 'Login',
        }
    },

    /**
     * Injecting Parent Validator
     * Don't remove it.
     *
     * https://baianat.github.io/vee-validate/concepts/injections.html
     * https://vuejs.org/v2/api/#provide-inject
     */
    inject: [ '$validator' ],


    /**
     * The component's data.
     */
    data: () => ({
        action: 'login',
        email:'',
        password:'',
        remember:false,
    }),

    computed: {
        authErrors(){
            return this.$store.getters.authErrors;
        }
    },


    methods: {

        login: function () {
            this.$validator.validateAll().then((result) => {
                if (!result){
                    this.$toast.error({ title: 'Error', message: "Please, check your inputs." });
                    throw new Error("Please, check your inputs.");
                }else {
                    const { email, password, remember, action } = this;

                    this.$store.dispatch('authRequest', { email, password, remember, action })
                        .then(() => {
                            this.$router.push('/home')
                        }).catch(() => {
                            this.$setErrorsFromResponse(this.authErrors.all());
                    })
                }
            }).catch(() => {

            });
        }
    }
}
</script>

<template>
    <Layout>
        <main-header />
        <div class="login_modal">
            <div class="login_modal_card">
                <div class="login_modal_card_wrapper">
                    <div class="login_modal_card_left">
                        <div class="login_modal_card_left_content">
                            <h2>Melo To Your Heart</h2>
                            <p>
                                ဂီတအမျိုးအစားများစွာနဲ့ အနုပညာရှင်တွေရဲ့
                                တေးသီချင်းပေါင်းများစွာက သင့်အနားမှာ
                                အမြဲရှိနေမှာပါ။
                            </p>
                        </div>
                    </div>
                    <div class="login_modal_card_right">
                        <div class="login_modal_card_right_content">
                            <div class="login_wrapper">
                                <div>
                                    <img
                                        src="/images/melo_icon.png"
                                        alt="melo_logo"
                                        class="logo_img"
                                    />
                                    <p>Log In</p>
                                </div>
                            </div>
                            <div class="input_wrapper">
                                <router-link to="/login/phone" class="input_div input_phone"><i class="fa fa-phone fa-lg phone_logo" aria-hidden="true"></i><p>Login with Phone</p></router-link>
                                <a href="#" @click.prevent="logInWithGoogle('/')" class="input_div"><img src="/images/google.svg" class="google_logo"></img><p>Continue with Google</p></a>
                                <a href="#" @click.prevent="logInWithFacebook('/')" class="input_div input_fb"><img src="/images/facebook_logo.png" class="google_logo"></img><p>Continue with Facebook</p></a>
                            </div>

                            <div class="terms_policy">
                                <p>
                                    By Signing in, I agreed to the <span>Terms of Service</span>, <span>Melo UserTerms and Privacy Policy</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="ms_login">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col"></div>
                        <div class="col-md-9">
                            <div class="ms_register_img">
                                <img src="/images/register_img.png" alt="" class="img-fluid" />
                            </div>
                            <div class="ms_register_form">
                                <h2>login / Sign in</h2>
                                <div class="social-ors">
                                    <div class="social_buttn">
                                        <router-link to="/login/phone" class="facebook_login"> <i class="fa fa-phone fa-lg" style="padding: 10px;" aria-hidden="true"></i> Sign In With Phone</router-link>
                                    </div>
                                    <div class="social_button">
                                        <a href="#" @click.prevent="logInWithFacebook('/')" class="facebook_login"><img src="/images/facebook_logo.png" width="30px" height="100%" class="img-fluid"><span style="padding-left:10px">Sign in with Facebook</span></a>
                                    </div>
                                    <div class="social_button">
                                        <a href="#" @click.prevent="logInWithGoogle('/')" class="facebook_login"><img src="/images/btn_google_signin.png" width="30px%" height="100%" class="img-fluid"/><span style="padding-left:10px">Sign in with Google</span></a>
                                    </div>
                                </div>

                                <form method="POST" @submit.prevent="login()" style="padding-top: 20px;">
                                    <div class="form-group">
                                        <input type="text" v-model="email" v-validate="'required|email'" name="email" placeholder="Enter Your Email" :class="hasError('email')">
                                        <span class="form_icon"><i class="fa_icon form-envelope" aria-hidden="true"></i></span>
                                        <span v-show="errors.has('email')" class="invalid-feedback text-left text-white" role="alert">
                                            <strong>{{ errors.first('email') }}</strong>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <input type="password" v-model="password" v-validate="'required'" name="password" placeholder="Enter Password" :class="hasError('password')">
                                        <span class="form_icon"><i class="fa_icon form-lock" aria-hidden="true"></i></span>
                                        <span v-show="errors.has('password')" class="invalid-feedback text-left text-white" role="alert">
                                            <strong>{{ errors.first('password') }}</strong>
                                        </span>
                                    </div>

                                    <div class="remember_checkbox">
                                        <label>Keep me signed in
                                            <input type="checkbox" value="remember" v-model="remember">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <button type="submit" class="ms_btn">login now</button>
                                </form>

                                <div class="popup_forgot">
                                    <a href="/password/reset">Forgot Password ?</a>
                                </div>
                                <p>Don't Have An Account? <router-link to="/register" class="ms_modal1 hideCurrentModel">register here</router-link></p>
                            </div>
                        </div>
                        <div class="col"></div>

                        <br>

                    </div>
                </div>
            </div> -->
    </Layout>
</template>

<style scoped>
p {
    margin: 0;
}
.login_modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    /*This is not the correct way to set the z-index but I have to due to previous developers */
    z-index: 1000;
}
.login_modal_card {
    width: 80%;
    height: 600px;
    background-color: #22222d;
    border-radius: 1rem;
}
.login_modal_card_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}
.login_modal_card_left {
    width: 50%;
    background-image: url(/images/login_modal_left_bg.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_modal_card_left h2 {
    color: white !important;
    font-size: 2.5rem;
}
.login_modal_card_left_content {
    padding: 0 5rem 0px 5rem;
}
.login_modal_card_left_content p {
    color: white;
    margin-top: 2rem;
}

.login_modal_card_right {
    width: 50%;
    display: flex;
    padding: 0 3.8rem 0 3.8rem;
    justify-content: center;
    align-items: center;
}

.login_modal_card_right_content {
    width: 100%;
}

.logo_img {
    width: 3rem;
    height: 3rem;
    margin: 0 auto;
}
.login_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.login_wrapper div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login_wrapper p {
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 1rem;
    color: #ffffff;
}
.google_logo {
    width: 2rem;
    height: 2rem;
}

.phone_logo {
    width: 2rem;
    height: 2rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input_wrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.input_div {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem 0.5rem 2.3rem;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}

.router_link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.input_div:hover {
    opacity: 0.85;
}

.input_div p {
    margin: 0 auto;
    font-size: 1.05rem;
    color: #000
}

.input_phone {
    background-color: transparent;
    border: 1px solid #fff;
}

.input_fb {
    background-color: #1877f2;
}

.input_fb p {
    color: #fff;
}

.input_phone p {
    color: #808080;
}

.terms_policy {
    padding: 0rem 1rem 0rem 1rem;
    margin-top: 2rem;
}
.terms_policy p {
    color: #fff;
    font-size: 1rem;
    text-align: center;
}

.terms_policy p span {
    color: #ff2150;
}

@media screen and (max-width: 1000px) {
   .login_modal_card_left {
        display: none;
   }
   .login_modal_card_right {
        width: 100%;
        padding: 0 2rem 0 2rem;
    }
}
</style>
