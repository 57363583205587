<script type="text/ecmascript-6">
import Layout from '../../layouts/Index'
import MainHeader from '../../layouts/Header'
import Sliders from './Slider';
import Ads from "./Ads";
import Recents from "./RecentPlay";
import Sections from "./Sections";
import Sectionstwo from "./Sections";
import { mapGetters } from 'vuex';

export default {
    name: 'home',

    metaInfo: {
        title: 'Melo Music'
    },

    data: () => ({
        data: null,
        loading: true,
    }),

    computed: {
        ...mapGetters({
            isAuthenticated: 'isAuthenticated'
        }),

    },

    components: {
        Ads,
        Layout,
        MainHeader,
        Sliders,
        Recents,
        Sections,
        Sectionstwo
    },
    mounted() {
        this.getHome()
    },

    methods: {
        getHome() {
            console.log("calling home api")
            axios.get('/api/v2/home').then((response) => {
                this.data = response.data;
                console.log("data: " + response.data)
                this.loading = false;
            }).catch((error) => {
                console.log(error, "error data fetching")
                this.loading = false;
            });
        },

    }


}
</script>

<template>
    <layout>

        <div class="ms_content_wrapper ms_profile">

            <main-header/>

            <div class="header_padder_top"></div>

            <div v-show="!loading">
                <div v-for="(value,key) in data">
                    <component v-bind:is="key" :data="value"></component>
                </div>
            </div>
            <div style="height:100vh;align-items: center;display: flex;margin-top: -10%;" v-show="loading">
                <div class="more_loading">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>
            </div>
        </div>


    </layout>

</template>
