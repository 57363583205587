<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'edit-profile',

        components: {
            Layout,
            MainHeader,
        },
        data: () => ({
            image:null,
        }),

        metaInfo() {
            return {
                title: 'Edit Profile',
            }
        },

        /**
         * Injecting Parent Validator
         * Don't remove it.
         *
         * https://baianat.github.io/vee-validate/concepts/injections.html
         * https://vuejs.org/v2/api/#provide-inject
         */
        inject: [ '$validator' ],


        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                user : 'getProfile',
            }),
        },


        methods: {
            editProfile: function () {
                this.$validator.validateAll().then((result) => {
                    if (!result){
                        this.$toast.error({ title: 'Error', message: "Please, check your inputs." });
                        throw new Error("Please, check your inputs.");
                    }else {

                        let data=new FormData();
                        data.append('name',this.user.name);
                        data.append('phone',this.user.phone);
                        data.append('streaming_quality',this.user.streaming_quality);
                        if(this.image){
                            data.append('avatar',this.image);
                        }

                        axios.post('/api/mmn/edit-account-info', data).then((response) => {
                            this.$toast.success({ title : 'Success', message: response.data.message});
                            this.$store.dispatch('userRequest');
                            this.$router.push('/user/profile');
                        }).catch((error) => {
                            if (error.response.status === 422) {
                                this.$setErrorsFromResponse(error.response.data);
                            }
                        })
                    }
                }).catch(() => {

                });
            },
            changeImage(e)
            {
                this.image=event.target.files[0];
                this.user.image=URL.createObjectURL(this.image);
            },
        }
    }
</script>

<template>
    <layout>

        <div class="padder_top80 ms_profile">

            <main-header/>

            <!----Edit Profile Wrapper Start---->
            <div class="ms_profile_wrapper">
                <h1>Edit Your Account Information</h1>
                <div class="ms_profile_box">

                    <form method="POST" @submit.prevent="editProfile()" enctype="multipart/form-data">
                        <div class="ms_pro_form">
                            <div class="form-group text-center">
                                <img :src="user.image? user.image : '/img/avatar/admin.jpg'" class="rounded-circle" width="200" height="200">
                                <input type="file" name="avatar" v-on:change="changeImage($event)" ref="inputFIle" style="display:none;">
                                <div class="d-block"> 
                                    <button type="button" class="btn mt-2 btn-success btn-sm" @click="$refs.inputFIle.click()">Select Image...</button>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Your Name *</label>
                                <input type="text" name="name" v-model="user.name" placeholder="Your Name" :class="hasError('name')">
                                <span v-show="errors.has('name')" class="invalid-feedback text-left text-white" role="alert">
                                            <strong>{{ errors.first('name') }}</strong>
                                        </span>
                            </div>
                            <div class="form-group">
                                <label>Your Phone </label>
                                <input type="text" name="phone" v-model="user.phone" placeholder="Your Phone" :class="hasError('phone')">
                                <span v-show="errors.has('phone')" class="invalid-feedback text-left text-white" role="alert">
                                            <strong>{{ errors.first('phone') }}</strong>
                                        </span>
                            </div>

                            <div class="form-group">
                                <label for="quality">Streaming Quality </label>
                                <select name="streaming_quality" v-model="user.streaming_quality" v-validate="'required'" id="quality" :class="hasError('streaming_quality')">
                                    <option value="256">High Quality</option>
                                    <option value="128">Medium Quality</option>
                                    <option value="96">Low Quality</option>
                                </select>
                                <span v-show="errors.has('streaming_quality')" class="invalid-feedback text-left text-white" role="alert">
                                            <strong>{{ errors.first('streaming_quality') }}</strong>
                                        </span>
                            </div>

                            <div class="pro-form-btn text-center marger_top15">
                                <button type="submit" class="ms_btn" style="border:0px;">save</button>
                                <router-link to="/user/profile" class="ms_btn">cancel</router-link>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>


    </layout>
</template>
