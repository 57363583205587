<script type="text/ecmascript-6">
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'
    import CommentShow from '../components/Component/Comment.vue';
    import {mapGetters} from 'vuex';
    
    export default {
        name: 'MeloLive',

        components: {
            Layout,
            MainHeader,
            CommentShow,
        },

        metaInfo() {
            return {
                title: 'Live',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            video:null,
            data:[],
            src:null,
            flag:true,
            lives:[],
            comments:[],
            comment_loading:true,
            comment_body:null,
        }),
        destroyed() {
            this.video.pause();
            this.$nextTick(() => {
                $('.player_mid').css('display','block');
            });
        },
        computed: {
            ...mapGetters({
                user : 'getProfile',
                isAuthenticated: 'isAuthenticated',
            }),
        },
        mounted() {
            let self=this;
            this.$nextTick(() => {
                $('.player_mid').css('display','none');
            });
        },

        /**
         * Created the component.
         */
        created() {
            this.fetchLiveData();
            this.fetchLivesData();
            this.fetchComment();
        },


        methods: {
            /**
             *  Get the Live Data.
             */
            async fetchLivesData() {
                axios.get(`/api/v2/live-melo`).then((response) => {
                    this.lives = response.data.data;
                   
                    this.liveLoading = false;
                }).catch((error) => {
                    this.liveLoading = false;
                });
            },

            async fetchLiveData() {
                axios.get(`/api/v2/live-melo/${this.$route.params.id}`).then((response) => {
                    this.data = response.data;
                    if(response.data!=null){
                        this.flag=false
                    }else{
                        $('.video-js').addClass('d-none');
                    }
                   
                    this.video = videojs('live',{
                        controls: true,
                        liveui:true,
                        poster:'/images/social-cover-image-v4.png',
                        errorDisplay: false,
                    });
                    this.video.src({
                      src: this.data.url,
                      type: 'application/x-mpegURL'
                    });
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

            async fetchComment() {
                axios.get(`/api/v2/comment/${this.$route.params.id}/live`).then((response) => {
                    this.comments=response.data.data;
                    this.comment_loading=false;
                }).catch((error) => {
                    this.comment_loading=false;
                });
            },

            submitForm(){
                if(this.isAuthenticated){
                    let data = {
                        'comment_body':this.comment_body,
                        'live_id':this.$route.params.id
                    }
                    axios.post(`/api/v2/comment`,data).then((response) => {
                        this.comment_body=null;
                        this.fetchComment();
                    }).catch((error) => {
                    });
                }
            },

        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <!----<div class="ms_content_wrapper ms_profile padder_top100">---->
        <div class="ms_content_wrapper ms_profile padder_top50">
            <main-header/>
            <div class="ms_featured_slider row">
                    <div class="col-lg-12">
                        <div class="ms_heading">
                            <h1>Live</h1>
                        </div>
                    </div>
                    <div class="col-md-8 col-12">
                        <video id="live" style="width: 100%;" class="video-js vjs-default-skin vjs-16-9" controls>
                            <!-- <source src="https://pyawsayar.com/archives/538?fbclid=IwAR0EMdNWwqRcv_C3Gs4IvcjqebEOl5t8S2Cx33TnlLbvOfBMCewqIbPjbkE" type="application/x-mpegURL"></source> -->
                        </video>
                        <div class="d-block mt-4" style="border-bottom:1px solid">
                            <h5 style="font-size: 18px;">{{data.name}}</h5>
                            <p>{{moment(String(data.created_at)).format('MMMM DD, YYYY')}}</p>
                        </div>
                        <div class="d-block mt-4" style="border-bottom:1px solid">
                            <div class="">
                                <h5 style="font-size: 18px;">Description</h5>
                            </div>
                            <p v-html="data.description"></p>
                        </div>
                        <div class="row d-block mt-4" id="comments">
                            <div class="d-block">
                                <div class="col-lg-9 col-md-12 col-sm-12 col-12 pt-1 pb-3 form-inline">
                                    <div style="width:10%">
                                        <img :src="user.image? user.image:'/images/svg/Profile.svg'" class="img-fluid rounded-circle" width="50">
                                    </div>
                                    <div class="pl-2 pt-3" style="width:90%">
                                        <form @submit.prevent="submitForm()">
                                            <textarea v-model="comment_body" class="comment-input d-block" placeholder="Add a write comment" :readonly="!isAuthenticated" style="height: 35px"></textarea>
                                            <input type="text" v-model="comment_body" class="comment-input d-none" placeholder="Add a write comment" :readonly="!isAuthenticated">
                                            <a href="javascript:;" :class="{'disabled': !isAuthenticated}" @click.prevent="submitForm()" class="btn video-btn float-right mt-2 btn-secondary" >Comment</a>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="d-block comments-show">
                                <div class="col-lg-9 col-md-12 col-sm-12 col-12 pt-1 pb-3">
                                    <div v-for="(comment,key) in comments" >
                                        <comment-show :parent="comment.id" :comments="comment" :fetchComment="fetchComment" type="live"></comment-show>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="live-card d-flex mb-2" v-for="video in lives" v-if="video.id != $route.params.id">
                            <div class="col-6">
                                <img :src="video.image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/social-cover-image-v4.png';">
                            </div>
                            <div class="col-6">
                                <div class="live-text-box">
                                    <h6>{{video.name}}</h6>
                                    <div class="mt-2">
                                        <router-link :to="{ name : 'live', params : { id : video.id } }" class="btn btn-sm btn-outline-danger">Live Now</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

        </div>

    </layout>
</template>
<style>
.video-js .vjs-big-play-button{
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
#comments .comment-input{
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: 3px;
    border-radius: 0;
    background: transparent;
    width: 100%;
    color: #fff;
}
</style>
