export const phone = {
    getMessage() {
        return 'Only plus-sign(beginning), numbers, dash, space allowed.';
    },
    validate(value) {
        return /^(\+?)[\d\s-]+$/.test(value);
    }
};

export const numeric = {
    getMessage() {
        return 'Only plus-sign(beginning), numbers, dash, space allowed.';
    },
    validate(value) {
        return /^[-+]?[0-9]+$/.test(value);
    }
};

export const number = {
    getMessage() {
        return 'Only numbers without space.';
    },
    validate(value) {
        return /^[0-9]{0,}$/.test(value);
    }
};

export const letter = {
    getMessage() {
        return 'Only letter';
    },
    validate(value) {
        return /^[a-zA-Z- _]+$/.test(value);
    }
};
