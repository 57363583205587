
<script type="text/ecmascript-6">
    import Layout from '../../layouts/Index.vue'
    import MainHeader from '../../layouts/Header.vue'
    import {bus} from "../../app";

    export default {
        name: "MyPlaylists",
        components:{
            Layout,
            MainHeader
        },
        metaInfo() {
            return {
                title: 'MyPlaylist',
            }
        },
        data:()=>({
            my_playlist:[],
            follow_playlist:[],
            loading:true,
            res_data:null,
            status:false,
        }),
        created(){
            this.fetchMyPlaylist();
            this.fetchFollowingPlaylist();
            this.getEmitData();
        },
        methods:{
            /**
             *  Get the playlist.
             */
            fetchMyPlaylist() {
                axios.get('/api/mmn/my-play-lists').then((response) => {
                    this.my_playlist = response.data.data;
                    this.loading = false;
                    this.status=response.data.status;
                }).catch((error) => {
                    this.loading = false;
                });
            },
            fetchFollowingPlaylist(){
                axios.get('/api/v2/me/followed-playlists').then((response) => {
                    this.follow_playlist = response.data.data;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },
            getCreateForm()
            {
                $('#playlistCreateModal').modal('show');
            },
            getEmitData()
            {
                bus.$on("my_playlist",(data)=>{
                    this.my_playlist=data;
                })
            }
        }

    }
</script>

<template>
    <layout>
        <!---Main Content Start--->
        <div class="ms_content_wrapper ms_genres_single ms_profile padder_top80">

            <main-header/>

            <div class="ms_fea_album_slider padder_bottom30">
                <div class="row" id="list-show">
                    <div class="col-lg-12">
                        <div class="ms_heading">
                            <h1 style="width:auto;">My Playlist</h1>
                            <div class="pull-right">
                                <a href="#" @click.prevent="getCreateForm()" style="font-size: 15px; color: #ed1557; border: 2px solid #ed1557;padding:5px 10px; border-radius: 10px; opacity: 0.7;margin-bottom: 5px;display: inline-block;">
                                    <span class="fa fa-plus"></span> New Playlist
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="status" id="show_playlist" class="row ml-2 mr-2">
                    <div v-for="item in my_playlist" class="cs-lg-col-8 cs-md-col-5 col-md-2 col-6">
                        <router-link tag="a" :to="{ name: 'my-play-list', params: { id: item.uuid }}" :title="item.name">
                        <div class="ms_genres_box">
                            <div class="ms_rcnt_box_img">
                                <img :src="item.image" alt="" onError="this.onerror=null;this.src='/images/album/collection_playlist.png';">
                                <div class="ms_main_overlay">
                                    <div class="ms_box_overlay"></div>
                                    <div class="ms_play_icon">
                                        <a href="javascript:;" @click.prevent="playUserPlaylistSong(item.uuid)">
                                            <img src="/images/svg/play2.svg" alt="">
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="ms_rcnt_box_text">
                                <h3><router-link tag="a" :to="{ name: 'my-play-list', params: { id: item.uuid }}">{{ item.name }}</router-link></h3>
                                <p style="font-size:12px;">
                                {{item.songs.length}} Song<span v-if="item.songs.length > 1">s</span>
                                </p>
                            </div>
                        </div>
                        </router-link>
                    </div>
                </div>
                <div v-else>
                    <h4 class="text-center">No Playlist</h4>
                </div>
            </div>
            <div class="ms_fea_album_slider padder_bottom30">
                <div class="row" id="list-show">
                    <div class="col-lg-12">
                        <div class="ms_heading">
                            <h1>Follow Playlist</h1>
                        </div>
                    </div>
                </div>
                <div v-if="follow_playlist.length > 0" id="show_playlist" class="row ml-2 mr-2">
                    <div v-for="item in follow_playlist" class="cs-lg-col-8 col-md-2 col-6">
                        <router-link tag="a" :to="{ name: 'playlist-detail', params: { id: item.uuid }}" :title="item.name">
                        <div class="ms_genres_box">
                            <div class="ms_rcnt_box_img">
                                <img :src="item.image" alt="" onError="this.onerror=null;this.src='/images/album/collection_playlist.png';">
                                <div class="ms_main_overlay">
                                    <div class="ms_box_overlay"></div>
                                </div>
                            </div>
                            <div class="ms_rcnt_box_text">
                                <h3><router-link tag="a" :to="{ name: 'playlist-detail', params: { id: item.uuid }}">{{ item.name_mm }}</router-link></h3>
                            </div>
                        </div>
                        </router-link>
                    </div>
                </div>
                <div v-else>
                    <h4 class="text-center">No Followed Playlist</h4>
                </div>
            </div>
        </div>
    </layout>
</template>


<style scoped>

</style>