<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'albums',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                // if no subcomponents specify a metaInfo.title, this title will be used
                title: 'Albums',
            }
        },


        /**
         * The component's data.
         */
        data: () => ({
            albums: [],
            loading: true,
            links: {
                first: null,
                last: null,
                next: "/api/mmn/albums?page=1",
                prev: null
            },
            meta: {
                current_page: 1,
                from: 1,
                last_page: 1,
                per_page: 1,
                to: 1,
                total: 1,
            },
        }),


        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                isAuthenticated: 'isAuthenticated'
            }),
        },


        /**
         * Created the component.
         */
        created() {
            this.fetchAlbums();
        },


        methods: {
            /**
             *  Get albums.
             */
            async fetchAlbums() {
                if (this.loading === true && this.links.first) {
                    this.$toast.warn({ title: 'Warning', message: 'Loading ...' });
                    return;
                }

                if (this.links.next === null) {
                    return;
                }

                this.loading = true;

                axios.get(this.links.next).then((response) => {
                    this.albums.push(...response.data.data);
                    this.links = response.data.links;
                    this.meta = response.data.meta;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },
        }
    }
</script>

<template>
    <layout>

        <!---Main Content Start--->
        <div class="ms_content_wrapper ms_profile padder_top50">

            <main-header/>

            <div class="ms_fea_album_slider padder_bottom30" id="albums">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="ms_heading">
                            <h1>Albums</h1>
                        </div>
                    </div>

                    <div v-for="album in albums" class="cs-lg-col-8 cs-md-col-5 col-md-2 col-6">
                        <div class="ms_rcnt_box marger_bottom30">
                            <div class="ms_rcnt_box_img">
                                <img :src="album.image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/album/Album.png';">
                                <div class="ms_main_overlay">
                                    <router-link tag="a" :to="{ name: 'Album', params: { uuid: album.uuid }}" :title="album.name_mm">
                                        <div class="ms_box_overlay"></div>
                                    </router-link>

                                    <div class="ms_more_icon" data-toggle="popup">
                                        <img src="/images/svg/more.svg" alt="">
                                    </div>

                                    <div class="ms_play_icon">
                                        <a href="javascript:;" @click.prevent="playAllSong(album.uuid)">
                                            <img src="/images/svg/play2.svg" alt="">
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <ul class="more_option">
                                <template v-if="isAuthenticated">
                                    <li><a href="#" @click.prevent="syncAlbumFavourite(album.uuid)"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites </a></li>
                                    <li v-if="album.is_store && album.price > 0 && !album.is_bought">
                                        <a href="#" @click.prevent="buyAlbum(album.uuid)"><span class="opt_icon"><span class="fa fa-shopping-cart"></span></span>{{ album.price.toLocaleString() }} Kyat</a>
                                    </li>
                                </template>

                                <template v-else>
                                    <li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites </a></li>
                                    <li v-if="album.is_store && album.price > 0">
                                        <a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-shopping-cart"></span></span>{{ album.price.toLocaleString() }} Kyat</a>
                                    </li>
                                </template>

                                <li><a :href="shareFacebookLink(album.image,'albums',album.name_mm,album.id,album.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share to facebook</a></li>
                                <li><a :href="shareTwitterLink(album.image,'albums',album.name_mm,album.id,album.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share to twitter</a></li>
                                <li><a href="#" @click.prevent="copyText('album/'+album.uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                            </ul>
                            <div class="ms_rcnt_box_text">
                                <h3><router-link tag="a" :to="{ name: 'Album', params: { uuid: album.uuid }}">{{ album.name_mm }}</router-link></h3>
                                <p>
                                    <template v-if="album.artists.length > 4">
                                        <a href="javascript:;">Various Artists</a>
                                    </template>

                                    <template v-else>
                                        <template v-for="(artist, index1) in album.artists">
                                            <router-link :to="{ name : 'artist', params:{ id : artist.uuid }}">{{ artist.name_eng }} <span v-if="isNotLast(album.artists, index1)">, </span></router-link>
                                        </template>
                                    </template>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="more_loading" v-show="loading">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>
            </div>
        </div>

        <div class="ms_view_more padder_bottom20" v-show="meta.current_page !== meta.last_page">
            <a href="#" @click.prevent="fetchAlbums()" class="ms_btn">view more</a>
        </div>

    </layout>
</template>
