<script type="text/ecmascript-6">

    export default {

        /**
         * The component's data.
         */
        data: () => ({
            ads : '',
            loading: false,
        }),


        /**
         * Created the component.
         */
        created() {
            this.getAds();
        },


        methods: {
            /**
             *  Get the slider.
             */
            getAds() {
                axios.get('/api/mmn/simple-ads').then((response) => {
                    this.ads = response.data;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

        }
    }
</script>

<template>
    <div class="ms_advr_wrapper ms_advr2">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <a :href="ads.link" v-show="!loading"><img :src="ads.image" alt="" class="img-fluid"></a>
                </div>
            </div>
        </div>
    </div>
</template>
