<script type="text/ecmascript-6">
    import Layout from '../../../layouts/Index.vue'
    import MainHeader from '../../../layouts/Header.vue'

    export default {
        name: 'PasswordEmail',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Email',
            }
        },

        /**
         * Injecting Parent Validator
         * Don't remove it.
         *
         * https://baianat.github.io/vee-validate/concepts/injections.html
         * https://vuejs.org/v2/api/#provide-inject
         */
        inject: [ '$validator' ],


        /**
         * The component's data.
         */
        data: () => ({
            'disableSubmit' : false,
            'email':'',
            'seconds': 5
        }),

        computed: {
            authErrors(){
                return this.$store.getters.authErrors;
            },
            progress:function(){
                return 'width: ' + ( 20*Math.abs(5-this.seconds) ) + '%';
            }
        },


        /**
         * Created the component.
         */
        created() {

        },


        methods: {

            sendPasswordResetEmail: function () {
                this.$validator.validateAll().then((result) => {
                    if (!result){
                        this.$toast.error({ title: 'Error', message: "Please, check your inputs." });
                        throw new Error("Please, check your inputs.");
                    }else {
                        this.disableSubmit = true;

                        axios.post('/api/mmn/password/email', {'email': this.email} )
                            .then((resp) => {
                                this.countdownRedirect();
                            })
                            .catch(()=>{
                                this.disableSubmit = false;
                                this.seconds = 5;
                            })
                    }
                }).catch(() => {

                });
            },

            countdownRedirect: function () {
                setInterval(()=>{
                    this.seconds = this.seconds-1;
                    if (this.seconds === 0) {
                        this.$router.push('/');
                    }
                }, 1000);

            },
        }
    }
</script>

<template>
    <layout>

        <div class="padder_top80 ms_profile">

            <main-header/>
            <div class="ms_profile_wrapper">
                <h1>Reset Password</h1>
                <div class="ms_profile_box">

                    <div class="ms_pro_form">

                        <form method="POST" @submit.prevent="sendPasswordResetEmail()" v-if="seconds > 4">
                            <div class="form-group">
                                <label for="email">E-Mail Address</label>
                                <input type="text" v-model="email" v-validate="'required|email'" id="email" name="email" placeholder="Enter Your Email" :class="hasError('email')">
                                <span v-show="errors.has('email')" class="invalid-feedback text-left text-white" role="alert">
                                    <strong>{{ errors.first('email') }}</strong>
                                </span>
                            </div>

                            <div class="col-md-12">
                                <button type="submit" :disabled="disableSubmit" class="ms_btn"> Reset</button>
                            </div>
                        </form>

                        <template v-else>
                            <h1 class=" mt-5">We have e-mailed your password reset link!</h1>
                            <h1>Redirecting in {{seconds}} seconds</h1>
                        </template>

                    </div>
                </div>
            </div>

        </div>


    </layout>
</template>
