<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'favourite-songs',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Favourite Songs',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            songs_loading: true,
            songs: [],
        }),


        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                isPlay: 'isPlay',
                playerCurrentTrack: 'playerCurrentTrack',
                isAuthenticated: 'isAuthenticated'
            }),
        },


        /**
         * Created the component.
         */
        created() {
            this.fetchSongs();
        },


        methods: {
            /**
             *  Get the favourite songs.
             */
            async fetchSongs() {
                this.songs_loading=true;
                axios.get(`/api/mmn/favourite-songs`).then((response) => {
                    this.songs = response.data.data;
                    this.songs_loading = false;
                }).catch((error) => {
                    this.songs_loading = false;
                });
            },

            favouriteSong(song_id, index) {
                axios.post(`/api/mmn/favourite/song/${song_id}`).then((response) => {
                    this.songs.splice(index, 1)
                }).catch((error) => {

                })
            }

        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <div class="ms_album_single_wrapper ms_profile">

            <main-header/>

            <!----Free Download Css Start---->
            <div class="padder_bottom30">
                <div class="ms_heading text-center">
                    <h1>Your Favourites Songs</h1>
                </div>
                <div class="album-track-wrapper album_inner_list" v-show="!songs_loading">
                    <div class="album_list_wrapper album_track_content">

                        <ul class="track_ul" v-if="songs.length" v-for="(song, key) in songs" :class="isPlay && playerCurrentTrack.id === song.id ? 'play_active_song' : ''">
                            <li style="padding-top: 10px;top:-5px;">
                                <a href="javascript:;" class="mmn-play" @click="handleClickTrack(song, songs)">
                                    <span class="play_no" style="position: absolute;top: 0;">{{ $_.padStart(key + 1, 0) }}</span>
                                    <div class="ms_bars for_track_ul" v-if="isPlay && playerCurrentTrack.id === song.id">
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                        <div class="bar"></div>
                                    </div>
                                    <span class="play_hover" v-else></span>
                                </a>
                            </li>

                            <li><a href="javascript:;" class="mmn-play" @click="handleClickTrack(song, songs)"><span class="song_title">{{ song.name_mm }}</span>
                                <p style="font-size:10px;margin-bottom: 5px;">
                                    <a v-if="song.artists.length > 3" href="javascript:">
                                        <span>{{ song.artists[0].name_eng }}, {{ song.artists[1].name_eng }}, ...</span>
                                    </a>
                                    <template v-for="(artist, artist_index) in song.artists" v-else>
                                        <router-link :to="{ name: 'artist', params : {id : artist.uuid}}">{{ artist.name_eng }}
                                            <span v-if="isNotLast(song.artists, artist_index)">, </span>
                                        </router-link>
                                    </template>
                                    <template v-if="song.feats.length">
                                        <a href="javascript:">, ft : </a>
                                        <template v-for="(feat, feat_index) in song.feats">
                                            <router-link :to="{ name : 'artist', params:{ id : feat.uuid }}">{{ feat.name_eng }}
                                                <span v-if="isNotLast(song.feats, feat_index)">, </span>
                                            </router-link>
                                        </template>
                                    </template>
                                </p>
                            </a></li>
                            <li style="top: -10px;"><a href="#">{{ song.duration }}</a></li>
                            <li class="text-center" style="top: -10px;">
                                <a href="#" @click.prevent="saveSong(song.id)"><span>
                                    <img src="/images/svg/add_q.svg" alt="" style="width:15px;">
                                </span></a>
                            </li>
                            <li class="text-center" style="top: -10px;">
                                <a href="javascript:;" @click.prevent="favouriteSong(song.id, key)">
                                    <span class="fa fa-trash"></span></a>
                            </li>
                            <li class="text-center ms_more_icon" style="top: -10px;"><a href="javascript:"><span class="fa icon_menu_more"></span></a>
                                <ul class="more_option album_more">
                                    <li style="width: 100% !important ;padding: 10px 10px;"><router-link :to="{ name: 'Album', params: { uuid: song.album_uuid }}"><span class="opt_icon"><img src="/images/svg/go_to_album.svg" style="width:18px;margin-right:7px;"/></span>Go To Album </router-link></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongFacebook(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongTwitter(song.share_url)"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                    <li style="width: 100% !important;padding: 10px 10px;"><a href="#" @click.prevent="copyText(song.share_url)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                </ul>
                            </li>
                        </ul>

                        <ul v-else>
                            <li class="w-100 text-center"><a href="#">No Songs</a></li>
                        </ul>
                    </div>

                </div>
                <div class="more_loading" v-show="songs_loading">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>

            </div>

        </div>


    </layout>
</template>

<style>
    .ms_free_download.ms_purchase_wrapper .album_list_wrapper>ul>li {
        width: 16%;
    }
</style>
