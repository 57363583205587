import { render, staticRenderFns } from "./Payment.vue?vue&type=template&id=fa8b7854&scoped=true&"
import script from "./Payment.vue?vue&type=script&lang=js&"
export * from "./Payment.vue?vue&type=script&lang=js&"
import style0 from "./Payment.vue?vue&type=style&index=0&id=fa8b7854&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa8b7854",
  null
  
)

export default component.exports