<script type="text/ecmascript-6">
import { mapGetters } from 'vuex';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import Layout from '../layouts/Index.vue'
import MainHeader from '../layouts/Header.vue'

export default {
    name: 'song',

    components: {
        swiper,
        swiperSlide,
        Layout,
        MainHeader
    },

    metaInfo() {
        return {
            // if no subcomponents specify a metaInfo.title, this title will be used
            title: this.album.name_eng,
        }
    },

    /**
     * The component's data.
     */
    data: () => ({
        album_loading: true,
        related_album_loading: true,
        album_image_loading: true,
        album: {
            songs: [],
            artists: [],
            feats: [],
            view_count: 0,
            album_name: '',
            album_eng: '',
            image: '',
            sub_menu: {
                free: false,
                streaming: false,
                store: true,
            }
        },
        swiperOption: {
            slidesPerView: 8,
            slidesPerColumn: 1,
            spaceBetween: 25,
            loop: true,
            speed: 1500,
            navigation: {
                nextEl: '.swiper-button-next-related',
                prevEl: '.swiper-button-next-related',
            },
            breakpoints: {
                1800: {
                    slidesPerView: 8,
                },
                1400: {
                    slidesPerView: 8,
                },
                992: {
                    slidesPerView: 8,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                }
            },
        },
        relatedAlbums: [],
    }),


    /**
     * Computed these properties.
     */
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper
        },
        ...mapGetters({
            isPlay: 'isPlay',
            playerCurrentTrack: 'playerCurrentTrack',
            isAuthenticated: 'isAuthenticated',
        }),

        totalPlay: function() {
            let sum = 0;
            return this.album.songs.reduce((sum, song) => sum + song.play_count, 0);
        },
    },

    /**
     * Created the component.
     */
    created() {
        this.fetchSong();
        this.fetchRelatedAlbum();
    },


    methods: {
        /**
         * Get the album.
         */
        fetchSong() {
            axios.get('/api/mmn/album/' + this.$route.params.uuid).then((response) => {
                this.album = response.data.data;
                if (this.album.songs && this.album.songs.length > 0 && this.$route.query.autoplay === 'yes') {
                    this.handleClickTrack(this.album.songs[0], this.album.songs)
                }
                this.album_loading = false;
            }).catch((error) => {
                this.album_loading = false;
            });
        },

        /**
         * Get the related album.
         */
        fetchRelatedAlbum() {
            axios.get('/api/mmn/related_album/' + this.$route.params.uuid).then((response) => {
                this.relatedAlbums = response.data.data;
                this.related_album_loading = false;
            }).catch((error) => {
                this.related_album_loading = false;
            });
        },

        /**
         *  Like the album.
         */
        likeAlbum() {
            axios.post(`/api/mmn/like/album/${this.album.uuid}`).then((response) => {
                this.album.is_like = response.data.is_like;
            }).catch((error) => {

            })
        },

        /**
         *  Favourite the album.
         */
        favouriteAlbum() {
            axios.post(`/api/mmn/favourite-sync/album/${this.album.uuid}`).then((response) => {
                this.album.is_favourite = response.data.is_favourite;
            }).catch((error) => {

            })
        },

        /**
         *  Favourite the song.
         */
        favouriteSong(song, song_id) {
            axios.post(`/api/mmn/favourite/song/${song_id}`).then((response) => {
                song.is_favourite = response.data.is_favourite;
            }).catch((error) => {

            })
        },
        showPlaylistModal() {
            $('#playlistsModal').modal('show');
        },
        shareSongFacebook(url) {
            window.open(this.shareFacebookLink(url), '_blank');
        },
        shareSongTwitter(url) {
            window.open(this.shareTwitterLink(url), '_blank');
        }
    }
}

</script>
<style scoped>
    .dropdown-item {
    font-size: 14px;
    color: #777;
}
.dropdown-item span {
    margin-right: 5px;
}
</style>
<template>
    <layout>
        <!----Album Single Section Start---->
        <div class="ms_album_single_wrapper ms_profile">
            <main-header />
            <div class="album_single_data">
                <div class="album_single_img">
                    <div class="load-album-image" v-show="album_loading"></div>
                    <img :src="album.image" alt="" v-show="!album_loading" class="img-fluid" onError="this.onerror=null;this.src='/images/album/album.jpg';">
                </div>
                <div class="album_single_text">
                    <h2>{{ album.name_mm }}</h2>
                    <p class="singer_name">
                        <template v-if="album.artists.length > 5">
                            <a href="javascript:;">Various Artists</a>
                        </template>
                        <template v-else>
                            <template v-for="(artist, index1) in album.artists">
                                <router-link :to="{ name: 'artist', params: { id : artist.uuid }}">
                                    {{ artist.name_eng }}
                                    <span v-if="isNotLast(album.artists, index1)">, </span>
                                </router-link>
                            </template>
                        </template>
                        <template v-if="album.feats.length">
                            <br>
                            Feat:
                            <template v-for="(feat, index2) in album.feats">
                                <router-link :to="{ name: 'artist', params: { id : feat.uuid }}">
                                    {{ feat.name_eng }}
                                    <span v-if="isNotLast(album.feats, index2)">, </span>
                                </router-link>
                            </template>
                        </template>
                    </p>
                    <div class="album_feature">
                        <!--                        <a v-if="isAuthenticated" @click.prevent="likeAlbum()" href="#" id="album-like" style="font-size: 15px; color: #ed1557; border: 2px solid #ed1557;padding:5px; border-radius: 10px; opacity: 0.7;margin-bottom: 5px;display: inline-block;"><span class="fa fa-thumbs-up"></span> {{ album.is_like ? 'Liked' : 'Like' }}</a>-->
                        <!--                        <a v-else href="#" data-toggle="modal" data-target="#loginModal" style="font-size: 15px; color: #ed1557; border: 2px solid #ed1557;padding:5px; border-radius: 10px; opacity: 0.7;margin-bottom: 5px;display: inline-block;"><span class="fa fa-thumbs-up"></span> Like</a>-->
                        <a v-if="isAuthenticated" @click.prevent="favouriteAlbum()" href="#" id="album-favourite" style="font-size: 15px; color: #ed1557; border: 2px solid #ed1557;padding:5px; border-radius: 10px; opacity: 0.7;margin-bottom: 5px;display: inline-block;"><span :class="album.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span> {{ album.is_favourite ? 'Favourited' : 'Favourite' }}</a>
                        <a v-else href="#" data-toggle="modal" data-target="#loginModal" style="font-size: 15px; color: #ed1557; border: 2px solid #ed1557;padding:5px; border-radius: 10px; opacity: 0.7;margin-bottom: 5px;display: inline-block;"><span class="fa fa-heart-o"></span> Favourite</a>
                        <template v-if="isAuthenticated">
                            <a href="#" @click.prevent="saveAlbum(album.uuid)" style="font-size: 15px; color: #ed1557; border: 2px solid #ed1557;padding:5px; border-radius: 10px; opacity: 0.7;margin-bottom: 5px;display: inline-block;"><span class="fa fa-plus"></span>PlayList</a>
                        </template>
                        <template v-else>
                            <a href="#" data-toggle="modal" data-target="#loginModal" style="font-size: 15px; color: #ed1557; border: 2px solid #ed1557;padding:5px; border-radius: 10px; opacity: 0.7;margin-bottom: 5px;display: inline-block;"><span class="fa fa-plus"></span> PlayList</a>
                        </template>
                        <div class="btn-group dropright">
                            <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-size: 15px; color: #ed1557; border: 2px solid #ed1557;padding:5px; border-radius: 10px; opacity: 0.7; background: transparent;">
                                <span :class="'fa fa-share-alt'"></span> Share
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" :href="shareFacebookLink('/album/' + album.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a>
                                <a class="dropdown-item" :href="shareTwitterLink('/album/' + album.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a>
                                <a class="dropdown-item" href="#" @click.prevent="copyText('/album/' + album.uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a>
                            </div>
                        </div>
                        <a href="#" class="album_date">{{ album.songs.length }} song<span v-if="album.songs.length > 1">s</span></a>
                        <a href="#" class="album_date"><i class="fa fa-headphones"></i> - {{ (album.view_count + "" ).replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} </a>
                        <a href="#" class="album_date" v-show="album.release_date">Released - {{ album.release_date}}</a>
                        <a href="#" class="album_date" style="margin-top: 3px;">
                            <span><img src="/img/melomm_logo_st.png" class="img-fluid" style="width:30px;"></img></span>
                            <span v-if="album.is_store">Store</span>
                            <span v-if="album.is_streaming"> | Streaming</span>
                            <span v-if="album.is_free"> | Free</span>
                        </a>
                        <template v-if="album.is_store">
                            <a v-if="!isAuthenticated" href="#" data-toggle="modal" data-target="#loginModal" style="font-size: 15px; color: #ed1557; border: 2px solid #ed1557;padding:5px; border-radius: 10px; opacity: 0.7;"><span class="opt_icon"><span class="fa fa-shopping-cart"></span></span> {{ album.price.toLocaleString() }} Kyat</a>
                            <a v-else-if="!album.is_bought" href="#" @click.prevent="buyAlbum(album.uuid)" style="font-size: 15px; color: #ed1557; border: 2px solid #ed1557;padding:5px; border-radius: 10px; opacity: 0.7;">
                                <span class="opt_icon"><span class="fa fa-shopping-cart"></span></span> {{ album.price.toLocaleString() }} Kyat</a>
                        </template>
                    </div>
                </div>
            </div>
            <!----Song List---->
            <div class="album_inner_list">
                <div class="album_list_wrapper">
                    <ul class="album_list_name">
                        <li>#</li>
                        <li>Title</li>
                        <li>Artist</li>
                        <li class="text-center">Duration</li>
                        <!--                        <li class="text-center"><i class="fa fa-headphones"></i></li>-->
                        <li class="text-center">Favourite</li>
                        <li class="text-center">More</li>
                        <li class="text-center">Buy</li>
                    </ul>
                    <ul v-for="(song, key) in album.songs" :class="isPlay && playerCurrentTrack.id === song.id ? 'play_active_song' : ''">
                        <li>
                            <a href="javascript:" class="mmn-play" @click="handleClickTrack(song, album.songs)">
                                <span class="play_no">{{ $_.padStart(key + 1, 2, 0) }}</span>
                                <span class="play_hover"></span>
                            </a>
                        </li>
                        <li><a href="javascript:" class="mmn-play" @click="handleClickTrack(song, album.songs)">{{ song.name_mm }}</a></li>
                        <li>
                            <a v-if="song.artists.length > 3" href="javascript:">
                                <span>{{ song.artists[0].name_eng }}, {{ song.artists[1].name_eng }}, ...</span>
                            </a>
                            <template v-for="(artist, artist_index) in song.artists" v-else>
                                <router-link :to="{ name: 'artist', params : {id : artist.uuid}}">{{ artist.name_eng }}
                                    <span v-if="isNotLast(song.artists, artist_index)">, </span>
                                </router-link>
                            </template>
                            <template v-if="song.feats.length">
                                <a href="javascript:">, ft : </a>
                                <template v-for="(feat, feat_index) in song.feats">
                                    <router-link :to="{ name : 'artist', params:{ id : feat.uuid }}">{{ feat.name_eng }}
                                        <span v-if="isNotLast(song.feats, feat_index)">, </span>
                                    </router-link>
                                </template>
                            </template>
                        </li>
                        <li class="text-center"><a href="javascript:">{{ song.duration }}</a></li>
                        <!--                        <li class="text-center"><a href="javascript:">{{ song.play_count }}</a></li>-->
                        <template v-if="isAuthenticated">
                            <li class="text-center"><a href="#" @click.prevent="favouriteSong(song,song.id)"><span :class="song.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span> </a></li>
                            <li class="text-center ms_more_icon"><a href="javascript:"><span class="ms_icon1 ms_active_icon"></span></a>
                                <ul class="more_option">
                                    <li><a href="#" @click.prevent="saveSong(song.id)"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                    <li><a href="javascript:;" @click.prevent="shareSongFacebook('/album/' + song.album_uuid+'?share='+song.name_eng)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                    <li><a href="javascript:;" @click.prevent="shareSongTwitter('/album/' + song.album_uuid+'?share='+song.name_eng)"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                    <li><a href="#" @click.prevent="copyText('/album/' + song.album_uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                </ul>
                            </li>
                            <li v-if="song.is_bought" class="text-center">
                                <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                    <span class="ms_close"><img src="/images/svg/download.svg" alt=""></span>
                                </a>
                            </li>
                            <li v-else-if="album.is_store" class="text-center">
                                <template v-if="song.price > 0">
                                    <a href="javascript:" @click="buySong(song.id)">{{ song.price.toLocaleString() }} Kyat</a>
                                </template>
                                <template v-else-if="song.price === 0">
                                    <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                    <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                        <span class="ms_close"><img src="/images/svg/download.svg" alt=""></span>
                                    </a>
                                </template>
                            </li>
                            <li></li>
                        </template>
                        <template v-else>
                            <li class="text-center"><a href="#" data-toggle="modal" data-target="#loginModal"><span class="fa fa-heart"></span> </a></li>
                            <li class="text-center ms_more_icon">
                                <a href="javascript:"><span class="ms_icon1 ms_active_icon"></span></a>
                                <ul class="more_option">
                                    <li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                    <li><a href="javascript:;" @click.prevent="shareSongFacebook('/album/' + song.album_uuid+'?share='+song.name_eng)"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                    <li><a href="javascript:;" @click.prevent="shareSongTwitter('/album/' + album.uuid+'?share='+song.name_eng)"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                    <li><a href="#" @click.prevent="copyText('/album/' + album.uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                </ul>
                            </li>
                            <li v-if="album.is_store" class="text-center">
                                <a href="#" v-if="song.price > 0" data-toggle="modal" data-target="#loginModal">{{ song.price.toLocaleString() }} Kyat</a>
                                <a href="#" v-else-if="song.price === 0" data-toggle="modal" data-target="#loginModal" class="song_download">
                                    <span class="ms_close"><img src="/images/svg/download.svg" alt=""></span>
                                </a>
                            </li>
                            <li v-else class="text-center">
                                <a href="#" v-if="song.price > 0" data-toggle="modal" data-target="#loginModal">{{ song.price.toLocaleString() }} Kyat</a>
                                <a href="#" v-else data-toggle="modal" data-target="#loginModal" class="song_download">
                                    <span class="ms_close"><img src="/images/svg/download.svg" alt=""></span>
                                </a>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <!---Main Content Start--->
        <div class="ms_content_wrapper ms_album_content" v-if="relatedAlbums.length && !related_album_loading">
            <!----Featured Albumn Section Start---->
            <div class="ms_fea_album_slider padder_top5">
                <div class="ms_heading">
                    <h1>Related Album</h1>
                </div>
                <div class="row" v-show="related_album_loading">
                    <div class="col-md-2" style="display: inline-block">
                        <div class="load-music-box"></div>
                    </div>
                    <div class="col-md-2" style="display: inline-block">
                        <div class="load-music-box"></div>
                    </div>
                    <div class="col-md-2" style="display: inline-block">
                        <div class="load-music-box"></div>
                    </div>
                    <div class="col-md-2" style="display: inline-block">
                        <div class="load-music-box"></div>
                    </div>
                    <div class="col-md-2" style="display: inline-block">
                        <div class="load-music-box"></div>
                    </div>
                    <div class="col-md-2" style="display: inline-block">
                        <div class="load-music-box"></div>
                    </div>
                </div>
                <div v-show="!related_album_loading" class="ms_related_album_slider swiper-container">
                    <swiper :options="swiperOption" ref="mySwiper">
                        <!-- slides -->
                        <template v-for="album in relatedAlbums">
                            <swiper-slide>
                                <div class="ms_rcnt_box">
                                    <div class="ms_rcnt_box_img">
                                        <img :src="album.image" alt="" onError="this.onerror=null;this.src='/images/album/album.jpg';">
                                        <div class="ms_main_overlay">
                                            <router-link tag="a" :to="{ name: 'Album', params: { uuid: album.uuid }}" :title="album.name_mm">
                                                <div class="ms_box_overlay"></div>
                                            </router-link>
                                            <div class="ms_more_icon" data-toggle="popup">
                                                <img src="/images/svg/more.svg" alt="">
                                            </div>
                                            <div class="ms_play_icon">
                                            </div>
                                        </div>
                                    </div>
                                    <ul class="more_option">
                                        <template v-if="isAuthenticated">
                                            <li><a href="#" @click.prevent="saveAlbum(album.uuid)"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                            <li><a href="#" @click.prevent="syncAlbumFavourite(album.uuid)"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites </a></li>
                                            <li v-if="album.is_store && album.price > 0 && !album.is_bought">
                                                <a href="#" @click="buyAlbum(album.uuid)"><span class="opt_icon"><span class="fa fa-shopping-cart"></span></span>{{ album.price }} Kyat</a>
                                            </li>
                                        </template>
                                        <template v-else>
                                            <li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                            <li><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites </a></li>
                                            <li v-if="album.is_store && album.price > 0">
                                                <a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-shopping-cart"></span></span>{{ album.price }} Kyat</a>
                                            </li>
                                        </template>
                                        <li><a :href="shareFacebookLink('https://melomm.com/album/' + album.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share</a></li>
                                        <li><a :href="shareTwitterLink('https://melomm.com/album/' + album.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share</a></li>
                                    </ul>
                                    <div class="ms_rcnt_box_text">
                                        <h3>
                                            <router-link :to="{ name: 'Album', params: { uuid: album.uuid }}">{{ album.name_mm }}</router-link>
                                        </h3>
                                        <p>
                                            <template v-if="album.artists.length > 3">
                                                <a href="javascript:">Various Artists</a>
                                            </template>
                                            <template v-else>
                                                <template v-for="(artist, index1) in album.artists">
                                                    <router-link :to="{ name : 'artist', params:{ id : artist.uuid }}">{{ artist.name_eng }} <span v-if="isNotLast(album.artists, index1)">, </span></router-link>
                                                </template>
                                            </template>
                                        </p>
                                    </div>
                                </div>
                            </swiper-slide>
                        </template>
                    </swiper>
                </div>
                <!-- Add Arrows -->
                <div class="swiper-button-next-related slider_nav_next"></div>
                <div class="swiper-button-prev-related slider_nav_prev"></div>
            </div>
            <!----Main div close---->
        </div>
    </layout>
</template>
