<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'change-password',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Change Password',
            }
        },

        /**
         * Injecting Parent Validator
         * Don't remove it.
         *
         * https://baianat.github.io/vee-validate/concepts/injections.html
         * https://vuejs.org/v2/api/#provide-inject
         */
        inject: [ '$validator' ],

        /**
         * The component's data.
         */
        data: () => ({
            old_password: null,
            password : null,
            password_confirmation : null,
        }),

        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                isAuthenticated: 'isAuthenticated',
                user : 'getProfile',
            }),
        },

        methods: {
            changePassword: function () {
                this.$validator.validateAll().then((result) => {
                    if (!result){
                        this.$toast.error({ title: 'Error', message: "Please, check your inputs." });
                        throw new Error("Please, check your inputs.");
                    }else {
                        const { old_password, password, password_confirmation } = this;

                        axios.post('/api/mmn/change-password', { old_password, password, password_confirmation }).then((response) => {

                        }).catch((error) => {
                            if (error.response.status === 422) {
                                this.$setErrorsFromResponse(error.response.data);
                            }
                        })
                    }
                }).catch(() => {

                });
            }
        }
    }
</script>

<template>
    <layout>

        <div class="padder_top80 ms_profile">

            <main-header/>

            <div class="ms_profile_wrapper">
                <h1>Change Password</h1>
                <div class="ms_profile_box">
                    <div class="ms_pro_form">
                        <form method="POST" @submit.prevent="changePassword()">
                            <div class="form-group">
                                <label>Current password *</label>
                                <input v-validate="'required'" data-vv-as="Old password" v-model="old_password" type="password" name="old_password" placeholder="Current password" :class="hasError('old_password')">

                                <span v-show="errors.has('old_password')" class="invalid-feedback text-left" role="alert">
                                        <strong>{{ errors.first('old_password') }}</strong>
                                    </span>
                            </div>
                            <div class="form-group">
                                <label>New Password *</label>
                                <input v-validate="'required'" v-model="password" type="password" name="password" placeholder="New Password" class="form-control" :class="hasError('password')">
                                <span v-show="errors.first('password')" class="invalid-feedback text-left" role="alert">
                                        <strong>{{ errors.first('password') }}</strong>
                                    </span>
                            </div>
                            <div class="form-group">
                                <label>Confirm Password *</label>
                                <input v-validate="'required'" v-model="password_confirmation" data-vv-as="password confirmation" type="password" name="password_confirmation" placeholder="Re-type new password" :class="hasError('password_confirmation')">
                                <span v-show="errors.first('password_confirmation')" class="invalid-feedback text-left" role="alert">
                                        <strong>{{ errors.first('password_confirmation') }}</strong>
                                    </span>
                            </div>
                            <div class="pro-form-btn text-center marger_top15">
                                <button type="submit" class="ms_btn">save</button>
                                <router-link to="/user/profile" class="ms_btn">cancel</router-link>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>


    </layout>
</template>
