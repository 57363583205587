
<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import {bus} from "../../app";

    export default {
        name: "PlaylistCreateModal",
        computed: {
            ...mapGetters({
                isAuthenticated: 'isAuthenticated',
                song_uuid: 'getSongId'
            }),
        },
        data:()=>({
            title:null,
            description:'',
            checked:true,
        }),
        methods:{
            /**
             * Add Playlist
             */
            new_song_playlist() {

                if(this.title != null ) {
                    axios.post('/api/mmn/save/song/my-play-list',{name:this.title,description:this.description,song_id:this.song_uuid,checked:this.checked}).then((response) => {
                        $('#playlistCreateSongModal').modal('hide');
                        bus.$emit('my_playlist',response.data.data);
                        this.$toast.success({ title: 'Success', message: response.data.message });
                        this.title=null;
                    }).catch((error) => {
                    });
                    // this.closeModal();
                }
            },
            /**
             * Close Modal
             */
            closeModal() {
                this.$nextTick(() => {
                    this.modal('hide');
                });
            },
        }
    }
</script>

<template>
    <div class="ms_save_modal">
        <div id="playlistCreateSongModal" class="modal centered-modal" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal">
                            <i class="fa_icon form_close"></i>
                        </button>
                        <div class="modal-body">
                            <h1>New Playlist</h1>
                            <div class="row">
                                <div class="col-12 pull-left">
                                    <form @submit.prevent="new_song_playlist()">
                                        <div class="form-group mt-2">
                                            <input type="text" v-model="title" class="form-control" name="name" style="border-radius: 10px" placeholder="Title .....">
                                        </div>
                                        <div class="form-group">
                                            <label>Description</label>
                                            <textarea v-model="description" class="form-control" rows="3" style="height: 70px;"></textarea>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 offset-0">
                                                <div class="form-group">
                                                    <input type="radio" id="public" v-model="checked" :value="true" checked name="checked"><label for="public" style="margin-left: 5px;">Public</label>
                                                    <input type="radio" id="private" v-model="checked" :value="false" name="checked"><label for="private" style="margin-left: 5px">Private</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clr_modal_btn">
                                            <a href="#" @click.prevent="new_song_playlist()" id="addplaylistbtn" style="color: rgba(24,170,2,0.97)">Add</a>
                                            <a href="#" data-dismiss="modal">Close</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>