<script type="text/ecmascript-6">

    export default {

        props: {
            ads: {

            }
        },

    }
</script>

<template>
    <div class="modal" id="adsModal" tabindex="-1" role="dialog">
        <!-- Modal Content (The Image) -->
        <div class="modal-content">
            <!-- The Close Button -->
            <span class="close" data-dismiss="modal">&times;</span>
            <a :href="ads.url"><img id="img01" class="img-fluid" :src="ads.image"></a>
        </div>

    </div>
</template>

<style scoped>
    /* Style the Image Used to Trigger the Modal */
    #adsModal {
        cursor: pointer;
        transition: 0.3s;
    }

    /* The Modal (background) */
    .modal {
        position: fixed; /* Stay in place */
        /*padding-top: 100px; !* Location of the box *!*/
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
        z-index:10500;
    }

    /* Modal Content (Image) */
    .modal-content {
        padding: 0;
        margin: auto;
        display: block;
        height:100%;
        background-color: transparent;
    }
    .modal .modal-content a{
        display:flex;
        justify-content:center;
        padding:10px 0;
    }
    .modal .modal-content a img{
            width:20%;
            top: 12%;
            position: fixed;
        }
    /* Add Animation - Zoom in the Modal */
    .modal-content, #caption {
        animation-name: zoom;
        animation-duration: 0.6s;
    }

    @keyframes zoom {
        from {transform:scale(0)}
        to {transform:scale(1)}
    }

    /* The Close Button */
    .close {
        position: absolute;
        top: 15px;
        right: 15px;
        color: #f1f1f1;
        font-size: 60px;
        font-weight: 200;
        line-height: inherit;
        opacity: 1;
        z-index:99;
    }

    .close:hover,
    .close:focus {
        color: #EEEEEE;
        text-decoration: none;
        cursor: pointer;
    }

    /* 100% Image Width on Smaller Screens */
    @media only screen and (max-width: 700px){
        .modal-content {
            width: 100%;
        }
        .modal .modal-content a img{
            width: 80vw;
            object-fit: contain;
            height: 100vh;
            top: 0%;
            padding:15px 0;
        }
    }
    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) and (max-width: 991.98px) {
        .modal .modal-content a img{
            width:60%;
            top: 5%;
            position: fixed;
        }
    }

    .ads-image {
        width: 100vw;
        object-fit: contain;
        height: 100vh;
    }
</style>
