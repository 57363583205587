<script type="text/ecmascript-6">
    import Layout from '../../layouts/Index.vue'
    import MainHeader from '../../layouts/Header.vue'
    import {mapGetters} from 'vuex';

    export default {
        name: 'relative_playlist',

        components: {
            Layout,
            MainHeader
        },

        /**
         * The component's data.
         */
        data: () => ({
            count: 0,
            playlists: null,
            loading: false,
            show: false,
        }),


        /**
         * Created the component.
         */
        created() {
            this.fetchRelativePlaylist();
        },


        computed: {
            ...mapGetters({
                isAuthenticated: 'isAuthenticated',
                user : 'getProfile',
            }),
        },

        methods: {
            fetchRelativePlaylist(){
                this.loading=true;
                axios.get(`/api/mmn/relative-playlist/${this.$route.params.artist_id}`).then((response) => {
                    this.playlists = response.data.related_playlists;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            }

        }

    }
</script>

<template>
     <layout>

        <!---Main Content Start--->
        <div class="ms_content_wrapper ms_profile padder_top50">

            <main-header/>

            <div class="ms_fea_album_slider padder_bottom30" id="playlists">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="ms_heading">
                            <h1>Related Playlists</h1>
                        </div>
                    </div>

                    <div v-for="item in playlists" class="cs-lg-col-8 col-md-2 col-6">
                        <div class="ms_rcnt_box" style="margin-bottom: 30px;">
                            <div class="ms_rcnt_box_img">
                                <img :src="item.image" alt="" onError="this.onerror=null;this.src='/images/album/collection_playlist.png';">
                                <div class="ms_main_overlay">
                                    <router-link tag="a" :to="{ name: 'playlist-detail', params: { id: item.uuid }}" :title="item.name_mm">
                                        <div class="ms_box_overlay"></div>
                                    </router-link>
                                    <div class="ms_play_icon">
                                        <a href="javascript:;" @click.prevent="playPlaylistSong(item.uuid)">
                                            <img src="/images/svg/play2.svg" alt="">
                                        </a>
                                    </div>
                                    <div class="ms_more_icon" data-toggle="popup">
                                        <img src="/images/svg/more.svg" alt="">
                                    </div>
                                </div>
                            </div>
                            <ul class="more_option">
                                <li><a :href="shareFacebookLink(item.image,'playlists',item.name_mm,item.id,item.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share to facebook</a></li>
                                <li><a :href="shareTwitterLink(item.image,'playlists',item.name_mm,item.id,item.uuid)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share to twitter</a></li>
                                <li><a href="#" @click.prevent="textCopy(item.image,'playlists',item.name_mm,item.id,item.uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                            </ul>
                            <div class="ms_rcnt_box_text">
                                <h3><router-link tag="a" :to="{ name: 'playlist-detail', params: { id: item.uuid }}">{{ item.name_mm }}</router-link></h3>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="more_loading" v-show="loading">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>
            </div>
        </div>

    </layout>
</template>
