import { render, staticRenderFns } from "./MyPlaylistSongs.vue?vue&type=template&id=a5fb5364&scoped=true&"
import script from "./MyPlaylistSongs.vue?vue&type=script&lang=js&"
export * from "./MyPlaylistSongs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5fb5364",
  null
  
)

export default component.exports