<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'recent',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Recent',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            songs: [],
            selected: [],
            cover:{
                url: "/images/Recent_Song.png",
            },
            links: {
                first: null,
                last: null,
                next: "/api/mmn/recent?page=1",
                prev: null
            },
            meta: {
                current_page: 1,
                from: 1,
                last_page: 1,
                per_page: 1,
                to: 1,
                total: 1,
            },
        }),


        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                isPlay: 'isPlay',
                playerCurrentTrack: 'playerCurrentTrack',
                isAuthenticated: 'isAuthenticated'
            }),

            selectAll: {
                get: function() {
                    return this.songs ? this.selected.length == this.songs.length : false;
                },
                set: function (value) {
                    var checked = [];

                    if (value) {
                        this.songs.forEach(function (song) {
                            checked.push(song.id);
                        });
                    }

                    this.selected = checked;
                }
            }
        },


        /**
         * Created the component.
         */
        created() {
            this.fetchSongs();
            this.fetchCover();
        },

        methods: {
            /**
             *  Get the recent songs.
             */
            async fetchCover(){
                axios.get(`/api/v2/cover-img/recent`).then((response) => {
                    this.cover = response.data;
                }).catch((error) => {
                    this.cover.url = '/images/Recent_Song.png';
                })
            },

            async fetchSongs() {
                // if (this.loading === true && this.links.first) {
                //     this.$toast.warn({ title: 'Warning', message: 'Loading ...' });
                //     return;
                // }

                // if (this.links.next === null) {
                //     return;
                // }

                this.loading = true;
                axios.get(this.links.next).then((response) => {
                    this.songs.push(...response.data.data);
                    this.links = response.data.links;
                    this.meta = response.data.meta;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

            deleteRecent(song_id, index) {
                axios.delete(`/api/mmn/recent/song/${song_id}`).then((response) => {
                    this.songs.splice(index, 1);
                    this.$toast.success({ title: 'Success', message: response.data.message });
                }).catch((error) => {
                    this.$toast.warn({ title: 'Warning', message: error.response.data.message });
                })
            },

            deleteCheckedRecent() {
                if (this.selected.length > 0) {
                    axios.post(`/api/mmn/recent/selected-song`, {selected_recent: this.selected}).then((response) => {
                        for (var id of this.selected) {
                            var index = this.songs.findIndex(item => item.id === id);
                            if (index !== -1) {
                                this.songs.splice(index, 1);
                            }
                        }
                        this.$toast.success({ title: 'Success', message: response.data.message });
                    }).catch((error) => {
                        this.$toast.warn({ title: 'Warning', message: error.response.data.message });
                    })
                } else {
                    this.$toast.warn({ title: 'Warning', message: 'You need to select recent songs to delete.' });
                }

            },
            deleteAllRecent() {
                if (this.songs.length > 0) {
                    axios.post(`/api/mmn/recent/delete_all`).then((response) => {
                        this.songs=[];
                        this.$toast.success({ title: 'Success', message: response.data.message });
                    }).catch((error) => {
                        this.$toast.warn({ title: 'Warning', message: error.response.data.message });
                    })
                } else {
                    this.$toast.warn({ title: 'Warning', message: 'You does not have recent songs to delete.' });
                }
            },
            bgImage(image){
                return 'background-image: url("' + image + '")';
            }
        }
    }
</script>

<template>
    <layout>

        <!----Album Single Section Start---->
        <div class="ms_album_single_wrapper ms_profile">

            <main-header/>
            <div class="album-cover-wrapper" style="filter: blur(120px);height:320px;position:absolute;width: 100%;background-size: cover;background-position:center;opacity:0.4;" v-show="!loading" :style="bgImage(cover.url)"></div>

           <!--  <button v-if="songs.length > 0" class="btn btn-danger pull-right ml-2" @click="deleteAllRecent()"><i class="fa fa-trash-o"></i> Clear All Songs</button>

            <button v-if="songs.length > 0" class="btn btn-danger pull-right ml-2" @click="deleteCheckedRecent()"><i class="fa fa-trash-o"></i> Clear Selected Songs</button> -->

            <div class="album-cover-wrapper" v-show="!loading">
                <div class="album_single_data">
                    <div class="album_single_img">
                        <img :src="cover.url" alt="" class="img-fluid">
                    </div>
                    <div class="album_single_text">
                        <h2>Your Recent Songs</h2>
                        <p style="margin-bottom: 0.5rem">
                            <a href="#" class="album_date mt-2"><span class="fa fa fa-music" style="font-size:18px;margin-right: 8px;position: relative;"></span>{{ songs.length }} Song<span v-if="songs.length > 1">s</span></a>
                        </p>
                        <div class="album_feature">

                            <div class="btn-group dropright mt-5">
                                <template v-if="playerCurrentTrack != null && songs.findIndex(x => x.id === playerCurrentTrack.id) >= 0 && isPlay">
                                    <a @click.prevent="$store.dispatch(isPlay ? 'pause' : 'play')" href="javascript:;" class="custom_play_btn"><span class="fa icon_pause_btn"></span><span style="padding: 5px;">Pause</span></a>
                                </template>
                                <template v-else>
                                    <a @click.prevent="handleClickTrack(songs[0],songs)" href="javascript:;" class="custom_play_btn"><span class="fa fa fa-play"></span><span style="padding: 5px;">Play All</span></a>
                                </template>
                                <a @click.prevent="deleteAllRecent()" href="javascript:;" class="custom_btn_style ml-2"><span class="fa fa fa-trash"></span><span style="padding: 5px;">Delete All</span></a>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div class="album_single_data ms_purchase_wrapper text-left" v-show="!loading">
                <!-- <div class="ms_heading">
                    <h1>Your Recent Songs</h1>
                </div> -->

                <!----Song List---->
                <div class="album-track-wrapper album_inner_list">
                    <div data-v-e84545ce="" style="background: #1e1e25;position: absolute;top: 0;width: 100%;height: 100%;z-index: -1;left: 0;"></div>
                    <div class="album_list_wrapper album_track_content">
                        <h5 style="padding:20px;">Tracks<span style="font-size:11px;margin-left: 5px;">Total - {{ songs.length }} song<span v-if="songs.length > 1">s</span></span></h5>

                        <!-- <div v-show="!loading"> -->
                            <ul class="track_ul" v-for="(song, key) in songs" :class="isPlay && playerCurrentTrack.id === song.id ? 'play_active_song' : ''" v-show="!loading">

                                <!-- <li><input type="checkbox" :id="song.id" :value="song.id" v-model="selected"></li> -->

                                <li style="padding-top: 10px;top:-5px;">
                                    <a href="javascript:;" class="mmn-play" @click="handleClickTrack(song, songs)">
                                        <span class="play_no" style="position: absolute;top: 0;">{{ $_.padStart(key + 1, 2, 0) }}</span>
                                        <div class="ms_bars for_track_ul" v-if="isPlay && playerCurrentTrack.id === song.id">
                                            <div class="bar"></div>
                                            <div class="bar"></div>
                                            <div class="bar"></div>
                                            <div class="bar"></div>
                                            <div class="bar"></div>
                                            <div class="bar"></div>
                                        </div>
                                        <span class="play_hover" v-else></span>
                                    </a>
                                </li>

                                <li><a href="javascript:;" class="mmn-play" @click="handleClickTrack(song, songs)"><span class="song_title">{{ song.name_mm }}</span>
                                    <p style="font-size:10px;margin-bottom: 5px;">
                                        <a v-if="song.artists.length > 3" href="javascript:">
                                            <span>{{ song.artists[0].name_eng }}, {{ song.artists[1].name_eng }}, ...</span>
                                        </a>
                                        <template v-for="(artist, artist_index) in song.artists" v-else>
                                            <router-link :to="{ name: 'artist', params : {id : artist.uuid}}">{{ artist.name_eng }}
                                                <span v-if="isNotLast(song.artists, artist_index)">, </span>
                                            </router-link>
                                        </template>
                                        <template v-if="song.feats.length">
                                            <a href="javascript:">, ft : </a>
                                            <template v-for="(feat, feat_index) in song.feats">
                                                <router-link :to="{ name : 'artist', params:{ id : feat.uuid }}">{{ feat.name_eng }}
                                                    <span v-if="isNotLast(song.feats, feat_index)">, </span>
                                                </router-link>
                                            </template>
                                        </template>
                                    </p>
                                </a></li>

                                <li style="top: -10px;"><a href="javascript:;">{{ song.duration }}</a></li>
                                <li class="text-center" style="top: -10px;"><a href="#" @click.prevent="favouriteSong(song,song.id)"><span :class="song.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span> </a></li>

                                <li v-if="song.is_bought" class="text-center" style="top: -10px;">
                                    <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                    <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                        <span class=""><img src="/images/svg/download_1.svg" alt="" style="width: 15px;"></span>
                                    </a>
                                </li>

                                <li v-else-if="song.is_store" class="text-center" style="top: -10px;">
                                    <a href="javascript:;" @click="buySong(song.id)"><i class="fa icon_buy_dollar"></i></a>
                                </li>

                                <li v-else-if="song.is_free" class="text-center" style="top: -10px;">
                                    <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw" style="font-size: 18px;" v-show="download_songs.includes(song.id)"></i>
                                    <a href="#" @click.prevent="downloadSong(song.id)" class="song_download" v-show="!download_songs.includes(song.id)">
                                        <span class=""><img src="/images/svg/download_1.svg" alt="" style="width: 15px;"></span>
                                    </a>
                                </li>

                                 <li class="text-center ms_more_icon" style="top: -11px;"><a href="javascript:;"><span class="fa icon_menu_more"></span></a>
                                    <ul class="more_option album_more">
                                        <template v-if="isAuthenticated">
                                            <li style="width:100% !important;padding: 10px 10px;"><a href="#" @click.prevent="saveSong(song.id)"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                            <!-- <li style="width:100% !important;padding: 10px 10px;"><a href="#" @click.prevent="favouriteSong(song,song.id)"><span class="opt_icon"><span :class="song.is_favourite ? 'fa fa-heart' : 'fa fa-heart-o'"></span></span>Add To Favourites </a></li> -->
                                        </template>
                                        <template v-else>
                                            <li style="width:100% !important;padding: 10px 10px;"><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="fa fa-plus"></span></span>Add To Playlist </a></li>
                                            <!-- <li style="width:100% !important;padding: 10px 10px;"><a href="#" data-toggle="modal" data-target="#loginModal"><span class="opt_icon"><span class="icon icon_fav"></span></span>Add To Favourites </a></li> -->
                                        </template>
                                        <li style="width: 100% !important ;padding: 10px 10px;"><router-link :to="{ name: 'Album', params: { uuid: song.album_uuid }}"><span class="opt_icon"><img src="/images/svg/go_to_album.svg" style="width:18px;margin-right:7px;"/></span>Go To Album </router-link></li>
                                        <li style="width:100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongFacebook(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-facebook-official"></span></span>Share To Facebook</a></li>
                                        <li style="width:100% !important;padding: 10px 10px;"><a href="javascript:;" @click.prevent="shareSongTwitter(song.share_url)" target="_blank"><span class="opt_icon"><span class="fa fa-twitter-square"></span></span>Share To Twitter</a></li>
                                        <li style="width:100% !important;padding: 10px 10px;"><a href="#" @click.prevent="copyText('album/'+song.album_uuid)"><span class="opt_icon"><span class="fa fa-copy"></span></span>Copy link</a></li>
                                    </ul>
                                </li>

                                <!-- <li class="text-center">
                                    <a href="javascript:;" @click.prevent="deleteRecent(song.id, key)" title="Remove">
                                        <span class="ms_close"><img src="/images/svg/close.svg" alt=""></span></a>
                                </li> -->

                            </ul>
                        <!-- </div> -->
                        

                    </div>
                    
                </div>
                <div class="ms_view_more padder_bottom20" v-show="meta.current_page !== meta.last_page && songs.length > 0">
                        <a href="javascript:;" @click.prevent="fetchSongs()" class="ms_btn">view more</a>
                    </div>
            </div>

            <div style="height:100vh;align-items: center;display: flex;margin-top: -10%;" v-show="loading">
                <div class="more_loading">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>
            </div>

        </div>


    </layout>
</template>

<style scoped>
    .ms_close {
        width: 20px;
        height: 20px;
        background-color: #ff4865;
        display: inline-block;
        line-height: 20px;
        border-radius: 100%;
        text-align: center;
    }

    .recent_list_wrapper > ul > li:first-child,
    .recent_list_wrapper > ul > li:nth-child(2),
    .recent_list_wrapper > ul > li:nth-child(7),
    .recent_list_wrapper > ul > li:nth-child(9) {
    width: 5%;
    }
    .recent_list_wrapper > ul > li:nth-child(3),
    .recent_list_wrapper > ul > li:nth-child(4) {
     width: 20%;
    }
    .recent_list_wrapper > ul > li:nth-child(5) {
     width: 15%;
    }
    .recent_list_wrapper > ul > li:nth-child(6),
    .recent_list_wrapper > ul > li:nth-child(8) {
     width: 10%;
    }
</style>
