export default {
  SET_PLAYER: (state, player) => {
    state.player = player;
  },
  PLAY: (state) => {
    state.isPlay = true;
  },
  PAUSE: (state) => {
    state.isPlay = false;
  },
  SET_PLAYER_CURRENT_TIME: (state, time) => {
    state.playerCurrentTime = time;
  },
  SET_PLAYER_DURATION: (state, time) => {
    state.playerDuration = time;
  },
  SET_PLAYER_TRACKS: (state, tracks) => {
    localStorage.playerTracks = JSON.stringify(tracks);
    localStorage.playerOriginalTracks = JSON.stringify(tracks);
    state.playerTracks = tracks;
    state.playerOriginalTracks = tracks;
  },
  SET_SHUFFLE_TRACKS: (state, shuffleTracks) => {
    localStorage.playerTracks = JSON.stringify(shuffleTracks);
    state.playerTracks = shuffleTracks;
  },
  SET_ORIGINAL_TRACKS: (state) => {
    localStorage.playerTracks = JSON.stringify(state.playerOriginalTracks);
    state.playerTracks = state.playerOriginalTracks;
  },
  SET_PLAYER_CURRENT_TRACK: (state, track) => {
    localStorage.playerCurrentTrack = JSON.stringify(track);
    state.playerCurrentTrack = track;
  },
  SET_PLAYER_SEEKING: (state, seeking) => {
    state.playerSeeking = seeking;
  },
  INITIALISE_STORE(state) {

    if(localStorage.playerCurrentTrack) {
      state.playerCurrentTrack = JSON.parse(localStorage.playerCurrentTrack);
    }

    if(localStorage.playerTracks) {
      state.playerTracks = JSON.parse(localStorage.playerTracks);
    }

    if (localStorage.playerOriginalTracks) {
      state.playerOriginalTracks = JSON.parse(localStorage.playerOriginalTracks);
    }
  },
  REMOVE_TRACKS(state) {
    if (!state.isPlay) {
      localStorage.removeItem('playerCurrentTrack');
      localStorage.removeItem('playerTracks');
      localStorage.removeItem('playerOriginalTracks');

          state.playerTracks = [];
          state.playerOriginalTracks = [];
          state.playerCurrentTrack= null;
    }
  }
};
