
import Vue from 'vue';
window._ = require('lodash');
import moment_js from 'moment';
window.Cookies = require('js-cookie');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
    require('jquery-nice-select');
    require('malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min');

    require('./theme/main.js');

} catch (e) {}


Object.defineProperty(Vue.prototype, '$_', { value: _ });
Object.defineProperty(Vue.prototype, 'moment', { value: moment_js });

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Api Token
 */
window.axios.defaults.headers.common['api-key'] = 'yC5wfrIBSh7hrIHd4tvPmsAdId0cQwqkQjf3frqtdCP5Sd7Kbj2tGjyDCp4vJrgB';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

let bearer = Cookies.get('access_token');

if (bearer) {
    window.axios.defaults.headers.common['Authorization'] = bearer
}

Number.prototype.format = function(n, x) {
    if (isNaN(n)){
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
        return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
    }

    return 0;
};
