<script type="text/ecmascript-6">
    import Cookies from 'js-cookie'
    import {mapGetters} from 'vuex';
    import moment from 'moment';

    export default {
        props:{
            notifications:{
                type:Array,
            },
            upcoming:{
                type:Array,
            },
            routeToNotification:{
                type: Function
            }
        },
        data: () => ({
            like_count:0,
        }),

        computed: {
            ...mapGetters({
                isAuthenticated: 'isAuthenticated',
            }),
            filterNotification(){
                return this.notifications;
            },
            filterUpcomingNotification(){
                return this.upcoming;
            },
        },
        created(){
        },

        methods: {
           like(id,index){ 
                axios.post('/api/v2/like-notifications/'+id).then((response) => {
                    this.upcoming[index].intrest = response.data.count; 
                    this.upcoming[index].isLiked = response.data.status; 
                });
           },
           hideNotification(id,index){
                axios.post('/api/v2/hide-notification/'+id).then((response) => {
                    if(response.data.status){
                        this.notifications.splice(index , 1);
                        this.upcoming.splice(index , 1);
                    }
                });
           },
        }
    }
</script>

<template>
    <section id="tabs">
        <div class="container">
            <div class="row pl-2 pr-2">
                <div class="col-xs-12 w-100">
                    <nav>
                        <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">My Notification</a>
                            <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Upcoming</a>
                        </div>
                    </nav>
                    <div class="tab-content px-sm-0" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <template v-for="(notification,index) in filterNotification">
                                <div class="noti-container" :class="{'hover-noti-color':!notification.is_read}">
                                    <div class="noti-box d-flex w-100">
                                        <div style="width:100%">
                                            <div class="d-flex text-white">
                                                <div class="d-flex" style="align-items:center;width:69%;">
                                                    <a href='javascript:;' @click.prevent="routeToNotification(notification)" class="d-flex">
                                                        <img class="noti-image h-100" :src="notification.image" onError="this.onerror=null;this.src='/img/mmn-logo1.png';">
                                                        <div class="noti-header d-flex align-items-center">
                                                            <div class="text-white">
                                                                {{ notification.name }}
                                                                <p class="mt-2" style="font-size: 10px;font-weight:normarl;margin-bottom:0;color:#e1e1e1;font-weight:normal;">{{notification.artists}}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div class="float-right ml-auto" style="font-size: 12px;">
                                                    <img src="/img/melo-logo.png" class="img-fluid" style="width:15px">
                                                {{notification.release_date}}</div>
                                            </div>
                                            <!-- <i class="fa fa-circle" v-if="!notification.is_read"></i> -->
                                            
                                            <div class="noti-body d-flex">
                                                <span class="noti-time" v-html="notification.description" style="width:90%"></span>
                                                <div style="width:5%">
                                                    <a href="javascript:;" data-toggle="dropdown" class="d-block float-right" aria-haspopup="true" aria-expanded="false">
                                                        <span class="fa icon_album_menu_more" style="transform:rotate(90deg);height:10px;margin-top:-25px;background-size:15px;"></span>
                                                    </a>
                                                    <div class="dropdown-menu album_more_detail noti_share" style="background: #28282E;border-radius: 0px;z-index:10;">
                                                        <a class="dropdown-item" style="color: #fff;font-size:12px;padding: 0 20px;" href="javascript:;" @click.prevent="hideNotification(notification.id,index)" target="_blank">Hide</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <template v-for="(notification,index) in filterUpcomingNotification">
                                <div class="noti-container" :class="{'hover-noti-color':!notification.is_read}">
                                    <div class="noti-box d-block w-100">
                                        <div class="d-block">
                                            <div class="d-flex text-white">
                                                <router-link tag="a" :to="{ name: 'UpcomingNotification', params: { id: notification.id }}" :title="notification.name">
                                                    <img class="noti-image img-fluid upcoming-img" :src="notification.image" onError="this.onerror=null;this.src='/img/mmn-logo1.png';">
                                                </router-link>
                                                <div class="ml-2">
                                                    <router-link tag="a" :to="{ name: 'UpcomingNotification', params: { id: notification.id }}" :title="notification.name" class="d-block">
                                                        <span class="noti-header">{{ notification.name }}</span>
                                                    </router-link>
                                                    <p style="font-size: 12px;margin-bottom: 10px;line-height:0;margin-top:5px">
                                                    <i class="fa fa-calendar"></i>
                                                    {{moment(String(notification.schedule_date)).format('DD-MMMM-YYYY')}}</p>
                                                    <p style="font-size: 12px;margin-bottom: 0px;line-height:0;">
                                                    <i class="fa fa-clock"></i>
                                                    {{moment(String(notification.schedule_date)).format('h:mm A')}}</p>
                                                    <div class="noti-btn-group">
                                                        <a href="javascript:;" @click.prevent="like(notification.id,index)" class="noti-upcoming-btn btn-interest"><template><span>interest</span> </template><span class="ml-1" v-if="notification.intrest > 0">{{notification.intrest}}</span></a>
                                                        <div class="d-inline-block dropleft">
                                                            <a href="javascript:;" data-toggle="dropdown" class="noti-upcoming-btn btn-share ml-1" aria-haspopup="true" aria-expanded="false">
                                                                Share
                                                            </a>
                                                            <div class="dropdown-menu album_more_detail noti_share" style="background: #28282E;border-radius: 0px;">
                                                                <a class="dropdown-item" style="color: #fff;font-size:12px;padding: 0 20px;" href="javascript:;" @click.prevent="shareSongFacebook(notification.share_url)" target="_blank">Share To Facebook</a>
                                                                <a class="dropdown-item" style="color: #fff;font-size:12px;padding: 0 20px;" href="javascript:;" @click.prevent="shareSongTwitter(notification.share_url)" target="_blank">Share To Twitter</a>
                                                                <a class="dropdown-item" style="color: #fff;font-size:12px;padding: 0 20px;" href="javascript:;" @click.prevent="copyText('notification/'+notification.id)">Copy Link</a>
                                                            </div>
                                                        </div>
                                                        <div class="d-inline-block dropleft">
                                                            <a href="javascript:;" data-toggle="dropdown" class="ml-2" aria-haspopup="true" aria-expanded="false">
                                                                <span class="fa icon_album_menu_more" style="margin-top:15px;"></span>
                                                            </a>
                                                            <div class="dropdown-menu album_more_detail noti_share" style="background: #28282E;border-radius: 0px;z-index:10;">
                                                                <a class="dropdown-item" style="color: #fff;font-size:12px;padding: 0 20px;" href="javascript:;" @click.prevent="hideNotification(notification.id,index)" target="_blank">Hide</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                           
                                            </div>
                                            <!-- <i class="fa fa-circle" v-if="!notification.is_read"></i> -->
                                            
                                            <div class="noti-body">
                                                <span class="noti-time" v-html="notification.description"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
    </section>
</template>

<style>
    section .section-title {
        text-align: center;
        color: #007b5e;
        margin-bottom: 50px;
        text-transform: uppercase;
    }
    #tabs{
        background: #404040;
        color: #eee;
    }
    .hover-noti-color{
        background:#2C2B27;
    }
    #tabs h6.section-title{
        color: #eee;
    }
    .nav-tabs {
        border-bottom: 1px solid #555 !important;
    }

    #tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        color: #f3f3f3;
        background-color: transparent;
        border-color: transparent transparent #f3f3f3;
        border-bottom: 1px solid !important;
        font-size: 16px;
        font-weight: bold;
    }
    #tabs .nav-tabs .nav-link {
        border: 1px solid transparent;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        color: #eee;
        font-size: 16px;
        padding:0;
    }
    #nav-tabContent{
        max-height:70vh;
        overflow-y:auto;
    }
    #popper-body .noti-box{
        align-items : center;
    }
    #popper-body .noti-box i{
        font-size: 15px;
        padding-top: 0px;
        padding-right: 5px;
        color:#ff2150;
    }
    #popper-body .noti-image{
        padding-top: 5px;
    }
    .popover-body .noti-image.upcoming-img{
        width: 150px;
    }
    #popper-body .noti-header{
        line-height: 1.5rem;
        font-size: 16px;
        font-weight:bold;
        color:#e1e1e1;
    }
    #popper-body .noti-body .noti-time{
        line-height: 1.5rem;
        color:#e1e1e1;
    }
    .noti-upcoming-btn{
        padding: 5px 20px;
        border: 1px solid;
        border-radius: 5px;
        font-size: 12px;
    }
    .btn-interest{
        border:1px solid #ff2150;
        color: #ff2150 !important;
    }
    .btn-share{
        border:1px solid #fff;
        color: #fff !important;
    }
    .noti_share::after{
        content:none !important;
    }
    .fa-clock::before {
        content: "\f017";
    }
    .noti-container:hover{
        background:#404040;
    }
    @media(max-width: 991px){
        #tabs .nav-tabs .nav-link{
            padding: 10px 0;
        }
    }
    @media(max-width:767.99px){
        .popover-body .noti-image.upcoming-img{
            width: 100px;
        }
    }
</style>
