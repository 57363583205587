<script type="text/ecmascript-6">
    import { mapGetters  } from 'vuex';
    import Layout from '../layouts/Index.vue'
    import MainHeader from '../layouts/Header.vue'

    export default {
        name: 'genres',

        components: {
            Layout,
            MainHeader,
        },

        metaInfo() {
            return {
                title: 'Genres',
            }
        },

        /**
         * The component's data.
         */
        data: () => ({
            loading: true,
            genres: [],
        }),


        /**
         * Computed these properties.
         */
        computed: {
            ...mapGetters({
                isAuthenticated: 'isAuthenticated'
            }),
        },


        /**
         * Created the component.
         */
        created() {
            this.fetchGenres();
        },


        methods: {
            /**
             *  Get the genres.
             */
            async fetchGenres() {
                axios.get('/api/mmn/genres').then((response) => {
                    this.genres = response.data.data;
                    this.swiper.slideTo();
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },
        }
    }
</script>

<template>
    <layout>

        <!---Main Content Start--->
        <div class="ms_content_wrapper ms_profile padder_top80">

            <main-header/>

            <div class="ms_fea_album_slider ms_genres_single padder_bottom30" id="genres">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="ms_heading">
                            <h1>Genres & Moods</h1>
                        </div>
                    </div>

                    <div v-for="genre in genres" class="cs-lg-col-8 cs-md-col-5 col-md-2 col-6">
                        <router-link tag="a" :to="{ name: 'genre', params: { id: genre.uuid }}" :title="genre.name">
                            <div class="ms_genres_box">
                                <div class="ms_rcnt_box_img">
                                    <img :src="genre.image" alt="" class="img-fluid" onError="this.onerror=null;this.src='/images/album/collection_playlist.png';">
                                    <div class="ms_main_overlay">
                                        <div class="ms_box_overlay"></div>

                                    </div>
                                </div>
                                <div class="mb-3 genre_title">{{ genre.name }}</div>
                            </div>
                            
                        </router-link>
                    </div>

                </div>

                <div class="more_loading" v-show="loading">
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>

            </div>
        </div>


    </layout>
</template>
